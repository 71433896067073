import React from 'react';
import StyledTextField from './StyledTextField';

const TextArea = ({ label, value, onChange, rows = 3, required = false, ...props }) => (
    <StyledTextField
        inputProps={{
            sx: {
                color: '#626264',
                fontSize: '0.8rem',
                fontWeight: '400'
            },
        }}
        InputLabelProps={{
            sx: {
                color: '#626264',
                fontSize: '0.8rem',
                fontWeight: '400'
            },
        }}
        label={label}
        variant="outlined"
        multiline
        rows={rows}
        value={value}
        onChange={onChange}
        required={required}
        fullWidth
        {...props}
    />
);

export default TextArea;
