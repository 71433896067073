import axios from "axios";



export const getAcademicTestQuestion = async (id, accessToken, restart = false) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/academic/${id}/questions/${restart}`, config);
        console.log('Data:', response.data);
        console.log('Status:', response.status);
        return response.data;
    } catch (error) {
        throw error;
    }
}


