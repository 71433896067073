import React from 'react';
import styled from 'styled-components';
import Checkbox from '../../../../component/shared/Checkbox';

const PopoverContainer = styled.div`
  background-color: white;
  border: 0.1rem solid #e0e0e0;
  border-radius: 0.25rem;
  padding: 1rem;
  width: 300px;
  position: absolute;
  top: 50px;
  z-index: 100;
  font-size: 0.9rem;
  font-weight: 600;
`;

const FilterPopover = ({ title, options, selectedOptions, onOptionChange }) => {
    return (
        <PopoverContainer>
            <h4>{title}</h4>
            {options?.map((option) => (
                <Checkbox
                    key={option}
                    label={option}
                    checked={selectedOptions.includes(option)}
                    onChange={(e) => onOptionChange(option, e.target.checked)}
                />
            ))}
        </PopoverContainer>
    );
};

export default FilterPopover;
