import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { register } from "../../../../functions/api/authentication/register";
import { toast } from "react-toastify";
import validate from "../../../../functions/validate";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const JobListingSignup = ({ handleClose, setTrigger, setOpenLogin }) => {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [contact, setContact] = useState('');
    const [gender, setGender] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleSubmitJobSeeker = async (e) => {
        e.preventDefault();
        try {
            if (!name.trim()) {
                toast.warning("Please Fill all fields");
                return;
            }
            let val = validate(email, password);
            if (val) {
                const registerRes = await register(email, name, password);
                if (registerRes) {
                    toast.success("Successfully signed up");
                    handleClose();
                    setOpenLogin(true);
                }
                else {
                    toast.error("Email already exists");
                }
            }
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    };

    // func for password validation
    useEffect(() => {
        // custom rules
        ValidatorForm.addValidationRule('hasSpecialCharacter', (value) => {
            return /[!@#$%^&*]/.test(value);
        });

        ValidatorForm.addValidationRule('hasCapitalLetter', (value) => {
            return /[A-Z]/.test(value);
        });

        ValidatorForm.addValidationRule('hasSmallLetter', (value) => {
            return /[a-z]/.test(value);
        });

        ValidatorForm.addValidationRule('hasNumberDigit', (value) => {
            return /\d/.test(value);
        });

        ValidatorForm.addValidationRule('hasMinLength', (value) => {
            return value?.length >= 8;
        });

        // Cleanup the rules when component unmounts
        return () => {
            ValidatorForm.removeValidationRule('hasSpecialCharacter');
            ValidatorForm.removeValidationRule('hasCapitalLetter');
            ValidatorForm.removeValidationRule('hasSmallLetter');
            ValidatorForm.removeValidationRule('hasNumberDigit');
            ValidatorForm.removeValidationRule('hasMinLength');
        };
    }, [password]);

    return (
        <StyledSignup>
            <Box sx={{ width: "90%", position: "relative", top: "10rem", margin: "0 1rem", marginBottom: '7rem' }} className='box'>
                <span className="title">Register</span>
                <div id="form">
                    <ValidatorForm onSubmit={handleSubmitJobSeeker}>
                        <div className="inputBox">
                            <TextValidator
                                label="Name"
                                type="name"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                errorMessages={["This field is required", 'Must be a least 3 characters long', 'Must be less than 30 chatacters long']}
                                validators={['required', 'minStringLength:3', 'maxStringLength:29']}
                                fullWidth
                                required
                                inputProps={{
                                    sx: {
                                        color: '#626264',
                                        fontSize: '0.9rem',
                                        fontWeight: '400'
                                    },
                                }}
                                InputLabelProps={{
                                    sx: {
                                        color: '#626264',
                                        fontSize: '0.9rem',
                                        fontWeight: '400'
                                    },
                                }}
                            />
                        </div>


                        <div className="inputBox">

                            <FormControl fullWidth required>
                                <InputLabel id="demo-simple-select-label" style={{ fontSize: '0.9rem', }}>Gender</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={gender}
                                    label="Gender"
                                    onChange={(e) => setGender(e.target.value)}
                                    inputProps={{
                                        sx: {
                                            color: '#626264',
                                            fontSize: '0.9rem',
                                            fontWeight: '400'
                                        },
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            color: '#626264',
                                            fontSize: '0.9rem',
                                            fontWeight: '400'
                                        },
                                    }}
                                >
                                    <MenuItem value='male'>Male</MenuItem>
                                    <MenuItem value='female'>Female</MenuItem>
                                    <MenuItem value='not-disclose'>Rather not disclose</MenuItem>
                                </Select>
                            </FormControl>
                        </div>


                        <div className="inputBox">
                            <TextValidator
                                label="Email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                errorMessages={["This field is required", 'Email is not valid']}
                                validators={['required', 'isEmail']}
                                fullWidth
                                required
                                inputProps={{
                                    sx: {
                                        color: '#626264',
                                        fontSize: '0.9rem',
                                        fontWeight: '400'
                                    },
                                }}
                                InputLabelProps={{
                                    sx: {
                                        color: '#626264',
                                        fontSize: '0.9rem',
                                        fontWeight: '400'
                                    },
                                }}
                            />
                        </div>


                        <div className="inputBox">
                            <TextValidator
                                label="Phone number"
                                type="tel"
                                value={contact}
                                onChange={(e) => setContact(e.target.value)}
                                errorMessages={["This field is required", 'Must be a number', 'Must be at least 10 characters long',]}
                                validators={['required', 'isNumber', 'minStringLength:10']}
                                fullWidth
                                required
                                inputProps={{
                                    sx: {
                                        color: '#626264',
                                        fontSize: '0.9rem',
                                        fontWeight: '400'
                                    },
                                }}
                                InputLabelProps={{
                                    sx: {
                                        color: '#626264',
                                        fontSize: '0.9rem',
                                        fontWeight: '400'
                                    },
                                }}
                            />
                        </div>


                        <div className="inputBox">
                            <TextValidator
                                label="Password"
                                type={passwordVisible ? "text" : "password"}
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                validators={['required', 'hasSpecialCharacter', 'hasCapitalLetter', 'hasSmallLetter', 'hasNumberDigit', 'hasMinLength', 'maxStringLength:29']}
                                errorMessages={['This field is required', 'Must contain a special character', 'Must contain a capital letter', 'Must contain a small letter', 'Must contain a number digit', 'Must be at least 8 characters long', 'Must be less than 30 chatacters long']}
                                fullWidth
                                required
                                inputProps={{
                                    sx: {
                                        color: '#626264',
                                        fontSize: '0.9rem',
                                        fontWeight: '400'
                                    },
                                }}
                                InputLabelProps={{
                                    sx: {
                                        color: '#626264',
                                        fontSize: '0.9rem',
                                        fontWeight: '400'
                                    },
                                }}
                            />
                            <FontAwesomeIcon
                                icon={faEye}
                                id="eye"
                                onClick={togglePasswordVisibility}
                                className={`eye-icon ${passwordVisible ? "visible" : ""}`}
                            />
                        </div>

                        <a className="terms" onClick={() => navigate('/terms')}>By creating an account, you agree to our Terms of Service and Privacy Policy.</a>

                        <button type="submit" className="btn">Create Account</button>
                    </ValidatorForm>
                    <p>
                        Have an account ? <Link to="/login" className="link link1">Sign In</Link>
                    </p>
                </div>
            </Box>
        </StyledSignup>
    );
};

export default JobListingSignup;

const StyledSignup = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  
  .MuiFormHelperText-root {
    font-size: 0.6rem;
  }

  .terms {
    font-size: 0.7rem;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-size: 1.2rem;
    font-weight: 600;
  }

  p {
    font-size: 0.9rem;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    margin-top: 1rem;
    }

    .link {
      color: var(--lightOrange);
      text-decoration: none;
    }

    .link1 {
      color: var(--color);
    }

    .link1:hover {
      font-weight: 600;
      text-decoration: underline;
    }

  #form {
    width: 80%;
    background-color: var(--backgroundColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .inputBox {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-top: 1rem;
  }

  input {
    width: 100%;
    border-radius: 0.3rem;
    background-color: var(--white);
    font-size: 0.9rem;
  }

  label {
    color: var(--color);
    font-size: 0.8rem;
  }

  input:focus {
    outline-color: var(--lightOrange);
  }

  .btn {
    background-color: var(--lightOrange);
    color: var(--backgroundColor);
    font-size: 1.1rem;
    font-weight: 600;
    font-family: var(--font);
    border-radius: 0.4rem;
    border: none;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
  }

  .prev {
    background-color: var(--lightOrange);
    padding: 0.1rem;
    position: absolute;
    top: 6rem;
    left: 3rem;
    color: var(--white);
  }

  .prev:hover {
    color: var(--color);
  }

  select {
    padding: 0.7rem 0.5rem;
    margin-top: 0.7rem;
    height: 2.5rem;
    border: 0.05rem solid lightgrey;
    background-color: var(--white);
    border-radius: 0.3rem;
    font-size: 0.9rem;
    width: 102%;
}


select:focus {
  outline-color: var(--lightOrange);
}

select:focus + label {
  color: var(--lightOrange);
}

option {
  font-size: 0.9rem;
  font-weight: 400;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translateY(-50%);
  cursor: pointer;
  color: lightgray;
}

.eye-icon.visible {
  color: blue; /* Change to your desired color when password is visible */
}

  @media (max-width: 800px) {
    justify-content: center;
    
    #form {
      width: 100%;
    }
  }


  // Custom styled for tabs

  .custom-tab {
    color: white;
    background-color: var(--lightOrange);
    transition: background-color 0.3s;
    text-decoration: none !important;
  }
  
  .custom-tab-selected {
    background-color: white;
    color: var(--lightOrange) !important;
    border: 0.1rem solid var(--lightOrange);
    border-radius: 3rem;
    text-decoration: none !important;
  }
  
  .custom-tab-selected .MuiTab-label {
    text-transform: none;
  }

`;

