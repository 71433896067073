import React from "react";
import styled from "styled-components";

const Container = styled.div`
  color: var(--color);
  padding: 1rem 2.5%;
  background-color: transparent;
  width: 95%;
  padding: 0 auto;
  box-sizing: border-box;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  border: 0.05rem solid lightgrey;
  
`;

const Title = styled.h1`
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
  
  background: linear-gradient(
    135deg,
    #479e9c 24%,
    #000000 100%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;

  position: relative;
  transform: rotate(0deg);
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Card = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-left: 0.4rem solid #337170;

  background: linear-gradient(
    90deg,
    rgba(132, 255, 253, 0.2) 0%,
    rgba(162, 158, 158, 0.2) 50%,
    rgba(132, 255, 253, 0.2) 100%
  );
`;

const CardTitle = styled.h2`
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
`;

const CardSection = styled.div`
  margin-bottom: 0.25rem;
`;

const SectionHeading = styled.h3`
  font-size: 1rem;
  margin-bottom: 0.25rem;
  color: #555;
`;

const SectionText = styled.p`
  font-size: 1rem;
  line-height: 1rem;
`;

const ResultsList = styled.ul`
  font-size: 1rem;
  line-height: 1.25rem;
  margin-left: 1rem;
`;

const CaseStudy = ({ studyData }) => {
    return (
        <Container>
            <Title>
                Empowering Talent Acquisition with Proven Results
            </Title>
            <CardContainer>
                {studyData?.map((card, index) => (
                    <Card key={index}>
                        <CardTitle>{card?.title}</CardTitle>
                        {card?.sections?.map((section, secIndex) => (
                            <CardSection key={secIndex}>
                                <SectionHeading>{section?.heading}</SectionHeading>
                                {section?.text && <SectionText>{section?.text}</SectionText>}
                                {section?.list && (
                                    <ResultsList>
                                        {section?.list?.map((item, listIndex) => (
                                            <li key={listIndex}>{item}</li>
                                        ))}
                                    </ResultsList>
                                )}
                            </CardSection>
                        ))}
                    </Card>
                ))}
            </CardContainer>
        </Container>
    );
};

export default CaseStudy;
