import React from 'react'
import {
    TextField,
    Button,
    IconButton
} from '@mui/material';
import { LinkedIn } from '@mui/icons-material';
import styled from 'styled-components';
import copyIcon from "../../../../assets/icons/copy.png"
import { toast } from 'react-toastify';


const PublicLinkTab = ({ jobUrl, linkedinShareUrl }) => {

    const handleCopy = () => {
        navigator.clipboard.writeText(jobUrl).then(() => {
            toast.success('Link copied to clipboard', 5000);
        }).catch((err) => {
            toast.error(`Could not copy text: ${err}`, 5000);
        });
    };

    const handleShare = () => {
        const sharableUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${linkedinShareUrl}`
        window.open(sharableUrl, '_blank', 'noopener,noreferrer');
    }

    return (<MainBox>
        <p>Share this link publicly to receive applications; we'll collect their resumes and basic details for your review.</p>
        <TextField
            fullWidth
            label="Public URL"
            value={jobUrl}
            InputProps={{
                readOnly: true,
                endAdornment: (
                    <Button size="small" onClick={handleCopy} className="btn">
                        <img className='icon' src={copyIcon}/>Copy link
                    </Button>
                ),
            }}
            margin="normal"
        />
        <p>Share on LinkedIn</p>
        <div>
            <SocialButton color="#0077B5" onClick={handleShare}>
                <LinkedIn />
            </SocialButton>
        </div>
    </MainBox>
    )
};

export default PublicLinkTab


const MainBox = styled.div`


p {
 font-size: 0.9rem;
 font-weight: 500;
 color: var(--color);

}

.btn {
border: 0.05rem solid var(--color);
padding: 0.5rem 0.8rem;
margin: 0.5rem;
font-size: 0.8rem;
font-weight: 500;
font-family: var(--font);
color: var(--color);
width: 10rem;
display: flex;
gap: 0.5rem;

}

.icon {
 width: 1rem;
 height: 1rem;

}

`

const SocialButton = styled(IconButton)`
  &.MuiIconButton-root {
    background-color: ${props => props.color};
    color: white;
    margin-right: 0.5rem;
    &:hover {
      background-color: ${props => props.color};
    }
  }
`;