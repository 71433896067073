import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { styled } from "styled-components";
import validate from "../../functions/validate";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import logo from "../../assets/Academic/logo.png";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";
import { useJwt } from "react-jwt";
import { toast } from "react-toastify";
import { GoogleLogin } from "@react-oauth/google";
import { academicGoogleAuthentication } from "../../functions/api/academicAPIs/academicGoogleAuthentication";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { setCurrentItem, setCurrentMetric } from "../../slices/configSlice";
import Favicon from "react-favicon";
import testFavicon from "../../assets/Academic/examPrep.ico"
import { logout, performLogin, performLoginWithGoogle, setUserType } from "../../slices/authSlice";


const navigation = (decodedToken, navigate) => {

};

const AcademicLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accessToken = useSelector((state) => state.auth.userData?.accessToken);
    const role = useJwt(accessToken);


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [clientCode, setClientCode] = useState("ACADEMIC");

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [value, setValue] = useState("student");
    const error = useSelector((state) => state.auth?.error);
    const [portalType, setPortalType] = useState("EXAMPAPER");


    const captchaRef = useRef(null);
    const [captchaError, setCaptchaError] = useState(false);

    const [searchParams] = useSearchParams();

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setClientCode(newValue === 'institute' ? "" : "ACADEMIC");
        setEmail("");
        setPassword("");
        setPasswordVisible(false);
        captchaRef.current.reset();
        setCaptchaError(false);
    };

    useEffect(() => {
        const currentUser = role?.decodedToken?.type;

        if (accessToken && currentUser) {
            dispatch(setUserType(currentUser));

            // Navigation temporary code
            navigate("/dashboard/academic");
            dispatch(setCurrentMetric('completedTests'));
            dispatch(setCurrentItem('dashboard'));
        }
    }, [role]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = captchaRef.current.getValue();

            if (!token) {
                setCaptchaError(true);
            } else {
                setCaptchaError(false);
                captchaRef.current.reset();

                let val = validate(email, password);

                if (val) {
                    {
                        dispatch(performLogin({ password, email, clientCode, portalType }));
                    }
                }
            }
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    };


    useEffect(() => {
        if (error) {
            const errMsg = error || "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
            handleRetryFunc();
        }
    }, [error]);

    const handleRetryFunc = () => {
        dispatch(logout());
    };

    return (
        <>
        <Favicon url={testFavicon}/>
            <StyledLogin>
                <div
                    style={{
                        height: "3.5rem",
                        position: "absolute",
                        top: "1rem",
                        left: "3rem",
                    }}
                >
                    <img src={logo} style={{ height: "100%" }} />
                </div>

                <IconButton onClick={() => navigate("/academic")} className="prev">
                    <ArrowBackIcon sx={{ fontSize: "30px" }} />
                </IconButton>

                <Box
                    sx={{
                        width: "70%",
                        position: "relative",
                        top: "6rem",
                        margin: "0 1rem",
                        marginBottom: "7rem",
                    }}
                    className="box"
                >
                    <Tabs
                        style={{
                            width: "35rem",
                            borderRadius: "3rem",
                            backgroundColor: "#515290",
                        }}
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "#515290",
                            },
                        }}
                        variant="fullWidth"
                        aria-label="wrapped label tabs example"
                    >
                        <Tab
                            value="student"
                            label="Student"
                            sx={{
                                color: "#515290",
                                fontSize: "0.8rem",
                            }}
                            classes={{ root: "custom-tab", selected: "custom-tab-selected" }}
                        />
                        <Tab
                            value="institute"
                            label="Institute"
                            sx={{
                                color: "#515290",
                                fontSize: "0.8rem",
                            }}
                            classes={{ root: "custom-tab", selected: "custom-tab-selected" }}
                        />
                    </Tabs>
                    {value == "student" ? (
                        <div id="form">
                            <ValidatorForm onSubmit={handleSubmit}>
                                <div className="inputBox">
                                    <TextValidator
                                        label="Email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        errorMessages={["This field is required", 'Email is not valid']}
                                        validators={['required', 'isEmail']}
                                        fullWidth
                                        required
                                        inputProps={{
                                            sx: {
                                                color: '#626264',
                                                fontSize: '0.9rem',
                                                fontWeight: '400'
                                            },
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#626264',
                                                fontSize: '0.9rem',
                                                fontWeight: '400'
                                            },
                                        }}
                                    />
                                </div>

                                <div className="inputBox">
                                    <TextValidator
                                        label="Password"
                                        type={passwordVisible ? "text" : "password"}
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        fullWidth
                                        required
                                        inputProps={{
                                            sx: {
                                                color: '#626264',
                                                fontSize: '0.9rem',
                                                fontWeight: '400'
                                            },
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#626264',
                                                fontSize: '0.9rem',
                                                fontWeight: '400'
                                            },
                                        }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faEye}
                                        id="eye"
                                        onClick={togglePasswordVisibility}
                                        className={`eye-icon ${passwordVisible ? "visible" : ""}`}
                                    />
                                </div>

                                <div className="resetBox">
                                    <span className="remember">
                                        <input type="checkbox" />
                                        Remember me
                                    </span>
                                    <span>
                                        <Link to="/reset/seeker" className="reset">
                                            Forgot Password
                                        </Link>
                                    </span>
                                </div>

                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                                    ref={captchaRef}
                                    size="normal"
                                />
                                {captchaError && (
                                    <span className="captchaErrorText">
                                        Error: please verify captcha
                                    </span>
                                )}
                                <a className="terms" onClick={() => navigate("/terms")}>
                                    By logging in, you agree to our Terms and Conditions.
                                </a>

                                <button type="submit" className="btn">
                                    Login
                                </button>
                            </ValidatorForm>

                            <div className="bottomBox">
                                <div className="textBox">
                                    <span className="or">OR</span>
                                    <span className="loginWith">Login with</span>
                                </div>

                                <div className="logoBox">
                                    <GoogleLogin
                                        className="logo"
                                        onSuccess={(credentialResponse) => {
                                            delete credentialResponse.select_by;
                                            try {
                                                const getData = async () => {
                                                    const res = await academicGoogleAuthentication(
                                                        credentialResponse,
                                                        "ACADEMIC"
                                                    );
                                                    if (res) {
                                                        dispatch(performLoginWithGoogle(res?.data?.data));
                                                    };
                                                };

                                                getData();
                                            } catch (error) {
                                                const errMsg =
                                                    error?.response?.data?.notify?.message ||
                                                    "An error occurred. Please try again.";
                                                toast.error(errMsg, 8000);
                                            }
                                        }}
                                        onError={() => {
                                            toast.error("Login Failed", 8000);
                                        }}
                                    />
                                    {/* <img src={linkedinAuthIcon} className="logo" /> */}
                                </div>
                            </div>

                            <p>
                                <Link to="/academic/signup" className="link">
                                    Don't have an account ? <span className="link1">Sign Up</span>
                                </Link>
                            </p>
                        </div>
                    ) : (
                        <div id="form">
                            <ValidatorForm onSubmit={handleSubmit}>
                                <div className="inputBox">
                                    <TextValidator
                                        label="Email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        errorMessages={["This field is required", 'Email is not valid']}
                                        validators={['required', 'isEmail']}
                                        fullWidth
                                        required
                                        inputProps={{
                                            sx: {
                                                color: '#626264',
                                                fontSize: '0.9rem',
                                                fontWeight: '400'
                                            },
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#626264',
                                                fontSize: '0.9rem',
                                                fontWeight: '400'
                                            },
                                        }}
                                    />
                                </div>

                                <div className="inputBox">
                                    <TextValidator
                                        label="Institute Code"
                                        type="text"
                                        value={clientCode}
                                        onChange={(e) => setClientCode(e.target.value)}
                                        errorMessages={["This field is required"]}
                                        validators={['required']}
                                        fullWidth
                                        required
                                        inputProps={{
                                            sx: {
                                                color: '#626264',
                                                fontSize: '0.9rem',
                                                fontWeight: '400'
                                            },
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#626264',
                                                fontSize: '0.9rem',
                                                fontWeight: '400'
                                            },
                                        }}
                                    />
                                </div>

                                <div className="inputBox">
                                    <TextValidator
                                        label="Password"
                                        type={passwordVisible ? "text" : "password"}
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        fullWidth
                                        required
                                        inputProps={{
                                            sx: {
                                                color: '#626264',
                                                fontSize: '0.9rem',
                                                fontWeight: '400'
                                            },
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#626264',
                                                fontSize: '0.9rem',
                                                fontWeight: '400'
                                            },
                                        }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faEye}
                                        id="eye"
                                        onClick={togglePasswordVisibility}
                                        className={`eye-icon ${passwordVisible ? "visible" : ""}`}
                                    />
                                </div>

                                <div className="resetBox">
                                    <span className="remember">
                                        <input type="checkbox" />
                                        Remember me
                                    </span>
                                    <span>
                                        <Link to="/reset/employer" className="reset">
                                            Forgot Password
                                        </Link>
                                    </span>
                                </div>

                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                                    ref={captchaRef}
                                    size="normal"
                                />
                                {captchaError && (
                                    <span className="captchaErrorText">
                                        Error: please verify captcha
                                    </span>
                                )}
                                <a className="terms" onClick={() => navigate("/terms")}>
                                    By logging in, you agree to our Terms and Conditions.
                                </a>

                                <button type="submit" className="btn">
                                    Login
                                </button>
                            </ValidatorForm>
                            <p>
                                <Link to="/academic/signup" className="link">
                                    Don't have an account ? <span className="link1">Sign Up</span>
                                </Link>
                            </p>
                        </div>
                    )}
                </Box>
            </StyledLogin>
        </>
    );
};

export default AcademicLogin;

const StyledLogin = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .captchaErrorText {
    font-size: 0.8rem;
    font-weight: 500;
    color: red;
    margin-top: -0.5rem;
  }

  p {
    font-size: 0.9rem;
  }

  .terms {
    font-size: 0.7rem;
    font-weight: 400;
    color: #000;
    line-height: 0.8rem;
    text-decoration: none;
    cursor: pointer;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    margin-top: 1rem;
  }

  #form {
    width: 80%;
    background-color: var(--backgroundColor);
    // height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .link {
    text-decoration: none;
    color: var(--color);
  }

  .link1 {
    color: var(--color);
  }

  .link1:hover {
    font-weight: 600;
    text-decoration: underline;
  }

  .resetBox {
    display: flex;
    width: 60%;
    justify-content: space-between;
    align-items: center;
  }

  .reset {
    font-size: 0.9rem;
    text-decoration: none;
    font-weight: 500;
  }

  .remember {
    display: flex;
    font-size: 0.9rem;
    text-decoration: none;
    font-weight: 500;
    align-items: center;
    gap: 0.5rem;
    height: 1.4rem;

    input {
      width: 1rem;
      margin: 0rem;
      cursor: pointer;
    }
  }

  .inputBox {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-top: 1rem;
  }

  input {
    width: 100%;
    border-radius: 0.3rem;
    background-color: var(--white);
    font-size: 0.9rem;
  }

  input:focus {
    outline-color: #515290;
  }


  label {
    color: var(--color);
    font-size: 0.8rem;
  }

  .btn {
    background-color: #515290;
    color: var(--backgroundColor);
    font-size: 1.1rem;
    font-weight: 600;
    font-family: var(--font);
    border-radius: 0.4rem;
    border: none;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
  }

  .prev {
    background-color: #515290;
    padding: 0.1rem;
    position: absolute;
    top: 6rem;
    left: 3rem;
    color: var(--white);
  }

  .prev:hover {
    color: var(--color);
  }

  .eye-icon {
    position: absolute;
    top: 60%;
    right: 2%;
    transform: translateY(-50%);
    cursor: pointer;
    color: lightgray;
  }

  .eye-icon.visible {
    color: blue; /* Change to your desired color when password is visible */
  }

  @media (max-width: 800px) {
    justify-content: center;

    #form {
      width: 100%;
    }
  }

  // Custom styled for tabs

  .custom-tab {
    color: white;
    background-color: #515290;
    transition: background-color 0.3s;
    text-decoration: none !important;
  }

  .custom-tab-selected {
    background-color: white;
    color: #515290 !important;
    border: 0.1rem solid #515290;
    border-radius: 3rem;
    text-decoration: none !important;
  }

  .custom-tab-selected .MuiTab-label {
    text-transform: none;
  }

  .bottomBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
    gap: 0.5rem;

    .textBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      .or {
        font-size: 1rem;
        font-weight: 600;
      }

      .loginWith {
        font-size: 0.8rem;
        font-weight: 400;
      }
    }

    .logoBox {
      display: flex;
      gap: 1rem;
      margin-top: 0.3rem;
      margin-bottom: 0.5rem;

      img {
        width: 3rem;
        cursor: pointer;
      }
    }
  }
`;
