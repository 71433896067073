
export const getDateRange = (filter) => {
    const now = new Date();
    let startDate;
    const endDate = new Date(now);

    switch (filter) {
        case "today":
            startDate = new Date(now.setHours(0, 0, 0, 0));
            break;
        case "last7days":
            startDate = new Date(now.setDate(now.getDate() - 7));
            startDate.setHours(0, 0, 0, 0);
            break;
        case "thisMonth":
            startDate = new Date(now.getFullYear(), now.getMonth(), 1);
            break;
        case "thisYear":
            startDate = new Date(now.getFullYear(), 0, 1);
            break;
        case "lastMonth":
            startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
            endDate.setMonth(endDate.getMonth(), 0); // Last day of last month
            endDate.setHours(23, 59, 59, 999);
            break;
        case "last3Months":
            startDate = new Date(now.getFullYear(), now.getMonth() - 3, 1);
            break;
        case "last6Months":
            startDate = new Date(now.getFullYear(), now.getMonth() - 6, 1);
            break;
        case "allTime":
        default:
            startDate = new Date(1970, 0, 1); // Start from epoch time
    }

    return [startDate, endDate];
};


export const formatDate = (date) => {
    return date.toISOString().slice(0, 10); // Format to YYYY-MM-DD
};