import React, { useState } from 'react';
import styled from 'styled-components';
import { BookOpen, Clock, AlertCircle } from 'lucide-react';

const Card = styled.div`
box-shadow: 0.1rem 0.1rem 0.25rem 0.2rem rgba(0, 0, 0, 0.25);
border-radius: 0.5rem;
display: flex;
flex-direction: column;
padding: 1rem;
box-sizing: border-box;
gap: 1.5rem;


`
const CardHeader = styled.span`
display: flex;
flex-direction: column;
gap: 0.25rem;
`
const CardTitle = styled.span`
font-size: 1.1rem;
font-weight: 700;

`
const CardDescription = styled.span`
font-size: 1rem;
font-weight: 500;

`
const CardContent = styled.div`
font-size: 1rem;
font-weight: 600;
display: flex;
flex-direction: column;
gap: 1rem;

`
const CardFooter = styled.div`
width: 25%;
align-self: center;
`
const Button = styled.button`
width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  background-color: ${({ selected }) => (selected ? 'var(--academicLavendar)' : '#ffffff')};
  color: ${({ selected }) => (selected ? '#ffffff' : '#000000')};
  border-color: ${({ selected }) => (selected ? 'lavendar' : '#d1d5db')};

  &:hover {
    background-color: ${({ selected }) => (selected ? 'var(--lightAcademicLavendar)' : '#f3f4f6')};
  }
`
const Input = styled.input`
padding: 0.5rem 1rem;
outline-color: var(--academicLavendar);
`
const Label = styled.label`
font-size: 1rem;
font-weight: 600;

`


const PageWrapper = styled.div`
  min-height: 100vh;
  background-color: #ffffff;
  padding: 1rem 2rem;
  margin: 0 auto;
  width: 94%;
  box-sizing: border-box;
  margin-bottom: 2rem;
  border-radius: 0.25rem;
`;



const Section = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
  gap: 0.5rem;

`;

const Title = styled.h1`
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 0rem;
`;

const Description = styled.p`
  color: #4b5563;
  font-size: 1rem;
  font-weight: 500;

`;

const Grid = styled.div`
  display: grid;
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const GridCols = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;


`;

const ButtonBox = styled.div`
width: 100%;
display: flex;
gap: 5%;
box-sizing: border-box;

`

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const TextSmall = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--color);
`;

const MainListBox = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
`
const ListBox = styled.div`
width: 50%;

`

const List = styled.ul`
  list-style-type: disc;
  padding-left: 1.25rem;
  margin-top: 0.5rem;

  & > li {
    margin-bottom: 0.25rem;
  }
`;

const CreatePaper = () => {
    const [paperType, setPaperType] = useState('mathematics');
    const [paperTitle, setPaperTitle] = useState("");

    return (
        <PageWrapper>
            <Section>
                <Title>Create NDA Examination Paper</Title>
                <Description>
                    National Defence Academy (NDA) examination is conducted by UPSC for admission to Army, Navy and Air Force
                    wings of NDA. The exam consists of two papers: Mathematics and General Ability Test (GAT).
                </Description>
            </Section>

            <Grid>
                <Card>
                    <CardHeader>
                        <CardTitle>Examination Details</CardTitle>
                        <CardDescription>Key information about NDA examination pattern</CardDescription>
                    </CardHeader>
                    <CardContent>
                        <GridCols>
                            <CardTitle>Mathematics Paper</CardTitle>
                            <FlexRow>
                                <AlertCircle size="1.25rem" />
                                <span>300 Marks</span>
                            </FlexRow>
                            <FlexRow>
                                <Clock size="1.25rem" />
                                <span>2.5 Hours</span>
                            </FlexRow>
                            <FlexRow>
                                <BookOpen size="1.25rem" />
                                <span>120 Questions</span>
                            </FlexRow>
                        </GridCols>

                        <hr style={{ width: "100%" }} />

                        <GridCols>
                            <CardTitle>General Ability Test (GAT) Paper</CardTitle>
                            <FlexRow>
                                <AlertCircle size="1.25rem" />
                                <span>600 Marks</span>
                            </FlexRow>
                            <FlexRow>
                                <Clock size="1.25rem" />
                                <span>2.5 Hours</span>
                            </FlexRow>
                            <FlexRow>
                                <BookOpen size="1.25rem" />
                                <span>150 Questions</span>
                            </FlexRow>
                        </GridCols>
                    </CardContent>
                </Card>

                <Card>
                    <CardHeader>
                        <CardTitle>Create New Paper</CardTitle>
                        <CardDescription>Select paper type and enter details</CardDescription>
                    </CardHeader>
                    <CardContent>
                        <ButtonBox>
                            <Button
                                onClick={() => setPaperType('mathematics')}
                                selected={paperType === "mathematics"}
                            >
                                Mathematics
                            </Button>
                            <Button
                                onClick={() => setPaperType('gat')}
                                selected={paperType === "gat"}
                            >
                                General Ability Test
                            </Button>
                        </ButtonBox>

                        <Section>
                            <Label htmlFor="paperTitle">Paper Title</Label>
                            <Input id="paperTitle" placeholder="Enter paper title" value={paperTitle} onChange={(e) => setPaperTitle(e.target.value)} />
                        </Section>
                    </CardContent>
                    <CardFooter>
                        <Button selected={true}>Create Paper</Button>
                    </CardFooter>
                </Card>
            </Grid>

            {paperType === 'mathematics' && (
                <TextSmall>
                    <h3>Mathematics Paper Covers (300 Marks):</h3>
                    <List>
                        <li>Algebra</li>
                        <li>Calculus</li>
                        <li>Vector Algebra</li>
                        <li>Matrices and Determinants</li>
                        <li>Trigonometry</li>
                        <li>Analytical Geometry</li>
                        <li>Differential Calculus</li>
                        <li>Integral Calculus</li>
                        <li>Statistics and Probability</li>
                    </List>
                </TextSmall>
            )}

            {paperType === 'gat' && (
                <TextSmall>
                    <h3>General Ability Test Covers (600 Marks):</h3>
                    <MainListBox>
                    <ListBox>
                        <h3>English (200 Marks)</h3>
                        <List>
                            <li>Spotting Errors</li>
                            <li>Comprehension</li>
                            <li>Selecting Words</li>
                            <li>Ordering of Words in a Sentence</li>
                            <li>Sentence Improvement</li>
                            <li>Antonyms</li>
                            <li>Synonyms</li>
                        </List>
                    </ListBox>
                    <ListBox>
                        <h3>General Knowledge (400 Marks)</h3>
                        <List>
                            <li>Physics</li>
                            <li>Chemistry</li>
                            <li>General Science</li>
                            <li>History & Freedom Movement</li>
                            <li>Geography</li>
                            <li>Current Affairs</li>
                        </List>
                    </ListBox>
                    </MainListBox>
                </TextSmall>
            )}
        </PageWrapper>
    );
};

export default CreatePaper;
