import React from 'react'
import styled from 'styled-components'
import Button from '../../../../component/shared/Button'
import TestCard from '../components/TestCard'
import useFetchTests from '../hooks/useFetchTests'
import { useSelector } from 'react-redux'

const TestsPage = ({createTest, editTest, published, setTestTrigger, testTrigger}) => {
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

    const { tests, loading } = useFetchTests(accessToken, clientCode, published, testTrigger);

    console.log(tests);

    return (
        <Container>
            <TopBox>
                <Button text="Create Test" func={() => createTest()} disabled={false} />
            </TopBox>
            <ContentBox>
                {
                    tests && tests?.map((test, i) => (
                        <TestCard key={test?.id} data={test} editTest={editTest} setTestTrigger={setTestTrigger} index={i} />
                    ))
                }
            </ContentBox>
        </Container>
    )
}

export default TestsPage

const Container = styled.div`
width: 100%;
margin: 0 0;
padding: 1% 1rem;
display: flex;
flex-direction: column;
box-sizing: border-box;
gap: 2rem;


`

const TopBox = styled.div`
width: 96%;
margin: 0 auto;
display: flex;
justify-content: end;
align-items: center;
gap: 2%;



`


const ContentBox = styled.div`
display: flex;
flex-direction: column;
width: 96%;
margin: 0 auto;
gap: 0.5rem;




`