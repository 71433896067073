import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ProfileFilter from '../seekerCommonComponents/ProfileFilter'
import JobSearchBar from './JobSearchBar'
import { useSelector } from 'react-redux'
import { searchJds } from '../../../../functions/api/jobApplication/searchJds'
import { searchJdsWithFilter } from '../../../../functions/api/jobApplication/searchJdWithFilters'
import { toast } from 'react-toastify'
import resetIcon from '../../../../assets/icons/reset.png'
import { updateCandidateAvl, updateCompanyType, updateExp, updateFreshness, updateNoticePeriod, updateSalaryRange, updateSelectedLocations, updateSelectedSkills, updateWorkType } from '../../../../slices/jobFilterSlice';
import { useDispatch } from 'react-redux'
import AllJobsListNew from './AllJobsListNew'


const AllJobs = () => {
    const [jobListings, setJobListings] = useState([]);
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);
    const dispatch = useDispatch();

    const [trigger, setTrigger] = useState(false);
    const exp = useSelector((state) => state.jobFilter.exp);
    const selectedLocations = useSelector((state) => state.jobFilter.selectedLocations);
    const selectedSkills = useSelector((state) => state.jobFilter.selectedSkills);

    const workType = useSelector((state) => state.jobFilter.workType);
    const salaryRange = useSelector((state) => state.jobFilter.salaryRange);
    const freshness = useSelector((state) => state.jobFilter.freshness);
    const noticePeriod = useSelector((state) => state.jobFilter.noticePeriod);
    const companyType = useSelector((state) => state.jobFilter.companyType);
    const candidateAvl = useSelector((state) => state.jobFilter.candidateAvl);
    const [resetAllFilters, setResetAllFilters] = useState(false);

    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [total, setTotal] = useState(0);



    useEffect(() => {
        const handleFilter = async () => {
            try {
                let payload = {
                    ctc: salaryRange,
                    exp: exp,
                    noticePeriod: noticePeriod,
                    workType: workType,
                    companyType: companyType,
                    candidateAvailability: candidateAvl
                }

                payload = Object?.keys(payload)?.reduce((acc, key) => {
                    if (Array?.isArray(payload[key]) && payload[key]?.length > 0) {
                        acc[key] = payload[key];
                    }
                    return acc;
                }, {});


                const res = await searchJdsWithFilter(payload, selectedSkills, selectedLocations, accessToken, clientCode, page, size);
                if (res) {
                    setJobListings(res?.data?.data);
                    setTotal(res?.data?.total);
                }
            }
            catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }

        handleFilter();
    }, [selectedSkills, selectedLocations, exp, trigger, page, size, resetAllFilters])

    const resetFilter = () => {
        setResetAllFilters(!resetAllFilters);
        dispatch(updateExp([]));
        dispatch(updateSelectedLocations([]));
        dispatch(updateSelectedSkills([]));
        dispatch(updateWorkType([]));
        dispatch(updateSalaryRange([]));
        dispatch(updateFreshness([]));
        dispatch(updateNoticePeriod([]));
        dispatch(updateCompanyType([]));
        dispatch(updateCandidateAvl([]));
        setPage(1);
    }

    const handleSizeChange = (event) => {
        setSize(parseInt(event.target.value, 10));
        setPage(1);
    };

    const handlePageChange = (change) => {
        if (change && page < Math.ceil(+total / +size)) {
            setPage((prev) => prev + 1);
        } else if (!change && page > 1) {
            setPage((prev) => prev - 1);
        }
    };

    return (
        <MainBox>
            <div className='mainBox'>
                <JobSearchBar handleJobSearch={() => setTrigger(!trigger)} />
                <div className='childBox'>
                    <button className='mainBtn' onClick={resetFilter}><img src={resetIcon} /></button>
                    <ProfileFilter applyFilters={() => {
                        setTrigger(!trigger);
                        setPage(1);
                    }} setResetAllFilters={setResetAllFilters} />
                </div>
            </div>
            <InnerBox>
                <AllJobsListNew jobListings={jobListings} page={page} size={size} setPage={setPage} total={total} handlePageChange={handlePageChange} handleSizeChange={handleSizeChange} setTrigger={setTrigger} trigger={trigger} />
            </InnerBox>
        </MainBox>
    )
}

export default AllJobs

const MainBox = styled.div`
padding-bottom: 4rem;
display: flex;
flex-direction: column;
width: 100%;

.mainBox {
  display: grid;
  width: 99%;
  align-items: center;
  grid-template-columns: 9fr 1fr;

  .childBox {
    display: flex;
    align-items: center;
    gap: -0.2rem;
  }

  .mainBtn {
    background-color: var(--lightOrange);
    border: 0.08rem solid var(--lightOrange);
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 0.3rem;
    cursor: pointer;
    height: 3rem;
    width: 3rem;

    

    img {
      width: 1.75rem;
      height: 1.75rem;
    }
  }
}
`
const InnerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    
`