import React, { useState } from "react";
import { styled } from "styled-components";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setCurrentItem, setCurrentMetric } from "../../slices/configSlice";
import { useEffect } from "react";
import AcademicMetrics from "./AcademicMetrics";
import MyCourses from "./SidebarPages/MyCourses";
import AllCourses from "./SidebarPages/AllCourses";
import CompletedTests from "./SidebarPages/CompletedTests";
import Profile from "./SidebarPages/Profile";
import Subscription from "../Interviews/SeekerDashboard/sidebarPages/Subscription";
import AcademicSidebar from "./AcademicSidebar";
import AcademicHeader from "./AcademicHeader";
import AllPracticeTests from "./SidebarPages/AllPracticeTests";
import AcademicCallSupport from "./CommonComponents/AcademicCallSupport";
import Favicon from "react-favicon";
import testFavicon from "../../assets/Academic/examPrep.ico"
import AvailablePapers from "./TestPaper/AvailablePapers";
import CreatePaper from "./TestPaper/CreatePaper";
import { clearError, resetTestData } from "../../slices/academicTestSlice";


const AcademicDashboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentItem = useSelector(state => state.config?.currentItem);
    const clientType = useSelector((state) => state.auth.userData?.user?.clientType) || useSelector(state => state.auth.userData?.academicUser?.clientType);

    useEffect(() => {
        dispatch(resetTestData());
        dispatch(clearError());
    }, [])

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (event.currentTarget.performance.navigation.type === 1) {
                if(clientType && clientType === "ACADEMIC_INSTITUTE"){
                    dispatch(setCurrentItem('available-paper'));
                } else {
                    dispatch(setCurrentItem('dashboard'));
                }
                dispatch(setCurrentMetric('completedTests'));
            }
        };

        window.addEventListener('load', handleBeforeUnload);

        return () => {
            window.removeEventListener('load', handleBeforeUnload);
        };
    }, [dispatch]);

    return (
        <MainBox>
                <Favicon url={testFavicon}/>
            <AcademicHeader />
            <StyledContent>
                <AcademicSidebar />
                <MainContent>
                    {currentItem === 'dashboard' &&
                        <AcademicMetrics />
                    }

                    {currentItem === 'my-courses' && <MyCourses />}
                    {currentItem === 'all-courses' && <AllCourses />}
                    {currentItem === 'completed-tests' && <CompletedTests />}
                    {currentItem === 'practice-test' && <AllPracticeTests />}
                    {currentItem === 'available-paper' && <AvailablePapers />}
                    {currentItem === 'create-paper' && <CreatePaper />}

                    {currentItem === 'profile' && <Profile />}
                    {currentItem === 'subscriptions' && <Subscription />}
                    {currentItem === 'call-support' && <AcademicCallSupport />}
                </MainContent>
            </StyledContent>
        </MainBox>
    );
};


export default AcademicDashboard;


const MainBox = styled.div`
display: flex;
flex-direction: column;
min-height: 100vh;
width: 100%;
background-color: #f4f4f4;
`

const MainContent = styled.div`
  flex-grow: 1;
  margin-left: 16rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f4f4f4;
`;

const StyledContent = styled.div`
  width: 100%;
  display: flex;
  height: calc(100% - 4rem);
  margin-top: 4rem;
  color: var(--color);
`;
