

export const technicalSkills = [
    "account management",
    "accounting",
    "accounts payable",
    "accounts receivable",
    "analysis",
    "analytical",
    "analytical skills",
    "analytics",
    "analyze data",
    "analyzing data",
    "annual budget",
    "asset management",
    "audit",
    "auditing",
    "banking",
    "billing",
    "budget management",
    "budgeting",
    "business administration",
    "business cases",
    "business issues",
    "business management",
    "business planning",
    "business plans",
    "business process",
    "business requirements",
    "business stakeholders",
    "business strategy",
    "business systems",
    "CAD",
    "cash flow",
    "CFA",
    "client relationships",
    "client service",
    "compliance",
    "consulting experience",
    "consulting services",
    "consumers",
    "contracts",
    "co-op",
    "cost effective",
    "cost reduction",
    "data analysis",
    "data collection",
    "data management",
    "data quality",
    "database",
    "deposits",
    "due diligence",
    "dynamic environment",
    "economics",
    "EMEA",
    "expenses",
    "external partners",
    "finance",
    "financial analysis",
    "financial management",
    "financial performance",
    "financial reporting",
    "financial reports",
    "financial services",
    "financial statements",
    "financing",
    "forecasting",
    "forecasts",
    "GAAP",
    "general ledger",
    "governance",
    "internal audit",
    "internal controls",
    "internal customers",
    "internal stakeholders",
    "international",
    "inventory",
    "inventory management",
    "investigate",
    "investigation",
    "investigations",
    "invoices",
    "invoicing",
    "journal entries",
    "law enforcement",
    "leadership development",
    "lean",
    "legal",
    "legislation",
    "litigation",
    "machine learning",
    "MIS",
    "mortgage",
    "non-profit",
    "partnership",
    "partnerships",
    "payments",
    "payroll",
    "physical security",
    "PMP",
    "policies",
    "portfolio management",
    "presentations",
    "process improvement",
    "project planning",
    "proposal",
    "prospecting",
    "purchase orders",
    "purchasing",
    "real estate",
    "reconcile",
    "reconciliation",
    "regulations",
    "relationship management",
    "reporting",
    "revenue growth",
    "RFP",
    "RFPs",
    "risk assessment",
    "risk assessments",
    "risk management",
    "security clearance",
    "small business",
    "spreadsheets",
    "statistical analysis",
    "statistics",
    "strategic direction",
    "strategic initiatives",
    "strategic planning",
    "strategic plans",
    "strategy",
    "Tableau",
    "tax",
    "technical knowledge",
    "transactions",
    "underwriting",
    "vendor management",
    "vendors",
    "beverage",
    "build relationships",
    "customer experience",
    "customer-facing",
    "customer requirements",
    "customer service",
    "employee relations",
    "filing",
    "help desk",
    "hospitality",
    "hotel",
    "hotels",
    "human resource",
    "human resources",
    "immigration",
    "in-store",
    "internship",
    "media relations",
    "office software",
    "on-boarding",
    "outreach",
    "PeopleSoft",
    "performance metrics",
    "phone calls",
    "retail",
    "sales",
    "sales experience",
    "sales goals",
    "sales operations",
    "Salesforce",
    "staffing",
    "supervising",
    "supervisory experience",
    "support services",
    "talent acquisition",
    "talent management",
    "travel",
    "travel arrangements",
    "administrative support",
    "admissions",
    "biology",
    "chemistry",
    "coaching",
    "computer science",
    "computer software",
    "counsel",
    "counseling",
    "fundraising",
    "German",
    "higher education",
    "mathematics",
    "physics",
    "program development",
    "public relations",
    "recruiting",
    "recruitment",
    "relationship building",
    "research projects",
    "researching",
    "spelling",
    "sports",
    "teaching",
    "training",
    "writing",
    "case management",
    "CPR",
    "FDA",
    "fitness",
    "health",
    "healthcare",
    "hospital",
    "logistics",
    "medical device",
    "nursing",
    "on-call",
    "ordering",
    "pharmaceutical",
    "pharmacy",
    "presentation",
    "psychology",
    "public health",
    "public policy",
    "safety",
    "therapeutic",
    ".NET",
    "algorithms",
    "android",
    "architecture",
    "architectures",
    "audio",
    "AutoCAD",
    "AWS",
    "big data",
    "business analysis",
    "business continuity",
    "C (programming language)",
    "C#",
    "C++",
    "certification",
    "Cisco",
    "cloud",
    "computer applications",
    "controls",
    "CSS",
    "D (programming language)",
    "data center",
    "data entry",
    "datasets",
    "design",
    "development activities",
    "digital marketing",
    "digital media",
    "distribution",
    "DNS",
    "ecommerce",
    "e-commerce",
    "end user",
    "experimental",
    "experiments",
    "frameworks",
    "front-end",
    "GIS",
    "graphic design",
    "hardware",
    "HTML5",
    "I-DEAS",
    "information management",
    "information security",
    "information technology",
    "intranet",
    "iOS",
    "iPhone",
    "IT infrastructure",
    "ITIL",
    "Java",
    "Javascript",
    "JIRA",
    "LAN",
    "licensing",
    "Linux",
    "MATLAB",
    "matrix",
    "mechanical engineering",
    "migration",
    "mobile",
    "modeling",
    "networking",
    "operations management",
    "oracle",
    "OS",
    "process development",
    "process improvements",
    "product development",
    "product knowledge",
    "program management",
    "programming",
    "protocols",
    "prototype",
    "Python",
    "quality assurance",
    "real-time",
    "research",
    "resource management",
    "root cause",
    "routing",
    "SaaS",
    "SAS",
    "SCI",
    "scripting",
    "scrum",
    "SDLC",
    "SEO",
    "service delivery",
    "software development",
    "software development life cycle",
    "software engineering",
    "SQL",
    "SQL server",
    "tablets",
    "technical issues",
    "technical support",
    "test cases",
    "test plans",
    "testing",
    "troubleshooting",
    "UI",
    "Unix",
    "usability",
    "user experience",
    "UX",
    "variances",
    "VMware",
    "web services",
    "workflows",
    "assembly",
    "automation",
    "aviation",
    "budget",
    "chemicals",
    "circuits",
    "construction",
    "cross-functional team",
    "documentation",
    "documenting",
    "drafting",
    "drawings",
    "driving record",
    "electrical",
    "electrical engineering",
    "electronics",
    "engineering",
    "fabrication",
    "installation",
    "instructional design",
    "instrumentation",
    "ISO",
    "life cycle",
    "lifecycle",
    "manage projects",
    "management experience",
    "mining",
    "operations",
    "outsourcing",
    "performance improvement",
    "procurement",
    "product line",
    "product marketing",
    "product quality",
    "project delivery",
    "project management skills",
    "project plan",
    "QA",
    "quality control",
    "quality management",
    "quality standards",
    "raw materials",
    "regulatory compliance",
    "regulatory requirements",
    "repairs",
    "six sigma",
    "SolidWorks",
    "sourcing",
    "specifications",
    "standard operating procedures",
    "supply chain",
    "transport",
    "transportation",
    "valid drivers license",
    "warehouse",
    "acquisition",
    "Adobe",
    "Adobe Creative Suite",
    "advertising",
    "affiliate",
    "agile",
];

export const allTags = [
    "Accounts",
    "Software Engineer",
    "Mechanical Engineer",
    "Chemical Engineer",
    "Aerospace Engineer",
    "Petroleum Engineer",
    "Healthcare",
    "Finance",
    "Marketing",
    "Retail",
    "Hospitality",
    "Biotechnology",
]


export const locations = [
    "Delhi",
    "Mumbai",
    "Bengaluru",
    "Bengaluru/Bangalore",
    "Bangalore",
    "Chennai",
    "Kolkata",
    "Hyderabad",
    "Pune",
    "Ahmedabad",
    "Noida",
    "Gurgaon",
    "Gurugram",
    "Jaipur",
    "Lucknow",
    "Kanpur",
    "Nagpur",
    "Indore",
    "Patna",
    "Chandigarh",
    "Coimbatore",
    "Vadodara",
    "Ludhiana",
    "Agra",
    "Surat",
    "Visakhapatnam",
    "Bhopal",
    "Amritsar",
    "Raipur",
    "Ranchi",
    "Varanasi",
    "Jodhpur",
    "Udaipur",
    "Shimla",
    "Manali",
    "Mysuru/Mysore",
    "Goa",
    "Kochi",
    "Thiruvananthapuram",
    "Ooty",
];


export const data = [
    {
        id: 1,
    },
    {
        id: 2,
    },
    {
        id: 3,
    },
    {
        id: 4,
    },
    {
        id: 5,
    },
    {
        id: 6,
    },
    {
        id: 7,
    },
    {
        id: 8,
    },
    {
        id: 9,
    },
    {
        id: 10,
    },
    {
        id: 11,
    },
    {
        id: 12,
    },
];

export const resumeExampleData = {
    personalDetails: {
        address: "123 Main St",
        city: "Business Park",
        country: "USA",
        email: "johndoe@email.com",
        firstName: "John",
        lastName: "Doe",
        jobTitle: "Software Developer",
        nationality: "USA",
        phone: "(123) 456-7890",
    },
    social: {
        linkedin: "https://linkedin.com/in/abhipandey1402",
        github: "https://github.com/abhipandey1402",
    },
    professionalSummary:
        "A passionate and detail-oriented web developer with 5 years of experience in building responsive and user-friendly websites. Proficient in HTML, CSS, and JavaScript.",
    education: [
        {
            degree: "Bachelor of Science in Computer Science",
            university: "University Name",
            startDate: "2020-02-14",
            endDate: "2024-02-14",
            description: "<p>Skilled up in Full stack MERN and SpringBoot Backend.</p>",
            city: "New York",
        },
        {
            degree: "High School Diploma",
            university: "High School Name",
            startDate: "2020-02-14",
            endDate: "2024-02-14",
            description: "<p>Skilled up in Full stack MERN and SpringBoot Backend.</p>",
            city: "New York",
        },
    ],
    employmentHistory: [
        {
            jobTitle: "Frontend Developer",
            companyName: "Tech Company",
            city: "Miami",
            startDate: "2020-02-14",
            endDate: "2024-02-14",
            description: "<p>Collaborate with the design and backend teams to create seamless user experiences.</p>",
        },
        {
            jobTitle: "Intern",
            companyName: "AI Company",
            city: "Florida",
            startDate: "2020-02-14",
            endDate: "2024-02-14",
            description: "<p>Collaborate with the design and backend teams to create seamless user experiences.</p>",
        },
    ],
    skills: [
        { skill: "HTML", level: "Skillful" },
        { skill: "CSS", level: "Skillful" },
        { skill: "JavaScript", level: "Skillful" },
        { skill: "React", level: "Skillful" },
        { skill: "Responsive Design", level: "Skillful" },
        { skill: "Version Control (Git)", level: "Skillful" },
        { skill: "UI/UX Design", level: "Skillful" },
    ],
    languages: [
        { language: "English", level: "Proficient" },
        { language: "Spanish", level: "Proficient" },
    ],
    projects: [
        {
            title: "E-commerce Website",
            description: "<ul><li>Software Marketplace</li></ul>",
            startDate: "2020-02-14",
            endDate: "2024-02-14",
        },
        {
            title: "Brain-Quiz",
            description: "<ul><li>AI-Based MCQ Practice Platform</li></ul>",
            startDate: "2020-02-14",
            endDate: "2024-02-14",
        },
    ],
};
