import React from 'react';
import styled from 'styled-components';
import { AlertCircle, Upload, Tag, CheckCircle } from 'lucide-react';

const MessageContainer = styled.div`
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  font-family: Arial, sans-serif;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  color: #f59e0b;
`;

const Title = styled.h2`
  margin-left: 0.75rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937;
`;

const Message = styled.p`
  margin-bottom: 1.25rem;
  line-height: 1.6;
  color: #4b5563;
`;

const InstructionList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 1.5rem 0;
`;

const InstructionItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.75rem;
  background-color: #f3f4f6;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e5e7eb;
  }
`;

const IconWrapper = styled.span`
  margin-right: 1rem;
  color: #059669;
`;

const Emphasis = styled.span`
  font-weight: bold;
  color: #1f2937;
`;

const JDTagsInstructionMessage = () => {
  return (
    <MessageContainer>
      <Header>
        <AlertCircle size={28} />
        <Title>Important: Tags Required</Title>
      </Header>
      <Message>
        You've successfully created a Job Description, but you haven't selected any tags. Tags are <Emphasis>mandatory</Emphasis> and should be added after uploading candidates.
      </Message>
      <Message>
        <Emphasis>
        Please follow these steps:
        </Emphasis>
      </Message>
      <InstructionList>
        <InstructionItem>
          <IconWrapper><Upload size={22} /></IconWrapper>
          <div>
            <Message as="p"><Emphasis>Upload candidates with appropriate tags</Emphasis></Message>
          </div>
        </InstructionItem>
        <InstructionItem>
          <IconWrapper><Tag size={22} /></IconWrapper>
          <div>
            <Message as="p"><Emphasis>Return to update and select tags for your Job Description</Emphasis></Message>
          </div>
        </InstructionItem>
        <InstructionItem>
          <IconWrapper><CheckCircle size={22} /></IconWrapper>
          <div>
            <Message as="p"><Emphasis>Keep tags unique for each Job Description</Emphasis></Message>
          </div>
        </InstructionItem>
      </InstructionList>
      <Message>
        Adding unique and relevant tags helps in better matching candidates to your specific job requirements and significantly improves the overall hiring process.
      </Message>
    </MessageContainer>
  );
};

export default JDTagsInstructionMessage;

