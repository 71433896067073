import { useState, useCallback } from 'react';

export const useQuestionEditing = () => {
    const [editingIndex, setEditingIndex] = useState(-1);
    const [editingQuestionId, setEditingQuestionId] = useState('');
    const [questionType, setQuestionType] = useState('');

    // Function to handle editing a question
    const handleEditQuestion = useCallback((index, question) => {
        setEditingIndex(index);
        setQuestionType(question?.questionType);
        setEditingQuestionId(question?.id);
    }, []);

    // Function to handle selecting a question type
    const handleTypeSelect = useCallback((type) => {
        setQuestionType(type);
    }, []);

    return { 
        editingIndex, 
        questionType, 
        handleEditQuestion, 
        handleTypeSelect, 
        setEditingIndex,
        editingQuestionId,
    };
};
