import React from 'react';
import styled from 'styled-components';
import LazyLoadImage from './LazyLoadImage'; 

const VisualDetailImgBox = ({ imgSrc, altText }) => {
    return (
        <StyledImgBox>
            <LazyLoadImage src={imgSrc} alt={altText} />
        </StyledImgBox>
    );
};

const StyledImgBox = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    img {
        max-width: 40%;
        height: auto;
        border-radius: 8px;
    }
`;

export default VisualDetailImgBox;
