import React, { useState } from 'react'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MatchedResumes from './MatchedResumes';
import styled from 'styled-components';
import LogoHeader from '../../../commonComponents/LogoHeader';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setCurrentTrackerTab } from '../../../../slices/configSlice';

const MatchedResumeTabs = () => {
    const dispatch = useDispatch();
    const currentTab = useSelector(state => state.config?.currentTrackerTab);

    const [tabRowCounts, setTabRowCounts] = useState({
        MATCHED: 0,
        SHORTLISTED: 0,
        SCHEDULED: 0,
        COMPLETED: 0,
        NOT_SHORTLISTED: 0,
        DUPLICATE: 0
    });

    const updateTabRowCount = (tab, count) => {
        setTabRowCounts(prevCounts => ({
            ...prevCounts,
            [tab]: count
        }));
    };

    const handleChange = (event, newValue) => {
        dispatch(setCurrentTrackerTab(newValue));
    }

    return (
        <StyledDiv>
            <LogoHeader />
            <StyledBox>
                <Tabs
                    style={{
                        width: '60%',
                        borderRadius: '3rem',
                        backgroundColor: 'var(--lightOrange)'
                    }}
                    value={currentTab}
                    onChange={handleChange}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "var(--lightOrange)",
                        },
                    }}
                    variant="fullWidth"
                    aria-label="wrapped label tabs example"
                >
                    <Tab
                        value="MATCHED"
                        // label={`Matched: ${tabRowCounts.MATCHED}`}
                        label="Matched"
                        sx={{
                            color: "var(--lightOrange)",
                            fontSize: "0.8rem",
                        }}
                        classes={{ root: 'custom-tab', selected: 'custom-tab-selected' }}
                    />
                    <Tab
                        value="SHORTLISTED"
                        // label={`Shortlisted: ${tabRowCounts.SHORTLISTED}`}
                        label="Shortlisted"
                        sx={{
                            color: "var(--lightOrange)",
                            fontSize: "0.8rem",
                        }}
                        classes={{ root: 'custom-tab', selected: 'custom-tab-selected' }}
                    />

                    <Tab
                        value="SCHEDULED"
                        // label={`Scheduled: ${tabRowCounts.SCHEDULED}`}
                        label="Scheduled"
                        sx={{
                            color: "var(--lightOrange)",
                            fontSize: "0.8rem",
                        }}
                        classes={{ root: 'custom-tab', selected: 'custom-tab-selected' }}
                    />
                    <Tab
                        value="COMPLETED"
                        // label={`Completed: ${tabRowCounts.COMPLETED}`}
                        label="Completed"
                        sx={{
                            color: "var(--lightOrange)",
                            fontSize: "0.8rem",
                        }}
                        classes={{ root: 'custom-tab', selected: 'custom-tab-selected' }}
                    />
                    <Tab
                        value="NOT_SHORTLISTED"
                        // label={`Rejected: ${tabRowCounts.NOT_SHORTLISTED}`}
                        label="Rejected"
                        sx={{
                            color: "var(--lightOrange)",
                            fontSize: "0.8rem",
                        }}
                        classes={{ root: 'custom-tab', selected: 'custom-tab-selected' }}
                    />
                    <Tab
                        value="DUPLICATE"
                        // label={`Duplicate: ${tabRowCounts.DUPLICATE}`}
                        label="Duplicate"
                        sx={{
                            color: "var(--lightOrange)",
                            fontSize: "0.8rem",
                        }}
                        classes={{ root: 'custom-tab', selected: 'custom-tab-selected' }}
                    />

                </Tabs>
                {currentTab === 'MATCHED' && <MatchedResumes filterParams={currentTab} setTotalRowsCount={(count) => updateTabRowCount('MATCHED', count)} />}
                {currentTab === 'DUPLICATE' && <MatchedResumes filterParams={currentTab}  setTotalRowsCount={(count) => updateTabRowCount('DUPLICATE', count)}/>}
                {currentTab === 'SHORTLISTED' && <MatchedResumes filterParams={currentTab} setTotalRowsCount={(count) => updateTabRowCount('SHORTLISTED', count)} />}
                {currentTab === 'NOT_SHORTLISTED' && <MatchedResumes filterParams={currentTab} setTotalRowsCount={(count) => updateTabRowCount('NOT_SHORTLISTED', count)} />}
                {currentTab === 'SCHEDULED' && <MatchedResumes filterParams="IN_PROCESS" interviewStatus="SCHEDULED" setTotalRowsCount={(count) => updateTabRowCount('SCHEDULED', count)} />}
                {currentTab === 'COMPLETED' && <MatchedResumes filterParams="IN_PROCESS" interviewStatus="COMPLETED" setTotalRowsCount={(count) => updateTabRowCount('COMPLETED', count)} />}
            </StyledBox>
        </StyledDiv>
    )
}



export default MatchedResumeTabs


// MATCHED, NOT_SHORTLISTED, SHORTLISTED

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;


const StyledBox = styled.div`
    width: 99%;
    min-height: 30rem;
    margin: 5rem 0% 2rem 0%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;



    // Custom styled for tabs

    .custom-tab {
      color: white;
      background-color: var(--lightOrange);
      transition: background-color 0.3s;
      text-decoration: none !important;
    }

.custom-tab-selected {
    background-color: white;
    color: var(--lightOrange) !important;
    border: 0.1rem solid var(--lightOrange);
    border-radius: 3rem;
    text-decoration: none !important;
  }

.custom-tab-selected .MuiTab-label {
  text-transform: none;
}
`
