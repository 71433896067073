import axios from "axios"

export const getCommentsByIssueKey = async (accessToken, clientCode, issueKey) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'X-Client-Code': clientCode,
        }
    };

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/jira/getCommentsByIssueKey/${issueKey}`, config);
        console.log('Data:', response.data);
        console.log("Status", response.status)
        return response.data;
    } catch (error) {
        throw error;
    }
}

