import axios from "axios";

export const getFeedback = async (jdId, resumeId, accessToken) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/interviews/feedbacks?jdId=${jdId}&resumeId=${resumeId}`, config);
        return response?.data;
    } catch (error) {
        throw error;
    }
}


