import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getActiveJds } from "../../../../slices/jdSlice";
import { useDispatch } from "react-redux";
import TableSearchBar from "../commonComponents/TableSearchBar";
import { Pagination, PaginationSizeFilter } from "../../../commonComponents/Pagination";
import { toast } from "react-toastify";
import { getDashboardInterviewData } from "../../../../functions/api/employers/getDashboardInterviewData";
import visibleIcon from "../../../../assets/icons/visible.png"
import CommonDrawer from "../../../commonComponents/CommonDrawer";
import InterviewDashboardReport from "../commonComponents/InterviewDashboardReport";
import EmpScheduledInterviewsReport from "./EmpScheduledInterviewsReport";
import { getDashboardAllData } from "../../../../functions/api/employers/getDashboardAllData";

function Row(props) {
  const { row, index, setPage, page, size } = props;
  const accessToken = useSelector(state => state.auth.userData?.accessToken);

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };


  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }} className={`${index % 2 == 1 ? 'colored' : ''}`}>
        <TableCell align="center" className="tableCell">{((page-1)*size) + (index+1)}</TableCell>
        <TableCell align="center" className="tableCell">{row?.jdTitle}</TableCell>
        <TableCell align="center" className="tableCell">{row?.openReqs}/{row?.totalReqs}</TableCell>
        <TableCell align="center" className="tableCell">{row?.totalCandidates ?? 0}</TableCell>
        <TableCell align="center" className="tableCell">{row?.trackerStatus?.IN_PROCESS ?? 0}</TableCell>
        <TableCell align="center" className="tableCell">
          <div className="uniqueBox">
            <div className="innerBox">
              {row?.jdRoundsInfos?.length === 0
                ? 0 :
                row?.jdRoundsInfos?.map((stage, i) => (<span className="text"><span className="coloredStage">R{stage?.stage} </span> : {stage?.count}</span>))
              }
            </div>
          </div>
        </TableCell>
        <TableCell align="center" className="tableCell">
          <div>
            {Object.entries(row?.source)?.map(([key, value], i) => (
              <span>{value} {i !== Object.entries(row?.source)?.length - 1 && <span className="coloredPipe"> | </span>}</span>
            ))}
          </div>
        </TableCell>
        <TableCell component="th" scope="row" align="center" className="tableCell">
          <div style={{ display: 'flex', gap: '0.6rem', justifyContent: 'center', alignItems: 'center' }}>
            <CommonDrawer toggleDrawer={toggleDrawer} state={state} component={<InterviewDashboardReport data={row} />} />
            <img src={visibleIcon} className="icon" onClick={toggleDrawer("right", true)} />
          </div>
        </TableCell>
        <TableCell component="th" scope="row" align="center" className="tableCell"> 
          <button className="btn" onClick={() => setPage({ index: 2, jdId: row?.jdId })}>View Details</button>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


const EmpScheduledInterviews = ({ setPage }) => {
  const [tableRows, setTableRows] = useState([]);

  const dispatch = useDispatch();
  const accessToken = useSelector(state => state?.auth?.userData?.accessToken);
  const clientCode = useSelector(state => state?.auth?.userData?.user?.clientCode);

  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [finalJds, setFinalJds] = useState([]);
  const [page1, setPage1] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);

  const handleSizeChange = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage1(1);
  };

  const handlePageChange = (change) => {
    if (change && page1 < Math.ceil(+total / +size)) {
      setPage1((prev) => prev + 1);
    } else if (!change && page1 > 1) {
      setPage1((prev) => prev - 1);
    }
  };

  useEffect(() => {
    dispatch(getActiveJds({ accessToken, clientCode }));
  }, []);

  useEffect(() => {
    async function getData() {
      try {
        const res = await getDashboardInterviewData(accessToken, clientCode, page1, size);
        setTableRows(res?.data);
        setTotal(res?.total)
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }
    getData();
  }, [page1, size]);

  useEffect(() => {
    async function getData() {
      try {
        const res = await getDashboardInterviewData(accessToken, clientCode);
        setFinalJds(res?.data);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }
    getData();
  }, []);

  console.log(searchValue);
  console.log(finalJds);

  useEffect(() => {
    if (searchValue?.trim()) {
      setSearch(true);
      setFilteredData(() =>
        finalJds?.filter(
          (item) =>
            item?.jdId?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
            item?.jdTitle?.toLowerCase()?.includes(searchValue?.toLowerCase())
        )
      );
    } else {
      setSearch(false);
    }
  }, [searchValue]);


  const handleSearch = () => {

  }

  // const data = {
  //   "jdIds": ["ABHI14", "JD14", "JD2002", "JD02"],
  //   "openReqs": 14,
  //   "totalReqs": 25,
  //   "totalCandidates": 812,
  //   "trackerStatus": {
  //     "IN_PROCESS": 73,
  //     "SHORTLISTED": 191,
  //     "HOLD": 25,
  //     "NOT_SELECTED": 21,
  //   },
  //   "jdRoundsInfos": [
  //     {
  //       "stage": 1,
  //       "data": {
  //         "SCHEDULED": 15,
  //         "COMPLETED": 10,
  //       }
  //     },
  //     {
  //       "stage": 0,
  //       "count": 191
  //     },
  //     {
  //       "stage": 2,
  //       "data": {
  //         "SCHEDULED": 33,
  //         "COMPLETED": 10,
  //       }
  //     }
  //   ],
  //   "jdStatus": {
  //     "ACTIVE_JDS": 30,
  //     "AVAILABLE_JD": 45
  //   },
  //   "source": {
  //     "seeker": 74,
  //     "employer_POOL": 701,
  //     "agency": {
  //       "AGENCY x": 145,
  //       "AGENCY y": 290,
  //       "AGENCY z": 129,
  //       "AGENCY za": 113,
  //     }
  //   },
  // };


  return (
    <Content>
      <TableContainer component={Paper} className="tableBox">
        <div className="titleBox">
          <span className="title">Interview Status</span>
        </div>

        <SearchBarContainer>
          <TableSearchBar value={searchValue} setValue={setSearchValue} />
        </SearchBarContainer>
        <Table aria-label="collapsible table">
          <TableHead className="tableHead">
            <TableRow>
              <TableCell align="center" className="tableCell">S. No.</TableCell>
              <TableCell align="center" className="tableCell">JD Title</TableCell>
              <TableCell align="center" className="tableCell"> Open/total Reqs</TableCell>
              <TableCell align="center" className="tableCell">Total Candidates</TableCell>
              <TableCell align="center" className="tableCell">InProcess Candidates</TableCell>
              <TableCell align="center" className="tableCell">Round wise Candidates Counts</TableCell>
              <TableCell align="center" className="tableCell">Source (Emp | Agency | Seeker)</TableCell>
              <TableCell align="center" className="tableCell">Report</TableCell>
              <TableCell align="center" className="tableCell">Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {
              search ?
                filteredData?.map((row, index) => (
                  <Row key={row?.id} row={row} index={index} setPage={setPage} page={page1} size={size} />
                ))
                :
                tableRows?.map((row, index) => (
                  <Row key={row?.id} row={row} index={index} setPage={setPage} page={page1} size={size} />
                ))
            }
          </TableBody>
        </Table>
        {!search && <div className="paginationBox">
          <PaginationSizeFilter
            size={size}
            handleSizeChange={handleSizeChange}
          />
          <Pagination
            total={total}
            size={size}
            page={page1}
            handlePageChange={handlePageChange}
            setPage={setPage1}
          />
        </div>}
      </TableContainer>
      <EmpScheduledInterviewsReport />
    </Content>
  )
}

export default EmpScheduledInterviews


const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  margin: 0.5rem auto;
  background-color: var(--white);
  border-radius: 0.5rem;;
  padding: 0rem 1rem;
  gap: 1rem;

`

const Content = styled.div`
margin: 1rem 0% 2rem 0%;
width: 98%;
padding: 0 1%;
display: flex;
flex-direction: column;
align-items: center;

.icon {
  width: 0.8rem;
  height: 0.8rem;
  cursor: pointer;
  border: 0.075rem solid grey;
  padding: 0.3rem;
  border-radius: 0.3rem;
}

.colored {
  background-color: #ececec;
}

.paginationBox {
  display: flex;
  justify-content: end;
  gap: 2rem;
  margin: 1rem 3rem 1.5rem 0;
}

.tableBox {
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.20);
  border-radius: 0.5rem;
  padding-top: 1rem;


  .title {
    padding-left: 1.2rem;
    font-size: 0.9rem;
    font-weight: 600;
  }

  .titleBox {
  width: 99%; 
  padding: 0.5rem 0rem;;
  display: flex;
  align-items: center;
  justify-content: space-between;
  }


}

.MuiTableCell-root {
  border: none;
}

.MuiTableRow-root {
  border-bottom: none;
}

.selected {
  background-color: #d9fbf9;
  color: white;
}

.tableHead {
  background-color: #d1fff0;
  width: 100%;

  .tableCell {
    font-size: 0.9rem;
    font-weight: 500;
    font-family: var(--font);
    color: var(--color);
  }
  
}

.tableBody {
  width: 100%;

  .tableCell {
    font-size: 0.8rem;
    font-weight: 400;
    font-family: var(--font);
    color: var(--color);
  }
}

.uniqueBox {
display: flex;
min-width: 10rem;
justify-content: center;

}

.innerBox {
max-width: 3rem;
display: flex;
flex-direction: column;
gap: 0.25rem;
align-items: start;

.text {
 display: flex;
 align-items: center;
}

.coloredStage {
 background-color: var(--lightOrange);
 color: var(--white);
 font-weight: 600;
 padding: 0.2rem;
 border-radius: 0.2rem;
 width: 1.2rem;
 height: 1.2rem;
 display: flex;
 align-items: center;
 justify-content: center;
}

}

.coloredPipe {
 color: var(--lightOrange);
 font-size: 1rem;
 font-weight: 600;
 line-height: 3rem;
}

.btn {
  padding: 0.5rem 0.8rem;
  background-color: var(--lightOrange);
  border: none;
  color: var(--white);
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 0.5rem;
  cursor: pointer;
  font-family: var(--font);
}

`
