import axios from "axios";

export const getQuestions = async (testId, accessToken) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/academic/questions/${testId}`, config);
        console.log('Data:', response?.data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}



