import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    Button,
    Select,
    MenuItem,
    InputLabel,
    Typography,
    FormControl,
} from '@mui/material';
import { FileUpload, ArrowForward } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { importCandidatesFromJds } from '../../../../functions/api/employers/importCandidatesFromJds';
import { toast } from 'react-toastify';
import Loader from '../../../commonComponents/Loader';
import { getJds } from '../../../../functions/api/employers/getJds';

const ImportCandidateFromOtherJd = ({ jdId }) => {
    const [selectedJD, setSelectedJD] = useState('');
    const [loading, setLoading] = useState(false);

    const [allJdList, setAllJdList] = useState([]);

    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

    useEffect(() => {
        async function getData() {
            try {
                const res = await getJds(accessToken, clientCode, "", 1, 10000000);
                if (res && res?.data?.data) {
                    setAllJdList(res?.data?.data?.filter(row => row?.jdId !== jdId) // Filter out the currently selected JD if necessary
                        ?.map(row => ({
                            jdId: row?.jdId,
                            title: row?.title,
                        })));
                }
            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }
        getData();
    }, []);

    const handleJDChange = (event) => {
        setSelectedJD(event.target.value);
    };

    const handleImport = async () => {
        try {
            setLoading(true);
            const res = await importCandidatesFromJds(jdId, selectedJD, accessToken, clientCode);
            if (res) {
                setLoading(false);
                toast.success("JD Candidates imported successfully");
            }
        } catch (error) {
            setLoading(false);
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    };

    console.log(allJdList);
    console.log(selectedJD);

    return (
        <MainBox>
            {loading && <Loader message={"Importing Candidates from JD..."} />}
            <Typography variant="body1" gutterBottom className='para'>
                Import candidates from another job description to this one. Select the source JD to import.
            </Typography>

            <StyledFormControl fullWidth>
                <InputLabel id="jd-select-label">Source Job Description</InputLabel>
                <Select
                    labelId="jd-select-label"
                    value={selectedJD}
                    label="Source Job Description"
                    onChange={handleJDChange}
                >
                    {allJdList?.map(jd => (
                        <MenuItem key={jd?.jdId} value={jd?.jdId}>{jd?.title}</MenuItem>
                    ))}
                </Select>
            </StyledFormControl>

            {selectedJD && (
                <>
                    <Button
                        variant="contained"
                        className='btn'
                        startIcon={<FileUpload />}
                        endIcon={<ArrowForward />}
                        onClick={handleImport}
                        disabled={selectedJD === ""}
                    >
                        Import Selected JD Candidates
                    </Button>
                </>
            )}
        </MainBox>
    );
};

export default ImportCandidateFromOtherJd;


const MainBox = styled.div`
display: flex;
flex-direction: column;
gap: 1rem;
align-items: center;

.para {
 font-size: 0.9rem;
 font-weight: 500;
 color: var(--color);
 font-family: var(--font);
 align-self: flex-start;
}

.btn {
 background-color: var(--lightOrange);
 margin-top: 2rem;
}

.btn:hover {
 background-color: var(--lightOrange);
}

`

const StyledFormControl = styled(FormControl)`
  margin-bottom: 1rem;
  min-width: 12rem;
`;
