import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getUsers = async (accessToken, clientCode, page = 1, size = 1000000) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'x-client-code': clientCode
        }
    };

    try {
        const response = await axios.get(`${API_URL}/api/employers/employees?page=${page}&size=${size}`, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const getAgencies = async (
    accessToken,
    clientCode,
    page = 1,
    size = 1000000
) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            'x-client-code': clientCode
        },
    };

    try {
        const response = await axios.get(`${API_URL}/api/employers/agencyMappings?page=${page}&size=${size}`, config);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const generateCustomReport = async (accessToken, clientCode, fromDate = null, toDate = null, reportData) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            "X-Client-Code": clientCode,
        },
    };

    let url = `${API_URL}/api/jds/downloadReportWithFilters`;

    const queryParams = [];
    if (fromDate) {
        queryParams.push(`fromDate=${encodeURIComponent(fromDate)}`);
    }
    if (toDate) {
        queryParams.push(`toDate=${encodeURIComponent(toDate)}`);
    }

    if (queryParams.length > 0) {
        url += `?${queryParams.join('&')}`;
    }

    try {
        const response =
            await axios.post(url, reportData, {
                ...config,
                responseType: 'arraybuffer'
            });

        return response.data;
    } catch (error) {
        throw error;
    }
};
