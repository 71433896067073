import React, { useEffect, useState } from 'react'
import { getQuestions } from '../../../../functions/api/employers/employerTest/getQuestions';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAllQuestions, setFormData } from '../../../../slices/testFormSlice';

const TestQuestionsLibrary = ({ onClose }) => {
  const dispatch = useDispatch();
  const accessToken = useSelector(state => state.auth.userData?.accessToken);
  const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);
  const [data, setData] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const formData = useSelector((state) => state.testForm.formData);
  const allQuestions = useSelector((state) => state.testForm.allQuestions);


  useEffect(() => {
    const getAllQuestions = async () => {
      try {
        const res = await getQuestions(accessToken, clientCode);
        if (res) {
          setData(res);
        }
      } catch (error) {
        const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again."
        toast.error(errMsg);
      }
    }
    getAllQuestions();
  }, [])

  const handleCheckboxChange = (question) => {
    setSelectedIds((prevSelectedQuestions) => {
      const isChecked = prevSelectedQuestions.some(item => item.id === question.id);

      const updatedSelectedQuestions = isChecked
        ? prevSelectedQuestions.filter(item => item.id !== question.id)
        : [...prevSelectedQuestions, question];

      return updatedSelectedQuestions;
    });
  };

  const handleUpdateFormData = () => {
    dispatch(setFormData({
      ...formData,
      questionIds: [
        ...new Set([
          ...formData.questionIds,  
          ...selectedIds.map((que) => que.id) 
        ])
      ]
    }));

    const updatedQuestions = [...allQuestions];

    selectedIds.forEach((selectedQuestion) => {
        if (!updatedQuestions.some(existingQuestion => existingQuestion.id === selectedQuestion.id)) {
            updatedQuestions.push(selectedQuestion);
        }
    });

    dispatch(setAllQuestions(updatedQuestions));

    onClose();
  };


  return (
    <Container>
      {data && data?.map((item, index) => (
        <ListItem key={item?.id}>
          <span className='questionBox'>
            <span className='questionBox1'>
              <span className='questionTop'>
                <span><span className='bold'>Que Type:</span> {item?.questionType}</span>
                <span><span className='bold'>Difficulty Level:</span> {item?.difficultyLevel}</span>
              </span>
              <span className='questionText'><span className='bold'>Que.</span> {item?.questionText}</span>
            </span>
            <span className='iconBox'>
              <input
                type='checkbox'
                className='checkBox'
                checked={selectedIds.some(selectedItem => selectedItem.id === item.id)}
                onChange={() => handleCheckboxChange(item)}
              />
            </span>
          </span>
        </ListItem>
      ))}
      <Button
        onClick={handleUpdateFormData}
        style={{ marginTop: '1rem' }}
      >
        Import Questions
      </Button>
    </Container>
  )
}

export default TestQuestionsLibrary


const Container = styled.div`
width: 90%;
min-height: calc(100vh - 5rem);
  display: flex;
  flex-direction: column;
  margin: 2rem auto;
  padding-bottom: 3rem;
  gap: 0.5rem;

  .floatBtn {
    color: var(--lightOrange);
    font-size: 0.85rem;
    font-weight: 600;
    cursor: pointer;
    margin-left: 1rem;
    text-decoration: none;
  }

  .floatBtn:hover {
    text-decoration: underline;
  }
`;



const ListItem = styled.div`
  background-color: #f8f8f8;
  border: 0.08rem solid #ddd;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.3rem;
//   transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

//   &:hover{
//       box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
//       transform: scale(1.02)
//   }

  .questionBox {
    display: flex;
    justify-content: space-between;
    align-items: start;

    .iconBox {
      display: flex;
      gap: 0.25rem;
      align-items: start;
    }

    .checkBox {
      width: 0.9rem;
      height: 0.9rem;
      cursor: pointer;
      padding: 0.25rem;
      border: 0.05rem solid lightgrey;
      border-radius: 0.25rem;
    }

    .questionBox1 {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .questionTop {
      display: flex;
      justify-content: start;
      gap: 2rem;
    }

    .bold {
      font-weight: 600;
    }
    
  

  }
`;

const Button = styled.button`
  padding: 0.5rem 0.8rem;
  background-color: var(--lightOrange);
  color: var(--white);
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  font-family: var(--font);
  display: flex;
  align-self: center;
  margin: 1rem auto;
`;