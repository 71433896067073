import React from 'react';
import styled from 'styled-components';
import { CheckCircle, Calendar, Mail, FileText } from 'lucide-react';

const Container = styled.div`
  max-width: 500px;
  height: 100%;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const Icon = styled(CheckCircle)`
  color: var(--lightOrange);
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  color: #343a40;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  color: #495057;
  margin-bottom: 1.5rem;
`;

const InfoBox = styled.div`
  background-color: #F8F9FA;
  border: 0.1rem solid var(--lightOrange);
  border-radius: 4px;
  padding: 1rem;
  margin-top: 1.5rem;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: #495057;
  text-align: left;

  svg {
    margin-right: 0.5rem;
    color: var(--lightOrange);
    flex-shrink: 0;
  }
`;

const Button = styled.button`
  background-color: var(--lightOrange);
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  margin-top: 1.5rem;
  cursor: pointer;
  font-size: 1rem;

`;

const CustomPriceRequestSuccessMessage = () => {
  return (
    <Container>
      <Icon size={48} />
      <Title>Request Received Successfully</Title>
      <Message>
        Thank you for your interest in our product! We've received your custom price request and are excited to show you how our solution can meet your specific needs.
      </Message>
      <InfoBox>
        <InfoItem>
          <Calendar size={20} />
          <span>We'll be in touch shortly to schedule a personalized product demo at your convenience.</span>
        </InfoItem>
        <InfoItem>
          <FileText size={20} />
          <span>After understanding your requirements during the demo, we'll prepare a tailored quotation for you.</span>
        </InfoItem>
        <InfoItem>
          <Mail size={20} />
          <span>Expect an email from us soon with demo scheduling options and additional information.</span>
        </InfoItem>
      </InfoBox>
    </Container>
  );
};

export default CustomPriceRequestSuccessMessage;