import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: linear-gradient(
  90deg, 
  rgba(0, 0, 0, 1) 37%,
  rgba(132, 255, 253, 1) 100%);
  padding: 3rem;
  border-radius: 1rem;
  color: #fff;
  width: 95%;
  margin: 0rem auto;
  box-sizing: border-box;
  font-family: 'Inria Serif', serif;

`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 0 0 1rem 0;
`;

const Description = styled.p`
  font-size: 1.25rem;
  margin: 0 0 2rem 0;
  line-height: 1.5rem;
`;

const Button = styled.button`
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  color: #000;
  background: #fff;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const CTA = () => {
  const Navigate = useNavigate();

  const handleScheduleDemo = () => {
    Navigate("/demo");
  }

  return (
    <Container>
      <Title>Revolutionize Your Hiring Process with AI</Title>
      <Description>
        Discover top talent faster, streamline your recruitment workflow, <br /> and
        make data-driven hiring decisions, all in one powerful platform.
      </Description>
      <Button onClick={() => handleScheduleDemo()}>Schedule a Demo</Button>
    </Container>
  );
};

export default CTA;
