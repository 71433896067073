import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useRef } from 'react';
import { styled } from "styled-components";
import { codingQuestionFormat } from '../../../utils/codingQuestionFormat';
import { useDispatch } from 'react-redux';
import { toggleAccordion } from '../../../slices/accordionSlice';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';


function Row(props) {
    const { row, testId, index } = props;
    const rowRef = useRef(null);
    const dispatch = useDispatch();
    const accordion = useSelector((state) => state.accordion);
    const accessToken = useSelector((state) => state.auth.userData?.accessToken);
    const clientCode = useSelector(
        (state) => state.auth.userData?.user?.clientCode
    ) || useSelector(state => state.auth.userData?.academicUser?.clientCode);


    useEffect(() => {
        if (accordion[testId]?.[index] && rowRef.current) {
            rowRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
                inlineMargin: '10rem',
            });
        }
    }, [accordion[testId]?.[index]]);

    const handleToggle = () => {
        dispatch(toggleAccordion({ interviewId: testId, rowIndex: index }));
    };


    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleToggle()}
                    >
                        {accordion[testId]?.[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" dangerouslySetInnerHTML={{ __html: codingQuestionFormat(`${row?.question?.slice(0, 300)} <strong> <span class="clickable-span">...</span></strong>`) }} onClick={() => handleToggle()}>

                </TableCell>
                <TableCell align="right">{row?.skipped ? "0" : row?.processingState == "FAILED" ? "Failed" : (row?.processingState == "NEW" || row?.processingState == "PROCESSING" ? <Loader></Loader> : row?.score)}</TableCell>
            </TableRow>
            <TableRow ref={rowRef}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                    <Collapse in={accordion[testId]?.[index]} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Question:</strong> <div style={{ fontSize: "0.7rem" }} dangerouslySetInnerHTML={{ __html: codingQuestionFormat(row?.question) }}></div>
                                {row?.questionType === 'mcq' && <><br />
                                    <strong>Options:</strong> <div style={{ fontSize: "0.7rem" }}>{row?.mcqOptions ? row?.mcqOptions?.map((item, index) => <p>{item}</p>) : ""}</div>
                                </>}
                                <br />
                                <strong>Your Answer:</strong> <div style={{ fontSize: "0.7rem" }}>{row?.answer ? row?.answer : row?.skipped ? "skipped" : "Not Answered"}</div>
                                <br />
                                <strong>Expected Answer:</strong> <div style={{ fontSize: "0.7rem" }}>{row?.expectedAnswer ? row?.expectedAnswer : "Not Available"}</div>
                            </Typography>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function TestScorecardTemplate({ rows, trigger, setTrigger, apiCall, setApiCall, testId }) {
    const [tableRows, setTableRows] = useState(rows);
    const idRef = useRef(null);

    useEffect(() => {
        setApiCall(rows?.some((item) => item?.processingState == "PROCESSING" || (item?.processingState == "NEW" && item?.skipped == false)));

        return () => {
            if (idRef?.current) {
                clearTimeout(idRef.current);
                idRef.current = null;
            }
        }
    }, [])

    if (apiCall) {
        idRef.current = setTimeout(() => {
            setTrigger(prev => !prev);
        }, 30000);
    }


    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Question</TableCell>
                        <TableCell align="right">Score</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableRows?.map((row, index) => (
                        <Row key={row?.question} row={row} testId={testId} index={index} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


const Loader = styled.span`
  width: 1.3rem;
  height: 1.3rem;
  border: 1.5px solid var(--academicLavendar);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

`

const Button = styled.button`
font-size: 0.8rem;
font-weight: 500;
background-color: var(--white);
border: 0.05rem solid var(--color);
border-radius: 0.25rem;
padding: 0.25rem 0.4rem;
color: var(--color);
cursor: pointer;
margin: 0.5rem 0;


`