import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../../component/shared/Button';
import { useViewManagement } from '../hooks/useViewManagement';
import { useModal } from '../hooks/useModal';
import { useTestEditing } from '../hooks/useTestEditing';
import TestFormPage from './TestFormPage';
import TestTypeSelection from '../components/TestTypeSelection';
import TestTabs from './TestTabs';
import { useDispatch } from 'react-redux';
import { clearFormData } from '../../../../slices/testFormSlice';
import AiBasedTest from '../../../../components/Interviews/EmployerDashboard/sidebarPages/AiBasedTest';

const TestParentPage = () => {
    const dispatch = useDispatch();
    const { currentView, handleViewChange } = useViewManagement();
    const { isModalOpen, openModal, closeModal } = useModal();
    const { editingIndex, editingTestId, editingTestData, testQuestionData, testType, handleEditTest, handleTypeSelect, setEditingIndex } = useTestEditing();
    const [testTrigger, setTestTrigger] = useState(false);

    const handleCreateTest = () => {
        openModal();
        setEditingIndex(-1);
        dispatch(clearFormData());
        console.log("Created the test");
    }

    const handleCardClick = useCallback((view) => {
        closeModal();
        handleViewChange(view);
    }, [handleViewChange]);

    const handleEditClick = useCallback((editingIndex, id, testData, testQuestions, view) => {
        handleEditTest(editingIndex, id, testData, testQuestions);
        handleViewChange(view);
    }, [handleViewChange]);

    const renderCurrentView = () => {
        switch (currentView) {
            case 'test':
                return (
                    <TestTabs
                        createTest={handleCreateTest}
                        onEditTest={handleEditClick}
                        setTestTrigger={setTestTrigger}
                        testTrigger={testTrigger}
                    />
                );
            case 'create-ai':
                return (
                    <AiBasedTest
                        toggleView={handleViewChange}
                        setTestTrigger={setTestTrigger}
                    />
                );
            case 'create':
                return (
                    <TestFormPage
                        editingIndex={editingIndex}
                        editingTestId={editingTestId}
                        editingTestData={editingIndex != -1 && editingTestData}
                        testQuestionData={editingIndex != -1 && testQuestionData}
                        isReview={false}
                        toggleView={handleViewChange}
                        setTestTrigger={setTestTrigger}
                    />
                );
            case 'review':
                return (
                    <TestFormPage
                        editingIndex={editingIndex}
                        editingTestId={editingTestId}
                        editingTestData={editingIndex != -1 && editingTestData}
                        testQuestionData={editingIndex != -1 && testQuestionData}
                        isReview={true}
                        toggleView={handleViewChange}
                        setTestTrigger={setTestTrigger}
                    />
                )
            default:
                return <div>Select an operation</div>;
        }
    };



    return (
        <Container>
            <Content>
                {renderCurrentView()}
            </Content>

            {isModalOpen && (
                <Modal>
                    <ModalContent>
                        <div className='btnBox'>
                            <span className='title'>Choose a method to create your test</span>
                            <Button text="Close" func={closeModal} disabled={false} />
                        </div>
                        <TestTypeSelection onSelect={handleCardClick} />
                    </ModalContent>
                </Modal>
            )}
        </Container>
    );
};

export default TestParentPage;



const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const TopNav = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;
`;

const Content = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    width: 80%;
    height: 68%;
    overflow-y: auto;

    .btnBox {
     display: flex;
     justify-content: space-between;
     align-items: center;
     margin-bottom: 1.5rem;

     .title {
      font-size: 1rem;
      font-weight: 700;
     }
    }
`;


