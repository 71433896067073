import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router";
import ProgressBar from "../commonComponents/ProgressBar";
import SeekerTableSearchBar from "./SeekerDashboard/seekerCommonComponents/SeekerTableSearchBar";
import { Pagination, PaginationSizeFilter } from "../commonComponents/Pagination";
import { useSelector } from "react-redux";
import { getInterviewByStatus } from "../../functions/api/getInterviewByStatus";
import { toast } from "react-toastify";
import { getEmployer } from "../../functions/api/employers/profile/getEmployer";
import view from '../../assets/icons/visible.png'
import CommonDrawer from '../commonComponents/CommonDrawer';
import SeekerInterviewDetails from './SeekerDashboard/sidebarPages/SeekerInterviewDetails';

const Row = (props) => {
  const { row, index } = props;
  const navigate = useNavigate();
  const [companyDetails, setCompanyDetails] = useState({});
  const accessToken = useSelector(state => state.auth.userData?.accessToken);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getEmployer(accessToken, row?.clientCode);
        if (res) setCompanyDetails(res?.data);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }

    getData();
  }, [])

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <React.Fragment>
      <TableRow
        className={`${index % 2 == 1 ? 'colored' : ''}`}
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        <TableCell component="th" scope="row" align='center' className='logo tableCell'>
          <img src={companyDetails?.companyLogo} style={{ height: "2rem", width: "2rem" }} />
        </TableCell>
        <TableCell component="th" scope="row" align='center' className='tableCell'>
          {companyDetails?.companyName}
        </TableCell>
        <TableCell component="th" scope="row" align='center' className='tableCell'>
          {row?.jdId ? row?.jdId?.toUpperCase() : "MOCK"}
        </TableCell>
        <TableCell component="th" scope="row" align='center' className='tableCell'>
          {row?.testType?.toUpperCase()}
        </TableCell>
        <TableCell component="th" scope="row" align='center' className='tableCell'>
          {row?.createdAt?.split('T')[0]}
        </TableCell>
        <TableCell component="th" scope="row" align="center" className='tableCell'>
          <div style={{ display: 'flex', gap: '0.5rem', justifyContent: 'center', alignItems: 'center' }}>
            <CommonDrawer toggleDrawer={toggleDrawer} state={state} component={<SeekerInterviewDetails data={row} employer={false} />} />
            <img src={view} style={{ width: '0.8rem', height: '0.8rem', cursor: 'pointer', border: '0.08rem solid grey', padding: '0.3rem', borderRadius: '0.3rem' }} onClick={toggleDrawer("right", true)} />
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const InterviewList = () => {
  const [searchValue, setSearchValue] = useState('');
  const accessToken = useSelector(state => state?.auth?.userData?.accessToken)
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [filteredData, setFilteredData] = useState();
  const [value, setValue] = useState("COMPLETED");
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSizeChange = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handlePageChange = (change) => {
    if (change && page < Math.ceil(+total / +size)) {
      setPage((prev) => prev + 1);
    } else if (!change && page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    async function getData(value) {
      try {
        const response = await getInterviewByStatus(value, accessToken, page, size);
        if (response) {
          setFilteredData(response?.data?.data);
          setTotal(response?.data?.total);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }
    getData(value);
  }, [value, page, size]);

  if (total === 0) {
    return <h6 style={{ fontSize: '1.2rem' }}>No interview Here</h6>
  }



  return (
    <StyledInterviews>
      <TableContainer component={Paper} className="tableBox">
        <span className='title'>Completed Interviews</span>

        <SearchBarContainer>
          <SeekerTableSearchBar value={searchValue} setValue={setSearchValue} />
        </SearchBarContainer>
        <Table aria-label="collapsible table">
          <TableHead className="tableHead">
            <TableRow>
              <TableCell align='center' className='tableCell'></TableCell>
              <TableCell align='center' className='tableCell'>Company</TableCell>
              <TableCell align='center' className='tableCell'>JD ID</TableCell>
              <TableCell align='center' className='tableCell'>Test Type</TableCell>
              <TableCell align='center' className='tableCell'>Date of Interview</TableCell>
              <TableCell align="center" className='tableCell'>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {filteredData?.map((row, index) => (
              <Row key={index} row={row} index={index} />
            ))}
          </TableBody>
        </Table>
        <div className="paginationBox">
          <PaginationSizeFilter
            size={size}
            handleSizeChange={handleSizeChange}
          />
          <Pagination
            total={total}
            size={size}
            page={page}
            handlePageChange={handlePageChange}
            setPage={setPage}
          />
        </div>
      </TableContainer>
    </StyledInterviews>
  );
};

export default InterviewList;

const StyledInterviews = styled.div`
  display: flex;
  width: 95%;
  margin: 2.5rem auto;

  .colored {
    background-color: #ececec;
  }

  .tableBox {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.20);
    border-radius: 0.5rem;
    padding-top: 1rem;


    .title {
      padding-left: 1.2rem;
      font-size: 0.9rem;
      font-weight: 600;
    }
  }
  
  .tableBox {
    box-shadow: 0 0 0.7rem 0 rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
  }

  .MuiTableCell-root {
    border: none;
  }

  .MuiTableRow-root {
    border-bottom: none;
  }

  .btn {
    background-color: var(--lightOrange);
    padding: 0.5rem 0.8rem;
    border: none;
    color: var(--white);
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: pointer;
    font-family: var(--font);
  }

  .paginationBox {
    display: flex;
    justify-content: end;
    gap: 2rem;
    margin: 1rem 3rem 1.5rem 0;
  }

  .tableHead {
    background-color: #d1fff0;
    width: 100%;
  
    .tableCell {
      font-size: 0.9rem;
      font-weight: 500;
      font-family: var(--font);
      color: var(--color);
    }
    
  }
  
  .tableBody {
    width: 100%;
  
    .tableCell {
      font-size: 0.8rem;
      font-weight: 400;
      font-family: var(--font);
      color: var(--color);
    }
  }

  .logo {
    width: 2rem;
    height: 2rem;

    img {
        width: 100%;
        height: 100%;
        border-radius: 10%;
    }
  }

  .selected {
    background-color: #d9fbf9;
    color: white;
  }
`;



const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  margin: 1rem auto 0.5rem auto;
  height: 3rem;
  background-color: var(--white);
  border-radius: 0.5rem;;
  padding: 0rem 1rem;
  gap: 1rem;
`
