export const questionTypes = [
    {
        title: "Subjective",
        description: "Open-ended questions requiring detailed and thoughtful responses to assess understanding.",
        questionType: "subjective"
    },
    {
        title: "Coding",
        description: "Hands-on coding tasks to assess programming skills and problem-solving capabilities in real scenarios.",
        questionType: "coding"
    },
    {
        title: "MCQ",
        description: "Multiple-choice questions with predefined options to evaluate knowledge and quick decision-making skills.",
        questionType: "mcq"
    }
];
