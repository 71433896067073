import React from 'react';
import styled from 'styled-components';

const EmpCommonButton = ({ text, func, width, disabled }) => {
    return (
        <Button 
            onClick={func} 
            style={{ width: width ? width : '' }} 
            disabled={disabled ? disabled : false}
        >
            {text}
        </Button>
    );
}

export default EmpCommonButton;

const Button = styled.button`
    background-color: var(--lightOrange);
    color: var(--white);
    border-radius: 0.5rem;
    font-size: 0.9rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    padding: 0.5rem 0.8rem;
    display: flex;
    justify-content: center;

    &:disabled {
        cursor: not-allowed;
        color: #F4F4F4;
    }
`;
