import React, { Component } from 'react'
// import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import drawerIcon from '../../assets/icons/drawerIcon.png'
import styled from 'styled-components';

const LeftCommonDrawer = ({ toggleDrawer, state, component }) => {

    const list = (anchor) => (
        <Box
            role="presentation"
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <img src={drawerIcon} style={{ width: '2rem', cursor: 'pointer', position: 'fixed', top: '50%', left: 'calc(50vw + 2rem)', transform: 'rotate(180deg)' }} onClick={toggleDrawer(anchor, false)} />
            {component && component}
        </Box>
    );

    return (
        <div>
            {['left'].map((anchor) => (
                <div key={anchor} style={{ width: '100%' }}>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </div>
            ))}
        </div>
    )
}

export default LeftCommonDrawer



const Box = styled.div`
width: 50vw;
margin-top: 3rem;
padding: 1rem;
padding-right: 3rem;
transition: width 3s cubic-bezier(0.23, 1, 0.32, 1);

&::-webkit-scrollbar {
    display: none;
}

  &::-webkit-scrollbar-track {
    background: lightgrey;
    border-radius: 0.4rem;
}

  &::-webkit-scrollbar-thumb {
    background: grey;
    width: 0.4rem;
    border-radius: 0.4rem;
}

& {
  scrollbar-width: none;
} 

 -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
`

