import React, { useState } from "react";
import styled from "styled-components";
import LogoHeader from "../../../commonComponents/LogoHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import eyeIcon from "../../../../assets/icons/visible.png";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { getBlobData } from "../../../../functions/api/resume/getBlobData";
import { toast } from "react-toastify";
import CommonDrawer from "../../../commonComponents/CommonDrawer";
import InviteReviewListDetails from "../commonComponents/InviteReviewListDetails";

function Row(props) {
  const { row, index, data } = props;

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={`${index % 2 == 1 ? "colored" : ""}`}
      >
        <TableCell component="th" scope="row" align="center" className="tableCell">
          {row?.name ? row?.name : "..."}
        </TableCell>
        <TableCell component="th" scope="row" align="center" className="tableCell">
          {row?.email}
        </TableCell>
        <TableCell component="th" scope="row" align="center" className="tableCell">
          {row?.contact}
        </TableCell>
        <TableCell
          align="center"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.3rem",
          }}
          className="tableCell"
        >
          <CommonDrawer toggleDrawer={toggleDrawer} state={state} component={<InviteReviewListDetails data={data} resumeId={row?.resumeId} resumeFileName={row?.name} />} />
          <img src={eyeIcon} onClick={toggleDrawer("right", true)} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const InviteReviewList = ({data}) => {
  const invites = useSelector((state) => state.invite.selectedResumes);
  const { productType, testType, interviewType, difficultyLevel, numberOfQue } = data;

  return (
    <Container>
      <TableContainer component={Paper} className="tableBox">
        <Table aria-label="collapsible table">
          <TableHead className="tableHead">
            <TableRow>
              <TableCell align="center" className="tableCell">Name</TableCell>
              <TableCell align="center" className="tableCell">Email</TableCell>
              <TableCell align="center" className="tableCell">Contact</TableCell>
              <TableCell align="center" className="tableCell">Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {invites?.map((row, index) => (
              <Row row={row} index={index} data={data} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default InviteReviewList;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3.5rem 2% 2rem 2%;
  width: 96%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .colored {
    background-color: #ececec;
  }

  .tableBox {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
  }

  .MuiTableCell-root {
    border: none;
  }

  .MuiTableRow-root {
    border-bottom: none;
  }

  .selected {
    background-color: #d9fbf9;
    color: white;
  }

  .tableHead {
    background-color: #d1fff0;
    width: 100%;
  
    .tableCell {
      font-size: 0.9rem;
      font-weight: 500;
      font-family: var(--font);
      color: var(--color);
    }
    
  }
  
  .tableBody {
    width: 100%;
  
    .tableCell {
      font-size: 0.8rem;
      font-weight: 400;
      font-family: var(--font);
      color: var(--color);
    }
  }


  .checkBox {
    cursor: pointer;
    width: 1rem;
    height: 1rem;
  }

  img {
    width: 1.2rem;
    cursor: pointer;
  }
`;
