import React from 'react';
import styled from 'styled-components';
import { Languages } from 'lucide-react';
import { ChevronsRight } from 'lucide-react';
import { LaptopMinimal } from 'lucide-react';
import { Users } from 'lucide-react';
import { BarChartBig } from 'lucide-react';
import { Clock3 } from 'lucide-react';
import { GanttChartSquare } from 'lucide-react';
import { Percent } from 'lucide-react';
import { DatabaseZap } from 'lucide-react';

const cardData = [
    { img: <Languages size={40}/>, text: 'Leverage the potential of Natural Language Search technology to optimize the value derived from your current CRM database.' },
    { img: <ChevronsRight size={40}/>, text: 'Harness current data rapidly and effectively.' },
    { img: <LaptopMinimal size={40}/>, text: 'Consolidate and simplify online resourcing operations.' },
    { img: <Users size={40}/>, text: 'Utilize every accessible source, including the presently available pool of candidates.' },
    { img: <BarChartBig size={40}/>, text: 'Enhance the efficiency of recruiters and sourcers.' },
    { img: <Clock3 size={40}/>, text: 'Minimize the time required to provide clients with the most suitable resumes and CVs.' },
    { img: <GanttChartSquare size={40}/>, text: 'Evaluate the effectiveness of all resource-related activities.' },
    { img: <Percent size={40}/>, text: 'Assess the ROI of your online CV expenditure.' },
    { img: <DatabaseZap size={40}/>, text: 'Effortlessly construct your CRM database using the highest-quality data and available candidates.' },
];

const BenefitsSection = () => {
    return (
        <Container>
            <Description>
                RegorTalent offers comprehensive process automation, minimizing manual efforts in recruitment. Our solution streamlines the entire hiring process by eliminating initial sourcing, shortlisting efforts, and the first rounds of interviews. With highly customizable AI-powered systems, we ensure the delivery of top talent, providing peace of mind throughout the application life cycle. This not only optimizes your hiring process but also reduces costs and time spent on each hire, allowing you to invest those resources in further growth.
            </Description>

            <CardsContainer>
                {cardData.map((card, index) => (
                    <Card key={index}>
                        {card.img}
                        <CardText>{card.text}</CardText>
                    </Card>
                ))}
            </CardsContainer>
        </Container>
    );
};

export default BenefitsSection;

const Container = styled.div`
    width: 90%;
    margin: 2.5rem auto;
    display: flex;
    flex-direction: column;
`;

const Description = styled.span`
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.2rem;
    margin-bottom: 3rem;
`;

const CardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: space-between;
    margin-bottom: 2rem;
`;

const Card = styled.div`
    display: flex;
    align-items: center;
    width: calc(33.333% - 2rem); // Adjust for spacing
    gap: 1rem;

    img {
        width: 3.5rem;
        height: auto;
    }
`;

const CardText = styled.span`
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.1rem;
    flex: 1;
`;
