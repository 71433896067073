import profileIcon from '../../assets/icons/profile.png'
import notificationIcon from '../../assets/icons/notification.png'
import scheduleIcon from '../../assets/icons/schedule.png'
import subsIcon from '../../assets/icons/subscription.png'
import jdIcon from '../../assets/icons/job-description.png'
import candidatePool from '../../assets/icons/candidatePool.png'
import activeJdIcon from '../../assets/icons/active-job-desc.png'
import createTestIcon from '../../assets/icons/createTest.png'
import dashboardIcon from '../../assets/icons/dashboard.png'
import billingIcon from '../../assets/icons/billing.png'
import activityIcon from '../../assets/icons/activityLogs.png'
import reportIcon from '../../assets/icons/report.png'
import configureReportIcon from '../../assets/icons/configure-reports.png'
import configureDashboardIcon from '../../assets/icons/configure-dashboard.png'
import addUser from '../../assets/icons/addUser.png'
import availableTestIcon from '../../assets/icons/test.png'
import manageUsersIcon from '../../assets/icons/manageUsers.png'
import titleIcon1 from '../../assets/icons/titleIcon1.png'
import titleIcon2 from '../../assets/icons/titleIcon2.png'
import titleIcon3 from '../../assets/icons/titleIcon3.png'
import titleIcon4 from '../../assets/icons/titleIcon4.png'
import titleIcon5 from '../../assets/icons/titleIcon5.png'
import titleIcon6 from '../../assets/icons/titleIcon6.png'
import uploadProfileIcon from '../../assets/icons/uploadProfileIcon.png'
import applicationBlackIcon from '../../assets/icons/applicationBlack.png'


// Employer Menu Items
const employerMenuItems = [
    {
        title: 'Home',
        icon: titleIcon1,
        isExpanded: true,
        subItems: [
            {
                title: 'Dashboard',
                key: 'dashboard',
                isExpanded: false,
                subItems: [
                    {
                        title: 'Dashboard',
                        icon: dashboardIcon,
                        key: 'dashboard',
                        isExpanded: false
                    },
                    {
                        title: 'Configure Dashboard',
                        icon: configureDashboardIcon,
                        key: 'configure-dashboard',
                        isExpanded: false
                    }
                ]
            }
        ]
    },
    {
        title: 'Sourcing',
        icon: titleIcon2,
        isExpanded: true,
        subItems: [
            {
                title: 'Manage JDs',
                isExpanded: false,
                subItems: [
                    {
                        title: 'Available JDs',
                        icon: activeJdIcon,
                        key: 'jds',
                        isExpanded: false
                    },
                    {
                        title: 'Create JDs',
                        icon: jdIcon,
                        key: 'create-jds',
                        isExpanded: false
                    }
                ]
            },
            {
                title: 'Manage Candidates',
                isExpanded: false,
                subItems: [
                    {
                        title: 'Add Candidates',
                        icon: addUser,
                        key: 'candidate-register',
                        isExpanded: false
                    },
                    {
                        title: 'Candidates Pool',
                        icon: candidatePool,
                        key: 'candidate-registered',
                        isExpanded: false
                    },
                    {
                        title: 'Upload Profiles',
                        icon: uploadProfileIcon,
                        key: 'upload-profiles',
                        isExpanded: false
                    },
                    {
                        title: 'Applications',
                        icon: applicationBlackIcon,
                        key: 'applications',
                        isExpanded: false
                    }
                ]
            }
        ]
    },
    {
        title: 'Interview & ATS',
        icon: titleIcon3,
        isExpanded: true,
        subItems: [
            {
                title: 'Manage Interviews',
                isExpanded: false,
                subItems: [
                    {
                        title: 'Interview Dashboard',
                        icon: dashboardIcon,
                        key: 'interview-dashboard',
                        isExpanded: false
                    },
                    {
                        title: 'Schedule Interviews',
                        icon: scheduleIcon,
                        key: 'schedule-interview',
                        isExternalLink: true,
                        url: '/schedule',
                        isExpanded: false
                    },
                    {
                        title: 'Schedule Bulk Interviews',
                        icon: scheduleIcon,
                        key: 'schedule-bulk-interview',
                        isExternalLink: true,
                        url: '/schedule/bulk',
                        isExpanded: false
                    }
                ]
            },
            {
                title: 'Manage Tests',
                isExpanded: false,
                subItems: [
                    {
                        title: 'Question Library',
                        icon: createTestIcon,
                        key: 'create-tests',
                        isExpanded: false
                    },
                    {
                        title: 'Manage Tests',
                        icon: availableTestIcon,
                        key: 'available-tests',
                        isExpanded: false
                    }
                ]
            }
        ]
    },
    {
        title: 'Analytics',
        icon: titleIcon4,
        isExpanded: true,
        subItems: [
            {
                title: 'Reports',
                isExpanded: false,
                subItems: [
                    {
                        title: 'Reports',
                        icon: reportIcon,
                        key: 'report',
                        isExpanded: false
                    },
                    {
                        title: 'Custom Reports',
                        icon: configureReportIcon,
                        key: 'custom-report',
                        isExpanded: false
                    }
                ]
            }
        ]
    },
    {
        title: 'Admin',
        icon: titleIcon5,
        isExpanded: true,
        subItems: [
            {
                title: 'Manage Profile',
                isExpanded: false,
                subItems: [
                    {
                        title: 'Profile',
                        icon: profileIcon,
                        key: 'profile',
                        isExpanded: false
                    },
                    {
                        title: 'Manage Users',
                        icon: manageUsersIcon,
                        key: 'manage-users',
                        isExpanded: false
                    },
                    {
                        title: 'Manage Agencies',
                        icon: manageUsersIcon,
                        key: 'manage-agencies',
                        isExpanded: false
                    },
                    {
                        title: 'Subscriptions',
                        icon: subsIcon,
                        key: 'subscriptions',
                        isExpanded: false
                    },
                    {
                        title: 'Activity Logs',
                        icon: activityIcon,
                        key: 'activity-logs',
                        isExpanded: false
                    },
                    {
                        title: 'Billing',
                        icon: billingIcon,
                        key: 'billing',
                        isExpanded: false
                    },
                    {
                        title: 'Notifications',
                        icon: notificationIcon,
                        key: 'inbox',
                        isExpanded: false
                    }
                ]
            }
        ]
    },
    {
        title: 'Support',
        icon: titleIcon6,
        isExpanded: true,
        subItems: [
            {
                title: 'Create Ticket',
                isExternalLink: true,
                url: 'servicedesk/customer/portal/4',
                key: 'create-ticket',
                isExpanded: false
            },
            {
                title: 'Contact Support',
                key: 'call-support',
                isExpanded: false
            }
        ]
    }
];

// Agency Menu Items
const agencyMenuItems = [
    {
        title: 'Home',
        icon: titleIcon1,
        isExpanded: true,
        subItems: [
            {
                title: 'Dashboard',
                key: 'dashboard',
                isExpanded: false,
                subItems: [
                    {
                        title: 'Dashboard',
                        icon: dashboardIcon,
                        key: 'dashboard',
                        isExpanded: false
                    }
                ]
            }
        ]
    },
    {
        title: 'Sourcing',
        icon: titleIcon2,
        isExpanded: true,
        subItems: [
            {
                title: 'Manage JDs',
                isExpanded: false,
                subItems: [
                    {
                        title: 'JD Listings',
                        icon: activeJdIcon,
                        key: 'jdListing',
                        isExpanded: false
                    }
                ]
            }
        ]
    },
    {
        title: 'Analytics',
        icon: titleIcon4,
        isExpanded: true,
        subItems: [
            {
                title: 'Reports',
                isExpanded: false,
                subItems: [
                    {
                        title: 'Reports',
                        icon: reportIcon,
                        key: 'report',
                        isExpanded: false
                    },
                    {
                        title: 'Configure Reports',
                        icon: configureReportIcon,
                        key: 'configure-report',
                        isExpanded: false
                    }
                ]
            }
        ]
    },
    {
        title: 'Admin',
        icon: titleIcon5,
        isExpanded: true,
        subItems: [
            {
                title: 'Manage Profile',
                isExpanded: false,
                subItems: [
                    {
                        title: 'Profile',
                        icon: profileIcon,
                        key: 'profile',
                        isExpanded: false
                    },
                    {
                        title: 'Manage Users',
                        icon: manageUsersIcon,
                        key: 'manage-users',
                        isExpanded: false
                    },
                    {
                        title: 'Subscriptions',
                        icon: subsIcon,
                        key: 'subscriptions',
                        isExpanded: false
                    },
                    {
                        title: 'Billing',
                        icon: billingIcon,
                        key: 'billing',
                        isExpanded: false
                    },
                    {
                        title: 'Notifications',
                        icon: notificationIcon,
                        key: 'inbox',
                        isExpanded: false
                    }
                ]
            }
        ]
    },
    {
        title: 'Support',
        icon: titleIcon6,
        isExpanded: true,
        subItems: [
            {
                title: 'Create Ticket',
                isExternalLink: true,
                url: 'servicedesk/customer/portal/4',
                key: 'create-ticket',
                isExpanded: false
            },
            {
                title: 'Contact Support',
                key: 'call-support',
                isExpanded: false
            }
        ]
    }
];

export { employerMenuItems, agencyMenuItems };


