import axios from "axios";
import { toast } from "react-toastify";



export const forgetPassword = async (email,clientCode = 'regortalent') => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-client-code': clientCode
    }
  };
  

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/forgetpwd?email=${email}`,config);
        console.log('Data:', response.data);
        return response.data;
      } catch (error) {
        throw error;
      }
}


