import styled from 'styled-components';
import TextField from '@mui/material/TextField';

const StyledTextField = styled(TextField)`
  @media (max-width: 2000px) {
    padding: 0.75rem 0.5rem;
  }

  @media (max-width: 1700px) {
    padding: 0.85rem 0.5rem;
  }

  @media (max-width: 1350px) {
    padding: 0.95rem 0.5rem;
  }

  @media (max-width: 1200px) {
    padding: 1rem 0.5rem;
  }
`;

export default StyledTextField;
