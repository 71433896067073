import React, { useState } from 'react'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import styled from 'styled-components';
import LogoHeader from '../../../commonComponents/LogoHeader';
import ShortlistedResumeForInPerson from './ShortlistedResumeForInPerson';
import InterviewFlowCompletedForInPerson from './InterviewFlowCompletedForInPerson';

const InPersonModalTabs = ({handleClose}) => {
    const [value, setValue] = useState("SHORTLISTED");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return (
        <StyledDiv>
            <StyledBox>
                <Tabs
                    style={{
                        width: '50%',
                        borderRadius: '3rem',
                        backgroundColor: 'var(--lightOrange)'
                    }}
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "var(--lightOrange)",
                        },
                    }}
                    variant="fullWidth"
                    aria-label="wrapped label tabs example"
                >
                    <Tab
                        value="SHORTLISTED"
                        label="Shortlisted"
                        sx={{
                            color: "var(--lightOrange)",
                            fontSize: "0.8rem",
                        }}
                        classes={{ root: 'custom-tab', selected: 'custom-tab-selected' }}
                    />
                    <Tab
                        value="COMPLETED"
                        label="Interviews Completed"
                        sx={{
                            color: "var(--lightOrange)",
                            fontSize: "0.8rem",
                        }}
                        classes={{ root: 'custom-tab', selected: 'custom-tab-selected' }}
                    />
                </Tabs>
                {value === 'SHORTLISTED' && <ShortlistedResumeForInPerson handleClose={handleClose} />}
                {value === 'COMPLETED' && <InterviewFlowCompletedForInPerson handleClose={handleClose}/>}
            </StyledBox>
        </StyledDiv>
    )
}



export default InPersonModalTabs


const StyledDiv = styled.div`
      display: flex;
      flex-direction: column;
      width: 100%;
    `;


const StyledBox = styled.div`
        width: 99%;
        min-height: 30rem;
        margin: 1rem 0% 2rem 0%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    
    
    
        // Custom styled for tabs
    
        .custom-tab {
          color: white;
          background-color: var(--lightOrange);
          transition: background-color 0.3s;
          text-decoration: none !important;
        }
    
    .custom-tab-selected {
        background-color: white;
        color: var(--lightOrange) !important;
        border: 0.1rem solid var(--lightOrange);
        border-radius: 3rem;
        text-decoration: none !important;
      }
    
    .custom-tab-selected .MuiTab-label {
      text-transform: none;
    }
    `
