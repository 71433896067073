import React from 'react';
import { CheckCircle, Mail } from 'lucide-react';
import styled from 'styled-components';
import Button from '../../../component/shared/Button';
import { useNavigate } from 'react-router';

const Container = styled.div`
  width: 100%;
  max-width: 550px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;


const IconWrapper = styled.div`
  border-radius: 50%;
  background-color: #d1fae5;
  padding: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

const Title = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
`;

const Description = styled.p`
  color: #4b5563;
`;

const AlertStyled = styled.div`
  background-color: #eff6ff;
  border-color: #bfdbfe;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem;
`;

const MailIcon = styled(Mail)`
  height: 1.25rem;
  width: 1.25rem;
  color: #3b82f6;
`;

const AlertTitleStyled = styled.span`
  color: #1d4ed8;
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
`;

const AlertDescriptionStyled = styled.ol`
  color: #1e40af;
  margin-top: 1rem;
  list-style-type: decimal;
  list-style-position: inside;
  padding-left: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: start;
  font-size: 1rem;
  font-weight: 500;


`;

const AdditionalInfo = styled.div`
  font-size: 0.875rem;
  color: #6b7280;
  display: flex;
  flex-direction: column;

`;

const EmployerAccountRegisterSuccessMessage = () => {
    const Navigate = useNavigate();

    return (
        <Container>
                        <IconWrapper>
                            <CheckCircle className="w-12 h-12 text-green-600" />
                        </IconWrapper>

                        <div className='topBox'>
                            <Title>Registration Successful!</Title>
                            <Description>Thank you for choosing our platform</Description>
                        </div>

                        <AlertStyled>
                            <MailIcon />
                            <AlertTitleStyled>Next Steps</AlertTitleStyled>
                            <AlertDescriptionStyled>
                                    <li>Our team will review your registration details</li>
                                    <li>Once approved, you'll receive an activation link via email</li>
                                    <li>Click the activation link to verify your account</li>
                                    <li>After activation, you'll have full access to the portal through account</li>
                            </AlertDescriptionStyled>
                        </AlertStyled>

                        <Button text="Go to Homepage" func={() => Navigate("/")} />

                        <AdditionalInfo>
                            <span>Please check your email regularly for the activation link.</span>
                            <span>This process usually takes 12-24 hours.</span>
                        </AdditionalInfo>
        </Container>
    );
};

export default EmployerAccountRegisterSuccessMessage;
