import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import LazyLoadImage from './LazyLoadImage';

const ProductCardWrapper = styled.div`
  width: 22.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
    gap: 1rem;
    padding: 2rem 0rem;
    border-radius: 1rem;

& img {
    width: 60%;
}
    &:hover {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
    background: linear-gradient(to bottom, #f0f0f0, #89f3eb);
}


p {
    width: 80%;
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
    margin: 0;
}

.kMore {
    cursor: pointer;
    color: var(--lightOrange);
}

`;

const ProductCard = ({ product, route }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(route);
    };

    return (
        <ProductCardWrapper onClick={handleClick}>
            <LazyLoadImage src={product.imageUrl} alt={product.name} />
            <p>{product.description}
            <br/><b onClick={() => handleClick()} className='kMore'>Know more &gt;&gt;</b></p>
        </ProductCardWrapper>
    );
};

export default ProductCard;
