import React from 'react';
import styled from 'styled-components';
import { Check, ArrowRight } from 'lucide-react';
import { useNavigate } from "react-router";


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f3f4f6;
`;

const StyledCard = styled.div`
  width: 100%;
  max-width: 500px;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
`;

const CardHeader = styled.div`
  padding: 1rem;
`;

const CardTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #10b981;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardContent = styled.div`
  padding: 1rem;
`;

const Text = styled.p`
  text-align: center;
  color: ${props => props.color || '#4b5563'};
  margin-bottom: ${props => props.mb || '0'};
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem;
`;

const Button = styled.button`
  background-color: var(--lightOrange);
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  transition: background-color 0.2s;
  cursor: pointer;

`;

const InterviewCompletedMessage = () => {

    const navigate = useNavigate();

  return (
    <Container>
      <StyledCard>
        <CardHeader>
          <CardTitle>
            <Check style={{ display: 'inline-block', marginRight: '0.5rem' }} /> Interview Completed
          </CardTitle>
        </CardHeader>
        <CardContent>
          <Text mb="1rem">
            Congratulations! You've successfully completed your interview.
          </Text>
          <Text color="#4b5563" mb="1rem">
            We appreciate your time and effort. Our team will carefully review your interview and get back to you with updates soon.
          </Text>
          <Text color="#4b5563">
            In the meantime, feel free to check your dashboard for any other opportunities or to update your profile.
          </Text>
        </CardContent>
        <CardFooter>
          <Button onClick={() => navigate("/dashboard/jobseeker")}>
            Go to Dashboard <ArrowRight style={{ marginLeft: '0.5rem' }} />
          </Button>
        </CardFooter>
      </StyledCard>
    </Container>
  );
};

export default InterviewCompletedMessage;