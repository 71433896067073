import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import JiraHeader from './JiraHeader'
import { getAllIssuesByJqlQuery } from '../../../functions/api/AtlassianAPIs/getAllIssuesByJqlQuery'
import { jiraDateConversion } from '../../../utils/timeZoneConversation'
import { getCommentsByIssueKey } from '../../../functions/api/AtlassianAPIs/getCommentsByIssueKey'
import profileIcon from '../../../assets/icons/userWhiteIcon.png'



const JiraIssueDetails = () => {
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);
    const navigate = useNavigate();
    const { issueKey } = useParams();
    const authenticatedUserEmail = useSelector(state => state.auth.userData?.user?.email);
    const [issueDetails, setIssueDetails] = useState(null);
    const [comments, setComments] = useState([]);
    const [jqlQuery, setJqlQuery] = useState(`reporter = "${authenticatedUserEmail}" AND issueKey = "${issueKey}"`);
    const {portalId} = useParams();


    useEffect(() => {
        const getAllIssues = async () => {
            try {
                const res = await getAllIssuesByJqlQuery(accessToken, clientCode, jqlQuery);
                if (res) {
                    setIssueDetails(res?.data[0]);
                }
            }
            catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }
        getAllIssues();
    }, [])

    useEffect(() => {
        const getComments = async () => {
            try {
                const res = await getCommentsByIssueKey(accessToken, clientCode, issueKey);
                if (res) {
                    setComments(res?.data);
                }
            }
            catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }
        getComments();
    }, [])

    const navigateToLandingPage = () => {
        navigate(`/servicedesk/customer/portal/${portalId}`)
    }


    return (
        <MainBox>
            <JiraHeader />
            <div className='topBox'>
                <div className='searchBox'>

                </div>
            </div>
            <div className='bottomBox'>
                <div className='contentBox'>
                    <span className='navigationBox'>
                        <span onClick={navigateToLandingPage}>Help Center </span> /
                        <span> RegorTalent </span> /
                        <span> {issueKey}</span>

                    </span>
                    <span className='boldBigText'>{issueDetails?.summary}</span>

                    <div className='mainBox'>
                        <div className='left'>
                            <span className='text mainTitle'><span className='title'>{issueDetails?.reporterDisplayName}</span> raised this on {(issueDetails && issueDetails?.reportedAt && issueDetails?.timezone) && jiraDateConversion(issueDetails?.reportedAt, issueDetails?.timezone)}</span>

                            {issueDetails?.description && <div className='textBox'>
                                <span className='boldText'>Describe what happened and how it occurred</span>
                                <span dangerouslySetInnerHTML={{ __html: issueDetails?.description }} className='text' />
                            </div>}

                            {issueDetails?.urgency && <div className='textBox'>
                                <span className='boldText'>How urgently does this need to be fixed?</span>
                                <span className='text'>{issueDetails?.urgency}</span>
                            </div>}

                            {issueDetails?.impact && <div className='textBox'>
                                <span className='boldText'>How big of an impact is the problem to you or the organization?</span>
                                <span className='text'>{issueDetails?.impact}</span>
                            </div>}
                        </div>
                        <div className='right'>
                            {issueDetails?.status && <div className='textBox'>
                                <span className='boldText'>Status</span>
                                <span className='text statusBox'>{issueDetails?.status}</span>
                            </div>}

                            {issueDetails?.requestType && <div className='textBox'>
                                <span className='boldText'>Request type</span>
                                <span className='text'>{issueDetails?.requestType}</span>
                            </div>}
                        </div>
                    </div>
                    <div className='commentBox'>
                        <span className='title'>Activity</span>
                        {
                            comments?.map((comment, i) => (
                                <div key={i} className='commentContent'>
                                    <img src={profileIcon} className='profileIcon'/>
                                    <div className='commentContentBox'>
                                        <span className='commentTopBox'><span>{comment?.authorDisplayName}</span>  <span>{(comment?.commentedAt && comment?.timezone) && jiraDateConversion(comment?.commentedAt, comment?.timezone)}</span></span>
                                        <span className='commentBottomBox'>{comment?.commentText}</span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </MainBox>
    )
}

export default JiraIssueDetails;

const MainBox = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 2rem;
width: 100%;


.topBox {
    background: rgb(255,255,226);
    background: linear-gradient(90deg, rgba(255,255,226,1) 0%, rgba(24,226,211,1) 50%, rgba(24,226,211,1) 100%);    color: var(--white);
    width: 100%;
    height: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .searchBox {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;

        .title {
            font-size: 1.5rem;
            font-weight: 600;
        }
    }
}


.bottomBox {
    background-color: var(--white);
    color: var(--color);
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10rem;
    top: 3.5rem;
    position: absolute;
    padding: 2rem 0 7.5rem 0;
    border: 0.05rem solid #CCD3D8;
    border-radius: 0.5rem;


    .contentBox {
        width: 90%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .navigationBox {
            

            span {
                font-size: 0.9rem;
                font-weight: 700;
                color: var(--lightOrange);
                cursor: pointer;
            }

            span:hover {
                text-decoration: underline;
            }
        }

        .boldBigText {
            font-size: 1.5rem;
            font-weight: 600;
            font-family: var(--font);
        }

        .mainBox {
            display: flex;
            width: 100%;
            gap: 5%;
            margin-top: 2rem;

            .title {
                font-size: 1rem;
                font-weight: 700;
            }

            .mainTitle {
                margin-bottom: 1rem;
            }

            .textBox {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                .boldText {
                    font-size: 1rem;
                    font-weight: 600;
                }
    
                .text {
                    font-size: 0.9rem;
                    font-weight: 500;
                }
            }
            

            .left {
                width: 65%;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding: 1.5rem 2rem;
                box-sizing: border-box;
                border: 0.05rem solid lightgrey;
                border-radius: 0.5rem;
            }

            .right {
                width: 30%;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .statusBox {
                    display: flex;
                }
            }
        }

        .commentBox {
            display: flex;
            flex-direction: column;
            gap: 2rem;


            .title {
                display: block;
                font-size: 1rem;
                font-weight: 600;
                margin-top: 1.5rem;
            }

            .commentContent {
                display: flex;
                gap: 1rem;
                align-items: start;

                .profileIcon {
                    width: 2.3rem;
                    height: 2.3rem;
                    background-color: grey;
                    border: 0.05rem solid var(--white);
                    border-radius: 50%;
                    padding: 0.3rem;
                    box-sizing: border-box;
                }

                .commentContentBox {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    
                }
                
                .commentTopBox {
                    font-size: 0.9rem;
                    font-weight: 600;
                    display: flex;
                    gap: 1rem;
                }
                
                .commentBottomBox {
                    font-size: 0.9rem;
                    font-weight: 500;
                }
            }
        }
    }
}

`