import { useState, useEffect } from 'react';
import { useJwt } from 'react-jwt';

const useTokenExpiry = (token) => {
    const { isExpired, decodedToken } = useJwt(token);
    const [isSessionExpired, setIsSessionExpired] = useState(false);

    useEffect(() => {
        const checkTokenExpiry = () => {
            if (decodedToken !== null && isExpired) {
                setIsSessionExpired(true);
            }
        };

        // Check token expiry immediately
        checkTokenExpiry();

        // Set an interval to check the token expiry periodically
        const interval = setInterval(checkTokenExpiry, 1000 * 60); // Check every minute

        return () => clearInterval(interval);
    }, [isExpired, decodedToken]);

    return { isSessionExpired };
};

export default useTokenExpiry;

