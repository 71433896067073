import React from 'react';
import styled from 'styled-components';
import { CheckCircle, Calendar, Mail } from 'lucide-react';
import { useNavigate } from 'react-router';

const Container = styled.div`
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const Icon = styled(CheckCircle)`
  color: var(--lightOrange);
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  color: #343a40;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  color: #495057;
  margin-bottom: 1.5rem;
`;

const InfoBox = styled.div`
  background-color: #F8F9FA;
  border: 0.1rem solid var(--lightOrange);
  border-radius: 4px;
  padding: 1rem;
  margin-top: 1.5rem;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: #495057;

  svg {
    margin-right: 0.5rem;
    color: var(--lightOrange);
  }
`;

const Button = styled.button`
  background-color: var(--lightOrange);
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  margin-top: 1.5rem;
  cursor: pointer;
  font-size: 1rem;
`;

const ProductDemoSuccessMessage = () => {

    const Navigate = useNavigate();

    return (
        <Container>
            <Icon size={48} />
            <Title>Product Demo Request Submitted</Title>
            <Message>
                Thank you for your interest in our product! Your demo request has been successfully submitted. Our team will contact you shortly to schedule your personalized demo.
            </Message>
            <InfoBox>
                <InfoItem>
                    <Calendar size={20} />
                    <span>You will receive a invite soon.</span>
                </InfoItem>
            </InfoBox>
            <Button onClick={() => Navigate("/")}>Go to Homepage</Button>
        </Container>
    );
};

export default ProductDemoSuccessMessage;