
import React, { useEffect, useState, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled, { css } from "styled-components";
import threeDot from "../../../../assets/icons/threeDot.png";
import searchBlack from '../../../../assets/icons/searchBlack.png'
import deleteIcon from '../../../../assets/icons/delete.png'
import editIcon from '../../../../assets/icons/edit.png'
import visibleIcon from '../../../../assets/icons/visible.png'
import publishIcon from '../../../../assets/icons/publish.png'
import CommonDrawer from "../../../commonComponents/CommonDrawer";
import CommonDialog from "../../../commonComponents/CommonDialog";
import DeleteDialogContent from "../../../commonComponents/DeleteDialogContent";
import TableSearchBar from "../commonComponents/TableSearchBar";
import { toast } from "react-toastify";
import { getTests } from "../../../../functions/api/employers/employerTest/getTests";
import { useSelector } from "react-redux";
import { deleteTest } from "../../../../functions/api/employers/employerTest/deleteTest";
import { dateConversion, timeZoneConversion } from "../../../../utils/timeZoneConversation";
import ModalHOC from "../../SeekerDashboard/ModalHOC";
import CreateTestsForm from "../CreateTestsForm";
import TestDetails from "./TestDetails";

function Row(props) {
  const { row, rowsLength, index, setTestTrigger } = props;
  const accessToken = useSelector(state => state.auth.userData?.accessToken);
  const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

  const dropdownRef = useRef(null);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);

  const [testEditingIndex, setTestEditingIndex] = useState(-1);
  const [editingTestId, setEditingTestId] = useState('');
  const [editingTestData, setEditingTestData] = useState(null);
  const [openTestModal, setOpenTestModal] = useState(false);
  const [openReviewModal, setOpenReviewModal] = useState(false);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeAllDropdowns();
      }
    };

    document.addEventListener("mousedown", handleDocumentClick);

    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);


  const openDropdown = (index) => {
    setOpenDropdownIndex(index);
  };

  const closeAllDropdowns = () => {
    setOpenDropdownIndex(-1);
  };

  const handleDelete = async () => {
    try {
      const res = await deleteTest(row?.id, accessToken, clientCode);
      if (res) {
        toast.success('Test deleted successfully', 5000);
        setTestTrigger(prev => !prev);
      }
    } catch (error) {
      const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again."
      toast.error(errMsg);
    } finally {
      handleClose();
    }
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // State, function to open and close Drawer
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <React.Fragment>
      <ModalHOC openNewInterviewModal={openTestModal} setOpenNewInterviewModal={setOpenTestModal} component={<CreateTestsForm editingIndex={testEditingIndex} setEditingIndex={setTestEditingIndex} handleClose={() => setOpenTestModal(false)} editingTestId={editingTestId} editingTestData={editingTestData} setTestTrigger={setTestTrigger} isReview={false} />} />
      <ModalHOC openNewInterviewModal={openReviewModal} setOpenNewInterviewModal={setOpenReviewModal} component={<CreateTestsForm editingIndex={testEditingIndex} setEditingIndex={setTestEditingIndex} handleClose={() => setOpenTestModal(false)} editingTestId={editingTestId} editingTestData={editingTestData} setTestTrigger={setTestTrigger} isReview={true} />} />
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }} className={`${index % 2 == 1 ? 'colored' : ''}`}>
        <TableCell component="th" scope="row" align="center" className="tableCell">
          {row?.name}
        </TableCell>
        <TableCell component="th" scope="row" align="center" className="tableCell">
          {row?.testType === 'mix' ? "General (All types Questions)" : row?.testType === 'general' ? "Subjective" : row?.testType === 'mcq' ? "Objective" : "Coding"}
        </TableCell>{" "}
        <TableCell align="center" className="tableCell">{row?.role}</TableCell>
        <TableCell align="center" className="tableCell tagBox">
          {row?.tags?.length !== 0 && row?.tags?.split(", ")?.map((tag, i) => (
            <div className="tag">{tag}</div>
          ))}
        </TableCell>
        <TableCell align="center" className="tableCell">{row?.createdAt && timeZoneConversion(row?.createdAt)}</TableCell>
        <TableCell align="center" className="tableCell">{row?.published ? "Published" : "DRAFT"}</TableCell>
        <TableCell align="center" className="tableCell">
          <BoxRow isLast={index >= rowsLength - 2}>
            <img
              src={threeDot}
              style={{ width: "0.8rem", height: "0.8rem", cursor: "pointer" }}
              className={`three-dots ${openDropdownIndex === index ? "active" : ""
                }`}
              onClick={() => {
                if (openDropdownIndex === index) {
                  closeAllDropdowns();
                } else {
                  openDropdown(index);
                }
              }}
            />
            <div
              className={`dropdown-content ${openDropdownIndex === index ? "open" : ""
                }`}
              ref={dropdownRef}
            >
              <CommonDrawer toggleDrawer={toggleDrawer} state={state} component={<TestDetails data={row} />} />
              <CommonDialog open={open} handleClose={handleClose} component={<DeleteDialogContent handleClose={handleClose} text='Test' handleDelete={handleDelete} />} />
              {!row?.published && <span onClick={() => {
                setOpenReviewModal(true);
                setTestEditingIndex(index);
                setEditingTestId(row?.id);
                setEditingTestData(row);
              }}>
                <img src={publishIcon} className="threeDotIcon" /> Publish Test
              </span>}
              <span onClick={toggleDrawer('right', true)}>
                <img src={visibleIcon} className="threeDotIcon" /> View Details
              </span>
              <span onClick={() => {
                setOpenTestModal(true);
                setTestEditingIndex(index);
                setEditingTestId(row?.id);
                setEditingTestData(row);
              }}>
                <img src={editIcon} className="threeDotIcon" /> Edit
              </span>
              <span onClick={handleClickOpen}>
                <img src={deleteIcon} className="threeDotIcon" /> Delete
              </span>
            </div>
          </BoxRow>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


export default function AvailableTest({ published }) {
  const accessToken = useSelector(state => state.auth.userData?.accessToken);
  const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

  const [tests, setTests] = useState(null);
  const [testTrigger, setTestTrigger] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const handleSearch = () => {

  }

  useEffect(() => {
    const getAllTests = async () => {
      try {
        const res = await getTests(accessToken, clientCode, published);
        if (res) {
          setTests(res);
        }
      } catch (error) {
        const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again."
        toast.error(errMsg);
      }
    }
    getAllTests();
  }, [testTrigger])

  return (
    <Content>
      <TableContainer component={Paper} className="tableBox">
        <span className="title">{published ? "Published" : "Draft" } Tests</span>
        <SearchBarContainer>
          <TableSearchBar value={searchValue} setValue={setSearchValue} />
        </SearchBarContainer>
        <Table aria-label="collapsible table">
          <TableHead className="tableHead">
            <TableRow>
              <TableCell align="center" className="tableCell">Name</TableCell>
              <TableCell align="center" className="tableCell">Test Type</TableCell>
              <TableCell align="center" className="tableCell">Role</TableCell>
              <TableCell align="center" className="tableCell">Tags</TableCell>
              <TableCell align="center" className="tableCell">Created At</TableCell>
              <TableCell align="center" className="tableCell">Status</TableCell>
              <TableCell align="center" className="tableCell">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {tests && tests?.map((row, index) => (
              <Row key={row?.id} row={row} rowsLength={tests?.length} index={index} setTestTrigger={setTestTrigger} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Content>
  );
}


const Content = styled.div`
margin: 1rem 0% 2rem 0%;
width: 94%;
padding: 0 2.5%;
display: flex;
flex-direction: column;
align-items: center;

.colored {
  background-color: #ececec;
}

.tableBox {
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.20);
  border-radius: 0.5rem;
  padding-top: 1rem;


  .title {
    padding-left: 1.2rem;
    font-size: 0.9rem;
    font-weight: 600;
  }
}

.MuiTableCell-root {
  border: none;
}

.MuiTableRow-root {
  border-bottom: none;
}

.selected {
  background-color: #d9fbf9;
  color: white;
}

.tableHead {
  background-color: #d1fff0;
  width: 100%;

  .tableCell {
    font-size: 0.9rem;
    font-weight: 500;
    font-family: var(--font);
    color: var(--color);
  }
  
}

.tableBody {
  width: 100%;

  .tagBox {
    display: flex;
    flex-flow: row wrap;
    column-gap: 0.25rem;
    row-gap: 0.25rem;
    align-items: center;
    justify-content: center;
    max-width: 15rem;

    .tag {
      background-color: var(--lightOrange);
      margin: 0.25rem 0.25rem 0 0;
      white-space: nowrap;
      color: var(--white);
      font-weight: 500;
      padding: 0.2rem 0.4rem;
      border-radius: 0.2rem;
    }
  }

  .tableCell {
    font-size: 0.8rem;
    font-weight: 400;
    font-family: var(--font);
    color: var(--color);
  }
}



.checkBox {
  cursor: pointer;
}
`

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  margin: 0.5rem auto;
  height: 3rem;
  background-color: var(--white);
  border-radius: 0.5rem;;
  padding: 0rem 1rem;
  gap: 1rem;
`


const BoxRow = styled.div`
  position: relative;
  display: inline-block;

  .three-dots {
    cursor: pointer;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--white);
    box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 10%;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    min-width: 10rem;
    justify-content: start;
    padding: 0.5rem 0.5rem;

    ${(props) =>
    props.isLast &&
    css`
        bottom: 1.4rem;
        right: 10%;
      `}
  }

  .dropdown-content span {
    padding: 0.3rem 0.8rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--color);
    cursor: pointer;
  }

  .dropdown:hover .dropdown-content,
  .dropdown-content.open {
    display: block;
  }

  .threeDotIcon {
    width: 0.6rem;
    height: 0.6rem;
    cursor: pointer;
    border: 0.08rem solid grey;
    padding: 0.15rem;
    border-radius: 0.2rem;
  }
`;
