import React from 'react';
import styled from 'styled-components';
import { CheckCircle } from 'lucide-react';
import { useNavigate } from 'react-router';

const Container = styled.div`
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const Title = styled.h2`
  color: #343a40;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  color: #495057;
  margin-bottom: 1.5rem;
`;

const InfoBox = styled.div`
  background-color: #F8F9FA;
  border: 0.1rem solid var(--lightOrange);
  border-radius: 4px;
  padding: 1rem;
  margin-top: 1.5rem;
`;

const InfoTitle = styled.h3`
  color: var(--lightOrange);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;

  svg {
    margin-right: 0.5rem;
  }
`;

const InfoText = styled.p`
  color: #495057;
  margin: 0;
  font-size: 1rem;
`;

const Button = styled.button`
  background-color: var(--lightOrange);
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  margin-top: 1.5rem;
  cursor: pointer;
  font-size: 1rem;

`;

const PasswordResetSuccessMessage = () => {

    const Navigate = useNavigate();

    return (
        <Container>
            <CheckCircle size={48} color="var(--lightOrange)" />
            <Title>Password Reset Successful</Title>
            <Message>
                Your password has been successfully reset. You can now use your new password to log in to your account.
            </Message>
            <InfoBox>
                <InfoTitle>
                    <CheckCircle size={20} />
                    Next Steps
                </InfoTitle>
                <InfoText>
                    For security reasons, we recommend logging out of all other devices. You can do this in your account settings after logging in with your new password.
                </InfoText>
            </InfoBox>
            <Button onClick={() => Navigate("/login")}>Go to Login</Button>
        </Container>
    );
};

export default PasswordResetSuccessMessage;