import { useState } from 'react';
import { updateTest } from '../services/testService';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { clearFormData } from '../../../../slices/testFormSlice';

export const useUpdateTest = (accessToken, clientCode, toggleView, setTestTrigger) => {
    const dispatch = useDispatch();
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    const handleUpdateTest = async (id, payload) => {
        setLoadingUpdate(true);
        try {
            const res = await updateTest(payload, id, accessToken, clientCode);
            if(res){
                toast.success("Test updated successfully!", 5000);
                toggleView('test');
                setTestTrigger(prev => !prev);
                dispatch(clearFormData());
            }
        } catch (error) {
            const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        } finally {
            setLoadingUpdate(false);
        }
    };

    return { handleUpdateTest, loadingUpdate };
};
