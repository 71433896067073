import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import QuestionLibraryPage from './QuestionLibraryPage';
import QuestionFormPage from './QuestionFormPage';
import QuestionTypeSelectionPage from './QuestionTypeSelectionPage';
import Button from '../../../../component/shared/Button';
import { useViewManagement } from '../hooks/useViewManagement';
import { useModal } from '../hooks/useModal';
import { useQuestionEditing } from '../hooks/useQuestionEditing';

const QuestionParentPage = () => {
    const { currentView, handleViewChange } = useViewManagement();
    const { isModalOpen, openModal, closeModal } = useModal();
    const { editingIndex, questionType, handleEditQuestion, handleTypeSelect, setEditingIndex, editingQuestionId } = useQuestionEditing();
    const [questionData, setQuestionData] = useState();
    const [questionTrigger, setQuestionTrigger] = useState(true);

    const handleCreateQuestion = () => {
        openModal();
        setEditingIndex(-1);
    }

    const handleCardClick = useCallback((type) => {
        handleTypeSelect(type);
        closeModal();
        handleViewChange('create');
    }, [handleViewChange]);

    const handleEditClick = useCallback((editingIndex, questionData) => {
        handleEditQuestion(editingIndex, questionData);
        setQuestionData(questionData);
        handleViewChange('create');
    }, [handleViewChange]);

    const renderCurrentView = () => {
        switch (currentView) {
            case 'library':
                return (
                    <QuestionLibraryPage
                        onCreateQuestion={handleCreateQuestion}
                        onEditQuestion={handleEditClick}
                        questionTrigger={questionTrigger}
                        setQuestionTrigger={setQuestionTrigger}
                    />
                );
            case 'create':
                return (
                    <QuestionFormPage
                        editingIndex={editingIndex}
                        questionType={questionType}
                        setEditingIndex={setEditingIndex}
                        editingQuestionData={editingIndex != -1 && questionData}
                        toggleView={handleViewChange}
                        editingQuestionId={editingQuestionId}
                        setQuestionTrigger={setQuestionTrigger}
                    />
                );
            default:
                return <div>Select an operation</div>;
        }
    };

    return (
        <Container>
            <Content>
                {renderCurrentView()}
            </Content>

            {isModalOpen && (
                <Modal>
                    <ModalContent>
                        <div className='btnBox'>
                            <span className='title'>Select Question Type</span>
                            <Button text="Close" func={closeModal} disabled={false} />
                        </div>
                        <QuestionTypeSelectionPage onSelect={handleCardClick} />
                    </ModalContent>
                </Modal>
            )}
        </Container>
    );
};

export default QuestionParentPage;



const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const TopNav = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;
`;

const Content = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    width: 75%;
    height: 60%;

    .btnBox {
     display: flex;
     justify-content: space-between;
     align-items: center;
     margin-bottom: 1.5rem;

     .title {
      font-size: 1rem;
      font-weight: 700;
     }
    }
`;


