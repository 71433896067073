import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { getJDByIdAndHash } from '../functions/api/jobApplication/getJDByIdAndHash';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ConfigurableModal from '../components/Interviews/SeekerDashboard/seekerCommonComponents/ConfigurableModal';
import JobApplicationModal from '../components/Interviews/SeekerDashboard/seekerCommonComponents/JobApplicationModal';
import ctcIcon from '../assets/icons/JdDetails/ctcIcon.png'
import locationIcon from '../assets/icons/JdDetails/locationIcon.png'
import experienceIcon from '../assets/icons/JdDetails/experienceIcon.png'
import linkedinIcon from '../assets/icons/JdDetails/publicLinkedin.png'
import { dateConvert } from '../utils/globalFunctions';
import { useSelector } from 'react-redux';
import JobListingLogin from '../components/Interviews/SeekerDashboard/PublicPages/JobListingLogin';
import JobListingSignup from '../components/Interviews/SeekerDashboard/PublicPages/JobListingSignup';
import Header from '../components/LandingPage/Header';
import Footer from '../components/commonComponents/Footer';
import { shouldApply } from '../functions/api/jobApplication/shouldApply';


const PublicJobListing = () => {
    const accessToken = useSelector(state => state?.auth?.userData?.accessToken);
    const { jdId, jobSummaryHash } = useParams();
    const [JdDetails, setJdDetails] = useState();
    const [shouldApplyStatus, setShouldApplyStatus] = useState(true);
    const navigate = useNavigate();

    const [openBasic, setOpenBasic] = useState(false);
    const [openLogin, setOpenLogin] = useState(false);
    const [openSignup, setOpenSignup] = useState(false);

    const [trigger, setTrigger] = useState(false);

    useEffect(() => {
        const handleShouldApply = async (jdId, clientCode) => {
            try {
                const res = await shouldApply(accessToken,jdId, clientCode);
                setShouldApplyStatus(res);
            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }

        {(accessToken && JdDetails) && handleShouldApply(JdDetails?.jdId, JdDetails?.clientCode)};
    }, [JdDetails])


    useEffect(() => {
        const getData = async () => {
            try {
                const res = await getJDByIdAndHash(jdId, jobSummaryHash, "regortalent");
                if (res) {
                    setJdDetails(res?.data);
                }
            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }
        getData();
    }, [jdId, jobSummaryHash, trigger])

const handleClose = () => {
    setOpenBasic(false);
    navigate("/dashboard/jobseeker")
}

const handleShareOnSocial = (jdId, jobSummaryHash) => {
    const currentDomain = window.location.hostname;
    const baseUrl = `https://${currentDomain}`;
    const jobListingUrl = `${baseUrl}/job-listing/${jdId}/${jobSummaryHash}`
    const encodedUrl = encodeURIComponent(jobListingUrl);
    const sharableUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`
    window.open(sharableUrl, '_blank', 'noopener,noreferrer');
}

return (
    <Main>
        <Header />
        <CardMainBox>
            <ConfigurableModal open={openBasic} setOpen={setOpenBasic} component={<JobApplicationModal jdId={JdDetails?.jdId} empClientCode={JdDetails?.clientCode} handleClose={handleClose} setTrigger={setTrigger} />} style={{ width: '60%', height: '60%' }} />
            <ConfigurableModal open={openSignup} setOpen={setOpenSignup} component={<JobListingSignup handleClose={() => setOpenSignup(false)} setTrigger={setTrigger} setOpenLogin={setOpenLogin} />} style={{ width: '60%', height: '80%' }} />
            <ConfigurableModal open={openLogin} setOpen={setOpenLogin} component={<JobListingLogin handleClose={() => setOpenLogin(false)} setTrigger={setTrigger} setOpenSignup={setOpenSignup} />} style={{ width: '60%', height: '80%' }} />
            <Card>
                <div className='box1'>
                    <div className='left'>
                        <img src={JdDetails?.companyLogo} className='logo' />
                        <div className='textBox'>
                            <span className='title'>{JdDetails?.title}</span>
                            <span className='subTitle'>{JdDetails?.companyName}</span>
                        </div>
                    </div>
                    <div className='right'>
                        <div className='box'>
                            <img src={linkedinIcon} className='icon' onClick={() => handleShareOnSocial(JdDetails?.jdId, JdDetails?.jobSummaryHash)} />
                        </div>
                    </div>
                </div>

                <div className='box2'>
                    {JdDetails?.exp && <span className='text'><img src={experienceIcon} /> {JdDetails?.exp} Yrs</span>} {JdDetails?.exp && <>|</>}
                    {JdDetails?.ctc && <span className='text'><img src={ctcIcon} /> {JdDetails?.ctc} Lacs PA</span>} {JdDetails?.exp && <>|</>}
                    {JdDetails?.location && <span className='text'><img src={locationIcon} /> {JdDetails?.location}</span>}
                </div>

                <div className='box4'>
                    {JdDetails?.skills?.split(',')?.map((skill, index) => (
                        <span className='text' key={index}>{skill}</span>
                    ))}
                </div>

                <div className='box5'>
                    <span className='left'>{(JdDetails?.lastPublicAt) && dateConvert(JdDetails?.lastPublicAt)}</span>
                    <div className='rightBox'>
                        {!accessToken &&
                            <span className='right'>
                                <button onClick={() => setOpenSignup(true)} className="btn">Register to apply</button>
                            </span>
                        }
                        {!accessToken ?
                            <span className='right'>
                                <button onClick={() => setOpenLogin(true)} className="btn">Login to apply</button>
                            </span> :
                            <span className='right'>
                                {!shouldApplyStatus ? <button className="btn1">Applied</button> : <button onClick={() => setOpenBasic(true)} className="btn">Apply</button>}
                            </span>
                        }
                    </div>
                </div>
            </Card>

            <div className='details'>

                {JdDetails?.description &&
                    <div className='box2'>
                        <span className='title'>Job description</span>
                        <span dangerouslySetInnerHTML={{ __html: JdDetails?.description }} className='text' />
                    </div>
                }

                <div className='box3'>
                    {(JdDetails?.companyType) && <span className='text'><span className='boldText'>Company Type: </span>{JdDetails?.companyType}</span>}
                    {JdDetails?.workType && <span className='text'><span className='boldText'>Employment Type: </span>{JdDetails?.workType}</span>}
                    {(JdDetails?.noticePeriod) && <span className='text'><span className='boldText'>Notice Period: </span>{JdDetails?.noticePeriod}</span>}
                </div>

                {JdDetails?.skills &&
                    <div className='box4'>
                        <span className='title'>Key Skills</span>
                        <div className='skillBox'>
                            {
                                JdDetails?.skills?.split(',').map((skill, i) => (
                                    <span className='text'>{skill}</span>
                                ))
                            }
                        </div>
                    </div>}

            </div>
        </CardMainBox>
        <Footer />
    </Main>
)
}

export default PublicJobListing



const Main = styled.div`
background-color: #F4F4F4;
display: flex;
flex-direction: column;
align-items: center;


`


const CardMainBox = styled.div`
width: 95%;
border-radius: 1rem;
background-color: #F4F4F4;
color: var(--color);
font-family: var(--font);
margin: 6rem 0;
box-sizing: border-box;
display: flex;
flex-direction: column;
gap: 2rem;


.details {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border-radius: 0.75rem;
    padding: 1.5rem 2rem;
    gap: 1.5rem;
    box-sizing: border-box;


    .box1 {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;

        .title {
            font-size: 1rem;
            font-weight: 600;
        }

        .text {
            font-size: 0.9rem;
            font-weight: 400;
        }

        .jdSummary {
            line-height: 1.1rem;
            font-size: 0.9rem;
            font-weight: 400;
        }
    }
    
    .box2 {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;

        .title {
            font-size: 1rem;
            font-weight: 600;
        }

        .text {
            font-size: 0.9rem;
            font-weight: 400;
        }
    }
    
    .box3 {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;


        .text {
            font-size: 0.9rem;
            font-weight: 500;
            display: flex;
            gap: 0.4rem;
        }

        .boldText {
            font-size: 0.9rem;
            font-weight: 600;
        }
    }
    
    .box4 {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .title {
            font-size: 1rem;
            font-weight: 600;
        }

        .skillBox {
            display: flex;
            flex-flow: row wrap;
            gap: 1rem;

            .text {
                font-size: 0.8rem;
                font-weight: 500;
                border: 0.08rem solid lightgrey;
                padding: 0.2rem 0.7rem;
                border-radius: 0.5rem;
            }
        }
    }
}


`

const Card = styled.div`
padding: 1rem 1.75rem;
box-sizing: border-box;
display: flex;
flex-direction: column;
gap: 0.5rem;
background-color: var(--white);
border-radius: 1rem;



.box1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .left {
        display: flex;
        gap: 1rem;
        padding-bottom: 0.75rem;
        .logo {
            width: 2.5rem;
            height: 2.5rem;
            border: 0.025rem solid lightgrey;
            border-radius: 0.25rem;
        }
    
        .textBox {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
    
            .title {
                font-size: 1rem;
                font-weight: 700;
            }
            .subTitle {
                font-size: 0.9rem;
                font-weight: 600;
            }
        }
    }

    .right {
        .box{
            display: flex;
            gap: 0.5rem; 
            justify-content: center;
            align-items: center;

            .icon {
                width: 0.8rem;
                height: 0.8rem;
                cursor: pointer;
                border: 0.08rem solid grey;
                padding: 0.3rem;
                border-radius: 0.3rem;
            }

            .icon1 {
                width: 0.8rem;
                height: 0.8rem;
                border: 0.08rem solid grey;
                padding: 0.3rem;
                border-radius: 0.3rem;
            }
        }
    }
    
}

.box2 {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    .text {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
        font-size: 0.9rem;
        font-weight: 500;

        img {
            width: 1rem;
            height: 1rem;
        }
    }
}


.box3 {

    .text {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: start;
        font-size: 0.9rem;
        font-weight: 500;
        line-height: 1.3rem;

        img {
            width: 1rem;
            height: 1rem;
            padding-top: 0.1rem;
        }
    }
}

.box4 {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    padding-top: 0.5rem;

    .text{
        font-size: 0.75rem;
        font-weight: 400;
        border: 0.025rem solid lightgrey;
        border-radius: 0.25rem;
        padding: 0.15rem 0.25rem;
    }
}

.box5 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;

    .rightBox {
        display: flex;
        gap: 1rem;
    }

    .left {
        font-weight: 500;
    }

    .right {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        .btn {
            background-color: var(--lightOrange);
            padding: 0.5rem 0.8rem;
            border: none;
            color: var(--white);
            font-size: 0.9rem;
            font-weight: 600;
            border-radius: 0.5rem;
            cursor: pointer;
            text-decoration: none;
            font-family: var(--font);
        }

        .btn1 {
            background-color: var(--white);
            padding: 0.5rem 0.8rem;
            color: var(--lightOrange);
            font-size: 0.9rem;
            font-weight: 600;
            border-radius: 0.5rem;
            text-decoration: none;
            font-family: var(--font);
            border: 0.08rem solid var(--lightOrange);
        }
        
    }
}

`
