import React, { useState } from 'react';
import styled from 'styled-components';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 3rem;
  cursor: pointer;
  background-color: var(--lightOrange);
  color: var(--white);
  border: none;
  border-radius: 0.25rem;
`;

const ModalContent = styled.div`
  padding: 2rem;
  text-align: center;

  p {
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.5rem;
  }
`;

const Header = styled.h2`
  margin-bottom: 2rem;
`;

const ModalButton = styled(Button)`
  margin: 1rem;
`;

function InterviewSubmitionModal({ handleSubmit, closeModal }) {

    return (
        <Container>
            <ModalContent>
                <Header>Confirm Submission</Header>
                <p>Are you sure you want to submit the interview? <br /> Once submitted, you cannot make any changes.</p>
                <div>
                    <ModalButton onClick={handleSubmit}>Submit</ModalButton>
                    <ModalButton onClick={closeModal} style={{ backgroundColor: '#FF3131' }}>Cancel</ModalButton>
                </div>
            </ModalContent>
        </Container>
    );
}

export default InterviewSubmitionModal;
