import React from 'react'
import styled from 'styled-components';

const NumberBox = ({ number, text }) => {
    return (
        <NumberMainBox>
            <Number>{number}</Number>
            <NumberText>{text}</NumberText>
        </NumberMainBox>
    )
}

export default NumberBox

const NumberMainBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  max-height: 5rem;

  `;

const Number = styled.h4`
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--lightOrange);
  line-height: 2rem;
  margin: 0;
  padding: 0;

`;

const NumberText = styled.p`
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--color);
  text-align: center;
  margin: 0;

`;