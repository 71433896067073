import React from 'react'
import styled from 'styled-components';

const Container = styled.div`
  margin: 1rem;
  padding: 1rem 1.5rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  
`;

const MainTitle = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: center;
`;

const Section = styled.div`
  margin-bottom: 1rem;
`;

const SubTitle = styled.p`
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const Description = styled.p`
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
`;

const InterviewQuestionSummary = ({summaryJson}) => {
    const mainTitle = "Evaluation Summary";

    return (
        <Container>
            <MainTitle>{mainTitle}</MainTitle>
            {Object?.keys(summaryJson)?.map((sectionKey, index) => (
                <Section key={sectionKey}>
                    <SubTitle>{index+1}.  {sectionKey}</SubTitle>
                    <Description>{summaryJson[sectionKey]?.reasoning}</Description>
                </Section>
            ))}
        </Container>
    )
}

export default InterviewQuestionSummary


