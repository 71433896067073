import React from 'react';
import { Autocomplete, Paper } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import StyledTextField from './StyledTextField';

const filter = createFilterOptions();

const TagsInputClearable = ({ options, value, onChange, label }) => (
    <Autocomplete
        multiple
        options={options}
        getOptionLabel={(option) => option}
        onChange={onChange}
        value={value}
        selectOnFocus
        fullWidth
        renderInput={(params) => (
            <StyledTextField
                inputProps={{
                    sx: {
                        color: '#626264',
                        fontSize: '0.8rem',
                        fontWeight: '400'
                    },
                }}
                InputLabelProps={{
                    sx: {
                        color: '#626264',
                        fontSize: '0.8rem',
                        fontWeight: '400'
                    },
                }}
                {...params}
                label={label}
                sx={{ fontSize: '0.5rem' }}
            />
        )}
    />
);

export default TagsInputClearable;
