import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import { useSelector } from "react-redux";
import TableSearchBar from "../EmployerDashboard/commonComponents/TableSearchBar";
import downloadIcon from '../../../assets/icons/Profile/download.png'
import { toast } from "react-toastify";
import { Pagination, PaginationSizeFilter } from "../../commonComponents/Pagination";
import { getAgencyCandidatesInPipeline } from "../../../functions/api/agency/getAgencyCandidatesInPipeline";
import { getBlobData } from "../../../functions/api/resume/getBlobData";



function Row(props) {
    const { row, index } = props;
    const accessToken = useSelector(state => state?.auth?.userData?.accessToken);
    const clientCode = useSelector(state => state?.auth?.userData?.user?.clientCode);


    const getSourceAndAppliedBy = (source, type) => {
        if (type == "source") {
            if (source == "EMPLOYER_POOL") {
                return "Self"
            }
            else if (source == "AGENCY") {
                return "Agency";
            }
            else {
                return "Candidate"
            }
        }
        else {
            if (source == "EMPLOYER_POOL") {
                return row?.clientCode ?? clientCode;
            }
            else if (source == "AGENCY") {
                return row?.agencyCode;
            }
            else {
                return row?.agencyCode;
            }
        }
    }

    const handleDownload = async (id, name) => {
        try {
            if (!id) {
                toast.warning("No resume found or invalid resumeId", 5000);
                return;
            }
            const res = await getBlobData(
                `api/media/downloadById?fileType=resume&id=${id}`,
                accessToken,
                clientCode
            );

            const a = document.createElement("a");
            a.href = res;
            a.setAttribute("download", `${name}`);
            a.click();
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    };

    return (
        <React.Fragment>
            <TableRow
                sx={{ "& > *": { borderBottom: "unset" } }} className={`${index % 2 == 1 ? 'colored' : ''}`}>
                <TableCell align="center" className="tableCell">{row?.name}</TableCell>
                <TableCell align="center" className="tableCell">{row?.email}</TableCell>
                <TableCell align="center" className="tableCell">{row?.contact}</TableCell>
                <TableCell align="center" className="tableCell">{getSourceAndAppliedBy(row?.appliedBy, "source")}</TableCell>
                <TableCell align="center" className="tableCell">{getSourceAndAppliedBy(row?.appliedBy, "appliedBy")}</TableCell>
                <TableCell align="center" className="tableCell">{row?.aiScore}</TableCell>
                <TableCell component="th" scope="row" align="center" className="tableCell">
                    <div style={{ display: 'flex', gap: '0.6rem', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={downloadIcon} className="icon" onClick={() =>
                            handleDownload(row?.resumeId, row?.srcFilename)
                        } />
                    </div>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


const AgencyCandidatesInPipeline = () => {
    const [tableRows, setTableRows] = useState([]);

    const accessToken = useSelector(state => state?.auth?.userData?.accessToken);
    const clientCode = useSelector(state => state?.auth?.userData?.user?.clientCode);

    const [searchValue, setSearchValue] = useState('');
    const [search, setSearch] = useState(false);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        async function getData() {
            try {
                const res = await getAgencyCandidatesInPipeline(accessToken, clientCode, page, size);
                setTableRows(res?.data?.data[0]?.records?.data);
                setTotal(res?.data?.data[0]?.total);
            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }
        getData();
    }, [page, size]);

    const handleSizeChange = (event) => {
        setSize(parseInt(event.target.value, 10));
        setPage(1);
    };

    const handlePageChange = (change) => {
        if (change && (page < Math.ceil(+total / +size))) {
            setPage((prev) => prev + 1);
        } else if (!change && page > 1) {
            setPage((prev) => prev - 1);
        }
    };

    return (
        <Content>
            <TableContainer component={Paper} className="tableBox">
                <div className="titleBox">
                    <span className="title">Candidates in Pipeline</span>
                </div>

                <SearchBarContainer>
                    {/* <TableSearchBar value={searchValue} setValue={setSearchValue} /> */}
                </SearchBarContainer>
                <Table aria-label="collapsible table">
                    <TableHead className="tableHead">
                        <TableRow>
                            <TableCell align="center" className="tableCell">Name</TableCell>
                            <TableCell align="center" className="tableCell">Email</TableCell>
                            <TableCell align="center" className="tableCell">Contact</TableCell>
                            <TableCell align="center" className="tableCell">Source</TableCell>
                            <TableCell align="center" className="tableCell">Agency Code</TableCell>
                            <TableCell align="center" className="tableCell">Resume Score</TableCell>
                            <TableCell align="center" className="tableCell">Resume</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="tableBody">
                        {tableRows?.map((row, index) => (
                            <Row key={row?.id} row={row} index={index} />
                        ))}
                    </TableBody>
                </Table>
                {!search && <div className="paginationBox">
                    <PaginationSizeFilter
                        size={size}
                        handleSizeChange={handleSizeChange}
                    />
                    <Pagination
                        total={total}
                        size={size}
                        page={page}
                        handlePageChange={handlePageChange}
                        setPage={setPage}
                    />
                </div>}
            </TableContainer>
        </Content>
    )
}

export default AgencyCandidatesInPipeline


const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  margin: 0.5rem auto;
  background-color: var(--white);
  border-radius: 0.5rem;;
  padding: 0rem 1rem;
  gap: 1rem;

`

const Content = styled.div`
margin: 1rem 0% 2rem 0%;
width: 98%;
padding: 0 1%;
display: flex;
flex-direction: column;
align-items: center;



.colored {
  background-color: #ececec;
}

.tableBox {
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.20);
  border-radius: 0.5rem;
  padding-top: 1rem;


  .title {
    padding-left: 1.2rem;
    font-size: 0.9rem;
    font-weight: 600;
  }

  .titleBox {
  width: 99%; 
  padding: 0.5rem 0rem;;
  display: flex;
  align-items: center;
  justify-content: space-between;
  }


}

.MuiTableCell-root {
  border: none;
}

.MuiTableRow-root {
  border-bottom: none;
}

.selected {
  background-color: #d9fbf9;
  color: white;
}

.tableHead {
  background-color: #d1fff0;
  width: 100%;

  .tableCell {
    font-size: 0.9rem;
    font-weight: 500;
    font-family: var(--font);
    color: var(--color);
  }
  
}

.tableBody {
  width: 100%;

  .tableCell {
    font-size: 0.8rem;
    font-weight: 400;
    font-family: var(--font);
    color: var(--color);
  }
}

.paginationBox {
    display: flex;
    justify-content: end;
    gap: 2rem;
    margin: 1rem 3rem 1.5rem 0;
  }
  
  .icon {
    width: 0.8rem;
    height: 0.8rem;
    cursor: pointer;
    border: 0.075rem solid grey;
    padding: 0.3rem;
    border-radius: 0.3rem;
  }

.btn {
  background-color: var(--lightOrange);
  border: none;
  color: var(--white);
  border-radius: 0.3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  


  .icon {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.2rem;
}
}

.btn2 {
  background-color: var(--lightOrange);
  border: none;
  color: var(--white);
  border-radius: 0.3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 0.7rem;
  font-size: 0.9rem;
  font-weight: 600;
  font-family: var(--font);
}

`
