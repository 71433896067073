import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ReportDepartmentText from '../commonComponents/ReportDepartmentText'
import Chart from "react-google-charts";
import downloadIcon from '../../../../assets/icons/reportDownload.png';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getJds } from '../../../../functions/api/employers/getJds';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Stack } from '@mui/system';
import { Autocomplete, Paper, TextField } from '@mui/material';
import { getReportsCount } from '../../../../functions/api/employers/getReportsCount';
import dayjs from 'dayjs';
import moment from 'moment';
import { generateApplicationSourceChartData, generateCandidateAcceptanceByRoundChartData, generateCandidateAcceptanceChartData, generateCandidateProgressionChartData, generateCandidatesPipelineChartData, generateOpenPositionsBySkillsChartData } from '../../../../utils/ReportsData';
import { downloadReports } from '../../../../functions/api/employers/downloadReports';


const Report = () => {
    const accessToken = useSelector((state) => state?.auth?.userData?.accessToken);
    const clientCode = useSelector((state) => state?.auth?.userData?.user?.clientCode);
    const [jdList, setJdList] = useState([]);
    const [selectedJd, setSelectedJd] = useState([]);
    const [fromDate, setFromDate] = useState(dayjs(new Date()));
    const [toDate, setToDate] = useState(dayjs(new Date()));
    const [reportsCount, setReportsCount] = useState(null);

    useEffect(() => {
        const getAllReportsCount = async () => {
            const formatedFromDate = moment(fromDate.format("YYYY-MM-DD")).format("YYYY-MM-DD")
            const formatedToDate = moment(toDate.format("YYYY-MM-DD")).format("YYYY-MM-DD")
            const today = moment().format("YYYY-MM-DD");

            try {
                const res = await getReportsCount(accessToken, clientCode, selectedJd.length > 0 ? selectedJd : [], formatedFromDate === today ? null : formatedFromDate, formatedToDate === today ? null : formatedToDate);
                if (res) {
                    setReportsCount(res);
                }
            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg)
            }
        }

        getAllReportsCount();

        const interval = setInterval(() => {
            getAllReportsCount();
        }, 30000)

        return () => clearInterval(interval);
    }, [fromDate, toDate, selectedJd])


    useEffect(() => {
        const getAllJd = async () => {
            try {
                const res = await getJds(accessToken, clientCode, "", 1, 100000)
                if (res && res?.data?.total !== 0) {
                    setJdList(res?.data?.data?.map(d => ({
                        jdId: d?.jdId,
                        title: d?.title,
                    })));
                }
            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg)
            }
        }

        getAllJd();
    }, [])

    const handleJdChange = (_, newTags) => {
        const selectedJdIds = newTags?.map(tag => {
            const jd = jdList?.find(jd => jd?.title === tag);
            return jd ? jd?.jdId : null;
        })?.filter(jdId => jdId !== null);
        setSelectedJd(selectedJdIds);
    };

    const handleDownloadByReportType = async (reportType) => {
        const formatedFromDate = moment(fromDate.format("YYYY-MM-DD")).format("YYYY-MM-DD")
        const formatedToDate = moment(toDate.format("YYYY-MM-DD")).format("YYYY-MM-DD")
        const today = moment().format("YYYY-MM-DD");

        try {
            const res = await downloadReports(accessToken, clientCode, reportType, selectedJd.length > 0 ? selectedJd : [], formatedFromDate === today ? null : formatedFromDate, formatedToDate === today ? null : formatedToDate);

            const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${reportType}.xlsx`;

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg)
        }
    }

    const { openPosData, openPosOptions } = generateOpenPositionsBySkillsChartData(reportsCount?.openPositionsBySkillCount);
    const { candidatesPipelineData, candidatesPipelineOptions } = generateCandidatesPipelineChartData(reportsCount?.statusWiseCandidatesCount);
    const { candidateProgressionData, candidateProgressionOptions } = generateCandidateProgressionChartData(reportsCount?.statusWiseCandidatesCount);
    const { candidateAcceptanceData, candidateAcceptanceOptions } = generateCandidateAcceptanceChartData(reportsCount?.statusWiseCandidatesCount);
    const { candidateAcceptanceByRoundData, candidateAcceptanceByRoundOptions } = generateCandidateAcceptanceByRoundChartData(reportsCount?.roundWiseCandidatesCount);
    const { applicationSourceData, applicationSourceOptions } = generateApplicationSourceChartData(reportsCount?.sourceWiseCandidatesCount);


    return (
        <Box>
            <TopBox>
                {/* <span className='text1'>Showing Last {14} days Reports</span> */}
                <span className='selectBox'>
                    <Stack spacing={3} sx={{ width: "100%", }}>
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={jdList ? jdList?.filter(jd => !selectedJd?.includes(jd?.jdId))?.map(jd => jd?.title) : [{ jdId: "Dummy Jd", title: "Dummy Jd" }]}
                            getOptionLabel={(option) => option}
                            onChange={handleJdChange}
                            value={jdList && jdList
                                ?.filter(jd => selectedJd?.includes(jd?.jdId))
                                ?.map(jd => jd?.title)}                            selectOnFocus
                            fullWidth
                            PaperComponent={(props) => <Paper {...props} sx={{ backgroundColor: '#ffffff' }} />}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Jds"
                                    sx={{ backgroundColor: "#ffffff", }}
                                />
                            )}
                        />
                    </Stack>
                </span>
                <span className='calendar'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker label="From-Date" value={fromDate} onChange={(newDate) => setFromDate(dayjs(newDate))} />
                        </DemoContainer>
                    </LocalizationProvider>
                    -
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker label="To-Date" value={toDate} onChange={(newDate) => setToDate(dayjs(newDate))} />
                        </DemoContainer>
                    </LocalizationProvider>
                </span>
            </TopBox>
            <div className='box1'>
                <div className='middle'>
                    <div className='mainCard child1'>
                        <span className='title'>
                            <span className='text'>Open Positions by Skills</span>
                            <img src={downloadIcon} className='img' onClick={() => handleDownloadByReportType("OPEN_POSITIONS_SKILLS")} />
                        </span>

                        <div className='innerBox'>
                            <div className='innerBox1'>
                                {
                                    openPosData?.slice(1, 10).map((data, i) => (
                                        <ReportDepartmentText key={i} text={data?.[0]} color={openPosOptions?.colors[i]} />
                                    ))
                                }
                                {openPosData?.length > 10 && <span className='moreText'>and {openPosData?.length - 10} other skills.</span>}
                            </div>
                            <div className='innerBox2'>
                                <Chart
                                    chartType="PieChart"
                                    width="28vw"
                                    height="28vw"
                                    data={openPosData}
                                    options={openPosOptions}
                                    className="donut-chart"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mainCard child1'>
                        <span className='title'>
                            <span className='text'>Candidate Progression</span>
                            <img src={downloadIcon} className='img' onClick={() => handleDownloadByReportType("CANDIDATES_PROGRESSION")} />
                        </span>
                        <div className='innerBox'>
                            <div className='innerBox1'>
                                {
                                    candidateProgressionData?.slice(1).map((data, i) => (
                                        <ReportDepartmentText key={i} text={data?.[0]} color={candidateProgressionOptions?.colors[i]} />
                                    ))
                                }
                            </div>
                            <Chart
                                chartType="PieChart"
                                width="17vw"
                                height="17vw"
                                data={candidateProgressionData}
                                options={candidateProgressionOptions}
                                className="donut-chart"
                            />
                        </div>
                    </div>
                </div>
                <div className='right'>
                    <div className='mainCard child1'>
                        <span className='title'>
                            <span className='text'>Candidates Pipeline</span>
                            <img src={downloadIcon} className='img' onClick={() => handleDownloadByReportType("CANDIDATES_PIPELINE")} />
                        </span>

                        <div className='innerBox'>
                            <div className='innerBox1'>
                                {candidatesPipelineData?.slice(1).map((data, i) => (
                                    <ReportDepartmentText key={i} text={data?.[0]} color={candidatesPipelineOptions?.colors[i]} />
                                ))}
                            </div>
                            <div className='innerBox2'>
                                <Chart
                                    chartType="PieChart"
                                    width="28vw"
                                    height="28vw"
                                    data={candidatesPipelineData}
                                    options={candidatesPipelineOptions}
                                    className="donut-chart"
                                />
                            </div>
                        </div>
                    </div>

                    <div className='mainCard child1'>
                        <span className='title'>
                            <span className='text'>Candidate Acceptance Ratio</span>
                            <img src={downloadIcon} className='img' onClick={() => handleDownloadByReportType("CANDIDATE_ACCEPTANCE_RATIO")} />
                        </span>
                        <div className='innerBox'>
                            <div className='innerBox1'>
                                {
                                    candidateAcceptanceData?.slice(1).map((data, i) => (
                                        <ReportDepartmentText key={i} text={data?.[0]} color={candidateAcceptanceOptions?.colors[i]} />
                                    ))
                                }
                            </div>
                            <Chart
                                chartType="PieChart"
                                width="17vw"
                                height="17vw"
                                data={candidateAcceptanceData}
                                options={candidateAcceptanceOptions}
                                className="donut-chart"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='box3'>
                <div className='mainCard middle'>
                    <span className='title'>
                        <span className='text'>Selection and Rejection Rates by Round</span>
                        <img src={downloadIcon} className='img' onClick={() => handleDownloadByReportType("SELECTION_REJECTION_RATIO")} />
                    </span>
                    <Chart chartType="ColumnChart" width="33vw" height="20rem" data={candidateAcceptanceByRoundData} options={candidateAcceptanceByRoundOptions} />
                </div>
                <div className='mainCard right'>
                    <span className='title'>
                        <span className='text'>Applications Received by Source</span>
                        <img src={downloadIcon} className='img' onClick={() => handleDownloadByReportType("APPLICATIONS_RECEIVED_SOURCE")} />
                    </span>

                    <Chart chartType="ColumnChart" width="33vw" height="20rem" data={applicationSourceData} options={applicationSourceOptions} />
                </div>
            </div>
        </Box>
    )
}

export default Report


const TopBox = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 1rem 1.5rem;
background-color: var(--white);
box-sizing: border-box;
border-radius: 0.5rem;

.text1 {
    font-size: 1rem;
    font-weight: 600;
    font-family: var(--font);
}

.calendar {
    display: flex;
    flex-direction: row;
    min-width: 50%;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
}

.selectBox {
    width: 25%;
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}

`

const Box = styled.div`
width: 96%;
margin: 1rem auto;
display: flex;
flex-direction: column;
box-sizing: border box;
gap: 1rem;


.mainCard {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 1.5rem 1rem;
    gap: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
    box-sizing: border-box;


    .title {
        font-size: 1rem;
        font-weight: 600;
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;


        .text {
            font-size: 1rem;
            font-weight: 600;
        }

        .img {
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;
        }
    }

    .innerBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
    
        .innerBox1 {
            display: flex;
            gap: 0.75rem;
            flex-flow: row wrap;

            .moreText {
                font-size: 0.8rem;
                font-weight: 500;
            }
        }
    
        .innerBox2 {

        }
    }
}


.box1 {
    width: 100%;
    display: flex;
    gap: 1.5%;

.middle {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .child1 {
        width: 100%;
    }
    .child2 {
        width: 100%;
    }
}

.right {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .child1 {
        width: 100%;

        .title {
            font-size: 1rem;
            font-weight: 600;
        }

    }

    .child2 {
        width: 100%;
        gap: 3rem;

        .innerChild1 {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;

            .title {
                font-size: 1.5rem;
                font-weight: 600;
            }

            .text {
                font-size: 1rem;
                font-weight: 500;
            }
        }

        .innerChild2 {
            width: 100%;
            display: flex;
            gap: 0.5rem;
            justify-content: space-between;
            align-items: start;
            

            .number {
                font-size: 1.5rem;
                font-weight: 600;
            }

            .text {
                font-size: 0.9rem;
                font-weight: 500;
                text-align: center;
            }

            .left {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
            }

            .right {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
            }
        }


        
    }
}

}

.box3 {
    width: 100%;
    display: flex;
    gap: 1.5%;
    justify-content: space-between;
    box-sizing: border-box;

    .middle {
        width: 50%;
    }

    .right {
        width: 50%;
    }
}

`