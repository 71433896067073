import React from 'react'
import styled from 'styled-components';

const IconButton = ({icon, width, func, isBorder, bgColor, altText}) => {
    return (
        <ButtonWrapper
            width={width}
            isBorder={isBorder}
            bgColor={bgColor}
            onClick={func}
        >
            <Icon src={icon} alt={altText} />
        </ButtonWrapper>
    );
};

export default IconButton;

const ButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width || '1.5rem'};
  height: ${({ width }) => width || '1.5rem'};
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  border: ${({ isBorder }) => !isBorder ? 'none' : '0.1rem solid grey'};
  border-radius: 0.25rem;
  cursor: pointer;
  padding: 0.25rem;
  outline: none;
  
  &:hover {
    opacity: 0.9;
  }
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
`;