import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { forgetPassword } from '../functions/api/authentication/forget';
import styled from 'styled-components';
import logo from '../assets/regorTalentLogo.webp'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import loginImg from "../assets/loginPageSecureImg.webp";
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import PasswordResetRequestSuccessMessage from '../components/commonComponents/InfoMessageComp/PasswordResetRequestSuccessMessage';


const Forgot = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [clientCode, setClientCode] = useState('');
  const captchaRef = useRef(null);
  const [captchaError, setCaptchaError] = useState(false);

  const [success, setSuccess] = useState(false);

  const handleReset = async (e) => {
    e.preventDefault();

    try {
      const token = captchaRef.current.getValue();

      if (!token) {
        setCaptchaError(true);
      } else {
        const res = await forgetPassword(email, clientCode);
        if (res) {
          setCaptchaError(false);
          captchaRef.current.reset();
          setSuccess(true);
          setEmail('');
          setClientCode('');
        }

      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  }


  return (
    <>
      {!success ? <StyledDiv>
        <div
          style={{
            height: "3.5rem",
            position: "absolute",
            top: "1rem",
            left: "3rem",
          }}
        >
          <img src={logo} style={{ height: "100%" }} />
        </div>

        <IconButton onClick={() => navigate("/")} className="prev">
          <ArrowBackIcon sx={{ fontSize: "30px" }} />
        </IconButton>
        <>
          <div id="form">
            <h1>Reset</h1>

            <p>Enter your Email</p>

            <ValidatorForm onSubmit={handleReset}>
              <div className="inputBox">
                <TextValidator
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  errorMessages={["This field is required", 'Email is not valid']}
                  validators={['required', 'isEmail']}
                  fullWidth
                  required
                  inputProps={{
                    sx: {
                      color: '#626264',
                      fontSize: '0.9rem',
                      fontWeight: '400'
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: '#626264',
                      fontSize: '0.9rem',
                      fontWeight: '400'
                    },
                  }}
                />
              </div>
              {id != 'seeker' && <div className="inputBox">
                <TextValidator
                  label="Client Code"
                  type="text"
                  value={clientCode}
                  onChange={(e) => setClientCode(e.target.value)}
                  errorMessages={["This field is required"]}
                  validators={['required']}
                  fullWidth
                  required
                  inputProps={{
                    sx: {
                      color: '#626264',
                      fontSize: '0.9rem',
                      fontWeight: '400'
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: '#626264',
                      fontSize: '0.9rem',
                      fontWeight: '400'
                    },
                  }}
                />
              </div>}
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                ref={captchaRef}
                size="normal"
              />
              {captchaError && <span className="captchaErrorText">Error: please verify captcha</span>}
              <button type="submit" className="btn">
                Reset your Password
              </button>
            </ValidatorForm>
            <p>
              Don't have an account? <Link to="/signup">Sign Up</Link>
            </p>
          </div>
          <div id="cover">
            <img src={loginImg} />
          </div>
        </>
      </StyledDiv> : <PasswordResetRequestSuccessMessage />}</>
  )
}

export default Forgot


const StyledDiv = styled.div`
display: flex;
  width: 100%;
  margin-top: 0rem;

  .captchaErrorText {
    font-size: 0.8rem;
    font-weight: 500;
    color: red;
    margin-top: -0.5rem;
  }


  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 80%;
  }

  .inputBox {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-top: 1rem;
  }

  #form {
    width: 50%;
    background-color: var(--backgroundColor);
    // height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #cover {
    width: 50%;
    min-height: 100vh;
    background-color: var(--backgroundColor);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 40%;
    }
  }

  input {
    border-radius: 0.3rem;
    font-size: 0.9rem;
  }

  .btn {
    background-color: var(--lightOrange);
    color: var(--backgroundColor);
    font-size: 1rem;
    border-radius: 0.4rem;
    border: none;
    padding: 0.6rem 1rem;
    // width: 50%;
    cursor: pointer;
  }

  .prev {
    background-color: var(--lightOrange);
    padding: 0.1rem;
    position: absolute;
    top: 6rem;
    left: 3rem;
    color: var(--white);
  }

  .prev:hover {
    color: var(--color);
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50%;

    #form {
      width: 100%;
    }

    #cover {
      display: none;
    }
  }

`

