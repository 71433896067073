import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/commonComponents/Loader';
import view from '../../assets/icons/visible.png'
import iIcon from '../../assets/icons/iIcon.png'
import { getUniqueJds } from '../../functions/api/employers/getUniqueJds';
import { getAllTrackers } from '../../functions/api/employers/tracker/getAllTrackers';
import { getJobApplicationsByJdId } from '../../functions/api/jobApplication/getJobApplicationsByJdId';
import { getBlobData } from '../../functions/api/resume/getBlobData';
import download from '../../assets/icons/Profile/download.png'
import { getEmployer } from '../../functions/api/employers/profile/getEmployer';
import ConfigurableModal from '../../components/Interviews/SeekerDashboard/seekerCommonComponents/ConfigurableModal';
import AgencyUploadCandidates from '../../components/Interviews/EmployerAgency/AgencyUploadCandidates';
import { getJdByClientCode } from '../../functions/api/employers/getJdByClientCode';
// import { Pagination, PaginationSizeFilter } from '../commonComponents/Pagination';
import { toast } from "react-toastify";

function Row(props) {

  const { row, index, type } = props;
  const accessToken = useSelector(state => state?.auth?.userData?.accessToken);
  const clientCode = useSelector(state => state?.auth?.userData?.user?.clientCode);

  const handleDownload = async (id, name) => {
    try {
      if (!id) {
        toast.warning("No resume found or invalid resumeId", 5000);
        return;
      }

      const res = await getBlobData(
        `api/media/downloadById?fileType=resume&id=${id}`,
        accessToken,
        clientCode
      );
      const a = document.createElement("a");
      a.href = res;
      a.setAttribute("download", `${name}`);
      a.click();
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }} className={`${index % 2 == 1 ? 'colored' : ''}`}>
        <TableCell component="th" scope="row" align='center' className='tableCell'>
          {row?.name}
        </TableCell>{" "}
        <TableCell component="th" scope="row" align="center" className='tableCell'>
          {row?.email}
        </TableCell>
        <TableCell component="th" scope="row" align="center" className='tableCell'>
          {row?.contact}
        </TableCell>
        {type && <TableCell component="th" scope="row" align="center" className='tableCell'>
          {row?.stage}
        </TableCell>}
        <TableCell component="th" scope="row" align="center" className='tableCell'>
          <div style={{ display: 'flex', gap: '0.5rem', justifyContent: 'center', alignItems: 'center' }}>
            <img src={download} style={{ width: '1rem', height: '1rem', cursor: 'pointer', border: '0.08rem solid grey', padding: '0.3rem', borderRadius: '0.3rem' }} onClick={() =>
              handleDownload(row?.resumeId, row?.srcFilename)
            } />
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


const CandidateListing = ({ type, setCurrentItem, empClientCode, selectedJDID, setSelectedJDID }) => {
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  const accessToken = useSelector(state => state?.auth?.userData?.accessToken);
  const clientCode = useSelector(state => state?.auth?.userData?.user?.clientCode);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [jdOptions, setJdOptions] = useState([]);
  const [openUpload, setOpenUpload] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);

  const [jdBox, setJdBox] = useState(false);
  const [jdInfo, setJdInfo] = useState({});

  const [empInfoBox, setEmpInfoBox] = useState(false);
  const [empInfo, setEmpInfo] = useState({});

  const dispatch = useDispatch();

  const handleJDIDChange = (event) => {
    setSelectedJDID(event.target.value);
  };

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    // Implement your file upload logic here
  };

  const handleSizeChange = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handlePageChange = (change) => {
    if (change && page < Math.ceil(+total / +size)) {
      setPage((prev) => prev + 1);
    } else if (!change && page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    async function getData(value) {
      try {
        const res = await getUniqueJds(accessToken, clientCode, empClientCode);
        if (res) setJdOptions(res?.data);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }
    getData();
  }, [size, page])

  useEffect(() => {
    if (type) {

      const getData = async () => {
        try {
          const res = await getAllTrackers(selectedJDID,
            accessToken,
            page,
            size,
            type == "SHORTLISTED" ? type : "IN_PROCESS",
            clientCode);
          setData(change(res?.data?.data, "track"));
        } catch (error) {
          const errMsg =
            error?.response?.data?.notify?.message ||
            "An error occurred. Please try again.";
          toast.error(errMsg, 8000);
        }
      }
      getData();
    }
    else {
      const getData = async () => {
        try {
          const res = await getJobApplicationsByJdId(selectedJDID, accessToken, empClientCode, 1, 1000000, clientCode);
          setData(change(res?.data?.data, "app"));
        } catch (error) {
          const errMsg =
            error?.response?.data?.notify?.message ||
            "An error occurred. Please try again.";
          toast.error(errMsg, 8000);
        }
      }
      getData();
    }

  }, [selectedJDID])

  const handleGetEmpInfo = async (clientCode) => {
    try {
      const res = await getEmployer(accessToken, clientCode);
      if (res) {
        setEmpInfo(res?.data)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  }

  const handleGetJdInfo = async (jdId) => {
    try {
      const res = await getJdByClientCode(jdId, accessToken, empClientCode);
      if (res) setJdInfo(res?.data);
    }
    catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  }

  return (

    <StyledBox>
      {/* {isLoading && <Loader message={loaderMessage} />} */}
      <ConfigurableModal open={openUpload} setOpen={setOpenUpload} component={<AgencyUploadCandidates jdId={selectedJDID} empClientCode={empClientCode} handleClose={() => setOpenUpload(false)} />} style={{ width: '40%', height: '65%' }} />
      {!isLoading && <TableContainer component={Paper} className="tableBox">
        <StyledContainer>
          <LeftSide>
            <BoxRow >
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.2rem" }}><p>{empClientCode}</p>
                <img
                  src={iIcon}
                  style={{ width: "0.8rem", height: "0.8rem", cursor: "pointer", display: "flex" }}
                  className={`i-icon ${empInfoBox ? "active" : ""
                    }`}
                  onClick={() => {
                    if (empInfoBox) {
                      setEmpInfoBox(false)
                    }
                    else {
                      handleGetEmpInfo(empClientCode);
                      setEmpInfoBox(true)
                    }
                  }}
                />
              </div>
              <div
                className={`dropdown-content ${empInfoBox ? "open" : ""
                  }`}
                ref={dropdownRef}
              >
                <p><b>Employer Name:</b> <br />{empInfo?.companyName}</p>
                <p><b>Co-ordinator Name:</b> <br />{empInfo?.coOrdinatorName}</p>
              </div></BoxRow>

            <BoxRow >
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.2rem" }}><Select value={selectedJDID} onChange={handleJDIDChange}>
                {
                  jdOptions?.map((jd) => {
                    return <Option value={jd}>{jd}</Option>
                  })
                }
              </Select>
                <img
                  src={iIcon}
                  style={{ width: "0.8rem", height: "0.8rem", cursor: "pointer", display: "flex" }}
                  className={`i-icon ${jdBox ? "active" : ""
                    }`}
                  onClick={() => {
                    if (jdBox) {
                      setJdBox(false)
                    }
                    else {
                      setJdBox(true)
                      handleGetJdInfo(selectedJDID)

                    }
                  }}
                />
              </div>
              <div
                className={`dropdown-content ${jdBox ? "open" : ""
                  }`}
                ref={dropdownRef2}
              >
                <p><b>JD Title:</b> <br />{jdInfo?.title}</p>
                <p><b>Hiring Manager:</b> <br />{jdInfo?.hiringManager}</p>
              </div></BoxRow>
            {!type && <Button onClick={() => setOpenUpload(true)}>
              Upload
            </Button>}
          </LeftSide>
          <RightSide>

            <Button onClick={() => dispatch(setCurrentItem("jdListing"))}>Back</Button>

          </RightSide>
        </StyledContainer>
        {/* <SearchBarContainer> */}
        {/* <SeekerTableSearchBar value={searchValue} setValue={setSearchValue} /> */}
        {/* </SearchBarContainer> */}
        <Table aria-label="collapsible table">
          <TableHead className="tableHead">
            <TableRow>
              <TableCell align='center' className='tableCell'>Name</TableCell>
              <TableCell align='center' className='tableCell'>Email</TableCell>
              <TableCell align='center' className='tableCell'>Contact</TableCell>
              {type && <TableCell align='center' className='tableCell'>Round</TableCell>}
              <TableCell align='center' className='tableCell'>Download</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {data?.map((row, index) => (
              <Row key={row?.jobId} row={row} index={index} isLoading={isLoading} setIsLoading={setIsLoading} loaderMessage={loaderMessage} setLoaderMessage={setLoaderMessage} type={type} />
            ))}
          </TableBody>
        </Table>
        {/* <div className="paginationBox">
            <PaginationSizeFilter
              size={size}
              handleSizeChange={handleSizeChange}
            />
            <Pagination
              total={total}
              size={size}
              page={page}
              handlePageChange={handlePageChange}
              setPage={setPage}
            />
          </div> */}
      </TableContainer>}
    </StyledBox>

  );
};

export default CandidateListing;


const StyledBox = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  width: 100%;
  padding: 0;


  .colored {
    background-color: #ececec;
  }

  .paginationBox {
    display: flex;
    justify-content: end;
    gap: 2rem;
    margin: 1rem 3rem 1.5rem 0;
  }

  .tableBox {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.20);
    border-radius: 0.5rem;
    padding-top: 1rem;


    .title {
      padding-left: 1.2rem;
      font-size: 0.9rem;
      font-weight: 600;
    }
  }

  .MuiTableCell-root {
    border: none;
  }

  .MuiTableRow-root {
    border-bottom: none;
  }

  .btn {
    background-color: var(--lightOrange);
    padding: 0.3rem 0.5rem;
    border: none;
    color: var(--white);
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: pointer;
    text-decoration: none;
    font-family: var(--font);
  }

  .tableHead {
    background-color: #d1fff0;
    width: 100%;
  
    .tableCell {
      font-size: 0.9rem;
      font-weight: 500;
      font-family: var(--font);
      color: var(--color);
    }
    
  }
  
  .tableBody {
    width: 100%;
  
    .tableCell {
      font-size: 0.8rem;
      font-weight: 400;
      font-family: var(--font);
      color: var(--color);
    }
  }

  .rowText {
    font-size: 0.75rem;
  }

  .logo {
    width: 2rem;
    height: 2rem;

    img {
        width: 100%;
        height: 100%;
        border-radius: 10%;
    }
  }
  
`;



const Container1 = styled.div`
  width:95%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;


const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  margin: 1rem auto 0.5rem auto;
  height: 3rem;
  background-color: var(--white);
  border-radius: 0.5rem;;
  padding: 0rem 1rem;
  gap: 1rem;
`
const StyledContainer = styled.div`
  display: flex;
  height: 5rem;
`;

const LeftSide = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 4rem;
  padding: 0 10px;
  gap: 2rem;

  // Adjust styles as needed
`;

const RightSide = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 4rem;
`;

const JDIDDropdown = styled.select`
  margin-right: 10px;
`;

const FileInput = styled.input`
  margin-left: 10px;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: var(--lightOrange);
  color: #fff;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  align-self: center;
  font-size: 0.9rem;
  font-weight: 600;
  font-family: var(--font);
`


const Select = styled.select`
  height: 2.5rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  background-color: #ececec;
  outline: none;
  font-size: 0.9rem;
  font-weight: 600;
  color: #7D87A9;
  font-family: var(--font);

  
`;


const Option = styled.option`
font-size: 0.9rem;
font-weight: 500;
border: none;
color: var(--color);
`;

const BoxRow = styled.div`
  position: relative;
  display: inline-block;

  .i-icon {
    cursor: pointer;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--white);
    box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    left: 70%;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    min-width: 10rem;
    justify-content: start;
    padding: 0.5rem 0.5rem;
    botton: 0.3rem;
    ${(props) =>
    props.isLast &&
    css`
        bottom: 1.4rem;
        right: 10%;
      `}
  }

  .dropdown-content span {
    padding: 0.3rem 0.8rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--color);
    cursor: pointer;
  }

  .dropdown:hover .dropdown-content,
  .dropdown-content.open {
    display: block;
  }

  .threeDotIcon {
    width: 0.6rem;
    height: 0.6rem;
    cursor: pointer;
    border: 0.08rem solid grey;
    padding: 0.15rem;
    border-radius: 0.2rem;
  }
`;



function change(array, type) {

  if (type == "app") {
    array = array?.reduce((acc, it) => {
      let obj = {
        name: it.seekerName,
        email: it.seekerEmail,
        contact: it.seekerContact,
        resumeId: it.resume.id,
        srcFilename: it.resume.srcFilename
      }

      return [...acc, obj];
    }, [])
  }
  else {
    array = array?.reduce((acc, it) => {
      let record = it?.matchedRecord
      let obj = {
        name: record.name,
        email: record.email,
        contact: record?.contact,
        resumeId: record?.resumeId,
        srcFilename: record?.srcFilename,
        stage: it.stage
      }

      return [...acc, obj];
    }, [])
  }
  return array;
}