import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components'
import { formatDate, getDateRange } from '../../../../utils/BillingDateRangeFilter';
import { getTokenUsageByDateRange } from '../../../../functions/api/billing/getTokenUsageByDateRange';

const PrepaidBillingTop = () => {
  const clientCode = useSelector(state => state?.auth?.userData?.user?.clientCode);
  const accessToken = useSelector(state => state?.auth?.userData?.accessToken);

  const [dateString, setDateString] = useState("thisMonth");
  const [tokenUsageData, setTokenUsageData] = useState(null);
  const [dateRange, setDateRange] = useState(getDateRange(dateString));

  const creditBalance = 1402

  useEffect(() => {
    const getToken = async () => {
      try {
        const res = await getTokenUsageByDateRange(accessToken, clientCode, formatDate(dateRange[0]), formatDate(dateRange[1]));
        if (res) {
          setTokenUsageData(res?.data);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }
    getToken();
  }, [])

  function calculateTotalCost(data) {
    let totalCost = 0;
    for (const [key, value] of Object.entries(data)) {
      if (key.endsWith('Cost')) {
        totalCost += value;
      }
    }
    return totalCost?.toFixed(3);
  }

  const generateStructuredData = (data) => {
    return Object.entries(data)?.map(([key, value]) => {
      if (key.endsWith('Count')) {
        const feature = key.replace('Count', '');
        const featureText = feature === "questionsCreation" ? "Interview Question Creation" : feature === "questionsScoring" ? "Interview Question Evaluation" : feature === "resumeParsing" ? "Resume Parsing" : feature === "jdResumeMatching" ? "JD-Resume Matching" : "";
        const cost = data[`${feature}Cost`] || 0;
        return { featureText, count: value, cost };
      }
      return null;
    })?.filter(item => item !== null);
  };

  return (
    <TopBox>
      <Card>
        <div className="titleBox1">
          <Title>Business Plan</Title>
          <Amount>${creditBalance}</Amount>
        </div>
        <span className="creditTitle">${tokenUsageData && calculateTotalCost(tokenUsageData)}/${creditBalance} Credits used</span>
        <ProgressBar>
          <Progress width={tokenUsageData && (calculateTotalCost(tokenUsageData) / creditBalance) * 100} />
        </ProgressBar>
        <Button>Upgrade</Button>
      </Card>
      <Card>
        <div className="calculatorBox">
          <Title>Price Estimation Calculator</Title>
          <p>Calculate the Price for Your Enterprise Needs with Our Comprehensive Tool</p>
        </div>

        <Button bg="#66BB6A" hover="#57A35E">Calculate Now</Button>
      </Card>
      <Card>
        <Title>Card Details</Title>
        <div className="cardBox">
          <span>**** **** **** 1402</span>
          <span className="cardDetail"><span>02/27</span>       <span>***</span></span>
        </div>
        <Button>Change Card</Button>
      </Card>
    </TopBox>
  )
}

export default PrepaidBillingTop


const TopBox = styled.div`
display: flex;
width: 94%;
flex-direction: row;
justify-content: space-between;
margin-bottom: 0.75rem;
gap: 1rem;


`


const Card = styled.div`
  background-color: var(--white);
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 1.5rem;
  width: 30%;
  box-shadow: 0 2px 3px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: 0.5rem;

  .titleBox1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  }

  .cardBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .cardDetail {
   display: flex;
   width: 100%;
   gap: 2rem;
  }
  }

  .calculatorBox {
   display: flex;
   width: 100%;
   flex-direction: column;

  p {
    font-size: 0.9rem;
    font-weight: 500;
  }
  }

  .creditTitle {
   font-size: 0.9rem;
   font-weight: 600;
   margin-bottom: -0.5rem;
  }
`;

const Title = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.6rem;
  color: var(--color);
`;

const Amount = styled.h3`
  font-size: 1.2rem;
  color: var(--color);
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 10px;
  background: #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px 0;
`;

const Progress = styled.div`
  height: 100%;
  width: ${props => props.width || 0}%;
  background: #FFB74D;
  transition: width 0.3s;
`;

const Button = styled.button`
  background: ${props => props.bg || '#FFB74D'};
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 0.4rem;
  cursor: pointer;
  transition: background 0.3s;
  font-weight: 500;

  &:hover {
    background: ${props => props.hover || '#FFA726'};
  }
`;