import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import moment from "moment-timezone";
import TimeSlot from "../../EmployerDashboard/commonComponents/TimeSlot";
import dayjs from "dayjs";
import { rescheduleInterview } from "../../../../functions/api/employers/schedule/rescheduleInterview";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;
  margin: 2rem 0;

  .mainBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5rem;
    align-items: center;
    justify-content: center;


    .childBox {
        height: 100%;
    display: flex;
    flex-direction: column;
    width: 30%;
    gap: 3rem;
    align-items: center;
    justify-content: center;
    }
  }


`;


const ScheduleButton = styled.button`
background-color: var(--lightOrange);
color: #fff;
padding: 0.5rem 0.8rem;
font-size: 0.9rem;
cursor: pointer;
border: none;
border-radius: 0.25rem;
margin-bottom: 2rem;

`;

const RescheduleSlotSelection = ({ handleClose, interviewId, setTriggerRefresh }) => {
    const [value, setValue] = useState(dayjs(new Date()));
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(dayjs(new Date()));

    const accessToken = useSelector((state) => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

    const minDate = dayjs().startOf('day'); // Prevent going to previous months

    const handleReScheduleInterview = async (interviewId) => {
        const dateTime = moment(value?.format("YYYY-MM-DD") + "T" + (selectedTimeSlot.$H < 10 ? '0' + selectedTimeSlot.$H : selectedTimeSlot.$H) + ":" + (selectedTimeSlot.$m < 10 ? '0' + selectedTimeSlot.$m : selectedTimeSlot.$m) + ":" + "00.000").utc().format('YYYY-MM-DD HH:mm');
        const date = dateTime?.slice(0, 10);
        const time = dateTime?.slice(11);

        if ((dayjs(new Date()).$D === value.$D && dayjs(new Date()).$M === value.$M && dayjs(new Date()).$y === value.$y) && (((dayjs(new Date()).$H * 60) + (dayjs(new Date()).$m) + 0) > ((selectedTimeSlot?.$H * 60) + selectedTimeSlot?.$m))) {
            toast.warning("Please select future time slot", 8000)
            return;
        }

        const payload = {
            slot: date + "T" + time,
        }
        try {
            const res = await rescheduleInterview(interviewId, payload, accessToken, clientCode);
            if (res) {
                toast.success(`Rescheduled Interview`, 5000);
                setTriggerRefresh(prev => !prev);
                handleClose();
            }
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    }

    return (
        <PageContainer>
            <h3>Reschedule Interview</h3>
            <div className="mainBox">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="calendarBox">
                        <DateCalendar
                            value={value}
                            onChange={(newValue) => setValue(dayjs(newValue))}
                            views={['day']}
                            minDate={minDate} // Disables navigation to previous months
                            sx={{
                                height: '100%',
                                display: 'flex',
                            }}
                        />
                    </div>
                </LocalizationProvider>
                <div className="childBox">
                    <TimeSlot 
                    selectedTimeSlot={selectedTimeSlot} 
                    setSelectedTimeSlot={setSelectedTimeSlot}
                    selectedDate={moment(value?.format("YYYY-MM-DD") + "T" + (selectedTimeSlot.$H < 10 ? '0' + selectedTimeSlot.$H : selectedTimeSlot.$H) + ":" + (selectedTimeSlot.$m < 10 ? '0' + selectedTimeSlot.$m : selectedTimeSlot.$m) + ":" + "00.000").format('YYYY-MM-DD HH:mm')?.slice(0, 10)}
                    isReschedule={true}
                    />
                    <ScheduleButton onClick={() => handleReScheduleInterview(interviewId)}>
                        Reschedule Interview
                    </ScheduleButton>
                </div>
            </div>
        </PageContainer>
    );
};

export default RescheduleSlotSelection;
