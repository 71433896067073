import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const ProductIntroVideo = () => {
    const [isMuted, setIsMuted] = useState(true);
    const videoId = 'gf1MSzwDk-A';

    useEffect(() => {
        // Unmute on mount
        setIsMuted(false);
    }, []);

    const getIframeSrc = () => {
        const mutedQueryParam = isMuted ? '' : '&mute=1';
        const suggestedQualityParam = '&vq=hd';
        const controlQuery = '&controls=1';
        const loopQuery = '&loop=1';
        return `https://www.youtube.com/embed/${videoId}?playlist=${videoId}&autoplay=1${mutedQueryParam}${suggestedQualityParam}${controlQuery}${loopQuery}`;
    };

    return (
        <MainBox>
            <Iframe
                src={getIframeSrc()}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded YouTube Video"
            />
        </MainBox>
    );
};

export default ProductIntroVideo;

const MainBox = styled.div`
    position: relative;
    display: flex;
    width: 80%;
    margin: 1rem auto 2.5rem auto;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    overflow: hidden;
    border: 0.1rem solid var(--white);
    background-color: var(--white);

    /* Responsive height */
    height: 100%;
    max-height: 1000px; /* Adjust as needed */

    @media (max-width: 480px) {
        height: 300px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        height: 400px;
    }

    @media (min-width: 769px) and (max-width: 1200px) {
        height: 500px;
    }

    @media (min-width: 1201px) and (max-width: 1400px) {
        height: 600px;
    }

    @media (min-width: 1401px) and (max-width: 1600px) {
        height: 700px;
    }

    @media (min-width: 1601px) and (max-width: 1800px) {
        height: 800px;
    }

    @media (min-width: 1801px) and (max-width: 2000px) {
        height: 900px;
    }

    @media (min-width: 2001px) and (max-width: 2200px) {
        height: 1000px;
    }

    @media (min-width: 2201px) and (max-width: 2500px) {
        height: 1100px;
    }
`;

const Iframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;