import axios from "axios";

export const downloadReports = async (accessToken, clientCode, reportType, jdIds = [], fromDate = null, toDate = null) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            "X-Client-Code": clientCode,
        },
    };

    let url = `${process.env.REACT_APP_API_URL}/api/jds/downloadReport?reportType=${reportType}&`;
    if (fromDate) {
        url += `fromDate=${encodeURIComponent(fromDate)}&`;
    }
    if (toDate) {
        url += `toDate=${encodeURIComponent(toDate)}&`;
    }
    if (jdIds.length > 0) {
        url += jdIds.map(id => `jdIds=${encodeURIComponent(id)}`).join('&');
    }

    try {
        const response =
            await axios.get(url, {
                ...config,
                responseType: 'arraybuffer'
            });

        return response.data;
    } catch (error) {
        throw error;
    }
};
