import React from 'react';
import styled from 'styled-components';
import { Brain, BookMarked } from 'lucide-react';
import Button from '../../../../component/shared/Button';

const Container = styled.div`
  max-width: 75rem;
  margin: 0 auto;
  padding: 1rem;
`;


const Grid = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(1, 1fr);

  @media(min-width: 48rem) {
    grid-template-columns: repeat(2, 1fr);
  }

  .desc{
   font-size: 1rem;
   font-weight: 600;
   text-align: start;
  }
`;

const Card = styled.div`
  background: white;
  border-radius: 0.5rem; 
  box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1); 
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1); 
  }
`;

const CardHeader = styled.div`
  padding: 0.25rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

`;

const CardTitle = styled.h2`
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  margin-bottom: 0rem;


  svg {
    margin-right: 0.5rem;
  }
`;

const CardDescription = styled.p`
  color: #6b7280;
  font-size: 0.9rem;
  padding-left: 2rem;
`;

const CardContent = styled.div`
  padding: 0.25rem 1rem 1rem 1rem; 
`;

const List = styled.ul`
  list-style-type: disc;
  margin-left: 1rem;
  margin-bottom: 1rem;
  text-align: start;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
`;

const TestCard = ({ icon: Icon, title, description, children }) => (
  <Card>
    <CardHeader>
      <CardTitle>
        <Icon /> {title}
      </CardTitle>
      <CardDescription>{description}</CardDescription>
    </CardHeader>
    <CardContent>{children}</CardContent>
  </Card>
);

const TestTypeSelection = ({ onSelect }) => {
  return (
    <Container>
      <Grid>
        <TestCard
          icon={Brain}
          title="AI-Powered Test Generation"
          description="Create a test quickly using artificial intelligence"
        >
          <p className="desc">
            Our AI system generates a customized test based on your input. Simply provide some basic information about the subject matter and difficulty level, and let our advanced algorithms do the rest.
          </p>
          <List>
            <ListItem>Save time with automatic question generation</ListItem>
            <ListItem>Ensure diverse and relevant question types</ListItem>
            <ListItem>Easily adjust difficulty and topics</ListItem>
          </List>
          <Button text="Create AI Based Test" func={() => onSelect("create-ai")} disabled={false} />
        </TestCard>

        <TestCard
          icon={BookMarked}
          title="Personal Question Library"
          description="Create tests using your own curated question collection"
        >
          <p className="desc">
            Leverage your personal question library to create customized tests. Build and maintain your own collection of questions, then select from them to craft the perfect assessment.
          </p>
          <List>
            <ListItem>Access your private, self-curated question bank</ListItem>
            <ListItem>Reuse and adapt questions across multiple tests</ListItem>
            <ListItem>Continuously expand your library over time</ListItem>
          </List>
          <Button text="Create from My Library" func={() => onSelect('create')} disabled={false} />
        </TestCard>
      </Grid>
    </Container>
  );
};

export default TestTypeSelection;
