import axios from "axios";

export const getAllProctoringResults = async (jdId, email, accessToken) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/interviews/all-results?email=${email}&jdId=${jdId}`, config);
        return response;
    } catch (error) {
        throw error;
    }
}


