import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router';
import OngoingInterview from './OngoingInterview';
import Alerts from '../components/Interviews/ProctoringComponents/Alerts';
import { useProctoring } from '../components/Interviews/ProctoringHooks/useProctoring';
import axios from 'axios';
import { useSelector } from 'react-redux';
import getProctoringLevel from '../utils/getProctoringLevel';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
`;


const Proctored = () => {
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const user = useSelector((state) => state.auth.userData);

  const { interviewId,interviewState } = useParams();
  const [examHasStarted, setExamHasStarted] = useState(false);
  const [tabFocusCount, setTabFocusCount] = useState(0); // State to track tab focus count
  const [escapeFullScreenCount, setEscapeFullScreenCount] = useState(0); // State to track tab focus count
  const { fullScreen, tabFocus } = useProctoring({
    forceFullScreen: true,
    preventTabSwitch: true,
    preventContextMenu: true,
    preventUserSelection: true,
    preventCopyPaste: true,
  });

  // ====================PROCTORING==========================
  const [isProctorEduProctoring, setIsProctorEduProctoring] = useState(false);
  const [token, setToken] = useState('');
  const [supervisorInstance, setSupervisorInstance] = useState(null);
  const [supervisorFlag,setSupervisorFlag] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchToken = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      };
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/getTokenForProctoring/${interviewId}`, config);
        if(response?.data != "null"){
          setToken(response.data);
          setIsProctorEduProctoring(true);
          localStorage.setItem(interviewId,response.data);
        }
      } catch (error) {
        console.error('Failed to fetch JWT token:', error);
      }
    };
    // const level = getProctoringLevel(user);
    const existingToken = localStorage.getItem(interviewId);
    if (existingToken){
      setToken(existingToken);
      setIsProctorEduProctoring(true);
    }
    else if(token == '') {
      fetchToken();
    }


    return () => {
      localStorage.removeItem(interviewId);
    }
  }, []);

  useEffect(() => {
    const initializeProctoring = async () => {
      if (token) {
        try {
          const supervisor = new Supervisor({
            url: 'https://regortalent.proctoring.app'
          });

          await supervisor.init({
            provider: 'jwt',
            token: token
          });
          await supervisor.start();
          setSupervisorInstance(supervisor);
          setSupervisorFlag(prev => !prev);
        } catch (err) {
          console.error('Error initializing proctoring session:', err);
          toast.warning("Please allow proctoring permissions, It's Required");

          setTimeout(() => {
            window.location.reload();
          }, 500)
        }
      }

    };

    if(isProctorEduProctoring)initializeProctoring();

    return () => {
      if (supervisorInstance) {
        supervisorInstance.stop()
          .then(() => {
            localStorage.removeItem(interviewId);
          })
          .catch(err => {
            console.error('Error stopping proctoring session:', err);
          });
      }
    };
  }, [token]);
  // ==========================================================

  useEffect(() => {
    function handleKeyDown(event) {
      if (
        (event.ctrlKey && event.shiftKey && event.key === 'I') ||
        (event.ctrlKey && event.shiftKey && event.key === 'J') ||
        (event.ctrlKey && event.shiftKey && event.key === 'C') ||
        (event.ctrlKey && event.key === 'Tab') ||
        (event.ctrlKey && event.key === 'r') ||
        (event.ctrlKey && event.key === 'F5') ||
        (event.ctrlKey && event.key === 'w') ||
        (event.ctrlKey && event.key === 't') ||
        (event.ctrlKey && event.key === 'n') ||
        (event.ctrlKey && event.key === 'U') ||
        (event.ctrlKey && event.key === 'Shift' && event.key === 't') ||
        (event.altKey && event.key === 'Tab') ||
        (event.metaKey && event.key === 'Tab') ||
        (event.ctrlKey && event.key === 'F12') ||
        (event.metaKey && event.key === 'Option' && event.key === 'I') ||
        (event.metaKey && event.altKey && event.key === 'I') ||
        (event.metaKey && event.altKey && event.key === 'J')
      ) {
        event.preventDefault();
      }
    }

    if (examHasStarted) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [examHasStarted]);


  useEffect(() => {
    if (examHasStarted && tabFocus.status === false) {
      setTabFocusCount(prevCount => prevCount + 1); // Increment count if exam has not started and tab focus occurs
    }
  }, [examHasStarted, tabFocus.status]);

  useEffect(() => {
    if (examHasStarted && fullScreen.status === 'off') {
      setEscapeFullScreenCount(prevCount => prevCount + 1); // Increment count if exam has not started and tab focus occurs
    }
  }, [examHasStarted, fullScreen.status]);

  const handleStartExam = () => {
    if (!isProctorEduProctoring && !areDevToolsClosed()) {
      alert('Please close the Developer Tools before starting the exam.');
    } else {
      fullScreen.trigger();
      setTimeout(() => {
        setExamHasStarted(true);
      }, 100);
    }
  };

  const areDevToolsClosed = () => {
    return true;
  };

  const handleStopSupervisor = () => {
    if (supervisorInstance) {
      supervisorInstance.stop()
        .then(() => {
          localStorage.removeItem(interviewId);
        })
        .catch(err => {
          console.error('Error stopping proctoring session:', err);
        });
    }
  }


  return (
    <Container>
      <OngoingInterview
        isProctorEduProctoring={isProctorEduProctoring}
        start={examHasStarted}
        handleStart={handleStartExam}
        interviewState={interviewState}
        escapeFullScreenCount={escapeFullScreenCount}
        tabFocusCount={tabFocusCount}
        handleStopSupervisor={handleStopSupervisor}
              />
      {examHasStarted && <Alerts fullScreen={fullScreen} tabFocus={tabFocus} />}
    </Container>
  );
};

export default (Proctored);



