import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useNavigate } from "react-router";

import { useSelector } from "react-redux";
import EmployeMetrics from "../components/Interviews/EmployerDashboard/EmployerMetrics";
import EmployerSidebar from "../components/Interviews/EmployerDashboard/EmployerSidebar";
import EmployeProfile from "../components/Interviews/EmployerDashboard/sidebarPages/EmployerProfile";
import CreateQuestion from "../components/Interviews/EmployerDashboard/sidebarPages/CreateQuestion";
import EmployerHeader from "../components/commonComponents/EmployerHeader";
import Subscription from "../components/Interviews/EmployerDashboard/sidebarPages/Subscription";
import Report from "../components/Interviews/EmployerDashboard/sidebarPages/Report";
import Inbox from "../components/Interviews/EmployerDashboard/sidebarPages/Inbox";
import Billing from "../components/Interviews/EmployerDashboard/sidebarPages/Billing";
import RegisterCandidate from "../components/Interviews/EmployerDashboard/sidebarPages/RegisterCandidate";
import RegisteredCandidates from "../components/Interviews/EmployerDashboard/sidebarPages/RegisteredCandidates";
import ManageUsers from "../components/Interviews/EmployerDashboard/sidebarPages/ManageUsers";
import ConfigureReports from "../components/Interviews/EmployerDashboard/sidebarPages/ConfigureReports";
import InterviewDashboard from "../components/Interviews/EmployerDashboard/sidebarPages/InterviewDashboard";
import UploadCandidateProfile from "../components/Interviews/EmployerDashboard/sidebarPages/UploadCandidateProfile";
import EmpConfigureDash from "../components/Interviews/EmployerDashboard/sidebarPages/EmpConfigureDash";
import InterviewFlow from "../components/Interviews/EmployerDashboard/sidebarPages/InterviewFlow";
import RequestDemoPage from "../components/Interviews/EmployerDashboard/sidebarPages/RequestDemoPage";
import ManageAgencies from "../components/Interviews/EmployerDashboard/sidebarPages/ManageAgencies";
import JdListingTabs from "./agency/JdListingTabs";
import { useDispatch } from "react-redux";
import { setCurrentItem, setCurrentMetric } from "../slices/configSlice";
import CandidateListingTabs from "./agency/CandidateListingTabs";
import CallSupport from "../components/commonComponents/CallSupport";
import EmpApplications from "../components/Interviews/EmployerDashboard/sidebarPages/EmpApplications";
import EmpApplicationCandidatesList from "../components/Interviews/EmployerDashboard/sidebarPages/EmpApplicationCandidatesList";
import JdTabs from "../components/Interviews/EmployerDashboard/sidebarPages/JdTabs";
import CreateJds from "../components/Interviews/EmployerDashboard/sidebarPages/CreateJds";
import QuestionParentPage from "../features/Employer/questions/pages/QuestionParentPage";
import TestParentPage from "../features/Employer/tests/pages/TestParentpage";
import ActivityTracker from "../features/Employer/activityTracker/pages/ActivityTracker";
import CustomReport from "../features/Employer/report/pages/CustomReport";

const EmployerDashboard = () => {
    const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);
  const userType = useSelector((state) => state.auth.userType);
  const currentItem = useSelector(state => state.config?.currentItem);
  const [selectedJDID, setSelectedJDID] = useState("");
  const [empClientCode, setEmpClientCode] = useState("");

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [empScheduledPage, setEmpScheduledPage] = useState({ index: 1, jdId: null });

  useEffect(() => {
    if(currentItem==="applications"){
    setEmpScheduledPage({index: 1, jdId: null});
    }
  }, [currentItem])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (event.currentTarget.performance.navigation.type === 1) {
        dispatch(setCurrentItem('dashboard'));
        dispatch(setCurrentMetric(userType === 'EMPLOYER' ? 'interviews' : 'jdListings'))
      }
    };

    window.addEventListener('load', handleBeforeUnload);

    return () => {
      window.removeEventListener('load', handleBeforeUnload);
    };
  }, [dispatch]);

  useEffect(() => {
    // if (!accessToken) navigate("/login");
    if (!accessToken) navigate('/login');
    if (clientCode == 'regortalent') navigate('/access-denied');


    let initialOpen =
      currentItem === "jds" || currentItem === "create-tests" || currentItem === "available-tests";
    if (initialOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [currentItem]);


  useEffect(() => {
    // if (!accessToken) navigate("/login");
    let initialOpen =
      currentItem === "candidate-register" || currentItem === "candidate-registered";
    if (initialOpen) {
      setOpen2(true);
    } else {
      setOpen2(false);
    }
  }, [currentItem]);


  return (
    <MainBox>
      <EmployerHeader />
      <StyledContent>
        <EmployerSidebar
          open={open}
          open2={open2}
          setOpen={setOpen}
          setOpen2={setOpen2}
        />
        <MainContent>
          {currentItem === 'dashboard' &&
            <EmployeMetrics page={empScheduledPage} setPage={setEmpScheduledPage} setCurrentItem={setCurrentItem} setEmpClientCode={setEmpClientCode} setSelectedJDID={setSelectedJDID} />
          }
          

          {currentItem === "configure-dashboard" && <EmpConfigureDash />}
          {currentItem === "create-jds" && <CreateJds />}

          {currentItem === "candidate-register" && <RegisterCandidate />}
          <div className="innerBox">{currentItem === "candidate-registered" && <RegisteredCandidates />}</div>
          {currentItem === "upload-profiles" && <UploadCandidateProfile />}
          {currentItem === 'applications' && <>{empScheduledPage?.index === 1 && <EmpApplications page={empScheduledPage} setPage={setEmpScheduledPage} />}  {empScheduledPage?.index === 2 && <EmpApplicationCandidatesList page={empScheduledPage} setPage={setEmpScheduledPage} />}</>}

          {currentItem === "manage-tests" && <CreateQuestion />}
          <div className="innerBox">{currentItem === "jds" && <JdTabs />}</div>
          {currentItem === "create-tests" && <QuestionParentPage />}
          {currentItem === "available-tests" && <TestParentPage />}
          {currentItem === "interview-dashboard" && <InterviewDashboard page={empScheduledPage} setPage={setEmpScheduledPage} />}
          {currentItem === "interview-flow" && <InterviewFlow />}

          {currentItem === "profile" && <EmployeProfile />}
          {currentItem === "manage-users" && <ManageUsers />}
          {currentItem === "manage-agencies" && <ManageAgencies />}
          {currentItem === "subscriptions" && <Subscription />}
          {currentItem === "activity-logs" && <ActivityTracker />}
          {currentItem === "billing" && <Billing />}
          {currentItem === "inbox" && <Inbox />}
          {currentItem === "report" && <Report />}
          {currentItem === "custom-report" && <CustomReport />}

          {currentItem === "call-support" && <CallSupport />}
          {currentItem === "demo" && <RequestDemoPage />}

          {currentItem === "jdListing" && <JdListingTabs setCurrentItem={setCurrentItem} setEmpClientCode={setEmpClientCode} setSelectedJDID={setSelectedJDID} />}
          {currentItem === "candidateListing" && <CandidateListingTabs setCurrentItem={setCurrentItem} empClientCode={empClientCode} selectedJDID={selectedJDID} setSelectedJDID={setSelectedJDID} />}
        </MainContent>
      </StyledContent>
    </MainBox>
  );
};

export default EmployerDashboard;


const MainBox = styled.div`
display: flex;
flex-direction: column;
min-height: 100vh;
background-color: #f4f4f4;
`

const MainContent = styled.div`
  flex-grow: 1;
  margin-left: 17rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #f4f4f4;

  .innerBox {
    width: 96%;
  }
`;

const StyledContent = styled.div`
  width: 100%;
  display: flex;
  height: calc(100% - 4rem);
  margin-top: 4rem;
  background-color: var(--white);
  color: var(--color);
  background-color: #f4f4f4;
`;
