import React from 'react'
import styled from 'styled-components'
import QuestionFilter from '../components/QuestionFilter'
import Button from '../../../../component/shared/Button'
import { questionData } from '../../../../utils/dummyData/questionData'
import QuestionCard from '../components/QuestionCard'
import useFetchQuestions from '../hooks/useFetchQuestions'
import { useSelector } from 'react-redux'

const QuestionLibraryPage = ({onCreateQuestion, onEditQuestion, questionTrigger, setQuestionTrigger}) => {
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

    const { questions, loading } = useFetchQuestions(accessToken, clientCode, questionTrigger);

    return (
        <Container>
            <TopBox>
                <QuestionFilter/>
                <Button text="Create Question" func={() => onCreateQuestion()} disabled={false} />
            </TopBox>
            <ContentBox>
                {
                    questions?.map((question, i) => (
                        <QuestionCard key={question?.id} data={question} onEditQuestion={onEditQuestion} index={i} setQuestionTrigger={setQuestionTrigger} />
                    ))
                }
            </ContentBox>
        </Container>
    )
}

export default QuestionLibraryPage

const Container = styled.div`
width: 100%;
margin: 0 0;
padding: 1% 1rem;
display: flex;
flex-direction: column;
box-sizing: border-box;
gap: 2rem;


`

const TopBox = styled.div`
width: 96%;
margin: 0 auto;
display: flex;
justify-content: space-between;
align-items: center;
gap: 2%;



`


const ContentBox = styled.div`
display: flex;
flex-direction: column;
width: 96%;
margin: 0 auto;
gap: 0.5rem;




`