import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import EmpMetric1 from '../../../../assets/icons/metric2.1.png'
import EmpMetric2 from '../../../../assets/icons/metric2.2.png'
import EmpMetric3 from '../../../../assets/icons/metric2.3.png'
import EmpMetric4 from '../../../../assets/icons/metric2.4.png'
import EmpMetric5 from '../../../../assets/icons/metric2.5.png'
import EmpMetric6 from '../../../../assets/icons/metric2.6.png'
import EmpMetric7 from '../../../../assets/icons/metric2.7.png'
import EmpMetric8 from '../../../../assets/icons/metric2.8.png'
import { getUserSettingByKey } from '../../../../functions/api/configSettings/getUserSettingByKey';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateUserSettingByKey } from '../../../../functions/api/configSettings/updateUserSettingByKey';
import { CircularProgress } from '@mui/material';


const EmpConfigureDash = () => {
    const [data, setData] = useState(null);
    const [dashboardMetrics, setDashboardMetrics] = useState('');
    const [dashboardOptionalMetrics, setDashboardOptionalMetrics] = useState('');

    const [loading, setLoading] = useState(false);

    const [disabled, setDisabled] = useState(true);

    const accessToken = useSelector(state => state.auth.userData?.accessToken)
    const clientCode = useSelector(state => state.auth.userData?.clientCode)
    const userType = useSelector((state) => state.auth.userType);

    const initialData = {
        current: [],
        optional: []
    };

    useEffect(() => {
        const getDashboardConfiguration = async () => {
            try {
                setLoading(true);
                const dashboard = await getUserSettingByKey(accessToken, clientCode, userType === "EMPLOYER" ? "employerDashboardMetrics" : "agencyDashboardMetrics")
                const dashboardOptional = await getUserSettingByKey(accessToken, clientCode, userType === "EMPLOYER" ? "employerDashboardOptionalMetrics" : "agencyDashboardOptionalMetrics")

                if (dashboard) {
                    setDashboardMetrics(dashboard?.data?.itemValue)
                }
                if (dashboardOptional) {
                    setDashboardOptionalMetrics(dashboardOptional?.data?.itemValue)
                }

                if (dashboard && dashboardOptional) {
                    setLoading(false);
                }

            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
                setLoading(false);
            }
        }
        getDashboardConfiguration()
    }, []);

    const populateInitialData = (apiResponse, destination) => {
        const items = apiResponse.split(',');
        items.forEach((item, index) => {
            const id = (destination === initialData.current) ? index + 1 : index + 5;
            const newItem = {
                id: id,
                text: item.trim()
            };
            destination.push(newItem);
        });
    };

    useEffect(() => {
        populateInitialData(dashboardMetrics, initialData.current);
        populateInitialData(dashboardOptionalMetrics, initialData.optional);
        setData(initialData)
    }, [dashboardMetrics, dashboardOptionalMetrics])


    const onDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;

        // Clone the data to avoid modifying the state directly
        const updatedData = { ...data };

        // Swap the items between source and destination lists
        const sourceList = [...updatedData[source.droppableId]];
        const destinationList = [...updatedData[destination.droppableId]];

        const [movedItem] = sourceList.splice(source.index, 1);
        const [replacedItem] = destinationList.splice(destination.index, 1);

        // Replace the source index with the destination index in source list
        sourceList.splice(destination.index, 0, replacedItem);

        // Replace the destination index with the source index in destination list
        destinationList.splice(source.index, 0, movedItem);

        // Update the data with the modified lists
        updatedData[source.droppableId] = sourceList;
        updatedData[destination.droppableId] = destinationList;

        // Update the state with the modified data
        setData(updatedData);
    };

    const getMetricImage = (text) => {
        switch (text) {
            case "interviews":
                return EmpMetric1;
            case "activeJds":
                return EmpMetric2;
            case "applications":
                return EmpMetric3;
            case "candidatesPool":
                return EmpMetric4;
            case "inviteSent":
                return EmpMetric5;
            case "reviewedProfiles":
                return EmpMetric6;
            case "jdViews":
                return EmpMetric7;
            case "offers":
                return EmpMetric8;
            default:
                return "";
        }
    }

    const getMetricDisplayText = (text) => {
        switch (text) {
            case "interviews":
                return "Interviews";
            case "activeJds":
                return "Active JDs";
            case "applications":
                return "Applications";
            case "candidatesPool":
                return "Candidates Pool";
            case "inviteSent":
                return "Invite Sent (Last 30 Days)";
            case "reviewedProfiles":
                return "Reviewed Profiles (Last 30 Days)";
            case "jdViews":
                return "JD Views (Last 30 Days)";
            case "offers":
                return "Offers (Last 30 Days)";
            case "jdListings":
                return "JD Listings";
            case "scheduledInterviews":
                return "Scheduled Interviews";
            case "candidatesInPipeline":
                return "Candidates in Pipeline";
            case "reports":
                return "Reports";
            default:
                return "";
        }
    }


    const saveDashboardConfiguration = async () => {
        const currentPayload = data?.current?.map(item => item?.text)?.join(",");
        const optionalPayload = data?.optional?.map(item => item?.text)?.join(",");
        try {
            const currentMetrics = await updateUserSettingByKey(accessToken, clientCode, userType === "EMPLOYER" ? "employerDashboardMetrics" : "agencyDashboardMetrics", currentPayload)
            const optionalMetrics = await updateUserSettingByKey(accessToken, clientCode, userType === "EMPLOYER" ? "employerDashboardOptionalMetrics" : "agencyDashboardOptionalMetrics", optionalPayload)

            if (currentMetrics && optionalMetrics) {
                toast.success("Dashboard Configuration updated", 5000);
            }
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {loading ? <LoaderBox>
                <CircularProgress color="inherit" />
            </LoaderBox> :
                <Container>
                    <Droppable droppableId="optional" direction="vertical">
                        {(provided) => (
                            <LeftBox ref={provided.innerRef} {...provided.droppableProps}>
                                <span className='title'>Optional Metrics</span>
                                {
                                    data?.optional?.map((item, index) => (
                                        <Draggable key={item?.id?.toString()} draggableId={item?.id?.toString()} index={index}>
                                            {(provided) => (
                                                <div className='cardBox'
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}>
                                                    <Card>
                                                        <div className='top'>
                                                            <img src={getMetricImage(item?.text)} />
                                                            <span className='achievedNumberDigit'>{index + 1}</span>
                                                        </div>
                                                        <span className='hrLine'></span>
                                                        <span className='achievedNumberText'>{getMetricDisplayText(item?.text)}</span>
                                                    </Card>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                            </LeftBox>
                        )}
                    </Droppable>
                    <Droppable droppableId="current" direction="vertical">
                        {(provided) => (
                            <RightBox ref={provided.innerRef} {...provided.droppableProps}>
                                <span className='title'>Current Metrics</span>
                                {
                                    data?.current?.map((item, index) => (
                                        <Draggable key={item?.id?.toString()} draggableId={item?.id?.toString()} index={index}>
                                            {(provided) => (
                                                <div className='cardBox'
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}>
                                                    <Card>
                                                        <div className='top'>
                                                            <img src={getMetricImage(item?.text)} />
                                                            <span className='achievedNumberDigit'>{index + 1}</span>
                                                        </div>
                                                        <span className='hrLine'></span>
                                                        <span className='achievedNumberText'>{getMetricDisplayText(item?.text)}</span>
                                                    </Card>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                            </RightBox>
                        )}
                    </Droppable>
                    <div className='buttonBox'>
                        <button onClick={() => saveDashboardConfiguration()} disabled={disabled} style={{cursor: disabled ? "not-allowed" : "pointer"}}>Save Changes</button>
                    </div>
                </Container>}
        </DragDropContext>
    )
}

export default EmpConfigureDash


const LoaderBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 17rem);
    height: calc(100% - 4.1rem);
    background-color: #f4f4f4;
    position: absolute;
    z-index: 1000;
    right: 0;
    top: 4.1rem;
    color: var(--lightOrange);

`

const Container = styled.div`
display: flex;
width: 94%;
flex-direction: row;
align-items: start;
margin: 2rem auto
gap: 2rem;
background-color: var(--backgroundColor);
border-radius: 0.5rem;

.buttonBox {
    display: flex;
    padding: 1rem 0.75rem;
    align-items: center;
    justify-content: center;

    button {
        border: none;
        border-radius: 0.25rem;
        background-color: var(--lightOrange);
        color: var(--white);
        padding: 0.5rem 0.5rem;
        font-size: 0.9rem;
        font-weight: 600;
        font-family: var(--font);
        width: 7.5rem;
        cursor: pointer;
    }
}

.cardBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

`

const LeftBox = styled.div`
width: 70%;
margin: 1rem 2rem 1rem 15%;
display: flex;
flex-direction: column;
justify-content: space-between;
background-color: var(--white);
padding: 2rem 3%;
box-sizing: border-box;
border-radius: 0.5rem;
border: 0.075rem solid grey;
gap: 2rem;

.title {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
}

`

const RightBox = styled.div`
width: 70%;
margin: 1rem 15% 1rem 2rem;
display: flex;
flex-direction: column;
justify-content: space-between;
background-color: var(--white);
padding: 2rem 3%;
box-sizing: border-box;
border-radius: 0.5rem;
border: 0.075rem solid grey;
gap: 2rem;

.title {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
}

`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.7rem;
  background-color: var(--white);
  padding: 1rem 0 1.5rem 0;
  width: 90%;
  height: 7rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.5);

  .top {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    img {
      width: 3rem;
      height: 3rem;
    }
  }

  .achievedNumberDigit {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--color);
  }
  
  .achievedNumberText {
    font-size: 0.9rem;
      font-weight: 600;
      color: var(--color);
      text-align: center;
  }

  .hrLine {
    width: 100%;
    border-top: 0.1rem groove lightgrey;
    margin: -0.2rem 0 -0.9rem 0;
    box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.25);
  }

`