import axios from "axios"


export const updateTrackers = async (payload, accessToken, clientCode) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'X-Client-Code': clientCode,
        }
    };

    try {
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/interviews/updateTrackers`, payload, config);
        console.log('Data:', response.data);
        console.log("Status", response.status)
        return response.data;
    } catch (error) {
        throw error;
    }
}

