import axios from "axios";

export const submitAcademicAnswer = async (answer, id, lastQuestion, testId, accessToken) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    };

    const requestData = {
        answer,
        id,
        lastQuestion
    };

    try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/academic/${testId}/answers`, requestData, config);
        console.log('Data:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
}