import styled from 'styled-components';
import { Check } from 'lucide-react';
import React from 'react';

const GradientBackground = styled.div`
  background: linear-gradient(
    90deg,
    rgba(132, 255, 253, 0.2) 0%,
    rgba(162, 158, 158, 0.2) 50%,
    rgba(132, 255, 253, 0.2) 100%
  );
  padding: 1rem 0rem;
  margin: 0rem auto;
  width: 95%;
  box-sizing: border-box;
  color: var(--color);
  border-radius: 0.75rem;

  @media (max-width: 768px) {
    padding: 4rem 1rem;
    margin: 0 1rem;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const Title = styled.h2`
  font-size: 1.75rem;
  font-weight: 700;
  text-align: center;
  color: var(--color);
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
`;

const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  gap: 3rem;
  align-items: center;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 3rem;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }

  img {
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
`;

const ContentWrapper = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--color);
    margin-bottom: 0.5rem;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  p {
    font-size: 1.2rem;
    color: var(color);
    margin-bottom: 0.5rem;

    @media (max-width: 768px) {
      font-size: 1.1rem;
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      font-size: 1rem;
      line-height: 1.25rem;
      color: var(--color);

      @media (max-width: 768px) {
        font-size: 1rem;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
        color: #60a5fa;
        margin-right: 0.75rem;
      }
    }
  }
`;


const FeatureSection = ({ features }) => (
    <GradientBackground>
        <Container>
            <Title>Empowering Recruiters for Hiring Excellence</Title>
            {features?.map((feature, index) => (
                <FeatureWrapper key={index} reverse={index % 2 !== 0}>
                    <ImageWrapper>
                        <img src={feature?.image} alt={feature?.title} />
                    </ImageWrapper>
                    <ContentWrapper>
                        <h3>{feature.title}</h3>
                        <p>{feature?.description}</p>
                        <ul>
                            {feature?.features?.map((item, i) => (
                                <li key={i}>
                                    <Check />
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </ContentWrapper>
                </FeatureWrapper>
            ))}
        </Container>
    </GradientBackground>
);

export default FeatureSection;
