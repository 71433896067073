import React, { useEffect, useState, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getMatches } from "../../../../functions/api/employers/match/getResumes";
import { useNavigate, useParams } from "react-router";
import styled, { css } from "styled-components";
import ModalHOC from "../../SeekerDashboard/ModalHOC";
import ScheduleModal from "./ScheduleModal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addResumes, addSelectedJd, resetSelection, toggleSelected } from "../../../../slices/invitationSlice";
import { Tooltip } from "react-tooltip";
import {
    Pagination,
    PaginationSizeFilter,
} from "../../../commonComponents/Pagination";
import { getBlobData } from "../../../../functions/api/resume/getBlobData";
import TableSearchBar from "../commonComponents/TableSearchBar";
import iIcon from '../../../../assets/icons/iIcon.png'
import { getEmployer } from "../../../../functions/api/employers/profile/getEmployer";
import { getAllTrackers } from '../../../../functions/api/employers/tracker/getAllTrackers'
import downloadIcon from '../../../../assets/icons/Profile/download.png'

function Row(props) {
    const { row, index, rowsLength, handleSelectChange, isCandidateSelected } = props;
    const selected = useSelector((state) => state?.invite?.selectedRows);
    const [agencyData, setAgencyData] = useState({});
    const accessToken = useSelector(state => state?.auth?.userData?.accessToken);
    const clientCode = useSelector(state => state?.auth?.userData?.user?.clientCode);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();


    const handleDownload = async (id, name) => {
        try {
            if (!id) {
                toast.warning("No resume found or invalid resumeId", 5000);
                return;
            }
            const res = await getBlobData(
                `api/media/downloadById?fileType=resume&id=${id}`,
                accessToken,
                clientCode
            );

            const a = document.createElement("a");
            a.href = res;
            a.setAttribute("download", `${name}`);
            a.click();
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    };

    const openDropdown = (index) => {
        setOpenDropdownIndex(index);
    };

    const closeAllDropdowns = () => {
        setOpenDropdownIndex(-1);
    };

    const handleGetAgencyInfo = async (agencyCode) => {
        try {
            const res = await getEmployer(accessToken, agencyCode);
            if (res) setAgencyData(res?.data);
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    }

    const getSourceAndAppliedBy = (source, type) => {
        if (type == "source") {
            if (source == "EMPLOYER_POOL") {
                return "Self"
            }
            else if (source == "AGENCY") {
                return "Agency";
            }
            else {
                return "Candidate"
            }
        }
        else {
            if (source == "EMPLOYER_POOL") {
                return row?.clientCode ?? clientCode;
            }
            else if (source == "AGENCY") {
                return row?.agencyCode;
            }
            else {
                return row?.agencyCode?.toUpperCase();
            }
        }
    }

    return (
        <React.Fragment>
            <TableRow
                sx={{ "& > *": { borderBottom: "unset" } }}
                className={`${index % 2 == 1 ? "colored" : ""}`}
            >
                <TableCell component="th" scope="row" align="center" className="tableCell">
                    {row?.name}
                </TableCell>
                <TableCell align="center" className="tableCell">{row?.email}</TableCell>
                <TableCell align="center" className="tableCell">{row?.contact}</TableCell>
                <TableCell align="center" className="tableCell">{getSourceAndAppliedBy(row?.appliedBy, "source")}</TableCell>
                <TableCell align="center" className="tableCell">{getSourceAndAppliedBy(row?.appliedBy, "appliedBy") == "agency" ? <BoxRow isLast={index >= rowsLength - 2}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.2rem" }}><p>{row?.agencyCode}</p>
                        <img
                            src={iIcon}
                            style={{ width: "0.8rem", height: "0.8rem", cursor: "pointer", display: "flex" }}
                            className={`i-icon ${openDropdownIndex === index ? "active" : ""
                                }`}
                            onClick={() => {
                                if (openDropdownIndex === index) {
                                    closeAllDropdowns();
                                }
                                else {
                                    openDropdown(index);
                                    handleGetAgencyInfo(row?.agencyCode);
                                }
                            }}
                        />
                    </div>
                    <div
                        className={`dropdown-content ${openDropdownIndex === index ? "open" : ""
                            }`}
                        ref={dropdownRef}
                    >
                        <p><b>Agency Name:</b> <br />{agencyData?.companyName}</p>
                        <p><b>Co-ordinator Name:</b> <br />{agencyData?.coOrdinatorName}</p>
                    </div></BoxRow> : getSourceAndAppliedBy(row?.appliedBy, "appliedBy")}</TableCell>
                <TableCell align="center" className="tableCell">{row?.aiScore}</TableCell>
                <TableCell
                    align="center"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "0.5rem",
                    }}
                    className="tableCell"
                >
                    <input
                        type="checkbox"
                        checked={selected?.find(item => item?.id === row?.id)?.selected}
                        onChange={() => handleSelectChange(row)}
                        className="checkBox"
                        disabled={isCandidateSelected && !selected?.find(item => item?.id === row?.id)?.selected}
                    />
                    <img src={downloadIcon} onClick={() =>
                        handleDownload(row?.resumeId, row?.srcFilename)
                    } />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function ShortlistedResumeForInPerson({handleClose}) {
    const [filterParams, setFilterParams] = useState('SHORTLISTED');
    const { jdId } = useParams();
    const [tableRows, setTableRows] = useState([]);
    const [idToSendInvite, setIdToSendInvite] = useState(null);
    const selected = useSelector((state) => state?.invite?.selectedRows);
    const [selectedArray, setSelectedArray] = useState([]);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const accessToken = useSelector((state) => state.auth.userData.accessToken);
    const clientCode = useSelector(
        (state) => state.auth.userData.user.clientCode
    );
    const dispatch = useDispatch();

    const [searchValue, setSearchValue] = useState('');
    const [search, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [trackerData, setTrackerData] = useState([]);
    const [matchedData, setMatchedData] = useState([]);
    const [isCandidateSelected, setIsCandidateSelected] = useState(false);


    const handleSizeChange = (event) => {
        setSize(parseInt(event.target.value, 10));
        setPage(1);
    };

    const handlePageChange = (change) => {
        if (change && (page < Math.ceil(+total / +size))) {
            setPage((prev) => prev + 1);
        } else if (!change && page > 1) {
            setPage((prev) => prev - 1);
        }
    };

    // running an effect to empty selectedArray whenever filter value changes
    useEffect(() => {
        setSelectedArray([]);
        dispatch(resetSelection());
    }, [filterParams]);


    useEffect(() => {
        if (!accessToken || !clientCode) {
            toast.warning("Login First");
            navigate("/login");
        }

        // func to get tracker data by trackerStatus
        async function getTrackersData() {
            try {
                const trackerRes = await getAllTrackers(jdId, accessToken, search ? 1 : page, search ? 1000000 : size, filterParams, clientCode);

                if (trackerRes) {
                    setTotal(trackerRes?.data?.total);
                    setTrackerData(trackerRes?.data?.data);
                }
            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }

        // func to get all matched resume
        async function getData() {
            try {
                const resObj = await getMatches(jdId, accessToken, clientCode, search ? 1 : page, search ? 1000000 : size, false);
                if (resObj) {

                    setMatchedData(resObj?.data[0]?.records?.data);

                    if (filterParams === 'MATCHED') {
                        setTotal(resObj?.data[0]?.records?.total);

                        setTableRows(resObj?.data[0]?.records?.data);
                    }
                    setIdToSendInvite(resObj?.data[0]?.jdId);
                }
            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }

        getData();

        // tracker data api to be called when filter params are valid, like here:- SHORTLISTED & NOT_SHORTLISTED
        if (filterParams === 'SHORTLISTED' || filterParams === 'NOT_SHORTLISTED') {
            getTrackersData();
        }

    }, [page, size, filterParams, search, updateTrigger]);


    useEffect(() => {

        // func to filter matched resumes data on the basis of trackers status.
        const filterData = (array) => {
            array = array.reduce((acc, it) => {
                let match = it?.matchedRecord;
                let obj = {
                    id: it?.id,
                    name: match?.name,
                    email: match?.email ?? it?.userEmail,
                    contact: match?.contact,
                    appliedBy: match?.appliedBy,
                    agencyCode: it?.agencyCode,
                    score: match?.score,
                    aiScore: match?.aiScore,
                    resumeId: match?.resumeId,
                    srcFilename: match?.srcFilename
                }

                return [...acc, obj]
            }, []);

            return array
        }

        if (filterParams === 'SHORTLISTED' || filterParams === 'NOT_SHORTLISTED') {
            const filteredData = filterData(trackerData);
            setTableRows(filteredData);
        }

    }, [page, size, filterParams, matchedData, trackerData, search, updateTrigger]);


    useEffect(() => {
        if (searchValue?.trim()) {
            setSearch(true);

            setFilteredData(() =>
                tableRows?.filter(
                    (item) =>
                        item?.name?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
                        item?.email?.toLowerCase()?.includes(searchValue.toLowerCase())
                ))
        } else {
            setSearch(false);
        }
    }, [searchValue, updateTrigger])


    const handleSelectArray = (id, action) => {
        if (action) {
            setSelectedArray((prev) => [...prev, id]);
        } else {
            setSelectedArray((prev) => prev.filter((item) => item?.id !== id));
        }
    };

    const handleSelectChange = (row) => {
        const isSelected = selected?.find(item => item?.id === row?.id);
        const isChecked = isSelected ? isSelected?.selected : false;

        dispatch(toggleSelected({ id: row?.id, value: !isChecked }));

        if (isChecked) {
            handleSelectArray(row?.id, false);
            setIsCandidateSelected(false);
        } else {
            handleSelectArray(row, true);
            setIsCandidateSelected(true);
        }
    }


    const handleSchedule = () => {
        if (selectedArray?.length > 0) {
            const uniqueSelectedData = selectedArray?.filter((item, index, self) =>
                index === self?.findIndex((t) => (
                    t?.id === item?.id
                ))
            );
            dispatch(addResumes([...uniqueSelectedData]));
            dispatch(addSelectedJd(jdId));
            handleClose();
        } else {
            toast.warning("Select Resume First");
        }
    };



    return (
        <StyledDiv>
            <Content>
                <TableContainer component={Paper} className="tableBox">
                    <ModalHOC
                        openNewInterviewModal={open}
                        setOpenNewInterviewModal={setOpen}
                        Component={ScheduleModal}
                        array={[...selectedArray, idToSendInvite]}
                    />

                    <span className='mainTitle'>
                        {/* <span className="title">{filterParams === 'MATCHED' ? 'Matched' : filterParams === 'SHORTLISTED' ? 'Shortlisted' : 'Rejected'} Resumes for JD ID: {jdId} </span> */}
                        <BoxRow >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.2rem" }}><p>Select 1 Candidate from below shorlisted Candidates List</p></div>
                        </BoxRow>
                    </span>
                    <SearchBarContainer>
                        <TableSearchBar value={searchValue} setValue={setSearchValue} />
                    </SearchBarContainer>
                    <Table aria-label="collapsible table">
                        <TableHead className="tableHead">
                            <TableRow>
                                <TableCell align="center" className="tableCell">Name</TableCell>
                                {/* <TableCell align="center" className="tableCell">Match Percentage</TableCell> */}
                                <TableCell align="center" className="tableCell">Email</TableCell>
                                <TableCell align="center" className="tableCell">Contact</TableCell>
                                <TableCell align="center" className="tableCell">Source</TableCell>
                                <TableCell align="center" className="tableCell">Agency Code</TableCell>

                                <TableCell align="center" className="tableCell">
                                    <span
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: 'center',
                                            gap: "0.4rem",
                                        }}
                                    >
                                        Resume Score{" "}
                                        <img
                                            className="iIcon"
                                            src={iIcon}
                                            data-tooltip-id="AI-Score"
                                            data-tooltip-html="<div>JD resume match AI score on a scale of 10</div>"
                                        />
                                    </span>
                                    <Tooltip id="AI-Score" />
                                </TableCell>
                                <TableCell align="center" className="tableCell">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="tableBody">
                            {search ?
                                filteredData?.map((row, index) => (
                                    <Row
                                        key={row?.resumeId}
                                        row={row}
                                        handleSelectChange={handleSelectChange}
                                        index={index}
                                        rowsLength={filteredData?.length}
                                        isCandidateSelected={isCandidateSelected}
                                    />
                                )) :
                                tableRows?.map((row, index) => (
                                    <Row
                                        key={row?.resumeId}
                                        row={row}
                                        handleSelectChange={handleSelectChange}
                                        index={index}
                                        rowsLength={tableRows?.length}
                                        isCandidateSelected={isCandidateSelected}
                                    />
                                ))
                            }
                        </TableBody>
                    </Table>

                    {!search && <div className="paginationBox">
                        <PaginationSizeFilter
                            size={size}
                            handleSizeChange={handleSizeChange}
                        />
                        <Pagination
                            total={total}
                            size={size}
                            page={page}
                            handlePageChange={handlePageChange}
                            setPage={setPage}
                        />
                    </div>}

                </TableContainer>
                <div className="btnBox">
                     {selectedArray?.length > 0 && <button onClick={() => handleSchedule()} className="btn">Done</button>}
                </div>
            </Content>
        </StyledDiv>
    );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Content = styled.div`
  width: 98%;
  padding: 0 1%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .colored {
    background-color: #ececec;
  }

  .paginationBox {
    display: flex;
    justify-content: end;
    gap: 2rem;
    margin: 1rem 3rem 1.5rem 0;
  }

  .mainTitle {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 1rem 0 1rem 3rem;
    width: calc(98% - 3rem);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 0.9rem;
      font-weight: 600;
    }
}
  

  .prev {
    background-color: var(--lightOrange);
    padding: 0.1rem;
    position: absolute;
    top: 4.8rem;
    left: 1.5rem;
    color: var(--white);
  }

  .prev:hover {
    color: var(--color);
  }

  .tableBox {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
  }

  .MuiTableCell-root {
    border: none;
  }

  .MuiTableRow-root {
    border-bottom: none;
  }

  .selected {
    background-color: #d9fbf9;
    color: white;
  }


  .tableHead {
    background-color: #d1fff0;
    width: 100%;
  
    .tableCell {
      font-size: 0.9rem;
      font-weight: 500;
      font-family: var(--font);
      color: var(--color);
    }
    
    .iIcon {
      width: 1.1rem;
    }
  }
  
  .tableBody {
    width: 100%;
  
    .tableCell {
      font-size: 0.8rem;
      font-weight: 400;
      font-family: var(--font);
      color: var(--color);
    }
  }

  .btnBox {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }


  .btn {
    padding: 0.5rem 1rem;
    margin-top: 3rem;
    background-color: var(--lightOrange);
    border: none;
    color: var(--white);
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: pointer;
    font-family: var(--font);
  }

  .checkBox {
    cursor: pointer;
    width: 1rem;
    height: 1rem;
  }

  img {
    width: 1.2rem;
    cursor: pointer;
  }
`;


const Button = styled.button`
padding: 0.5rem 0.8rem;
  background-color: var(--lightOrange);
  color: #fff;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  align-self: center;
  font-size: 0.9rem;
  font-weight: 600;
  font-family: var(--font);
`

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  margin: 0.5rem auto;
  height: 3rem;
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 0rem 1rem;
  gap: 1rem;

`;

const BoxRow = styled.div`
  position: relative;
  display: inline-block;

  .i-icon {
    cursor: pointer;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--white);
    box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 10%;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    min-width: 10rem;
    justify-content: start;
    padding: 0.5rem 0.5rem;
    botton: 0.3rem;
    ${(props) =>
        props.isLast &&
        css`
        bottom: 1.4rem;
        right: 10%;
      `}
  }

  .dropdown-content span {
    padding: 0.3rem 0.8rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--color);
    cursor: pointer;
  }

  .dropdown:hover .dropdown-content,
  .dropdown-content.open {
    display: block;
  }

  .threeDotIcon {
    width: 0.6rem;
    height: 0.6rem;
    cursor: pointer;
    border: 0.08rem solid grey;
    padding: 0.15rem;
    border-radius: 0.2rem;
  }
`;


const Label = styled.label`
font-size: 0.85rem;
font-weight: 600;
display: flex;
gap: 0.25rem;
align-items: center;

`