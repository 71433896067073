import axios from "axios"

export const getTokenUsageByDateRange = async (accessToken, clientCode, startDate, endDate) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'x-client-code': clientCode
        }
    };

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/openaiTokenUsage/getUsageByClientCode?clientCode=${clientCode}&startDate=${startDate}&endDate=${endDate}`, config);
        console.log('Data:', response?.data);
        console.log("Status", response?.status)
        return response.data;
    } catch (error) {
        throw error;
    }
}

