import React from 'react'
import styled from 'styled-components'
import searchBlack from '../../../assets/icons/searchBlack.png'

const SearchBox = () => {
    return (
        <MainBox>
            <img src={searchBlack} />
            <input
                className='skillInput'
                type="text"
                placeholder="Search for Request types"
            />
        </MainBox>
    )
}

export default SearchBox


const MainBox = styled.div`
position: relative;
width: 100%;
display: flex;
align-items: center;
background-color: var(--white);
padding: 0.75rem 1rem;
border-radius: 0.5rem;
font-family: var(--font);


img {
  width: 1.2rem;
}

.skillInput {
flex-grow: 1;
border: none;
height: 1rem;
width: 50%;
padding: 0.5rem;
font-size: 1rem;
font-weight: 600;
background-color: transparent;
outline: none;
font-family: var(--font);
}


`