import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    formData: {
        testType: '',
        tags: '',
        topics: '',
        questionIds: [],
        role: '',
        name: '',
    },
    allQuestions: [],
};

const testFormSlice = createSlice({
    name: 'testForm',
    initialState,
    reducers: {
        setFormData: (state, action) => {
            state.formData = { ...state.formData, ...action.payload };
        },
        setAllQuestions: (state, action) => {
            state.allQuestions = action.payload;
        },
        setQuestionIds: (state, action) => {
            state.formData.questionIds = action.payload;
        },
        clearFormData: (state) => {
            state.formData = initialState.formData;
            state.allQuestions = initialState.allQuestions;
        },
    },
});

export const {
    setFormData,
    setAllQuestions,
    setQuestionIds,
    clearFormData,
} = testFormSlice.actions;

export default testFormSlice.reducer;
