import React from 'react'
import styled from 'styled-components'
import QuestionCard from '../../questions/components/QuestionCard'
import TestHeader from '../components/TestHeader'
import TestBasicDetails from '../components/TestBasicDetails'


const TestDetailsPage = ({data}) => {

    return (
        <Container>
            <TopBox>
                <TestHeader data={data} />
                <TestBasicDetails data={data} />
            </TopBox>
            
            <ContentBox>
                {
                    Object.entries(
                        data?.testQuestions?.reduce((acc, question) => {
                            const { questionType } = question;

                            if (!acc[questionType]) {
                                acc[questionType] = [];
                            }

                            acc[questionType].push(question);

                            return acc;
                        }, {})
                    )?.map(([questionType, questions]) => (
                        <div key={questionType} className='cardBox'>
                            <span className='cardTitle'>{questionType} ({questions?.length})</span>
                            {questions?.map((question) => (
                                <QuestionCard key={question?.id} data={question} viewOnly={true} />
                            ))}
                        </div>
                    ))
                }
            </ContentBox>

        </Container>
    )
}

export default TestDetailsPage

const Container = styled.div`
width: 100%;
margin: 0 0;
padding: 1% 1rem;
display: flex;
flex-direction: column;
box-sizing: border-box;
gap: 2.5rem;


`

const TopBox = styled.div`
width: 96%
margin: 0 auto;
display: flex;
flex-direction: column;
justify-content: space-between;
gap: 2rem;

`

const ContentBox = styled.div`
display: flex;
flex-direction: column;
width: 96%;
margin: 0 auto;
gap: 0.5rem;

.cardBox { 
display: flex;
flex-direction: column;
width: 100%;
gap: 1rem;
}

.cardTitle {
font-size: 1.25rem;
font-weight: 600;
padding-top: 1rem;
}

`

