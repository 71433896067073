import axios from "axios"


export const editCandidate = async (formData, accessToken, clientCode) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'X-Client-Code': clientCode,
        }
    };
    const requestData = { ...formData };

    try {
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/candidates/edit-candidate`, requestData, config);
        console.log('Data:', response.data);
        console.log("Status", response.status)
        return response.data;
    } catch (error) {
        throw error;
    }
}

