export const getRandomDarkColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export const generateOpenPositionsBySkillsChartData = (apiResponse) => {
    const openPosData = [
        ["openPositions", "ratio"] // Static value for the first element
    ];
    const openPosOptions = {
        pieHole: 0.3,
        is3D: false,
        backgroundColor: "transparent",
        legend: {
            position: "none",
        },
        colors: [],
        chartArea: {
            left: 0,
            right: 0,
            top: 2,
            bottom: 2,
            width: "100%",
            height: "100%",
        },
        pieSliceTextStyle: {
            color: "white",
        },
        pieSliceBorderColor: "transparent",
        sliceVisibilityThreshold: 0,
        shadow: "none",
    };

    if (apiResponse) {
        const keys = Object.keys(apiResponse);
        keys.forEach((key, index) => {
            openPosData.push([key, apiResponse[key]]);
            openPosOptions.colors.push(getRandomDarkColor());
        });
    }

    return { openPosData, openPosOptions };
};


export const generateCandidatesPipelineChartData = (apiResponse) => {
    const candidatesPipelineData = [
        ["Stage", "ratio"] // Static value for the first element
    ];
    const candidatesPipelineOptions = {
        pieHole: 0.3,
        is3D: false,
        backgroundColor: "transparent",
        legend: {
            position: "none",
        },
        colors: [],
        chartArea: {
            left: 0,
            right: 0,
            top: 2,
            bottom: 2,
            width: "100%",
            height: "100%",
        },
        pieSliceTextStyle: {
            color: "white",
        },
        pieSliceBorderColor: "transparent",
        sliceVisibilityThreshold: 0,
        shadow: "none",
    };

    if (apiResponse) {
        const keys = Object.keys(apiResponse);
        keys.forEach((key, index) => {
            candidatesPipelineData.push([key, apiResponse[key]]);
            candidatesPipelineOptions.colors.push(getRandomDarkColor());
        });
    }

    return { candidatesPipelineData, candidatesPipelineOptions };
};


export const generateCandidateProgressionChartData = (apiResponse) => {
    const candidateProgressionData = [
        ["Stage", "ratio"] // Static value for the first element
    ];
    const candidateProgressionOptions = {
        pieHole: 0.3,
        is3D: false,
        backgroundColor: "transparent",
        legend: {
            position: "none",
        },
        colors: [],
        chartArea: {
            left: 0,
            right: 0,
            top: 2,
            bottom: 2,
            width: "100%",
            height: "100%",
        },
        pieSliceTextStyle: {
            color: "white",
        },
        pieSliceBorderColor: "transparent",
        sliceVisibilityThreshold: 0,
        shadow: "none",
    };

    if (apiResponse) {
        const keys = Object.keys(apiResponse);
        keys.forEach((key, index) => {
            if (key === "SHORTLISTED" || key === "IN_PROCESS") {
                candidateProgressionData.push([key, apiResponse[key]]);
                candidateProgressionOptions.colors.push(getRandomDarkColor());
            }
        });
    }

    return { candidateProgressionData, candidateProgressionOptions };
};


export const generateCandidateAcceptanceChartData = (apiResponse) => {
    const candidateAcceptanceData = [
        ["Status", "Rate"] // Static value for the first element
    ];
    const candidateAcceptanceOptions = {
        pieHole: 0.3,
        is3D: false,
        backgroundColor: "transparent",
        legend: {
            position: "none",
        },
        colors: [],
        chartArea: {
            left: 0,
            right: 0,
            top: 2,
            bottom: 2,
            width: "100%",
            height: "100%",
        },
        pieSliceTextStyle: {
            color: "white",
        },
        pieSliceBorderColor: "transparent",
        sliceVisibilityThreshold: 0,
        shadow: "none",
    };

    if (apiResponse) {
        const keys = Object.keys(apiResponse);
        keys.forEach((key, index) => {
            if (key === "SHORTLISTED" || key === "NOT_SELECTED") {
                candidateAcceptanceData.push([key, apiResponse[key]]);
                candidateAcceptanceOptions.colors.push(getRandomDarkColor());
            }
        });
    }

    return { candidateAcceptanceData, candidateAcceptanceOptions };
};

// export const generateCandidateAcceptanceByRoundChartData = (apiResponse) => {
//     const candidateAcceptanceByRoundData = [
//         ["Interview Round", "Selected", { role: "style" }, "Rejected", { role: "style" }]
//     ];
//     const candidateAcceptanceByRoundOptions = {
//         legend: {
//             position: "none",
//         },
//         chartArea: {
//             left: '1rem',
//             right: '1rem',
//             top: '3rem',
//             bottom: '3rem',
//             width: "80%",
//             height: "80%",
//         },
//         hAxis: {
//             title: "Interview Rounds",
//             minValue: 0,
//         },
//         vAxis: {
//             title: "Selected/Rejected Counts",
//         },
//     };

//     if (apiResponse) {
//         const keys = Object.keys(apiResponse);
//         keys.forEach((status) => {
//             const rounds = Object.keys(apiResponse[status]);
//             rounds.forEach((round, i) => {
//                 const count = Object.values(apiResponse[status])[i] || 0;
//                 const existingRoundIndex = candidateAcceptanceByRoundData.findIndex(data => data[0] === round);
                
//                 if (existingRoundIndex !== -1) {
//                     if (status === 'Selected') {
//                         candidateAcceptanceByRoundData[existingRoundIndex][1] = count;
//                     } else if (status === 'Rejected') {
//                         candidateAcceptanceByRoundData[existingRoundIndex][3] = count;
//                     }
//                 } else {
//                     if (status === 'Selected') {
//                         candidateAcceptanceByRoundData.push([round, count, "#00CC00", 0, "#ff4d4d"]);
//                     } else if (status === 'Rejected') {
//                         candidateAcceptanceByRoundData.push([round, 0, "#00CC00", count, "#ff4d4d"]);
//                     }
//                 }
//             });
//         });
//     }

//     return { candidateAcceptanceByRoundData, candidateAcceptanceByRoundOptions };
// };


export const generateCandidateAcceptanceByRoundChartData = (apiResponse) => {
    const candidateAcceptanceByRoundData = [
        ["Interview Round", "Selected", { role: "style" }, "Rejected", { role: "style" }]
    ];
    const candidateAcceptanceByRoundOptions = {
        legend: {
            position: "none",
        },
        chartArea: {
            left: '1rem',
            right: '1rem',
            top: '3rem',
            bottom: '3rem',
            width: "80%",
            height: "80%",
        },
        hAxis: {
            title: "Interview Rounds",
            minValue: 0,
        },
        vAxis: {
            title: "Selected/Rejected Counts",
        },
    };

    if (apiResponse) {
        const keys = Object.keys(apiResponse);
        keys.forEach((status) => {
            const rounds = Object.keys(apiResponse[status]);
            rounds.forEach((round) => {
                const count = apiResponse[status][round] || 0;
                const existingRoundIndex = candidateAcceptanceByRoundData.findIndex(data => data[0] === round);
                
                if (existingRoundIndex !== -1) {
                    if (status === 'Selected') {
                        candidateAcceptanceByRoundData[existingRoundIndex][1] = count;
                    } else if (status === 'Rejected') {
                        candidateAcceptanceByRoundData[existingRoundIndex][3] = count;
                    }
                } else {
                    if (status === 'Selected') {
                        candidateAcceptanceByRoundData.push([round, count, "#00CC00", 0, "#ff4d4d"]);
                    } else if (status === 'Rejected') {
                        candidateAcceptanceByRoundData.push([round, 0, "#00CC00", count, "#ff4d4d"]);
                    }
                }
            });
        });
    }

    // Ensure there is at least one data row with default values if no data is provided
    if (candidateAcceptanceByRoundData?.length === 1) {
        candidateAcceptanceByRoundData.push(["No Data", 0, "#00CC00", 0, "#ff4d4d"]);
    }

    return { candidateAcceptanceByRoundData, candidateAcceptanceByRoundOptions };
};




export const generateApplicationSourceChartData = (apiResponse) => {
    const applicationSourceData = [
        ["Source", "Applications", { role: "style" }] // Static value for the first element
    ];
    const applicationSourceOptions = {
        legend: {
            position: "none", // Set the legend position to 'none' to remove the legend
        },
        chartArea: {
            left: '1rem',
            right: '1rem',
            top: '3rem',
            bottom: '3rem',
            width: "80%",
            height: "80%",
        },
    };

    if (apiResponse && Object.keys(apiResponse).length > 0) {
        const keys = Object.keys(apiResponse);
        keys.forEach((key, index) => {
            const count = apiResponse[key] || 0;
            applicationSourceData.push([key, count, getRandomDarkColor()]);
        });
    } else {
        applicationSourceData.push(["No Data", 0, "#cccccc"]);
    }

    return { applicationSourceData, applicationSourceOptions };
};