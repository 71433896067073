import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PaperQuestionCard from './PaperQuestionCard';
import { toast } from 'react-toastify';
import { getQuestions } from '../../../functions/api/academicAPIs/getQuestions';
import { useSelector } from 'react-redux';

const PaperDetailsPage = ({testId}) => {
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const [data, setData] = useState([]);

    useEffect(() => {
        async function getData() {
            try {
                const response = await getQuestions(testId, accessToken);
                if (response) {
                    setData(response?.data);
                }
            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }
        getData();
    }, [testId]);


    return (
        <Container>
            <ContentBox>
                {
                    Object.entries(
                        data?.reduce((acc, question) => {
                            const { section } = question;
                    
                            // Check if the section is 'english' or 'mathematics'
                            const targetSection = (section === "english" || section === "mathematics") 
                                ? section 
                                : "General Ability Test";
                    
                            // Initialize the array for the target section if it doesn't exist
                            if (!acc[targetSection]) {
                                acc[targetSection] = [];
                            }
                    
                            // Push the question to the appropriate section
                            acc[targetSection].push(question);
                    
                            return acc;
                        }, {})
                    )?.map(([section, questions]) => (
                        <div key={section} className='cardBox'>
                            <span className='cardTitle'>{section} ({questions?.length})</span>
                            {questions?.map((question) => (
                                <PaperQuestionCard key={question?.id} data={question} viewOnly={true} />
                            ))}
                        </div>
                    ))
                }
            </ContentBox>

        </Container>
    )
}

export default PaperDetailsPage

const Container = styled.div`
width: 100%;
margin: 0 0;
padding: 1% 1rem;
display: flex;
flex-direction: column;
box-sizing: border-box;
gap: 2.5rem;


`


const ContentBox = styled.div`
display: flex;
flex-direction: column;
width: 96%;
margin: 0 auto;
gap: 0.5rem;

.cardBox { 
display: flex;
flex-direction: column;
width: 100%;
gap: 1rem;
}

.cardTitle {
font-size: 1.25rem;
font-weight: 600;
padding-top: 1rem;
}

`

