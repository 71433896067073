import React from 'react';
import styled from 'styled-components';
import TextInput from '../../../../component/shared/TextInput';
import SelectInput from '../../../../component/shared/SelectInput';
import TagsInput from '../../../../component/shared/TagsInput';
import TextArea from '../../../../component/shared/TextArea';
import Button from '../../../../component/shared/Button';
import { Stack } from '@mui/system';
import { allTags } from '../../../../utils/contantData';
import { useSelector } from 'react-redux'
import { useQuestionForm } from '../hooks/useQuestionForm';
import useQuestionActions from '../hooks/useQuestionActions';


const QuestionFormPage = ({ editingIndex, questionType, setEditingIndex, editingQuestionData, toggleView, editingQuestionId, setQuestionTrigger }) => {
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

    const {
        formData,
        handleChange,
        handleChoiceChange,
        handleTagsChange,
        handleTopicsChange,
        handleTagInputBlur,
        handleTopicInputBlur,
        selectedTags,
        selectedTopics,
        tagInputValue,
        setTagInputValue,
        topicInputValue,
        setTopicInputValue,
        resetFormData,
    } = useQuestionForm(editingIndex, editingQuestionData, questionType);


    const {
        handleCreateQuestion,
        handleUpdateQuestion,
    } = useQuestionActions(accessToken, clientCode, resetFormData, toggleView, editingQuestionId, setQuestionTrigger);


    const handleCreate = () => {
        handleCreateQuestion(formData);
    }

    const handleUpdate = () => {
        handleUpdateQuestion(formData);
    }

    return (
        <Container>
            <div className='titleBox'>
                <span className='title'>{editingIndex === -1 ? 'Create' : 'Update'} Question Form</span>
                <Button text="Back to Question Library" func={() => toggleView('library')} disabled={false} />
            </div>
            <Form>
                <div className='textAreaBox'>
                    <label>Question Description</label>
                    <TextArea
                        name="questionText"
                        value={formData?.questionText}
                        onChange={handleChange}
                        required
                        rows={5}
                    />
                </div>

                <div className="inputBox">
                    <SelectInput
                        label="Difficulty Level"
                        name="difficultyLevel"
                        value={formData?.difficultyLevel}
                        onChange={handleChange}
                        options={[
                            { value: "easy", label: "Easy" },
                            { value: "moderate", label: "Moderate" },
                            { value: "difficult", label: "Difficult" },
                        ]}
                        required
                    />
                    <TextInput
                        label="Score"
                        type="number"
                        value={formData?.score}
                        onChange={handleChange}
                        name="score"
                        required
                    />
                </div>

                <div className="inputBox">
                    <SelectInput
                        label="Section"
                        name="category"
                        value={formData?.category}
                        onChange={handleChange}
                        options={[
                            { value: "technical", label: "Technical" },
                            { value: "non-technical", label: "Non-technical" },
                            { value: "aptitude", label: "Aptitude" },
                            { value: "cultural", label: "Cultural" },
                        ]}
                        required
                    />

                    <Stack spacing={3} sx={{ width: "100%" }}>
                        <TagsInput
                            options={allTags}
                            value={selectedTags}
                            onChange={handleTagsChange}
                            onBlur={handleTagInputBlur}
                            inputValue={tagInputValue}
                            setInputValue={setTagInputValue}
                            label="Tags*"
                        />
                    </Stack>
                </div>

                <div style={{ width: 'calc(50% - 1rem)' }}>
                    <Stack spacing={3} sx={{ width: "100%" }}>
                        <TagsInput
                            options={allTags}
                            value={selectedTopics}
                            onChange={handleTopicsChange}
                            onBlur={handleTopicInputBlur}
                            inputValue={topicInputValue}
                            setInputValue={setTopicInputValue}
                            label="Topics*"
                        />
                    </Stack>
                </div>

                {questionType === 'mcq' && (
                    <>
                        <Label>Options</Label>
                        <div className="inputBoxMap">
                            {formData?.mcqOptions?.map((choice, index) => (
                                <div key={index} className='box'>
                                    <TextInput
                                        label={String.fromCharCode(index + 97)}
                                        value={choice}
                                        onChange={(e) => handleChoiceChange(e, index)}
                                        required
                                    />
                                </div>
                            ))}
                        </div>
                        <div style={{ width: 'calc(50% - 1rem)' }}>
                            <TextInput
                                label="Correct Answer"
                                name="mcqAnswer"
                                value={formData?.mcqAnswer}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </>
                )}
            </Form>
            <Button text={editingIndex === -1 ?  `Create Question` : `Update Question`} func={() => editingIndex === -1 ? handleCreate() : handleUpdate()} disabled={false} />
        </Container>
    );
};

export default QuestionFormPage;

const Container = styled.div`
    width: 96%;
    display: flex;
    flex-direction: column;
    margin: 0 auto 1rem auto;
    padding: 1rem;
    box-sizing: border-box;
    gap: 1rem;
    background-color: var(--white);
    border-radius: 0.5rem;
    align-items: center;
    

    .titleBox {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
    }

    .title {
        font-size: 0.9rem;
        font-weight: 700;
        display: block;
    }

    .textAreaBox {
        width: 100%;
        position: relative;
        margin-top: 0.5rem;
        margin-bottom: 1rem;

        label {
            font-size: 0.7rem;
            font-weight: 600;
            position: absolute;
            top: -0.5rem;
            left: 0.5rem;
            padding: 0 0.5rem;
            background-color: var(--white);
            z-index: 10000;
        }
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    gap: 0.7rem;
    width: 100%;

    .inputBox {
        width: 100%;
        display: flex;
        gap: 2rem;
        margin-bottom: 1rem;
        align-items: center;
    }

    .inputBoxMap {
        width: 100%;
        display: flex;
        gap: 2rem;
        margin-bottom: 1rem;
        flex-flow: row wrap;
    }

    .box {
        width: calc(50% - 1rem);
    }
`;

const Label = styled.label`
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    margin-top: 0.75rem;
    font-weight: 600;
`;
