import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Paper } from "@mui/material";
import moment from "moment-timezone";
import LogoHeader from "../../../commonComponents/LogoHeader";
import { TextField } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import ReactQuill from "react-quill";
import uploadIcon from "../../../../assets/icons/uploadBrowseIcon.png";
import TimeSlot from "../commonComponents/TimeSlot";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { technicalSkills } from "../../../../utils/contantData";
import { WithContext as ReactTags } from "react-tag-input";
import BulkEmailInviteSteps from "./BulkEmailInviteSteps";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { sendInvitesByEmail } from "../../../../functions/api/employers/match/sendInvitesByEmail";
import DOMPurify from "dompurify";
import { useDispatch } from "react-redux";
import { addSelectedEmails } from "../../../../slices/invitationSlice";
import AttachTestBox from "./AttachTestBox";
import NumberCounter from "../commonComponents/NumberCounter";
import Loader from "../../../commonComponents/Loader";
import { checkTrackerExists } from "../../../../functions/api/employers/tracker/checkTrackerExists";
import InstructionForInProcessCandidateCheck from "../commonComponents/InstructionForInProcessCandidateCheck";
import ConfigurableModal from "../../SeekerDashboard/seekerCommonComponents/ConfigurableModal";
import { setCurrentTrackerTab } from "../../../../slices/configSlice";
import InterviewDurationConfig from "../commonComponents/InterviewDurationConfig";


const filter = createFilterOptions();

export default function BulkEmailInvite() {
  const [value, setValue] = useState(dayjs(new Date()));
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(dayjs(new Date()));
  const [productTypes, setProductTypes] = useState([]);
  const [testTypes, setTestTypes] = useState([]);
  const [productType, setProductType] = useState("");
  const [interviewType, setInterviewType] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [numberOfQue, setNumberOfQue] = useState(0);
  const [testType, setTestType] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [jdText, setJdText] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [experience, setExperience] = useState("");
  const [interviewDuration, setInterviewDuration] = useState({ hours: 1, minutes: 0 });


  const accessToken = useSelector((state) => state.auth.userData.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData.user.clientCode
  );
  const selectedJd = useSelector((state) => state.invite.selectedJd);
  const { jdId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);

  const sanitizedDesc = DOMPurify.sanitize(selectedJd?.description);
  const plainTextDescription = sanitizedDesc.replace(/<[^>]+>/g, "");

  const [isTestAttached, setIsTestAttached] = useState(false);
  const [attachedTestData, setAttachedTestData] = useState(null);

  // State for managing mix testType, where it will contain Subjective, Coding, MCQ.
  const [subjectiveQueNo, setSubjectiveQueNo] = useState(0);
  const [codingQueNo, setCodingQueNo] = useState(0);
  const [mcqQueNo, setMcqQueNo] = useState(0);
  const [isIncludeAptitude, setIsIncludeAptitude] = useState(false);
  const [isIncludeCulturalfit, setIsIncludeCulturalfit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inProcess, setInProcess] = useState(false);
  const [inProcessEmail, setInProcessEmail] = useState("");
  const [inProcessCheckLoading, setInProcessCheckLoading] = useState(false);

  const minDate = dayjs().startOf('day'); // Prevent going to previous months

  useEffect(() => {
    dispatch(setCurrentTrackerTab("MATCHED"));
  }, [dispatch])

  useEffect(() => {
    setJdText(plainTextDescription);
  }, [plainTextDescription]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const handleEmailDelete = (i) => {
    const newEmails = [...emailList];
    newEmails.splice(i, 1);
    setEmailList(newEmails);
  };

  const handleEmailAddition = async (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email?.text)) {
      setInProcessCheckLoading(true);
      const trackerStatus = await checkTracker(email?.text, jdId);
      if (trackerStatus === "NOT_EXIST") {
        setEmailList((emailList) => [...emailList, email]);
        setInProcessCheckLoading(false);
      } else {
        setInProcess(true);
        setInProcessEmail(email.text);
        setInProcessCheckLoading(false);
      }
    } else {
      toast.warning("Please enter a valid email address");
    }
  };


  const handleIncludeAptitudeChange = () => {
    setIsIncludeAptitude(!isIncludeAptitude);
  };

  const handleIncludeCulturalfitChange = () => {
    setIsIncludeCulturalfit(!isIncludeCulturalfit);
  }

  const handleBlur = (value, event) => {
    if (value?.length > 0) {
      handleEmailAddition({ id: value, text: value });
    }
  };

  const handleSkillsChange = (_, newSkills) => {
    setSelectedSkills(newSkills);
  };

  const handleInputBlur = () => {
    if (inputValue?.trim() !== "") {
      setSelectedSkills([...selectedSkills, inputValue.trim()]);
      setInputValue("");
    }
  };

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const selectedClassName =
    emailList?.length > 0 ? "ReactTags__selected" : "ReactTagsSelected";

  const handleProductTypeChange = (inp) => {
    setProductType(inp);
  };

  const handleTestTypeChange = (inp) => {
    setTestType(inp);
    setNumberOfQue(0);
    setSubjectiveQueNo(0);
    setCodingQueNo(0);
    setMcqQueNo(0);
  };

  const handleInterviewTypeChange = (inp) => {
    setInterviewType(inp);
  };

  const handleInvite = () => {
    if (
      dayjs(new Date()).$D > value.$D &&
      dayjs(new Date()).$M >= value.$M &&
      dayjs(new Date()).$y >= value.$y
    ) {
      toast.warning("Please select future dates");
      return;
    } else if (clientCode !== "CHIRAG03" && (dayjs(new Date()).$D === value.$D && dayjs(new Date()).$M === value.$M && dayjs(new Date()).$y === value.$y) && (((dayjs(new Date()).$H * 60) + (dayjs(new Date()).$m) + 60) > ((selectedTimeSlot?.$H * 60) + selectedTimeSlot?.$m))) {
      toast.warning("Slot should be 60 minutes after current date and time", 8000)
      return;
    }

    const makeApiCall = async () => {
      const dateTime = moment(
        value.format("YYYY-MM-DD") +
        "T" +
        (selectedTimeSlot.$H < 10
          ? "0" + selectedTimeSlot.$H
          : selectedTimeSlot.$H) +
        ":" +
        (selectedTimeSlot.$m < 10
          ? "0" + selectedTimeSlot.$m
          : selectedTimeSlot.$m) +
        ":" +
        "00.000"
      )
        .utc()
        .format("YYYY-MM-DD HH:mm");
      const date = dateTime.slice(0, 10);
      const time = dateTime.slice(11);

      const durationInMin = (interviewDuration?.hours*60) + interviewDuration?.minutes;

      const payload = {
        productType: productType,
        emails: emailList?.map((item) => item?.id),
        testType: testType,
        interviewType: interviewType,
        jdId: jdId,
        jobSummary: interviewType == "JD" ? jdText : selectedSkills?.join(", "),
        resumeText: interviewType == "JD" ? jdText : experience,
        noOfQuestions: numberOfQue,
        difficultyLevel: difficultyLevel,
        slotDate: date,
        slotTime: time,
        timeZone: "UTC",
        durationInMins: durationInMin,
      };

      const mixQuestionTestPayload = {
        jdId: jdId,
        productType: productType,
        emails: emailList?.map((item) => item?.id),
        testType: testType,
        interviewType: interviewType,
        difficultyLevel: difficultyLevel,
        jobSummary: interviewType == "JD" ? jdText : selectedSkills?.join(", "),
        resumeText: interviewType == "JD" ? jdText : experience,
        questionDetails: `${subjectiveQueNo},${mcqQueNo},${codingQueNo}`,
        slotDate: date,
        slotTime: time,
        timeZone: "UTC",
        durationInMins: durationInMin,
      };

      const employerTestPayload = {
        jdId: jdId,
        productType: productType,
        emails: emailList?.map((item) => item?.id),
        testType: attachedTestData?.testType,
        slotDate: date,
        slotTime: time,
        timeZone: "UTC",
        durationInMins: durationInMin,
      };

      try {
        setLoading(true);
        const response = await sendInvitesByEmail(
          productType === "Employer"
            ? employerTestPayload
            : testType === "mix"
              ? mixQuestionTestPayload
              : payload,
          accessToken,
          clientCode
        );

        if (response.status == "FAILED") {
          setLoading(false);
          toast.error(response?.notify?.message, 8000);
        } else {
          setLoading(false);
          toast.success("Bulk Email Invites sent successfully", 5000);
          handleSuccessFunc();
          dispatch(addSelectedEmails([...emailList?.map((item) => item?.id)]));
        }
      } catch (error) {
        setLoading(false);
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };

    makeApiCall();
  };

  const checkTracker = async (email, jdId) => {
    try {
      const res = await checkTrackerExists(jdId, email, accessToken, clientCode);
      return res;
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  }

  const handleCloseTrackerCheckInstruction = () => {
    setInProcess(false);
    setInProcessEmail("");
  }

  const handleNavigateScheduled = () => {
    navigate(`/schedule/matches/${jdId}`)
    dispatch(setCurrentTrackerTab("SCHEDULED"));
  }

  const handleNavigateCompleted = () => {
    navigate(`/schedule/matches/${jdId}`)
    dispatch(setCurrentTrackerTab("COMPLETED"));
  }

  const handleNext = () => {
    if (step < 2) {
      if (step === 1) {
        if (productType === "Employer") {
          if (!isTestAttached || attachedTestData === null) {
            toast.warning(
              "Please Attach Test to Proceed with Employer test",
              5000
            );
            return;
          }
        } else {
          if (productType?.trim()?.length == 0) {
            toast.warning("Please select product type", 5000);
            return;
          } else if (interviewType?.trim()?.length == 0) {
            toast.warning("Please select interview based on", 5000);
            return;
          } else if (testType?.trim()?.length == 0) {
            toast.warning("Please select interview type", 5000);
            return;
          } else if (difficultyLevel?.trim()?.length == 0) {
            toast.warning("Please select difficulty level", 5000);
            return;
          } else if (testType !== "mix" && numberOfQue == 0) {
            toast.warning("Please select number of Questions", 5000);
            return;
          } else if (
            testType === "mix" &&
            subjectiveQueNo === 0 &&
            codingQueNo === 0 &&
            mcqQueNo === 0
          ) {
            toast.warning("Please select number of Questions", 5000);
            return;
          } else if (interviewType == "JD" && jdText?.trim()?.length == 0) {
            toast.warning("JD text shouldn't be empty", 5000);
            return;
          } else if (interviewType == "Skill" && selectedSkills?.length == 0) {
            toast.warning("Skills shouldn't be empty", 5000);
            return;
          } else if (interviewType == "Skill" && experience?.trim()?.length == 0) {
            toast.warning("Experience shouldn't be empty", 5000);
            return;
          } else if (emailList?.length == 0) {
            toast.warning("Please select atleast 1 email", 5000);
            return;
          }
        }
      }
      setStep(step + 1);
    }
  };

  const handleSuccessFunc = () => {
    navigate("/schedule/bulk/success");
  };

  const handleIncreaseNumber = () => {
    if (numberOfQue < 5 && testType === "coding") {
      setNumberOfQue((prevNum) => prevNum + 1);
    } else if (numberOfQue < 30 && testType !== "coding") {
      setNumberOfQue((prevNum) => prevNum + 1);
    }
  };

  const handleDecreaseNumber = () => {
    if (numberOfQue > 0) {
      setNumberOfQue((prevNum) => prevNum - 1);
    }
  };

  const handleIncreaseMultipleNumber = (questionType) => {
    if (questionType === "subjective" && subjectiveQueNo < 30) {
      setSubjectiveQueNo((prevNum) => prevNum + 1);
    } else if (questionType === "coding" && codingQueNo < 30) {
      setCodingQueNo((prevNum) => prevNum + 1);
    } else if (questionType === "mcq" && mcqQueNo < 30) {
      setMcqQueNo((prevNum) => prevNum + 1);
    }
  };

  const handleDecreaseMultipleNumber = (questionType) => {
    if (questionType === "subjective") {
      if (subjectiveQueNo > 0) {
        setSubjectiveQueNo((prevNum) => prevNum - 1);
      }
    } else if (questionType === "coding") {
      if (codingQueNo > 0) {
        setCodingQueNo((prevNum) => prevNum - 1);
      }
    } else if (questionType === "mcq") {
      if (mcqQueNo > 0) {
        setMcqQueNo((prevNum) => prevNum - 1);
      }
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    parseEmailsFromFile(selectedFile);
  };

  const parseEmailsFromFile = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const content = event.target.result;
      const emailPattern =
        /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g;
      const parsedEmails = content.match(emailPattern);
      if (parsedEmails) {
        parsedEmails.forEach((item) => {
          handleEmailAddition({ id: item, text: item });
        });
      } else {
        toast.warn("No emails found in uploaded file");
      }
    };

    reader.readAsText(file);
  };

  return (
    <>
      <ConfigurableModal open={inProcess} setOpen={setInProcess} component={<InstructionForInProcessCandidateCheck jdId={jdId} email={inProcessEmail} handleNavigateToScheduled={() => handleNavigateScheduled()} handleNavigateToCompleted={() => handleNavigateCompleted()} handleClose={() => handleCloseTrackerCheckInstruction()} />} style={{ width: "80%", height: "80%" }} />
      <MainContainer>
        {loading && <Loader message="Sending Invites..." />}

        <LogoHeader />
        <Container>
          <BulkEmailInviteSteps step={step} setStep={setStep} />
          <IconButton onClick={() => navigate(`/schedule/bulk`)} className="prev">
            <ArrowBackIcon sx={{ fontSize: "30px" }} />
          </IconButton>
          <div className="mainBox">
            {step === 2 && (
              <div className="step1Box">
                <div className="step1ChildBox">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="calendarBox">
                      <DateCalendar
                        value={value}
                        onChange={(newValue) => setValue(dayjs(newValue))}
                        views={["day"]}
                        minDate={minDate} // Disables navigation to previous months
                        sx={{
                          // width: "100%",
                          height: "100%",
                          display: "flex",
                        }}
                      />
                    </div>
                  </LocalizationProvider>

                  <div className="slotBox">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["TimePicker", "TimePicker"]}
                        className="slotChildBox"
                        sx={{ width: "40%" }}
                      >
                        <TimeSlot
                          selectedTimeSlot={selectedTimeSlot}
                          setSelectedTimeSlot={setSelectedTimeSlot}
                          selectedDate={moment(
                            value.format("YYYY-MM-DD") +
                            "T" +
                            (selectedTimeSlot.$H < 10
                              ? "0" + selectedTimeSlot.$H
                              : selectedTimeSlot.$H) +
                            ":" +
                            (selectedTimeSlot.$m < 10
                              ? "0" + selectedTimeSlot.$m
                              : selectedTimeSlot.$m) +
                            ":" +
                            "00.000"
                          ).format("YYYY-MM-DD HH:mm").slice(0, 10)}
                          isReschedule={false}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            )}

            {step === 1 && (
              <div className="step2Box">
                <div className="inputBox">
                  <span className="title">Product Type</span>
                  <div className="childInputBox">
                    <label className="label">
                      <input
                        type="radio"
                        value="AI"
                        checked={productType === "AI"}
                        onChange={() => handleProductTypeChange("AI")}
                      />
                      <span>AI</span>
                    </label>
                    <label className="label">
                      <input
                        type="radio"
                        value="Employer"
                        checked={productType === "Employer"}
                        onChange={() => handleProductTypeChange("Employer")}
                      />
                      <span>Employer</span>
                    </label>
                    <label className="label">
                      <input
                        type="radio"
                        value="AI + Employer"
                        checked={productType === "AI + Employer"}
                        onChange={() =>
                          handleProductTypeChange("AI + Employer")
                        }
                      />
                      <span>AI + Employer</span>
                    </label>
                  </div>
                </div>

                {productType === "Employer" ? (
                  <div className="childBox1">
                    <AttachTestBox
                      jdId={jdId}
                      setAttachedTestData={setAttachedTestData}
                      setIsTestAttached={setIsTestAttached}
                    />
                  </div>
                ) : (
                  <div className="childBox2">
                    <div className="inputBox">
                      <span className="title">Interview Based on</span>
                      <div className="childInputBox">
                        <label className="label">
                          <input
                            type="radio"
                            value="JD"
                            checked={interviewType === "JD"}
                            onChange={() => handleInterviewTypeChange("JD")}
                          />
                          <span>JD</span>
                        </label>

                        <label className="label">
                          <input
                            type="radio"
                            value="Skill"
                            checked={interviewType === "Skill"}
                            onChange={() => handleInterviewTypeChange("Skill")}
                          />
                          <span>Skill</span>
                        </label>
                      </div>
                    </div>

                    {interviewType === "JD" && (
                      <div className="textBox">
                        <div className="box1">
                          <label for="JD" className="label1">
                            JD
                          </label>
                          <br />
                          <textarea
                            rows={5}
                            type="text"
                            value={jdText}
                            onChange={(e) => setJdText(e.target.value)}
                          />
                        </div>
                      </div>
                    )}

                    {interviewType === "Skill" && (
                      <div className="textBox">
                        <Stack spacing={3} sx={{ width: "100%" }}>
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            options={technicalSkills}
                            getOptionLabel={(option) => option}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);
                              const { inputValue } = params;
                              const isExisting = options.some(
                                (option) => inputValue === option
                              );

                              if (inputValue !== "" && !isExisting) {
                                filtered.push(inputValue);
                              }

                              return filtered;
                            }}
                            onChange={handleSkillsChange}
                            value={selectedSkills}
                            selectOnFocus
                            clearOnBlur
                            freeSolo
                            onInputChange={(event, newInputValue) => {
                              setInputValue(newInputValue);
                            }}
                            onBlur={handleInputBlur}
                            PaperComponent={(props) => (
                              <Paper
                                {...props}
                                sx={{ backgroundColor: "#F0F0F6" }}
                              />
                            )}
                            renderInput={(params) => (
                              <TextField {...params} label="Skills" />
                            )}
                          />
                        </Stack>

                        <TextField
                          id="outlined-basic"
                          label="Experience (in years)"
                          variant="outlined"
                          fullWidth
                          type="number"
                          value={experience}
                          onChange={(e) => setExperience(e.target.value)}
                          inputProps={{
                            sx: {
                              color: "#626264",
                              fontSize: "0.9rem",
                              fontWeight: "400",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: "#626264",
                              fontSize: "0.9rem",
                              fontWeight: "400",
                            },
                          }}
                        />
                      </div>
                    )}

                    <div className="inputBox">
                      <span className="title">Interview Type</span>
                      <div className="childInputBox">
                        <label className="label">
                          <input
                            type="radio"
                            value="mcq"
                            checked={testType === "mcq"}
                            onChange={() => handleTestTypeChange("mcq")}
                          />
                          <span>MCQs</span>
                        </label>
                        <label className="label">
                          <input
                            type="radio"
                            value="general"
                            checked={testType === "general"}
                            onChange={() => handleTestTypeChange("general")}
                          />
                          <span>Subjective</span>
                        </label>
                        <label className="label">
                          <input
                            type="radio"
                            value="coding"
                            checked={testType === "coding"}
                            onChange={() => handleTestTypeChange("coding")}
                          />
                          <span>Coding</span>
                        </label>
                        <label className="label">
                          <input
                            type="radio"
                            value="mix"
                            checked={testType === "mix"}
                            onChange={() => handleTestTypeChange("mix")}
                          />
                          <span>General (Includes all types of Questions)</span>
                        </label>
                        <div className="checkBox">
                          <label className="extraCheckBox">
                            <input
                              type="checkbox"
                              checked={isIncludeAptitude}
                              onChange={handleIncludeAptitudeChange}
                            />
                            <span>Include Aptitude Interview</span>
                          </label>
                          <label className="extraCheckBox">
                            <input
                              type="checkbox"
                              checked={isIncludeCulturalfit}
                              onChange={handleIncludeCulturalfitChange}
                            />
                            <span>Include Cultural fit check</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="textBox">
                      <div className="inputBox">
                        <span className="title">Difficulty Level</span>
                        <div className="childInputBox">
                          <label className="label">
                            <input
                              type="radio"
                              value="easy"
                              checked={difficultyLevel === "easy"}
                              onChange={() => setDifficultyLevel("easy")}
                            />
                            <span>Easy</span>
                          </label>
                          <label className="label">
                            <input
                              type="radio"
                              value="moderate"
                              checked={difficultyLevel === "moderate"}
                              onChange={() => setDifficultyLevel("moderate")}
                            />
                            <span>Moderate</span>
                          </label>
                          <label className="label">
                            <input
                              type="radio"
                              value="difficult"
                              checked={difficultyLevel === "difficult"}
                              onChange={() => setDifficultyLevel("difficult")}
                            />
                            <span>Difficult</span>
                          </label>
                        </div>
                      </div>

                      {testType !== "mix" && (
                        <div className="numberMainBox">
                          <label className="label">Number of Questions</label>
                          <NumberCounter
                            handleDecreaseNumber={handleDecreaseNumber}
                            handleIncreaseNumber={handleIncreaseNumber}
                            value={numberOfQue}
                            handleChange={(value) => setNumberOfQue(value)}
                            testType={testType}
                            style={{ width: "40%" }}
                          />
                        </div>
                      )}
                    </div>
                    {testType === "mix" && (
                      <div className="inputBox">
                        <span className="title">Number of Questions</span>
                        <div className="childInputBox multipleNumberMainBox">
                          <div className="queTypeCard">
                            <label className="label">Subjective</label>
                            <NumberCounter
                              handleDecreaseNumber={() =>
                                handleDecreaseMultipleNumber("subjective")
                              }
                              handleIncreaseNumber={() =>
                                handleIncreaseMultipleNumber("subjective")
                              }
                              value={subjectiveQueNo}
                              handleChange={(value) =>
                                setSubjectiveQueNo(value)
                              }
                              testType={testType}
                              style={{ width: "75%" }}
                            />
                          </div>
                          <div className="queTypeCard">
                            <label className="label">Coding</label>
                            <NumberCounter
                              handleDecreaseNumber={() =>
                                handleDecreaseMultipleNumber("coding")
                              }
                              handleIncreaseNumber={() =>
                                handleIncreaseMultipleNumber("coding")
                              }
                              value={codingQueNo}
                              handleChange={(value) => setCodingQueNo(value)}
                              testType={testType}
                              style={{ width: "75%" }}
                            />
                          </div>
                          <div className="queTypeCard">
                            <label className="label">MCQ</label>
                            <NumberCounter
                              handleDecreaseNumber={() =>
                                handleDecreaseMultipleNumber("mcq")
                              }
                              handleIncreaseNumber={() =>
                                handleIncreaseMultipleNumber("mcq")
                              }
                              value={mcqQueNo}
                              handleChange={(value) => setMcqQueNo(value)}
                              testType={testType}
                              style={{ width: "75%" }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="textBox">
                  <ReactTags
                    tags={emailList}
                    delimiters={delimiters}
                    handleDelete={handleEmailDelete}
                    handleAddition={handleEmailAddition}
                    // handleInputBlur={handleBlur}
                    placeholder={inProcessCheckLoading ? "Adding email.." : "Add Emails"}
                    inputFieldPosition="top"
                    allowUnique="true"
                    classNames={{
                      selected: selectedClassName,
                    }}
                  />

                </div>
                <div className="textBox">
                  <Label htmlFor="emailUploadInput">
                    Upload Email List{" "}
                    <img src={uploadIcon} className="uploadIcon" />
                    <input
                      id="emailUploadInput"
                      type="file"
                      onChange={handleFileChange}
                      accept=".pdf, .doc, .csv, .txt"
                      style={{ display: "none" }}
                    />
                  </Label>
                </div>
                <InterviewDurationConfig duration={interviewDuration} setDuration={setInterviewDuration} />
              </div>
            )}
          </div>

          <ButtonBox>
            {step < 2 && <Button onClick={() => handleNext()}>Next</Button>}
            {step === 2 && <Button onClick={handleInvite} disabled={loading}>Send Invite</Button>}
          </ButtonBox>
        </Container>
      </MainContainer>
    </>
  );
}

const MainContainer = styled.div``;

const Label = styled.label`
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  border: 0.075rem solid #c4c4c4;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;

  span {
    color: var(--color);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6rem 3rem 2rem 3rem;
  align-items: center;
  width: calc(100% - 6rem);
  // height: calc(100vh - 8rem);
  justify-content: center;

  /* Hide the up and down arrows in number input */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .ReactTags__tags {
    display: flex;
    gap: 2rem;
    box-sizing: border-box;
    font-size: 0.9rem;
  }

  .ReactTags__tagInputField {
    width: 20rem;
    padding: 0.5rem 1rem;
    border: 0.075rem solid #c4c4c4;
    border-radius: 0.25rem;
    outline-color: #c4c4c4;
    font-size: 0.9rem;
    font-weight: 500;
    font-family: var(--font);
  }

  .ReactTags__selected {
    width: 80%;
    display: flex;
    flex-flow: row wrap;
    row-gap: 0.5rem;
    column-gap: 1rem;
    border: 0.075rem solid #c4c4c4;
    padding: 1rem 1rem;
    border-radius: 0.25rem;
    font-weight: 500;
    font-size: 0.9rem;
  }

  .ReactTagsSelected {
    display: none;
  }

  .ReactTags__selected .ReactTags__tag {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    border: 0.075rem solid #c4c4c4;
    padding: 0rem 0 0rem 0.3rem;
    border-radius: 0.3rem;
    font-size: 0.9rem;
    font-weight: 500;
  }

  .ReactTags__selected .ReactTags__remove {
    background-color: var(--lightOrange);
    color: var(--white);
    cursor: pointer;
    border: none;
    height: 1.75rem;
    width: 1.75rem;
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }

  .textEditorBox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    min-height: 15rem;

    .titleLabel {
      font-size: 0.9rem;
      font-weight: 600;
      margin-left: 1rem;
    }
  }

  .textEditor {
    height: 11rem;
    width: 100%;
    font-size: 0.9rem;
    font-weight: 500;
    font-family: var(--font);
  }

  .prev {
    background-color: var(--lightOrange);
    padding: 0.1rem;
    position: fixed;
    top: 5rem;
    left: 1.5rem;
    color: var(--white);
  }

  .prev:hover {
    color: var(--color);
  }

  .uploadIcon {
    width: 1.5rem;
    height: 1.5rem;
  }

  textarea {
    box-sizing: border-box;
    width: 100%;
    border-radius: 0.5rem;
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    outline-color: var(--lightOrange);
  }

  .box1,
  .box2 {
    position: relative;
    width: 100%;
    margin-top: -1rem;
  }

  .label1 {
    font-size: 0.9rem;
    font-weight: 600;
    position: absolute;
    top: 0.6rem;
    left: 1rem;
    background-color: var(--white);
    padding: 0 0.5rem;
  }

  .numberMainBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: -0.75rem;

    .label {
      font-size: 0.9rem;
      font-weight: 500;
    }
  }

  .numberBox {
    width: 40%;
    height: 3rem;
    display: flex;

    .numberBtn {
      height: 100%;
      width: 3.5rem;
      border: none;
      background-color: var(--lightOrange);
      color: var(--white);
      font-size: 1.4rem;
      cursor: pointer;
    }

    .btn1 {
      border-top-left-radius: 0.4rem;
      border-bottom-left-radius: 0.4rem;
      font-family: var(--font);
    }

    .btn2 {
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
      font-family: var(--font);
    }

    .numberInput {
      height: 100%;
      width: 6rem;
      padding: 0 2.3rem;
      box-sizing: border-box;
      font-size: 0.9rem;
      font-weight: 500;
      border: none;
      outline: none;
      background-color: #f0f0f0;
      font-family: var(--font);
    }
  }

  .prev {
    background-color: var(--lightOrange);
    padding: 0.1rem;
    position: fixed;
    top: 5rem;
    left: 1.5rem;
    color: var(--white);
    font-family: var(--font);
  }

  .prev:hover {
    color: var(--color);
  }

  .btn {
    padding: 0.5rem 1rem;
    margin-top: 0rem;
    background-color: var(--lightOrange);
    border: none;
    color: var(--white);
    font-size: 1.1rem;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: pointer;
    font-family: var(--font);
  }

  .smallTextBox {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: start;
    padding-left: 2rem;
  }

  .textBox {
    display: flex;
    width: 100%;
    gap: 2rem;
  }

  .smallText {
    font-size: 0.75rem;
  }

  .mainBox {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    align-items: center;
    width: 100%;

    .step1Box {
      display: flex;
      width: 50%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      margin-top: 3rem;

      .step1ChildBox {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
      }

      .calendarBox {
        border: 0.08rem solid lightgrey;
        border-radius: 0.5rem;
        width: 100%;
      }

      .slotBox {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .slotChildBox {
          width: 50%;
        }

        .smallTextBox {
          width: 50%;
          display: flex;
          align-items: start;
          gap: 1rem;
          height: 2rem;
        }
      }
    }

    .step2Box {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      padding: 0rem 1rem;
      margin-top: 4rem;
      margin-bottom: 1rem;
      box-sizing: border-box;

      .childBox1 {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        padding: 0rem;
        margin-bottom: 1rem;
        box-sizing: border-box;
      }

      .childBox2 {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        padding: 0rem;
        margin-bottom: 1rem;
        box-sizing: border-box;
      }

      .inputBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        border: 0.08rem solid #c4c4c4;
        padding: 1rem 1rem;
        border-radius: 0.3rem;
        position: relative;
        box-sizing: border-box;
      }

      .childInputBox {
        display: flex;
        gap: 0.5rem;
        flex-flow: row wrap;

         .checkBox {
         display: flex;
         flex-direction: column;
         gap: 0.5rem;

        }

        .extraCheckBox {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          cursor: pointer;

          input {
            cursor: pointer;
          }

          span {
            align-items: center;
            font-size: 0.9rem;
            font-weight: 500;
            font-family: var(--font);
          }
        }
      }

      .multipleNumberMainBox {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        .label {
          font-size: 0.9rem;
        }

        .queTypeCard {
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
          align-items: center;
          width: 25%;
        }
      }

      .title {
        font-size: 0.9rem;
        font-weight: 600;
        position: absolute;
        top: -0.8rem;
        background-color: var(--white);
        padding: 0 0.3rem;
      }
    }

    .step3Box {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }

  .label {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    margin-bottom: 0rem;

    input {
      position: absolute;
      left: -9999px;
      &:checked + span {
        background-color: #f0f0f6;
        &:before {
          box-shadow: inset 0 0 0 0.3rem var(--lightOrange);
        }
      }
    }
    span {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      padding: 0.3rem 0.75rem 0.3rem 0.3rem;
      border-radius: 99rem; // or something higher...
      transition: 0.25s ease;
      &:hover {
        background-color: mix(#fff, var(--lightOrange), 84%);
      }
      &:before {
        display: flex;
        flex-shrink: 0;
        content: "";
        background-color: #fff;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        margin-right: 0.375em;
        transition: 0.25s ease;
        box-shadow: inset 0 0 0 0.125em var(--lightOrange);
      }
    }
  }
`;

const ButtonBox = styled.div`
  display: flex;
  margin: 2rem 0;
  gap: 2rem;
`;

const Button = styled.button`
  background-color: var(--lightOrange);
  color: var(--white);
  border: none;
  padding: 0.5rem 0.8rem;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 0.5rem;
  cursor: pointer;
  font-family: var(--font);
`;
