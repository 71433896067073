import axios from "axios"

export const rescheduleInterview = async (interviewId, data, accessToken, clientCode) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'X-Client-Code': clientCode,
        }
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/interviews/reschedule/${interviewId}`, data, config);
        console.log('Data:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
}

