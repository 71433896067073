import React, { useState } from 'react'
import styled from 'styled-components'
import Label from '../../../component/shared/Label'
import IconButton from '../../../component/shared/IconButton'
import visibleIcon from '../../../assets/icons/visible.png'
import { useSelector } from 'react-redux'
import CommonDrawer from '../../../component/global/Drawer'
import PaperQuestionDetailsPage from './PaperQuestionDetailsPage'

const PaperQuestionCard = ({ data }) => {
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode) || useSelector(state => state.auth.userData?.academicUser?.clientCode);

    const [state, setState] = useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };


    return (
        <>
            <CommonDrawer toggleDrawer={toggleDrawer} state={state} component={<PaperQuestionDetailsPage data={data} />} width='60vw' />
            <Card>
                <TopBox>
                    <span className="title">{data?.question?.length > 150 ? data?.question?.slice(0, 150) + "..." : data?.question}</span>
                    <div className='iconBox'>
                        <IconButton
                            icon={visibleIcon}
                            width="1.5rem"
                            func={toggleDrawer('right', true)}
                            isBorder={true}
                            altTitle="viewDetailButton" />
                    </div>
                </TopBox>
                <BottomBox>
                    <div className='left'>
                        <Label
                            bgColor="transparent"
                            borderColor="grey"
                            borderRadius="1rem"
                            text={data?.score}
                            title="Score: "
                            isTitle={true}
                            fontType="bold" />
                        <Label
                            bgColor="transparent"
                            borderColor="grey"
                            borderRadius="1rem"
                            text={data?.questionType?.split(",")?.map((topic, i) => (topic) + ", ")}
                            title="Topics: "
                            isTitle={true}
                            fontType="bold" />
                    </div>
                </BottomBox>
            </Card>
        </>
    )
}

export default PaperQuestionCard

const Card = styled.div`
width: 100%;
margin: 0 auto;
padding: 0.75rem 1rem;
box-sizing: border-box;
border: 0.1rem solid lightgrey;
border-radius: 0.25rem;
display: flex;
flex-direction: column;
gap: 2rem;
background-color: var(--white);

`

const TopBox = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: start;
gap: 2rem;

.title {
 font-size: 0.9rem;
 font-weight: 700;
}

.iconBox {
 display: flex;
 gap: 0.5rem;
 align-items: center;
}
`

const BottomBox = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
align-items: center;
gap: 5%;

.left {
display: flex;
justify-content: space-between;
align-items: center;
gap: 2rem;
}

.right {
display: flex;
justify-content: space-between;
align-items: center;
gap: 2rem;
}
`

