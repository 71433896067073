import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const textContent = {
    fullscreen: 'Looks like your browser is not in full-screen mode. Press the button below to continue.',
    tabFocus: 'You seem to be accessing a different tab. Press the button below to continue.',
};

const Alerts = ({ fullScreen, tabFocus }) => {
    const [open, setOpen] = useState(null);

    useEffect(() => {
        if (fullScreen.status === 'off') {
            setOpen('fullscreen');
        } else if (tabFocus.status === false) {
            setOpen('tabFocus');
        } else {
            setOpen(null);
        }
    }, [fullScreen.status, tabFocus.status]);

    if (!open) return null;

    const handleClick = () => {
        fullScreen.trigger();
    };

    return (
        <Modal>
            <div className="modal-content">
                <span className='textTop'>If you Change Tabs or Escape FullScreen for more than 5 times then Interview will be terminated and submitted automatically.</span>
                <div className='bottomBox'>
                    <h3>{textContent[open]}</h3>
                    <button className="close" onClick={handleClick}>
                        Continue
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default Alerts;


const Modal = styled.div`
position: fixed;
z-index: 1; 
left: 0;
top: 0;
display: flex;
width: 100%; 
height: 100%; 
overflow: auto; 
background-color: rgba(0, 0, 0, 0.85);


.modal-content {
    background-color: var(--white);
    margin: auto;
    padding: 1rem;
    width: 75%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 1rem;
    gap: 1rem;
  }

  .bottomBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .textTop {
    font-size: 1.1rem;
    font-weight: 600;
    color: red;
  }

  h3 {
    font-size: 1.2rem;
  }

  .close {
    font-size: 1.2rem;
    font-weight: 600;
    background-color: var(--lightOrange);
    color: var(--white);
    border: none;
    padding: 0.5rem 0.8rem;
    border-radius: 0.5rem;
    cursor: pointer;
    z-index: 1000;
  }

`

