import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getOnboardReqs = async (accessToken, clientCode, status, page=1, size=1000000) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'x-client-code': clientCode
        }
    };

    try {
        const response = await axios.get(`${API_URL}/api/onboards?page=${page}&size=${size}&status=${status}`, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const updateOnboardStatus = async (accessToken, clientCode, userOnboardId, payload) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'x-client-code': clientCode
        }
    };

    try {
        const response = await axios.patch(`${API_URL}/api/onboards/${userOnboardId}`, payload, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}