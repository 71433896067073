import React from 'react';
import styled from 'styled-components';
import bookImg from "../../../assets/Academic/book.webp"
import middleSchoolGradeIcon from "../../../assets/Academic/middleSchoolGradeIcon.webp"
import highSchoolGradeIcon from "../../../assets/Academic/highSchoolGradeIcon.webp"
import competitiveGradeIcon from "../../../assets/Academic/competitiveGradeIcon.webp"
import governmentGradeIcon from "../../../assets/Academic/governmentGradeIcon.webp"
import { getAllCategories, getCoursesByCategory } from '../../../utils/academicData';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const BrowseCategoryWrapper = styled.section`
  padding: 2rem;
  background: rgb(239,236,247);
  background: linear-gradient(90deg, rgba(239,236,247,1) 0%, rgba(239,236,247,1) 35%, rgba(168,162,226,1) 100%);
  text-align: start;
  width: 100%;
  box-sizing: border-box;

  .mainTitle {
  text-align: center;
  padding-bottom: 2rem;
  }
`;

const CategoryGroup = styled.div`
  margin-bottom: 2rem;
`;

const CategoryTitle = styled.h3`
  margin-bottom: 1rem;
`;

const CourseList = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 3.5%;
`;

const Course = styled.div`
  margin: 1rem 0;
  padding: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 20%;
  text-align: center;
  cursor: pointer;

  img {
   width: 5rem;
  }
`;

const BrowseCategory = () => {
  const categories = getAllCategories();
  const accessToken = useSelector(state => state.auth.userData?.accessToken);
  const Navigate = useNavigate();

  const handlePractice = (courseCategory, course) => {
    if(accessToken){
      window.location.href = `/academic/practice-test/${courseCategory}/${course}`;
    } else {
      Navigate("/academic/login")
    }
  };

  const getImageByCategory = (category) => {
    switch (category) {
      case 'Middle School':
        return middleSchoolGradeIcon;
      case 'High School':
        return highSchoolGradeIcon;
      case 'Competitive Exams':
        return competitiveGradeIcon;
      case 'Government Exams':
        return governmentGradeIcon;
      default:
        return bookImg;
    }
  };

  return (
    <BrowseCategoryWrapper>
      <h2 className='mainTitle'>Explore by Grade and Exam</h2>
      {categories.map((category, index) => (
        <CategoryGroup id={category.name} key={index}>
          <CategoryTitle>{category.name}</CategoryTitle>
          <CourseList>
            {getCoursesByCategory(category?.name)?.map((course, courseIndex) => (
              <Course key={courseIndex} onClick={() => handlePractice(category?.name, course?.name)}>
                <img src={getImageByCategory(category?.name)} alt="Book" />
                <h3>{course?.name}</h3>
              </Course>
            ))}
          </CourseList>
        </CategoryGroup>
      ))}
    </BrowseCategoryWrapper>
  );
};

export default BrowseCategory;