import { useState } from 'react';
import { updateTest } from '../services/testService';

export const useUpdateTestForPublish = (accessToken, clientCode) => {
    const [loadingUpdatePublish, setLoadingUpdatePublish] = useState(false);

    const handleUpdateTestForPublish = async (id, payload) => {
        setLoadingUpdatePublish(true);
        try {
            const res = await updateTest(payload, id, accessToken, clientCode);
        } catch (error) {
            const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        } finally {
            setLoadingUpdatePublish(false);
        }
    };

    return { handleUpdateTestForPublish, loadingUpdatePublish };
};
