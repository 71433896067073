import { useState } from 'react';
import { publishTest } from '../services/testService';  // Adjust the import path accordingly
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { clearFormData } from '../../../../slices/testFormSlice';

export const usePublishTest = (accessToken, clientCode, toggleView, setTestTrigger) => {
    const dispatch = useDispatch();
    const [loadingPublish, setLoadingPublish] = useState(false);

    const handlePublishTest = async (id, published) => {
        setLoadingPublish(true);
        try {
            const res = await publishTest(accessToken, clientCode, id, published);
            if(res){
                toast.success("Test published successfully", 5000);
                dispatch(clearFormData());
                setTestTrigger(prev => !prev);
                toggleView('test');
            }
        } catch (error) {
            const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        } finally {
            setLoadingPublish(false);
        }
    };

    return { handlePublishTest, loadingPublish };
};
