import React from 'react';
import styled from 'styled-components';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';

const HowItWorkSection = ({ steps }) => {

    return (
        <StyledBox>
            <GridBackground>
                <h1 className='mainTitle'>How RegorTalent Works</h1>
                <Timeline position="alternate">
                    {steps?.map((step, index) => (
                        <TimelineItem key={index}>
                            <TimelineSeparator>
                                <TimelineDot className='numberDot'>
                                    <span>{index + 1}</span>
                                </TimelineDot>
                                {index < steps?.length - 1 && <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent>
                                <StepContainer>
                                    <Typography variant="h6" className="timelineTitle">
                                        {step?.title}
                                    </Typography>
                                    <Typography variant="body2" className="timelineText">
                                        {step?.description}
                                    </Typography>
                                    <ul className='detailsList'>
                                        {step?.details?.map((detail, i) => (
                                            <li key={i}>{detail}</li>
                                        ))}
                                    </ul>
                                </StepContainer>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            </GridBackground >

        </StyledBox >
    );
};

export default HowItWorkSection;


const GridBackground = styled.div`
  background-color: transparent;
  background-image: linear-gradient(0deg, transparent 99%, #CDD5D5 5%), 
                    linear-gradient(90deg, transparent 99%, #CDD5D5 5%);
  background-size: 60px 60px;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;

`;

const StyledBox = styled.div`
  width: 95%;
  margin: 0rem auto;
  padding: 0rem;
  background: linear-gradient(
    90deg,
    rgba(162, 158, 158, 0.2) 0%,
    rgba(132, 255, 253, 0.2) 50%,
    rgba(162, 158, 158, 0.2) 100%
  );
  border-radius: 0.75rem;
  position: relative;
  box-sizing: border-box;

  h1.mainTitle {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    color: var(--color);
    font-family: 'Inria Serif', serif;

  }

  .numberDot {
    background-color: #000;
    color: #fff;
    font-weight: bold;
    font-size: 1.5rem;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
`;

const StepContainer = styled.div`
  background-color: var(--white);
  border-radius: 0.75rem;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;


  .timelineTitle {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }

  .timelineText {
    font-size: 1rem;
    font-weight: 500;
    color: var(--color);
    margin-bottom: 0.25rem;
  }

  .detailsList {
    margin-top: 0.25rem;
    padding-left: 1.5rem;
    list-style-type: disc;
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--color);
    text-align: start;
  }
`;

