import { useState, useCallback } from 'react';

const useFilters = () => {
    const [filters, setFilters] = useState({
        category: [],
        questionType: [],
        difficultyLevel: [],
        tags: '',
    });

    const handleCheckboxChange = useCallback((filterType, option, checked) => {
        setFilters((prev) => ({
            ...prev,
            [filterType]: checked
                ? [...prev[filterType], option]
                : prev[filterType].filter((item) => item !== option),
        }));
    }, []);

    const handleApply = () => {
        console.log("Filtered");
    };

    const handleReset = () => {
        setFilters({
            category: [],
            questionType: [],
            difficultyLevel: [],
            tags: '',
        });
    };

    return { filters, handleCheckboxChange, handleApply, handleReset };
};

export default useFilters;
