import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useNavigate } from "react-router";

import { useSelector } from "react-redux";
import EmployeMetrics from "../components/Interviews/EmployerDashboard/EmployerMetrics";
import EmployerSidebar from "../components/Interviews/EmployerDashboard/EmployerSidebar";
import EmployeProfile from "../components/Interviews/EmployerDashboard/sidebarPages/EmployerProfile";
import CreateQuestion from "../components/Interviews/EmployerDashboard/sidebarPages/CreateQuestion";
import EmployerHeader from "../components/commonComponents/EmployerHeader";
import Subscription from "../components/Interviews/EmployerDashboard/sidebarPages/Subscription";
import Report from "../components/Interviews/EmployerDashboard/sidebarPages/Report";
import Inbox from "../components/Interviews/EmployerDashboard/sidebarPages/Inbox";
import Billing from "../components/Interviews/EmployerDashboard/sidebarPages/Billing";
import RegisterCandidate from "../components/Interviews/EmployerDashboard/sidebarPages/RegisterCandidate";
import RegisteredCandidates from "../components/Interviews/EmployerDashboard/sidebarPages/RegisteredCandidates";
import ManageUsers from "../components/Interviews/EmployerDashboard/sidebarPages/ManageUsers";
import ConfigureReports from "../components/Interviews/EmployerDashboard/sidebarPages/ConfigureReports";
import InterviewDashboard from "../components/Interviews/EmployerDashboard/sidebarPages/InterviewDashboard";
import UploadCandidateProfile from "../components/Interviews/EmployerDashboard/sidebarPages/UploadCandidateProfile";
import EmpConfigureDash from "../components/Interviews/EmployerDashboard/sidebarPages/EmpConfigureDash";
import InterviewFlow from "../components/Interviews/EmployerDashboard/sidebarPages/InterviewFlow";
import RequestDemoPage from "../components/Interviews/EmployerDashboard/sidebarPages/RequestDemoPage";
import ManageAgencies from "../components/Interviews/EmployerDashboard/sidebarPages/ManageAgencies";
import JdListingTabs from "./agency/JdListingTabs";
import { useDispatch } from "react-redux";
import { setCurrentItem, setCurrentMetric } from "../slices/configSlice";
import CandidateListingTabs from "./agency/CandidateListingTabs";
import CallSupport from "../components/commonComponents/CallSupport";
import EmpApplications from "../components/Interviews/EmployerDashboard/sidebarPages/EmpApplications";
import EmpApplicationCandidatesList from "../components/Interviews/EmployerDashboard/sidebarPages/EmpApplicationCandidatesList";
import CreateTestTabs from "../components/Interviews/EmployerDashboard/sidebarPages/CreateTestTabs";
import AvailableTestTabs from "../components/Interviews/EmployerDashboard/sidebarPages/AvailableTestTabs";
import JdTabs from "../components/Interviews/EmployerDashboard/sidebarPages/JdTabs";
import CreateJds from "../components/Interviews/EmployerDashboard/sidebarPages/CreateJds";
import EmployerSidebarTrial from "../components/Interviews/EmployerDashboard/EmployerSidebarTrial";
import { agencyMenuItems, employerMenuItems } from "../utils/employerData/sidebarMenu";
import QuestionParentPage from "../features/Employer/questions/pages/QuestionParentPage";
import TestParentPage from "../features/Employer/tests/pages/TestParentpage";
import ActivityTracker from "../features/Employer/activityTracker/pages/ActivityTracker";
import CustomReport from "../features/Employer/report/pages/CustomReport";


const EmployerDashboardTrial = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);
  const userType = useSelector((state) => state.auth.userType);
  const currentItem = useSelector((state) => state.config?.currentItem);

  const [empScheduledPage, setEmpScheduledPage] = useState({ index: 1, jdId: null });
  const [selectedJDID, setSelectedJDID] = useState("");
  const [empClientCode, setEmpClientCode] = useState("");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
    } else if (clientCode === "regortalent") {
      navigate("/access-denied");
    }

    setOpen(["jds", "create-tests", "available-tests"]?.includes(currentItem));
    setOpen2(["candidate-register", "candidate-registered"]?.includes(currentItem));

    if (currentItem === "applications") {
      setEmpScheduledPage({ index: 1, jdId: null });
    }
  }, [currentItem, accessToken, clientCode, navigate]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (event.currentTarget.performance.navigation.type === 1) {
        dispatch(setCurrentItem("dashboard"));
        dispatch(setCurrentMetric(userType === "EMPLOYER" ? "interviews" : "jdListings"));
      }
    };

    window.addEventListener("load", handleBeforeUnload);
    return () => {
      window.removeEventListener("load", handleBeforeUnload);
    };
  }, [dispatch, userType]);

  const renderCurrentItem = () => {
    switch (currentItem) {
      case "dashboard":
        return (
          <EmployeMetrics
            page={empScheduledPage}
            setPage={setEmpScheduledPage}
            setCurrentItem={setCurrentItem}
            setEmpClientCode={setEmpClientCode}
            setSelectedJDID={setSelectedJDID}
          />
        );
      case "configure-dashboard":
        return <EmpConfigureDash />;
      case "create-jds":
        return <CreateJds />;
      case "candidate-register":
        return <RegisterCandidate />;
      case "candidate-registered":
        return <RegisteredCandidates />;
      case "upload-profiles":
        return <UploadCandidateProfile />;
      case "applications":
        return (
          <>
            {empScheduledPage?.index === 1 && <EmpApplications page={empScheduledPage} setPage={setEmpScheduledPage} />}
            {empScheduledPage?.index === 2 && <EmpApplicationCandidatesList page={empScheduledPage} setPage={setEmpScheduledPage} />}
          </>
        );
      case "manage-tests":
        return <CreateQuestion />;
      case "jds":
        return <JdTabs />;
      case "create-tests":
        return <QuestionParentPage />;
      case "available-tests":
        return <TestParentPage />;
      case "interview-dashboard":
        return <InterviewDashboard page={empScheduledPage} setPage={setEmpScheduledPage} />;
      case "interview-flow":
        return <InterviewFlow />;
      case "profile":
        return <EmployeProfile />;
      case "manage-users":
        return <ManageUsers />;
      case "manage-agencies":
        return <ManageAgencies />;
      case "subscriptions":
        return <Subscription />;
      case "activity-logs":
        return <ActivityTracker />;
      case "billing":
        return <Billing />;
      case "inbox":
        return <Inbox />;
      case "report":
        return <Report />;
      case "custom-report":
        return <CustomReport />;
      case "call-support":
        return <CallSupport />;
      case "demo":
        return <RequestDemoPage />;
      case "jdListing":
        return <JdListingTabs setCurrentItem={setCurrentItem} setEmpClientCode={setEmpClientCode} setSelectedJDID={setSelectedJDID} />;
      case "candidateListing":
        return <CandidateListingTabs setCurrentItem={setCurrentItem} empClientCode={empClientCode} selectedJDID={selectedJDID} setSelectedJDID={setSelectedJDID} />;
      default:
        return null;
    }
  };

  return (
    <MainBox>
      <EmployerHeader />
      <StyledContent>
        <EmployerSidebarTrial menuItems={userType === "EMPLOYER" ? employerMenuItems : agencyMenuItems} />
        <MainContent>
          {renderCurrentItem()}
        </MainContent>
      </StyledContent>
    </MainBox>
  );
};

export default EmployerDashboardTrial;

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f4f4f4;
`;

const MainContent = styled.div`
  flex-grow: 1;
  margin-left: 17rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #f4f4f4;

  .innerBox {
    width: 96%;
  }
`;

const StyledContent = styled.div`
  width: 100%;
  display: flex;
  height: calc(100% - 4rem);
  margin-top: 4rem;
  background-color: var(--white);
  color: var(--color);
  background-color: #f4f4f4;
`;
