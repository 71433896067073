import axios from "axios"


export const getJDByIdAndHash = async (jdId, jobSummaryHash, clientCode) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Client-Code': clientCode,
        }
    };

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jds/${jdId}/getByIdAndHash/${jobSummaryHash}`, config);
        console.log('Data:', response.data);
        console.log("Status", response.status)
        return response.data;
    } catch (error) {
        throw error;
    }
}

