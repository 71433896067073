import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getActivityLogs = async (accessToken, clientCode, payload, page=0, size=1000000) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'x-client-code': clientCode
        }
    };

    try {
        const response = await axios.post(`${API_URL}/api/common/getActivityLogs?page=${page}&size=${size}`, payload, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getUsers = async (accessToken, clientCode, page=1, size=1000000) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'x-client-code': clientCode
        }
    };

    try {
        const response = await axios.get(`${API_URL}/api/employers/employees?page=${page}&size=${size}`, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}

