import React from 'react';
import styled from 'styled-components';
import { getAllCategories } from '../../../utils/academicData';

const GroupSectionWrapper = styled.section`
padding: 2rem 3rem;
  background: var(--white);
  text-align: center;
  justify-content: center;
  display: flex;
  flex-flow: row wrap;
  row-gap: 3rem;
  column-gap: 5%;
  width: 94%;
  margin: 1rem auto;
  box-sizing: border-box;
  border-radius: 1rem;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.20);



`;

const Group = styled.div`
  padding: 1rem;
  background: rgb(206,203,239);
background: linear-gradient(0deg, rgba(206,203,239,1) 0%, rgba(239,236,247,1) 100%);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 0.05rem solid var(--lightAcademicLavendar);

  h2 {
    font-size: 1.5rem;
    font-size: 600;
    line-height: 1.5rem;
    margin-bottom: 0rem;
  }

  p {
    margin-bottom: 1.5rem;
  }

`;

const Button = styled.button`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #515290;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #442f90;
  }
`;

const groups = getAllCategories();


const AllPracticeTests = () => {

    const handleLinkClick = (name) => {
        window.open(`/academic/practice-test/${name}/all`, '_blank');
    };

    return (
        <GroupSectionWrapper>
            {groups.map((group, index) => (
                <Group key={index}>
                    <h2>{group?.name}</h2>
                    <p>{group?.description}</p>
                    <Button onClick={() => handleLinkClick(group?.name)}>Start Practice Test</Button>
                </Group>
            ))}
        </GroupSectionWrapper>
    )
};

export default AllPracticeTests;
