import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import styled from 'styled-components'
import Resume1 from '../components/Interviews/SeekerDashboard/resumeTemplates/Resume1'
import Resume2 from '../components/Interviews/SeekerDashboard/resumeTemplates/Resume2'
import Resume3 from '../components/Interviews/SeekerDashboard/resumeTemplates/Resume3'
import Resume4 from '../components/Interviews/SeekerDashboard/resumeTemplates/Resume4'
import Resume5 from '../components/Interviews/SeekerDashboard/resumeTemplates/Resume5'
import Resume6 from '../components/Interviews/SeekerDashboard/resumeTemplates/Resume6'
import Resume7 from '../components/Interviews/SeekerDashboard/resumeTemplates/Resume7'
import CreateResumeLeftBox from '../components/Interviews/SeekerDashboard/resumeTemplates/CreateResumeLeftBox';
import imgDummy from '../assets/resume/imgDummy.webp'
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import Header from '../components/Interviews/CreateInterview/Header';
import Resume8 from '../components/Interviews/SeekerDashboard/resumeTemplates/Resume8';
import Resume9 from '../components/Interviews/SeekerDashboard/resumeTemplates/Resume9';


const CreateResumePage = () => {
    const { resumeId } = useParams();
    const [trigger, setTrigger] = useState(false);

    const [expanded, setExpanded] = useState(false);
    const [personalDetails, setPersonalDetails] = useState({});
    const [professionalSummary, setProfessionalSummary] = useState("");
    const [employmentHistory, setEmploymentHistory] = useState([]);
    const [education, setEducation] = useState([]);
    const [social, setSocial] = useState({});
    const [skills, setSkills] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [projects, setProjects] = useState([]);
    const [profileImg, setProfileImg] = useState(imgDummy);
    const resume1TemplateRef = useRef(null);
    const resume2TemplateRef = useRef(null);
    const resume3TemplateRef = useRef(null);
    const resume4TemplateRef = useRef(null);
    const resume5TemplateRef = useRef(null);
    const resume6TemplateRef = useRef(null);
    const resume7TemplateRef = useRef(null);
    const resume8TemplateRef = useRef(null);
    const resume9TemplateRef = useRef(null);
    


    const handleGeneratePdf = useReactToPrint({
        content: () => getRef(resumeId).current,
    })

    const getRef = (id) => {
        if (id == 'berlin') return resume1TemplateRef;
        if (id == 'tokyo') return resume2TemplateRef;
        if (id == 'nairobi') return resume3TemplateRef;
        if (id == 'denver') return resume4TemplateRef;
        if (id == 'rio') return resume5TemplateRef;
        if (id == 'moscow') return resume6TemplateRef;
        if (id == 'martin') return resume7TemplateRef;
        if (id == 'professor') return resume8TemplateRef;
        if (id == 'oslo') return resume9TemplateRef;

    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleAddEducation = () => {
        setEducation([...education, {}])
    }

    const handleRemoveEducation = (indexToRemove) => {
        setEducation((prevEducation) =>
            prevEducation.filter((_, index) => index !== indexToRemove)
        );
    };

    const handleAddExperience = () => {
        setEmploymentHistory([...employmentHistory, {}])
    }

    const handleRemoveExperience = (indexToRemove) => {
        setEmploymentHistory((prevEmployments) =>
            prevEmployments.filter((_, index) => index !== indexToRemove)
        );
    };

    const handleAddProject = () => {
        setProjects([...projects, {}])
    }

    const handleRemoveProject = (indexToRemove) => {
        setProjects((prevProjects) =>
            prevProjects.filter((_, index) => index !== indexToRemove)
        );
    };

    const handleAddLanguage = () => {
        setLanguages([...languages, {}])
    }

    const handleAddSkill = () => {
        setSkills([...skills, {}])
    }



    return (
        <Box>
            <Header />
            <div className='childBox'>
                <div className='box1'>
                    <CreateResumeLeftBox
                        templateId={resumeId}
                        personalDetails={personalDetails}
                        setPersonalDetails={setPersonalDetails}
                        professionalSummary={professionalSummary}
                        setProfessionalSummary={setProfessionalSummary}
                        employmentHistory={employmentHistory}
                        setEmploymentHistory={setEmploymentHistory}
                        education={education}
                        setEducation={setEducation}
                        social={social}
                        setSocial={setSocial}
                        skills={skills}
                        setSkills={setSkills}
                        languages={languages}
                        setLanguages={setLanguages}
                        projects={projects}
                        setProjects={setProjects}
                        profileImg={profileImg}
                        setProfileImg={setProfileImg}
                        expanded={expanded}
                        setExpanded={setExpanded}
                        handleChange={handleChange}
                        handleAddEducation={handleAddEducation}
                        handleAddProject={handleAddProject}
                        handleAddLanguage={handleAddLanguage}
                        handleRemoveEducation={handleRemoveEducation}
                        handleRemoveExperience={handleRemoveExperience}
                        handleRemoveProject={handleRemoveProject}
                        handleAddExperience={handleAddExperience}
                        handleAddSkill={handleAddSkill}
                        trigger={trigger}
                        setTrigger={setTrigger}
                        handleGeneratePdf={handleGeneratePdf}
                    />
                </div>
                <div className='box2'>

                    <ResumeBox>
                        {resumeId === 'berlin' && <div ref={resume1TemplateRef}><Resume1 personalDetails={personalDetails} professionalSummary={professionalSummary} employmentHistory={employmentHistory} education={education} social={social} skills={skills} languages={languages} projects={projects} profileImg={profileImg} /></div>}
                        {resumeId === 'tokyo' && <div ref={resume2TemplateRef}><Resume2 personalDetails={personalDetails} professionalSummary={professionalSummary} employmentHistory={employmentHistory} education={education} social={social} skills={skills} languages={languages} projects={projects} profileImg={profileImg} /></div>}
                        {resumeId === 'nairobi' && <div ref={resume3TemplateRef}><Resume3 personalDetails={personalDetails} professionalSummary={professionalSummary} employmentHistory={employmentHistory} education={education} social={social} skills={skills} languages={languages} projects={projects} profileImg={profileImg} /></div>}
                        {resumeId === 'denver' && <div ref={resume4TemplateRef}><Resume4 personalDetails={personalDetails} professionalSummary={professionalSummary} employmentHistory={employmentHistory} education={education} social={social} skills={skills} languages={languages} projects={projects} profileImg={profileImg} /></div>}
                        {resumeId === 'rio' && <div ref={resume5TemplateRef}><Resume5 personalDetails={personalDetails} professionalSummary={professionalSummary} employmentHistory={employmentHistory} education={education} social={social} skills={skills} languages={languages} projects={projects} profileImg={profileImg} /></div>}
                        {/* {resumeId === 'moscow' && <div ref={resume6TemplateRef}><Resume6 personalDetails={personalDetails} professionalSummary={professionalSummary} employmentHistory={employmentHistory} education={education} social={social} skills={skills} languages={languages} projects={projects} profileImg={profileImg} /></div>} */}
                        {resumeId === 'martin' && <div ref={resume7TemplateRef}><Resume7 personalDetails={personalDetails} professionalSummary={professionalSummary} employmentHistory={employmentHistory} education={education} social={social} skills={skills} languages={languages} projects={projects} profileImg={profileImg} /></div>}
                        {resumeId === 'professor' && <div ref={resume8TemplateRef}><Resume8 personalDetails={personalDetails} professionalSummary={professionalSummary} employmentHistory={employmentHistory} education={education} social={social} skills={skills} languages={languages} projects={projects} profileImg={profileImg} /></div>}
                        {resumeId === 'oslo' && <div ref={resume9TemplateRef}><Resume9 personalDetails={personalDetails} professionalSummary={professionalSummary} employmentHistory={employmentHistory} education={education} social={social} skills={skills} languages={languages} projects={projects} profileImg={profileImg} /></div>}

                    </ResumeBox>
                </div>
            </div>
        </Box>
    )
}

export default CreateResumePage

const Box = styled.div`
width: 100%;
display: flex;


.childBox {
    margin-top: 4rem;
    width: 100%;
    display: flex;
}

.box1 {
    width: 50%;
    background-color: lightgrey;
}

.box2 {
    width: 50%;
    height: 100vh;
    background-color: lightgrey;
    display: flex;
    align-items: start;
    left: 50%;
    position: fixed;
}

`

const ResumeBox = styled.div`
margin: 1rem auto;
height: 95%;
width: 98%;
overflow-y: auto;
border-radius: 0.5rem;


& {
    scrollbar-width: none;
  }  

  &::-webkit-scrollbar {
    width: 0rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`
