import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  display: flex;
  align-items: center;
  border: 0.05rem solid lightgrey;
  padding: 0.5rem 0.75rem;
  background-color: white;
  cursor: pointer;
  border-radius: 0.25rem;
  font-size: 0.9rem;
  font-weight: 600;


  svg {
    height: 1rem;
    width: 1rem;
  }

  span {
    margin-left: 0.5rem;
  }
`;

const FilterButton = ({ onClick, children }) => {
    return <Button onClick={onClick}>{children}</Button>;
};

export default FilterButton;
