import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Autocomplete, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from "@mui/material";
import AcademicHeader from "../AcademicHeader";
import AcademicNumberCounter from "../CommonComponents/AcademicNumberCounter";
import { Stack } from "@mui/system";
import { getCoursesByCategory, getSubjectsByCourse, getTopicsBySubject } from "../../../utils/academicData";
import { createAcademicTest } from "../../../functions/api/academicAPIs/createAcademicTest";
import { clearError, resetTestData } from "../../../slices/academicTestSlice";
import { useDispatch } from "react-redux";
import AcademicLoader from "../CommonComponents/AcademicLoader";
import Favicon from "react-favicon";
import testFavicon from "../../../assets/Academic/examPrep.ico"


const PracticeTest = () => {
  const [numberOfQue, setNumberOfQue] = useState(5);
  const [testType, setTestType] = useState("");
  const [courses, setCourses] = useState("");
  const [subject, setSubject] = useState('');
  const [topics, setTopics] = useState([]);
  const [difficulty, setDifficulty] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  const accessToken = useSelector(state => state?.auth?.userData?.accessToken);
  const clientCode = useSelector(state => state?.auth?.userData?.user?.clientCode) || useSelector(state => state.auth.userData?.academicUser?.clientCode);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [courseList, setCourseList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const { courseCategory, course } = useParams();


  useEffect(() => {
    if(course !== 'all'){
      setTimeout(() => {
        setCourses(course)
      }, 500)
    }
  }, [course])

  useEffect(() => {
    const allCourses = getCoursesByCategory(courseCategory);
    setCourseList(allCourses);
  }, [courseCategory])

  useEffect(() => {
    const allSubjects = getSubjectsByCourse(courseCategory, courses);
    setSubjectList(allSubjects);
  }, [courseCategory, courses])

  useEffect(() => {
    const allTopics = getTopicsBySubject(courseCategory, courses, subject);
    setTopicList(allTopics);
  }, [courseCategory, courses, subject])

  useEffect(() => {
    if (!accessToken || !clientCode) {
      toast.warning("Login First", 5000);
      navigate("/login");
    }
  }, []);

  const handleTestTypeChange = (inp) => {
    setTestType(inp);
    setNumberOfQue(5);
  };

  const handleIncreaseNumber = () => {
    if (numberOfQue < 30 && testType !== 'coding') {
      setNumberOfQue(prevNum => prevNum + 1);
    }
  }

  const handleDecreaseNumber = () => {
    if (numberOfQue > 0) {
      setNumberOfQue(prevNum => prevNum - 1);
    }
  }

  const handleSubjectChange = (_, newSubjects) => {
    setSubject(newSubjects);
    setTopics([]);
  }

  const handleTopicChange = (_, newTopics) => {
    setTopics(newTopics);
  }

  const isNDA = () => {
    return courses === "NDA";
  }

  const handleCreateTest = async (e) => {
    e.preventDefault();
    dispatch(clearError());
    dispatch(resetTestData())
    try {
      setLoaderMessage("Creating Test... please wait");
      setIsLoading(true);

      if (courses === '') {
        toast.warning("Please Select Course", 5000)
        setIsLoading(false);
        setLoaderMessage("");
        return;
      } else if (subject === '') {
        toast.warning("Please Select Subject", 5000)
        setIsLoading(false);
        setLoaderMessage("");
        return;
      } else if (topics.length === 0 && !isNDA()) {
        toast.warning("Please Select Topics", 5000)
        setIsLoading(false);
        setLoaderMessage("");
        return;
      } else if (testType === '' && !isNDA()) {
        toast.warning("Please Select Test type", 5000)
        setIsLoading(false);
        setLoaderMessage("");
        return;
      } else if (difficulty === '' && !isNDA()) {
        toast.warning("Please Select Difficulty level", 5000)
        setIsLoading(false);
        setLoaderMessage("");
        return;
      } else if (numberOfQue === 0 && !isNDA()) {
        toast.warning("Please Select Number of Questions", 5000)
        setIsLoading(false);
        setLoaderMessage("");
        return;
      }

      const payload = {
        chapter: topics?.join(",")?.trim()?.toLowerCase(),
        course: courses?.toLowerCase(),
        noOfQuestions: numberOfQue,
        subject: subject?.toLowerCase(),
        testType: testType,
      };

      const ongoing = await createAcademicTest(payload, accessToken)

      if (ongoing?.id) {
        navigate(`/academic/create-test/${ongoing?.id}/start`)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
      navigate("/dashboard/academic");
    }
  }


  return <MainContainer>
        <Favicon url={testFavicon}/>
    <AcademicHeader />
    <Container>
      {isLoading ? (
        <AcademicLoader message={loaderMessage} />
      ) : (<>
        <div className="mainBox">
          <div className="step2Box">
            <div className="textBox">
              <FormControl sx={{ backgroundColor: '#ffffff', width: "100%" }} fullWidth>
                <InputLabel id="demo-simple-select-label">Select Course</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={courses}
                  label="Select Course"
                  disabled={course !== 'all'}
                  onChange={(e) => {
                    setCourses(e.target.value);
                    setSubject('');
                    setTopics([]);
                  }}
                >
                  {
                    courseList?.map((course, i) => (
                      <MenuItem value={course?.name}>{course?.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>

              <Stack spacing={3} sx={{ width: "100%", }} fullWidth>
                <Autocomplete
                  id="tags-standard"
                  options={subjectList}
                  getOptionLabel={(option) => option}
                  onChange={handleSubjectChange}
                  value={subject}
                  selectOnFocus
                  fullWidth
                  PaperComponent={(props) => <Paper {...props} />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Subject"
                    />
                  )}
                />
              </Stack>
            </div>
            {!isNDA() && <div className="textBox">
              <Stack spacing={3} sx={{ width: "103%" }} fullWidth>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={topicList}
                  getOptionLabel={(option) => option}
                  onChange={handleTopicChange}
                  value={topics}
                  selectOnFocus
                  fullWidth
                  PaperComponent={(props) => <Paper {...props} />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Topics"
                      sx={{ width: "100%" }}
                    />
                  )}
                />
              </Stack>
              <div className="inputBox">
                <span className="title">Test Type</span>
                <div className="childInputBox">
                  <label className="label">
                    <input
                      type="radio"
                      value="mcq"
                      checked={testType === 'mcq'}
                      onChange={() => handleTestTypeChange('mcq')}
                    />
                    <span>MCQs</span>
                  </label>
                  <label className="label">
                    <input
                      type="radio"
                      value="subjective"
                      checked={testType === 'subjective'}
                      onChange={() => handleTestTypeChange('subjective')}
                    />
                    <span>Subjective</span>
                  </label>
                </div>
              </div>
            </div>}
            {!isNDA() && <div className="textBox">
              <div className="inputBox">
                <span className="title">Difficulty Level</span>
                <div className="childInputBox">
                  <label className="label">
                    <input
                      type="radio"
                      value="fundamental"
                      checked={difficulty === 'fundamental'}
                      onChange={() => setDifficulty('fundamental')}
                    />
                    <span>Fundamental</span>
                  </label>
                  <label className="label">
                    <input
                      type="radio"
                      value="advance"
                      checked={difficulty === 'advance'}
                      onChange={() => setDifficulty('advance')}
                    />
                    <span>Advance</span>
                  </label>
                </div>
              </div>
              <div className="numberMainBox">
                <label className="label">Number of Questions</label>
                <AcademicNumberCounter handleDecreaseNumber={handleDecreaseNumber} handleIncreaseNumber={handleIncreaseNumber} value={numberOfQue} handleChange={(value) => setNumberOfQue(value)} testType={testType} style={{ width: '40%' }} />
              </div>
            </div>}
          </div>
        </div></>)}
    </Container>
    <button className='btn' onClick={handleCreateTest}>Start Practice Test</button>
  </MainContainer>
}


export default PracticeTest;

const MainContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;

.btn{
    background-color: var(--backgroundColor);
      color: var(--color);
      padding: 0.5rem 0.8rem;
      border: 0.1rem solid var(--academicLavendar);
      border-radius: 0.4rem;
      font-size: 0.9rem;
      font-weight: 600;
      cursor: pointer;
      font-family: var(--font);
      align-self: center;
      margin-bottom: 2rem;
  }

`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4.5rem 3rem 2rem 3rem;
  align-items: center;
  width: 100%;
  justify-content: space;
  box-sizing: border-box;

 

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.maintitle{
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1rem auto ;
}

.numberMainBox {
  width: 105%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: -0.75rem;

  .label {
    font-size: 0.9rem;
    font-weight: 500;
  }
}



.inputCont {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    width: 100%;
  }

  .label1 {
    font-size: 0.9rem;
    font-weight: 600;
    position: absolute;
    top: 0.8rem;
    left: 1rem;
    background-color: var(--white);
    color: #757575;
    padding: 0 0.5rem;
  }

  textarea {
    box-sizing: border-box;
    width: 100%;
    border: 0.08rem solid #C4C4C4;
    border-radius: 0.5rem;
    padding: 0.75rem 0.5rem;
    font-size: 1rem;
    box-sizing: border-box;
  }


  .numberBox {
    width: 40%;
    height: 3rem;
    display: flex;

    .numberBtn {
      height: 100%;
      width: 3.5rem;
      border: none;
      background-color: var(--academicLavendar);
      color: var(--white);
      font-size: 1.4rem;
      cursor: pointer;
    }

    .btn1 {
      border-top-left-radius: 0.4rem;
      border-bottom-left-radius: 0.4rem;
      font-family: var(--font);
    }

    .btn2 {
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
      font-family: var(--font);
    }

    .numberInput {
      height: 100%;
      width: 6rem;
      padding: 0 2.3rem;
      box-sizing: border-box;
      font-size: 0.9rem;
      font-weight: 500;
      border: none;
      outline: none;
      background-color: #F0F0F0;
      font-family: var(--font);
    }

  }

  .prev {
    background-color: var(--academicLavendar);
    padding: 0.1rem;
    position: fixed;
    top: 5rem;
    left: 1.5rem;
    color: var(--white);
    font-family: var(--font);
  }

  .prev:hover {
    color: var(--color);
  }

  .btn {
    padding: 0.5rem 1rem;
    margin-top: 0rem;
    background-color: var(--academicLavendar);
    border: none;
    color: var(--white);
    font-size: 1.1rem;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: pointer;
    font-family: var(--font);
  }

  .smallTextBox {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: start;
    padding-left: 2rem;
  }

  .textBox {
    display: flex;
    align-items: start;
    width: 100%;
    gap: 2rem;

  }
  
  .smallText {
    font-size: 0.8rem;
  }

  .mainBox {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    align-items: center;
    width: 100%;


    .step2Box {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      padding: 0rem 1rem;
      margin-top: 4rem;
      margin-bottom: 1rem;
      box-sizing: border-box;

      .inputBox {
        width: 100%;
        display: flex;
        gap: 0.5rem;
        border: 0.08rem solid #C4C4C4;
        padding: 1rem 1rem;
        border-radius: 0.3rem;
        position: relative;
        box-sizing: border-box;
      }

      .childInputBox {
        display: flex !important; 
        flex-direction: row;
        gap: 5rem;
      }

      .multipleNumberMainBox {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        .label {
            font-size: 0.9rem;
        }

        .queTypeCard {
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
          align-items: center;
          width: 25%;
        }
      }

      .title {
        font-size: 0.8rem;
        font-weight: 400;
        position: absolute;
        top: -0.8rem;
        background-color: var(--white);
        padding: 0 0.3rem;
      }
    }
    
  }
  
.label {
	display: flex;
	cursor: pointer;
	font-weight: 500;
	position: relative;
	margin-bottom: 0rem;

	input {
		position: absolute;
		left: -9999px;
		&:checked + span {
			background-color: #f0f0f6;
			&:before {
				box-shadow: inset 0 0 0 0.3rem var(--academicLavendar);
			}
		}
	}
	span {
		display: flex;
		align-items: center;
    font-size: 0.9rem;
		padding: 0.3rem 0.75rem 0.3rem 0.3rem;
		border-radius: 99rem; // or something higher...
		transition: 0.25s ease;
		&:hover {
			background-color: mix(#fff, var(--academicLavendar), 84%);
		}
		&:before {
			display: flex;
			flex-shrink: 0;
			content: "";
			background-color: #fff;
			width: 1rem;
			height: 1rem;
			border-radius: 50%;
			margin-right: 0.375em;
			transition: 0.25s ease;
			box-shadow: inset 0 0 0 0.125em var(--academicLavendar);
		}
	}
}
`;


