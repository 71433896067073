import React from 'react';
import styled from 'styled-components';

const TestimonialsSectionWrapper = styled.section`
  padding: 2rem;
  background-color: #d4edda;
  text-align: center;
`;

const TestimonialList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const Testimonial = styled.div`
  flex: 1;
  margin: 1rem;
  padding: 1rem;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;

  p {
    margin-bottom: 0.5rem;
  }

  h4 {
    margin: 0;
    color: #155724;
  }
`;

const testimonials = [
    { name: 'Student 1', feedback: 'The AI mock tests really helped me understand my weak points and improve.' },
    { name: 'Student 2', feedback: 'The personalized learning paths were exactly what I needed to stay on track.' },
    { name: 'Student 3', feedback: 'The resources for competitive exams are top-notch!' },
];

const TestimonialsSection = () => (
    <TestimonialsSectionWrapper>
        <h2>Hear from Our Students</h2>
        <TestimonialList>
            {testimonials.map((testimonial, index) => (
                <Testimonial key={index}>
                    <p>{testimonial.feedback}</p>
                    <h4>- {testimonial.name}</h4>
                </Testimonial>
            ))}
        </TestimonialList>
    </TestimonialsSectionWrapper>
);

export default TestimonialsSection;
