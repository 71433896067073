import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Chart from "react-google-charts";
import { useSelector } from 'react-redux';
import { generateCandidateByRoundChartData, generateCandidatesStatusChartData, generateReqsChartData, generateSourceChartData } from '../../../../utils/dashboardReportData';
import { getDashboardAllData } from '../../../../functions/api/employers/getDashboardAllData';
import { toast } from 'react-toastify';


const EmpScheduledInterviewsReport = () => {
    const accessToken = useSelector(state => state?.auth?.userData?.accessToken);
    const clientCode = useSelector(state => state?.auth?.userData?.user?.clientCode);
    const [dashboardAllData, setDashboardAllData] = useState(null);

    useEffect(() => {
        async function getData() {
            try {
                const res = await getDashboardAllData(accessToken, clientCode);
                setDashboardAllData(res);
                if(res)console.log(res);
            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }
        getData();
    }, []);

    const ReqsStatus = {
        "OPEN Reqs": dashboardAllData?.openReqs,
        "Closed Reqs": dashboardAllData?.totalReqs - dashboardAllData?.openReqs
    }

    const structuredJdStatus = {
        "ACTIVE_JD": dashboardAllData?.jdStatus?.ACTIVE_JDS,
        "INACTIVE_JD": dashboardAllData?.jdStatus?.AVAILABLE_JD - dashboardAllData?.jdStatus?.ACTIVE_JDS
    }

    const { candidatesStatusData, candidatesStatusOptions } = generateCandidatesStatusChartData(dashboardAllData?.trackerStatus);
    const { reqsData, reqsOptions } = generateReqsChartData(structuredJdStatus, ReqsStatus);
    const { candidateByRoundData, candidateByRoundOptions } = generateCandidateByRoundChartData(dashboardAllData?.jdRoundsInfos);
    const { sourceData, sourceOptions } = generateSourceChartData(dashboardAllData?.source);


    return (
        <Box>
            <div className='box1'>
                <div className='middle'>
                    <div className='mainCard child1'>
                        <span className='title'>
                            <span className='text'>JD and Reqs Status</span>
                        </span>
                        <div className='innerBox'>
                            <Chart chartType="ColumnChart" width="33vw" height="30rem" data={reqsData} options={reqsOptions} />
                        </div>
                    </div>
                </div>
                <div className='right'>
                    <div className='mainCard child1'>
                        <span className='title'>
                            <span className='text'>Candidates Status</span>
                        </span>

                        <div className='innerBox'>
                            <Chart
                                chartType="PieChart"
                                width="33vw"
                                height="33vw"
                                data={candidatesStatusData}
                                options={candidatesStatusOptions}
                                className="donut-chart"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='box3'>
                <div className='mainCard middle'>
                    <span className='title'>
                        <span className='text'>Candidates in Each Round</span>
                    </span>
                    <Chart chartType="ColumnChart" width="33vw" height="20rem" data={candidateByRoundData} options={candidateByRoundOptions} />
                </div>
                <div className='mainCard right'>
                    <span className='title'>
                        <span className='text'>Candidate Source</span>
                    </span>

                    <Chart chartType="ColumnChart" width="33vw" height="20rem" data={sourceData} options={sourceOptions} />
                </div>
            </div>
        </Box>
    )
}

export default EmpScheduledInterviewsReport


const Box = styled.div`
width: 98%;
margin: 0.5rem auto;
display: flex;
flex-direction: column;
box-sizing: border box;
gap: 1rem;
background-color: var(--white);
padding: 0.5rem;
border-radius: 0.5rem;
box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);


.mainCard {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 1.5rem 1rem;
    gap: 2rem;
    border-radius: 0.5rem;
    // box-shadow: 0.05rem 0.075rem 0.1rem 0.05rem rgba(0, 0, 0, 0.1);
    border: 0.025rem solid lightgrey;
    box-sizing: border-box;
    width: 50%;


    .title {
        font-size: 1rem;
        font-weight: 600;
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;


        .text {
            font-size: 1rem;
            font-weight: 600;
        }
    }

    .innerBox {
        min-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;

        .innerBox1 {
            display: flex;
            gap: 0.75rem;
            flex-flow: row wrap;

            .moreText {
                font-size: 0.8rem;
                font-weight: 500;
            }
        }
    
        .innerBox2 {

        }
    }
}


.box1 {
    width: 100%;
    display: flex;
    gap: 1.5%;

.middle {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .child1 {
        width: 100%;
    }
    .child2 {
        width: 100%;
    }
}

.right {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .child1 {
        width: 100%;

        .title {
            font-size: 1rem;
            font-weight: 600;
        }

    }

    .child2 {
        width: 100%;
        gap: 3rem;

        .innerChild1 {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;

            .title {
                font-size: 1.5rem;
                font-weight: 600;
            }

            .text {
                font-size: 1rem;
                font-weight: 500;
            }
        }

        .innerChild2 {
            width: 100%;
            display: flex;
            gap: 0.5rem;
            justify-content: space-between;
            align-items: start;
            

            .number {
                font-size: 1.5rem;
                font-weight: 600;
            }

            .text {
                font-size: 0.9rem;
                font-weight: 500;
                text-align: center;
            }

            .left {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
            }

            .right {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
            }
        }


        
    }
}

}

.box3 {
    width: 100%;
    display: flex;
    gap: 1.5%;
    justify-content: space-between;
    box-sizing: border-box;

    .middle {
        width: 50%;
    }

    .right {
        width: 50%;
    }
}

`