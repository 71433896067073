import axios from "axios"

export const updateTestStatus = async (id, data, accessToken) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    };

    const requestData = {
        data,
        op: "statusUpdate",
    };

    try {
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/academic/${id}`, requestData, config);
        console.log('Data:', response);
        return response.data;
    } catch (error) {
        throw error;
    }
}