import axios from "axios"
import { toast } from "react-toastify"


export const auth = async (password, email, clientcode, portalType) => {
  const requestData = {
    password: password,
    username: email
  };

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-client-code': clientcode
    }
  };

  try {
    let url;
    if(portalType === 'REGORTALENT'){
      url = `${process.env.REACT_APP_API_URL}/api/auth/login`
    } else if(portalType === 'EXAMPAPER'){
      url = `${process.env.REACT_APP_API_URL}/api/academic/auth/login`
    }
    const response = await axios.post(url, requestData, config);
    console.log('Data:', response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
}