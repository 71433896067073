import { useState, useCallback } from 'react';


export const useTestEditing = () => {
    const [editingIndex, setEditingIndex] = useState(-1);
    const [editingTestId, setEditingTestId] = useState();
    const [editingTestData, setEditingTestData] = useState();
    const [testQuestionData, setTestQuestionData] = useState();

    const [testType, setTestType] = useState('');

    const handleEditTest = useCallback((index, id, test, testQuestions) => {
        setEditingIndex(index);
        setEditingTestId(id);
        setEditingTestData(test);
        setTestQuestionData(testQuestions)
    }, []);

    const handleTypeSelect = useCallback((type) => {
        setTestType(type);
    }, []);

    return { 
        editingIndex, 
        editingTestId,
        editingTestData,
        testQuestionData,
        testType, 
        handleEditTest, 
        handleTypeSelect, 
        setEditingIndex 
    };
};
