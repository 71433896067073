import React, { useState } from 'react';
import styled from 'styled-components';
import { Minus, Plus } from 'lucide-react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 0.25rem;
  width: 100%;
  margin: 0rem auto;
  background-color: white;
  border-radius: 0.5rem;
`;

const Title = styled.h2`
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

const ControlsContainer = styled.div`
  display: flex;
  align-items: start;
  gap: 0.75rem;

`;

const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  border: none;
  border-radius: 0.25rem;
  color: white;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;

  &:focus {
    outline: none;
    ring: 2px solid ${props => props.bgColor};
    ring-opacity: 0.5;
  }

  &:hover {
    background-color: ${props => props.hoverColor};
  }
`;

const Button1 = styled(Button)`
  background-color: var(--lightOrange);
`;


const ButtonText = styled.span`
  font-size: 0.8rem;
  font-weight: 600;
  margin-top: 0.2rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Input = styled.input`
  width: 3rem;
  padding: 0.25rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Label = styled.label`
  font-size: 0.75rem;
  color: #4b5563;
  margin-top: 0.25rem;
`;

const Separator = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  color: #4b5563;
  margin-bottom: 1.5rem;
`;

const InterviewDurationConfig = ({duration, setDuration}) => {
    const adjustTime = (amount) => {
        let newMinutes = duration.minutes + amount;
        let newHours = duration.hours;

        if (newMinutes >= 60) {
            newHours += Math.floor(newMinutes / 60);
            newMinutes %= 60;
        } else if (newMinutes < 0) {
            newHours -= 1;
            newMinutes = 60 + newMinutes;
        }

        setDuration({
            hours: Math.max(0, newHours),
            minutes: Math.max(0, Math.min(59, newMinutes))
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let numValue = parseInt(value) || 0;

        if (name === 'hours') {
            numValue = Math.max(0, numValue);
        } else if (name === 'minutes') {
            numValue = Math.max(0, Math.min(59, numValue));
        }

        setDuration(prev => ({ ...prev, [name]: numValue }));
    };

    return (
        <Container>
            <Title>Interview Duration</Title>
            <ControlsContainer>
                <Button1 onClick={() => adjustTime(-15)}>
                    <Minus size={14} />
                    <ButtonText>-15 mins</ButtonText>
                </Button1>
                <InputContainer>
                    <InputWrapper>
                        <InputContainer>
                            <Input
                                type="number"
                                name="hours"
                                value={duration.hours}
                                onChange={handleInputChange}
                                min="0"
                            />
                            <Label>Hours</Label>
                        </InputContainer>
                        <Separator>:</Separator>
                        <InputContainer>
                            <Input
                                type="number"
                                name="minutes"
                                value={duration.minutes.toString().padStart(2, '0')}
                                onChange={handleInputChange}
                                min="0"
                                max="59"
                            />
                            <Label>Minutes</Label>
                        </InputContainer>
                    </InputWrapper>
                </InputContainer>
                <Button1 onClick={() => adjustTime(15)}>
                    <Plus size={14} />
                    <ButtonText>+15 mins</ButtonText>
                </Button1>
            </ControlsContainer>
        </Container>
    );
};

export default InterviewDurationConfig;