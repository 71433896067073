import React from 'react';
import styled from 'styled-components';
import homepage1 from '../../assets/homepage1.webp';
import homepage3 from '../../assets/homepage3.webp';
import productCard1 from '../../assets/homepage2.webp';
import productCard2 from '../../assets/homepage4.webp';
import productCard3 from '../../assets/homepage6.webp';
import bg1 from '../../assets/bg1.webp';
import bg2 from '../../assets/bg5.webp';
import { useNavigate } from 'react-router';
import BenefitsSection from './CommonComponents/BenefitsSection';
import ProductIntroVideo from './CommonComponents/ProductIntroVideo';
import ProductCard from './CommonComponents/ProductCard';
import VisualDetailImgBox from './CommonComponents/VisualDetailImgBox';
import NumberBox from './CommonComponents/NumberBox';

const DetailedContent = () => {
  const navigate = useNavigate();

  const topBoxContent = [
    {
      number: 1,
      title: 'AI Powered Systems',
      text: 'RegorTalent provide you an edge with its AI enabled tools to power your HR systems.',
    },
    {
      number: 2,
      title: 'Automate Manual Tasks',
      text: 'Leave behind time and bandwidth consuming manual hiring processed to utilize your manpower better and getthing things done with few clicks.',
    },
    {
      number: 3,
      title: 'Streamline Processes',
      text: 'Simplify complex recruitment processes for smooth operations.',
    },
    {
      number: 4,
      title: 'Speed Up Growth',
      text: 'We know what matter most and that where all we build aims to fuel your growth.',
    },
  ];

  const visualDetailsContent = [
    {
      title: 'Efficient Sourcing',
      text: 'One of the most time-consuming tasks in recruitment is reviewing resumes and job applications. AI-powered resume screening systems can significantly improve this process. These systems use natural language processing (NLP) and machine learning algorithms to quickly analyze and rank resumes based on their relevance to the job description.',
      imgSrc: homepage1,
    },
    {
      title: 'Processes to Fuel Growth',
      text: "To fully leverage the growth potential of AI in hiring processes, it's essential to choose the right AI tools and platforms, integrate them seamlessly into your existing systems, and provide appropriate training to HR professionals to use these tools effectively. Additionally, continuous monitoring and refinement of AI-driven processes are crucial to ensure ongoing success and alignment with your organization's growth goals.",
      imgSrc: homepage3,
    },
  ];

  const numberData = [
    { number: 1500, text: 'Registered Job Seekers' },
    { number: 30, text: 'Employers on Board' },
    { number: 4500, text: 'Interviews Completed' },
    { number: 150, text: 'Processes Automated' }
  ];


  return (
    <StyledBox>
      <TextBox>
        <Title>Empower Your Hiring Ecosystem</Title>
        <Text>
          RegorTalent enables you with tools powered by AI & system that give you an edge, speed and cost effectiiveness on your regular and special hirng neeeds.
        </Text>
      </TextBox>

      <TopBox>
        {topBoxContent.map((item, index) => (
          <TopBoxChild key={index}>
            <TopBoxChildNumber>{item.number}</TopBoxChildNumber>
            <TopBoxChildTitle>{item.title}</TopBoxChildTitle>
            <TopBoxChildText>{item.text}</TopBoxChildText>
          </TopBoxChild>
        ))}
      </TopBox>

      <ProductIntroVideo />

      <VisualDetailSection>
        <VisualDetailBox>
          <VisualDetailTextBox>
            <VisualDetailTitle>{visualDetailsContent[0].title}</VisualDetailTitle>
            <VisualDetailText>{visualDetailsContent[0].text}</VisualDetailText>
          </VisualDetailTextBox>
          <VisualDetailImgBox imgSrc={visualDetailsContent[0].imgSrc} altText={visualDetailsContent[0].title} />
        </VisualDetailBox>
        <VisualDetailBox>
          <VisualDetailImgBox imgSrc={visualDetailsContent[1].imgSrc} altText={visualDetailsContent[1].title} />
          <VisualDetailTextBox>
            <VisualDetailTitle>{visualDetailsContent[1].title}</VisualDetailTitle>
            <VisualDetailText>{visualDetailsContent[1].text}</VisualDetailText>
          </VisualDetailTextBox>
        </VisualDetailBox>
      </VisualDetailSection>

      <BenefitsSection />

      <ContactSection>
        <ContactTitle>Become part of advanced Recruitment Solutions powered by AI, same time with Human Touch in your journey of growth.</ContactTitle>
        <ContactButton onClick={() => navigate('/contact')}>Contact Us</ContactButton>
      </ContactSection>

      <AchievedNumbers>
        {numberData.map((item, index) => (
          <NumberBox key={index} number={item.number} text={item.text} />
        ))}
      </AchievedNumbers>

      <ProductSection>
        <ProductTitle>What’s the Latest We Have Built</ProductTitle>
        <ProductText>
          Explore our blog for insightful articles, personal reflections, and ideas that inspire action on the topics you care about.
        </ProductText>
        <ProductCards>
          <ProductCard product={{ name: 'Product 1', imageUrl: productCard1, description: "AI benefits with human touch" }} route="/product/talentview" />
          <ProductCard product={{ name: 'Product 2', imageUrl: productCard2, description: "Streamline and Automate processes for better Opex" }} route="/product/talentsource" />
          <ProductCard product={{ name: 'Product 3', imageUrl: productCard3, description: "Technology and innovation that will inspire you" }} route="/product/talentboard" />
        </ProductCards>
      </ProductSection>

      <BottomContactSection>
        <BottomContactTitle>Ready to Get Started?</BottomContactTitle>
        <BottomContactText>RegorTalent is a highly automated set of AI tools and systems. Let your start your journey to build team of best talent available!</BottomContactText>
        <BottomContactButton onClick={() => navigate('/signup')}>Try Now</BottomContactButton>
      </BottomContactSection>
    </StyledBox>
  );
};

export default DetailedContent;

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5rem;
`;

const TextBox = styled.div`
  text-align: center;
  margin: 2rem 0;
  gap: 1.5rem;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
`;

const Text = styled.p`
  font-size: 1rem;
  font-weight: 400;
  width: 60%;
  margin: 0 auto;
  line-height: 1.5rem;
`;

const TopBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0 5%;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

const TopBoxChild = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 22%;
`;

const TopBoxChildNumber = styled.div`
  font-size: 1.5rem;
  background: var(--lightOrange);
  color: white;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopBoxChildTitle = styled.h2`
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
`;

const TopBoxChildText = styled.p`
  text-align: center;
  font-size: 0.9rem;
  line-height: 1.5rem;
`;

// Visual Detail Section
const VisualDetailSection = styled.div`
display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
    margin-left: 5%;
    margin-right: 5%;

`;

const VisualDetailBox = styled.div`
  display: flex;
    flex-direction: row;
    gap: 5%;
`;

const VisualDetailTextBox = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0rem;
`;

const VisualDetailTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

const VisualDetailText = styled.p`
  line-height: 1.5rem;
`;

// Contact Section Styling
const ContactSection = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--lightOrange);
    justify-content: center;
    align-items: center;
    padding: 3rem 7.5%;
    width: 85%;
    gap: 1rem;
    background-image: url(${bg1});
    background-size: cover;
    background-repeat: no-repeat; 
`;

const ContactTitle = styled.h3`
  color: white;
  font-size: 1.5rem;
  font-weight: 800;
  text-align: center;
`;


const ContactButton = styled.button`
  background-color: white;
  color: var(--color);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0rem;
`;

// Achieved Numbers Styling
const AchievedNumbers = styled.div`
display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 5% 2rem 5%;
`;



// Product Section Styling
const ProductSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 5rem;
    gap: 1rem;

`;

const ProductTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 1rem;
  margin: 0;
  padding: 0;
`;

const ProductText = styled.p`
    font-size: 1rem;
    line-height: 1rem;
    width: 60%;
    text-align: center;
    font-weight: 400;
    margin: 0;
    padding: 0;

`;

const ProductCards = styled.div`
  display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 2rem;
    padding: 0rem 2rem;

`;

// Bottom Contact Section Styling
const BottomContactSection = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--orange);
    justify-content: center;
    align-items: center;
    padding: 4rem 0%;
    width: 94%;
    margin: 0% 3%;
    gap: 2rem;
    border-radius: 1rem;
    margin-bottom: 5rem;
    background-image: url(${bg2});
    background-size: cover;
    background-repeat: no-repeat; 

`;

const BottomContactTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 800;
  color: white;
  text-align: center;
  margin: 0;
`;

const BottomContactText = styled.p`
    font-size: 1rem;
    font-weight: 400;
    color: var(--white);
    text-align: center;
    width: 60%;
    line-height: 1.5rem;
    margin: 0;

`;

const BottomContactButton = styled.button`
  background-color: white;
  color: var(--color);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
`;
