import React from 'react'
import styled from 'styled-components';

const Label = ({ bgColor, borderColor, borderRadius, text, title, isTitle, fontType, width }) => {
    return (
        <LabelWrapper
            bgColor={bgColor}
            borderColor={borderColor}
            borderRadius={borderRadius}
            fontType={fontType}
            width={width}
        >
            {isTitle && <Title>{title}</Title>}
            {text}
        </LabelWrapper>
    )
}

export default Label

const LabelWrapper = styled.div`
  padding: 0.3rem 0.75rem;
  background-color: ${({ bgColor }) => bgColor || '#e0e0e0'};
  border: 1px solid ${({ borderColor }) => borderColor || '#cccccc'};
  border-radius: ${({ borderRadius }) => borderRadius || '0.25rem'};
  font-weight: ${({ fontType }) => (fontType === 'bold' ? '600' : '400')};
  font-size: 0.9rem;
  color: #333;
  width: ${({width}) => width && width};
  display: flex;
  flex-flow: row-wrap;
  justify-content: center;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 0.9rem;

`;