import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const deleteQuestion = async (id, accessToken, clientCode) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'x-client-code': clientCode,
        },
    };

    try {
        const { data, status } = await axios.delete(`${API_URL}/api/tests/questions/${id}`, config);
        return data;
    } catch (error) {
        throw error;
    }
};


export const createQuestion = async (payload, accessToken, clientCode) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'x-client-code': clientCode
        }
    };

    try {
        const response = await axios.post(`${API_URL}/api/tests/questions`, payload, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const updateQuestion = async (payload, id, accessToken, clientCode) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'x-client-code': clientCode
        }
    };

    try {
        const response = await axios.put(`${API_URL}/api/tests/questions/${id}`, payload, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const getQuestions = async (accessToken, clientCode) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'x-client-code': clientCode
        }
    };

    try {
        const response = await axios.get(`${API_URL}/api/tests/questions`, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}

