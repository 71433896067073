import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getActivityLogs } from '../services/ActivityTrackerService';

const useActivityTracker = (accessToken, clientCode, filters, page, size, triggerActivityLogs) => {
    const [activities, setActivities] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchActivities = async () => {
            
            try {
                const data = await getActivityLogs(accessToken, clientCode, filters, page, size);
                if(data){
                    console.log(data);
                    setActivities(data);
                }
            } catch (error) {
                const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            } finally {
                setLoading(false);
            }
        };

        fetchActivities();
    }, [accessToken, clientCode, page, size, triggerActivityLogs]);

    return { activities, loading };
};

export default useActivityTracker;
