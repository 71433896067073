import axios from "axios"

export const register = async (email,firstName,password) => {
  let token = localStorage.getItem("token");
  let key = localStorage.getItem("key")
  const requestData = {
    email,
    firstName,
    password
  };

  if(token)requestData.token = token;
  
  console.log(requestData);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-client-code': 'regortalent'
    }
  };

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/signup`,requestData,config);
        console.log('Data:', response.data);
        if(key == 'interview' && token){
          localStorage.removeItem("token");
          localStorage.removeItem("key");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
}