import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import styled from 'styled-components'
import { getBlobData } from '../../../../functions/api/resume/getBlobData';
import { useSelector } from 'react-redux';
import { getFeedback } from '../../../../functions/api/interview/getFeedback';
import { Collapse, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ReportsGraphAccordian from '../seekerCommonComponents/ReportsGraphAccordian';



const Row = (props) => {
    const { index, title, description, rating, comments, isOpen, toggleAccordion } = props;
    const rowRef = useRef(null);

    const stringRating = rating === 1 ? "Doesn’t meet expectations" : rating === 2 ? "Needs a little more training" : rating === 3 ? "Slight fit" : rating === 4 ? "Meets expectations" : "Exceeds expectations";

    return (
        <RowBox>
            <TableRow sx={{ '& > *': { width: '100%' } }}>
                <TableCell align='left' className='rowTitle'>{title}</TableCell>
                <TableCell className='rowBtn'>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => toggleAccordion(index)}
                    >
                        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow ref={rowRef}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <ContentBox>
                            <span className='desc'><span className='boldText'>Rating:</span> {stringRating}</span>
                            <span className='desc'><span className='boldText'>Comments:</span> {comments}</span>
                        </ContentBox>
                    </Collapse>
                </TableCell>
            </TableRow>
        </RowBox>
    )
}



const SeekerInterviewDetails = ({ jdId, data, employer, email = "", proctoringResults }) => {
    const accessToken = useSelector((state) => state.auth.userData?.accessToken);
    const clientCode = useSelector(
        (state) => state.auth.userData?.user?.clientCode
    );
    const [feedbacks, setFeedbacks] = useState(null);
    const [openIndex, setOpenIndex] = useState(null);


    const toggleAccordion = (sectionIndex, accordionIndex) => {
        const newIndex = `section_${sectionIndex}_accordion_${accordionIndex}`;
        setOpenIndex((prevIndex) => (prevIndex === newIndex ? null : newIndex));
    };

    useEffect(() => {
        const getFeedbackDetails = async (jdId, resumeId, accessToken) => {
            try {
                const res = await getFeedback(jdId, resumeId, accessToken);
                if (res) {
                    setFeedbacks(res?.data);
                }
            } catch (error) {
                const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again."
                toast.error(errMsg);
            }
        }
        if (employer) {
            getFeedbackDetails(jdId, data?.resumeId, accessToken)
        }
    }, [employer])


    const handleDownload = async (id, name) => {
        try {
            if (!id) {
                toast.warning("No resume found or invalid resumeId", 5000);
                return;
            }
            const res = await getBlobData(
                `api/media/downloadById?fileType=resume&id=${id}`,
                accessToken,
                clientCode
            );
            const a = document.createElement("a");
            a.href = res;
            a.setAttribute("download", `${name}.pdf`);
            a.click();
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    };

    const handleViewDetails = (id) => {
        window.open(`/score/${id}`, '_blank');
    };

    const markers = [
        "b1: Browser not supported",
        "b2: Focus changed to a different window",
        "b3: Full-screen mode is disabled",
        "c1: Webcam is disabled",
        "c2: Face invisible or not looking into the camera",
        "c3: Several faces in front of the camera",
        "c4: Face does not match the profile",
        "c5: Found a similar profile",
        "k1: Atypical keyboard handwriting",
        "m1: Microphone muted or its volume is low",
        "m2: Conversation or noise in the background",
        "n1: No network connection",
        "n2: No connection to a mobile camera",
        "s1: Screen activities are not shared",
        "s2: Second display is used"
    ];



    return (
        <Box>
            <div className='box1'>
                <span className='title'>Basic Details</span>
                <div className='textBox'>
                    <div className='childBox'>
                        <span className='text'><span className='boldText'>Name:</span> {data?.userName || "..."}</span>
                        <span className='text'><span className='boldText'>Difficulty Level:</span> {data?.difficultyLevel || "..."}</span>
                        <span className='text'><span className='boldText'>No. of Questions:</span> {data?.noOfQuestions || "..."}</span>
                    </div>
                    <div className='childBox'>
                        <span className='text'><span className='boldText'>Email:</span> {data?.userEmail || "..."}</span>
                        <span className='text'><span className='boldText'>Interview Type:</span> {data?.interviewType || "..."}</span>
                        <span className='text'><span className='boldText'>Product Type:</span> {data?.productType || "..."}</span>
                    </div>
                    <div className='childBox'>
                        <span className='text'><span className='boldText'>TestType:</span> {data?.testType?.toUpperCase() || "..."}</span>
                        <span className='resumeText'><span className='boldText'>Resume:</span><button onClick={() => handleDownload(data?.resumeId, data?.userName)}>view resume</button></span>
                    </div>
                </div>
                <div className='textBox'>
                    <div className='childBox'>


                    </div>
                </div>
            </div>

            {employer &&
                <div className='resultBox'>
                    <span className="title">Interview Result</span>
                    {proctoringResults && proctoringResults?.map((interview, i) => (<div className='box2'>
                        <div className="box2Child">
                            <span className="scoreText">Round {interview?.stage} Score: {interview?.score}/{interview?.maxScore} <Button onClick={() => handleViewDetails(interview?.interviewId)} disabled={interview?.status !== "COMPLETED"}>View Details</Button></span>
                        </div>
                    </div>))}
                </div>}

            {employer && <div className='box2'>
                <span className='title'>Interview Feedbacks</span>
                <div className='box2Child'>
                    {
                        feedbacks?.map((feedback, index) => (
                            <div className='feedbackBox' key={index}>
                                <span className='mainText'><span className='title'>Feedback {index + 1}</span></span>
                                <span className='mainText'><span className='boldText'>Overall Feedback:</span> {feedback?.overallFeedback}</span>
                                <span className='mainText'><span className='boldText'>Recommendation:</span> {feedback?.recommendation}</span>

                                <div className='feedbackBoxChild'>
                                    <Row index={0} title="Education/Training" description="Candidate has the necessary education and/or training required for the position." rating={feedback?.educationRating} comments={feedback?.educationComments} isOpen={openIndex === `section_${index}_accordion_${0}`} toggleAccordion={() => toggleAccordion(index, 0)} />
                                    <Row index={1} title="Work Experience" description="Candidate has prior experience related to the position." rating={feedback?.workExperienceRating} comments={feedback?.workExperienceComments} isOpen={openIndex === `section_${index}_accordion_${1}`} toggleAccordion={() => toggleAccordion(index, 1)} />
                                    <Row index={2} title="Skills/Technical" description="Candidate demonstrates the required technical skills to perform the job successfully." rating={feedback?.skillsRating} comments={feedback?.skillsComments} isOpen={openIndex === `section_${index}_accordion_${2}`} toggleAccordion={() => toggleAccordion(index, 2)} />
                                    <Row index={3} title="Leadership Skills" description="Candidate has the leadership skills needed to perform the job successfully." rating={feedback?.leadershipRating} comments={feedback?.leadershipComments} isOpen={openIndex === `section_${index}_accordion_${3}`} toggleAccordion={() => toggleAccordion(index, 3)} />
                                    <Row index={4} title="Interpersonal Skills" description="Candidate shows ability to work collaboratively, communicate effectively and displays strong work ethic." rating={feedback?.interpersonalRating} comments={feedback?.interpersonalComments} isOpen={openIndex === `section_${index}_accordion_${4}`} toggleAccordion={() => toggleAccordion(index, 4)} />
                                    <Row index={5} title="Problem Solving" description="Candidate demonstrates the ability to develop innovative solutions and solve problems." rating={feedback?.problemSolvingRating} comments={feedback?.problemSolvingComments} isOpen={openIndex === `section_${index}_accordion_${5}`} toggleAccordion={() => toggleAccordion(index, 5)} />
                                    <Row index={6} title="Cultural Fit" description="Candidate’s attitude and behaviors are in alignment with Company’s core values and culture. Displays integrity and will contribute positively to our culture." rating={feedback?.culturalFitRating} comments={feedback?.culturalFitComments} isOpen={openIndex === `section_${index}_accordion_${6}`} toggleAccordion={() => toggleAccordion(index, 6)} />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            }

            {employer && <div className='box2'>
                <span className='title'>Proctoring Results</span>

                <ReportsGraphAccordian proctoringResults={proctoringResults} />
                <div>
                    {
                        markers?.map((item, index) => {
                            return <p className='markers'>{item}</p>
                        })
                    }
                </div>
            </div>}
        </Box>
    )
}

export default SeekerInterviewDetails


const Box = styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap: 2rem;



.box1 {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .title {
        font-size: 1rem;
        font-weight: 600;

    }

    .textBox {
        display: flex;
        flex-direction: row;
        gap: 3%;
        justify-content: space-between;

        .childBox {
            width: 30%;
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
        }


        .text {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
            font-size: 0.75rem;
            font-weight: 400;

            .boldText {
                font-size: 0.8rem;
                font-weight: 600;
            }
        }

        .resumeText {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
            font-size: 0.75rem;
            font-weight: 400;
            justify-content: start;
            align-items: start;

            .boldText {
                font-size: 0.8rem;
                font-weight: 600;
            }

            button {
                background-color: var(--white);
                border: 0.05rem solid  var(--color);
                border-radius: 0.3rem;
                cursor: pointer;
                padding: 0.25rem 0.5rem;
            }
        }
    }
}

.resultBox {
display: flex;
gap: 1rem;
flex-direction: column;

.title {
font-size: 1.1rem;
font-weight: 600;
font-family: var(--font);
}
}


.box2 {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 2rem;

    .title {
        font-size: 1.1rem;
        font-weight: 600;
        font-family: var(--font);
    }

    .box2Child {
        display: flex;
        flex-direction: column;
        gap: 2rem;


        .feedbackBox {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding: 1rem 1rem;
            border-radius: 0.5rem;
            box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.5);

            .mainText {
                font-size: 0.9rem;
                font-weight: 400;

                .boldText {
                    font-weight: 600;
                }

                .title {
                    font-size: 1.1rem;
                    font-weight: 600;
                    display: block;
                    padding-bottom: 0.5rem;
                }
            }

            .feedbackBoxChild {
                border: 0.05rem solid #e0e0e0;
                border-radius: 0.5rem;
                margin-top: 0.5rem;
            }
        }

        .scoreText {
         font-size: 0.9rem;
         font-weight: 600;

         display: flex;
         width: 50%;
         justify-content: space-between;
         align-items: center;
        }

        .btn {
         background-color: var(--lightOrange);
         border: none;
         cursor: pointer;
         padding: 0.5rem 0.8rem;
         border-radius: 0.5rem;
         color: var(--white);
         font-size: 0.9rem;
         font-weight: 600;
        }
    }
}


`

const Button = styled.button`
         background-color: var(--lightOrange);
         border: none;
         cursor: pointer;
         padding: 0.5rem 0.8rem;
         border-radius: 0.5rem;
         color: var(--white);
         font-size: 0.9rem;
         font-weight: 600;

         &:disabled {
        cursor: not-allowed;
        color: #F4F4F4;
    }

`


const RowBox = styled.div`
width: 100%;

.rowTitle {
    font-size: 0.9rem;
    font-weight: 600;
    font-family: var(--font);
    padding: 0 1rem;
}

.rowBtn {
    padding: 0.3rem 1rem;
}
`

const ContentBox = styled.div`
width: 96%;
display: flex;
flex-direction: column;
gap: 0.25rem;
padding: 0.5rem 0.5rem;

.desc {
    font-size: 0.9rem;
    font-weight: 400;
    font-family: var(--font);

    .boldText {
        font-weight: 600;
    }
}

`