import axios from "axios"
import { toast } from "react-toastify"


export const academicGoogleAuthentication = async (credentials, clientcode) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-client-code': clientcode
        }
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/academic/auth/google-login`, credentials, config);
        console.log('Data:', response.data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}