import { useState, useEffect } from "react";
import { getOnboardReqs } from "../services/onboardService";
import { toast } from "react-toastify";

const useFetchOnboardRequests = (accessToken, clientCode, status, page, size, onboardTrigger) => {
    const [onboardData, setOnboardData] = useState(null);
    const [onboardLoading, setOnboardLoading] = useState(true);

    useEffect(() => {
        const fetchOnboardData = async () => {
            setOnboardLoading(true);
            try {
                const result = await getOnboardReqs(accessToken, clientCode, status, page, size);
                if(result){
                    setOnboardData(result?.data?.data);
                }
            } catch (error) {
                const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            } finally {
                setOnboardLoading(false);
            }
        };

        fetchOnboardData();
    }, [accessToken, clientCode, status, page, size, onboardTrigger]);

    return { onboardData, onboardLoading };
};

export default useFetchOnboardRequests;
