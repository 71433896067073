import { useCallback, useState } from 'react';

export const useViewManagement = () => {
    const [currentView, setCurrentView] = useState('library');

    const handleViewChange = useCallback((view) => {
        if (view !== currentView) {
            setCurrentView(view);
        }
    }, [currentView]);

    return { currentView, handleViewChange };
};
