import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BillingTable from "./BillingTable";
import PrepaidBillingTop from "../commonComponents/PrepaidBillingTop";
import PostpaidBillingTop from "../commonComponents/PostpaidBillingTop";


export default function Billing() {
  const [paymentPreference, setPaymentPreference] = useState("postpaid")

  return (
    <StyledDiv>
      {paymentPreference === "prepaid" ? <PrepaidBillingTop/> : <PostpaidBillingTop/>}
      <ContentBox>
        <BillingTable />
      </ContentBox>
    </StyledDiv>
  );
}


const StyledDiv = styled.div`
margin: 1rem 0% 2rem 0%;
width: 100%;
padding: 0 auto;
display: flex;
flex-direction: column;
align-items: center;


`



const ContentBox = styled.div`
width: 100%;



`

