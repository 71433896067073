import { useState } from 'react';
import { updateOnboardStatus } from '../services/onboardService';
import { toast } from 'react-toastify';

const useUpdateOnboardStatus = (setOnboardTrigger) => {
    const [onboardUpdateLoading, setOnboardUpdateLoading] = useState(false);

    const updateStatus = async (accessToken, clientCode, userOnboardId, payload, setAssignedClientCode) => {
        setOnboardUpdateLoading(true);
        try {
            const result = await updateOnboardStatus(accessToken, clientCode, userOnboardId, payload);
            if(result){
                toast.success("User Onboard status changed to Approved");
                setOnboardTrigger(prev => !prev);
                setAssignedClientCode("");
            }
        } catch (error) {
            const errMsg = error?.response?.data?.notify?.message || error?.response?.data?.message || "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        } finally {
            setOnboardUpdateLoading(false);
        }
    };


    return { onboardUpdateLoading, updateStatus };
};

export default useUpdateOnboardStatus;
