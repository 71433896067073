import React, { useEffect } from 'react'
import styled from 'styled-components'
import RequestGroup from './RequestGroup'
import SearchBox from './SearchBox'
import { useNavigate, useParams } from 'react-router'
import JiraHeader from './JiraHeader'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPortalRequestTypes } from '../../../slices/atlassianSlice'
import { getRequestGroups } from '../../../utils/JiraHelpCenter'

const JiraHelpCenterLandingPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);
    const {portalId} = useParams();

    const handleGroupClickNavigation = (groupId) => {
        navigate(`/servicedesk/customer/portal/${portalId}/group/${groupId}`)
    }

    useEffect(() => {
        dispatch(fetchPortalRequestTypes({accessToken, clientCode, portalId}))
    }, [portalId])

    return (
        <MainBox>
            <JiraHeader/>
            <div className='topBox'>
                <div className='searchBox'>
                    <span className='title'>Welcome to the RegorTalent Help Center</span>
                    {/* <SearchBox /> */}
                </div>
            </div>
            <div className='bottomBox'>
                <div className='contentBox'>
                    <span className='boldText'>Raise a request</span>
                    <div className='requestGroupBox'>
                        {
                            getRequestGroups(portalId)?.map((request, i) => (
                                <RequestGroup groupId={request?.id} groupName={request?.name} handleGroupClickNavigation={handleGroupClickNavigation}/>
                            ))
                        }
                    </div>
                </div>
            </div>
        </MainBox>
    )
}

export default JiraHelpCenterLandingPage

const MainBox = styled.div`

display: flex;
flex-direction: column;
align-items: center;
gap: 2rem;
width: 100%;

.topBox {
    background: rgb(255,255,226);
background: linear-gradient(90deg, rgba(255,255,226,1) 0%, rgba(24,226,211,1) 50%, rgba(24,226,211,1) 100%);
    color: var(--white);
    width: 100%;
    height: 12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .searchBox {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;

        .title {
            font-size: 1.5rem;
            font-weight: 600;
        }
    }
}


.bottomBox {
    background-color: var(--white);
    color: var(--color);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10rem;

    .contentBox {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;


        .text {
            font-size: 0.9rem;
            font-weight: 500;
            font-family: var(--font);
        }

        .boldText {
            font-size: 1rem;
            font-weight: 700;
            font-family: var(--font);
            padding: 1rem 0;
        }

        .requestGroupBox {
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }
    }
}


`