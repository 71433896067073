import { useState } from 'react';
import { toast } from 'react-toastify';
import { createQuestion, deleteQuestion, updateQuestion } from '../services/questionService';

const useQuestionActions = (accessToken, clientCode, resetFormData, toggleView, editingQuestionId, setQuestionTrigger) => {
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deletingQuestionId, setDeletingQuestionId] = useState('');

    const [loadingCreate, setLoadingCreate] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);


    const handleDeleteClick = (questionId) => {
        setDeleteOpen(true);
        setDeletingQuestionId(questionId);
    };

    const handleDeleteQuestion = async () => {
        try {
            const res = await deleteQuestion(deletingQuestionId, accessToken, clientCode);
            if (res) {
                toast.success('Question deleted successfully', { autoClose: 5000 });
                setQuestionTrigger(prev => !prev);
            }
        } 
        catch (error) {
            const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        } 
        finally {
            setDeleteOpen(false);
        }
    };


    const handleCreateQuestion = async (formData) => {
        try {
            let updatedFormData = { ...formData };

            if (updatedFormData?.questionType === 'mcq') {
                const matchingOptionIndex = updatedFormData?.mcqOptions?.findIndex(option => option?.toLowerCase()?.trim() === updatedFormData?.mcqAnswer?.toLowerCase()?.trim());

                if (matchingOptionIndex !== -1) {
                    const letter = String.fromCharCode(65 + matchingOptionIndex);
                    updatedFormData.mcqAnswer = `${letter}. ${updatedFormData?.mcqAnswer}`;
                    updatedFormData.mcqOptions = updatedFormData?.mcqOptions?.map((option, index) => {
                        return `${String.fromCharCode(65 + index)}. ${option}`;
                    });
                } else {
                    toast.warning("Please select a correct answer from the provided options.");
                    return;
                }
            }

            if (updatedFormData?.questionType !== 'mcq') {
                updatedFormData.mcqOptions = [];
                updatedFormData.mcqAnswer = "";
            }

            setLoadingCreate(true);

            const res = await createQuestion(updatedFormData, accessToken, clientCode);
            if (res) {
                toast.success('Question created successfully!', 5000);
                resetFormData();
                setQuestionTrigger(prev => !prev);
                toggleView('library');
            }
        } catch (error) {
            const errMsg = error?.response?.data?.notify?.message || "Failed to create the question. Please try again.";
            toast.error(errMsg, { autoClose: 8000 });
        } finally {
            setLoadingCreate(false);
        }
    };


    const handleUpdateQuestion = async (formData) => {
        try {
            let updatedFormData = { ...formData };

            if (updatedFormData?.questionType === 'mcq') {
                const matchingOptionIndex = updatedFormData?.mcqOptions?.findIndex(option => option?.toLowerCase()?.trim() === updatedFormData?.mcqAnswer?.toLowerCase()?.trim());

                if (matchingOptionIndex === -1) {
                    toast.warning("Please select a correct answer from the provided options.");
                    return;
                }
            }

            if (updatedFormData?.questionType !== 'mcq') {
                updatedFormData.mcqOptions = [];
                updatedFormData.mcqAnswer = "";
            }

            setLoadingUpdate(true);

            const res = await updateQuestion(updatedFormData, editingQuestionId, accessToken, clientCode);
            if (res) {
                toast.success('Question updated successfully!', 5000);
                resetFormData();
                setQuestionTrigger(prev => !prev);
                toggleView('library');
            }
        } catch (error) {
            const errMsg = error?.response?.data?.notify?.message || "Failed to update the question. Please try again.";
            toast.error(errMsg, { autoClose: 8000 });
        } finally {
            setLoadingUpdate(false);
        }
    };



    return {
        deleteOpen,
        handleDeleteClick,
        handleDeleteQuestion,
        setDeleteOpen,
        loadingCreate,
        handleCreateQuestion,
        loadingUpdate,
        handleUpdateQuestion,
    };
};

export default useQuestionActions;
