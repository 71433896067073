import React from 'react'
import styled from 'styled-components'
import Label from '../../../component/shared/Label'

const PaperQuestionDetailsPage = ({data}) => {

    return (
        <Box>
            <TopBox>                    
                <div className='right'>
                    <span className="child">Score: {data?.maxScore}</span>
                    |
                    <span className="child">Topics: {data?.questionType?.split(",")?.map((topic, i) => (
                        <Label
                            key={i}
                            bgColor="transparent"
                            borderColor="grey"
                            borderRadius="0.25rem"
                            text={topic}
                            isTitle={false}
                            fontType="normal" />
                    ))}</span>
                </div>
            </TopBox>
            <ContentBox>
                <span className='title'>Problem Statement</span>
                <span className='text'>{data?.question}</span>

                {
                    <>
                        <span className='title'>Options</span>
                        <span className='optionBox'>{data?.mcqOptions?.map((option, i) => (
                            <span key={i} className='option'>{option}</span>
                        ))}
                        </span>

                        <span className='title'>Correct Option</span>
                        <span className='mcqAnswer'>{data?.mcqAnswer}</span>
                    </>
                }

            </ContentBox>
        </Box>
    )
}

export default PaperQuestionDetailsPage

const Box = styled.div`
width: 100%;
margin: 0 0;
padding: 1% 1rem;
display: flex;
flex-direction: column;
box-sizing: border-box;
gap: 1rem;


`

const TopBox = styled.div`
width: 96%;
display: flex;
justify-content: space-between;
margin: 0 auto;
align-items: center;
font-size: 1rem;
font-weight: 700;
box-sizing: border-box;

.left {
 display: flex;
 gap: 0.5rem;
 align-items: center;

 .child {
display: flex;
align-items: center;
gap: 0.5rem;
}
}

.right {
display: flex;
gap: 1rem;
align-items: center;

.child {
display: flex;
align-items: center;
gap: 0.5rem;
}
}

`

const ContentBox = styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap: 0.5rem;
padding: 0.5rem 2%;
box-sizing: border-box;
font-size: 0.9rem;
font-weight: 500;

.title {
 font-size: 1rem;
 font-weight: 700;
 margin-top: 1rem;
 margin-bottom: 0.5rem;
}

.text {
 font-size: 0.9rem;
 margin-bottom: -0.5rem;
}

.optionBox {
 display: flex;
 flex-direction: column;
 gap: 0.25rem;


 .option {
  font-weight: 600;
 }
}
`