import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import RequestType from './RequestType'
import { useNavigate, useParams } from 'react-router'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import JiraHeader from './JiraHeader'
import { useSelector } from 'react-redux'
import { getRequestGroups } from '../../../utils/JiraHelpCenter'

const JiraRequestTypesSelectionPage = () => {
    const navigate = useNavigate();
    const { groupId } = useParams();
    const { portalId } = useParams();
    const requestTypes = useSelector(state => state.atlassian?.portalRequestTypes);
    const [selectedGroup, setSelectedGroup] = useState(getRequestGroups(portalId).find(group => group?.id === parseInt(groupId)).name);
    const [selectedGroupId, setSelectedGroupId] = useState(groupId);


    const navigateToLandingPage = () => {
        navigate(`/servicedesk/customer/portal/${portalId}`)
    }

    const navigateToGroups = () => {
    }

    const navigateToCreateIssue = (requestId) => {
        navigate(`/servicedesk/customer/portal/${portalId}/group/${selectedGroupId}/create/${requestId}`)
    }

    const filteredRequestTypes = requestTypes?.filter(requestType => requestType?.requestGroupIds?.some(id => id === selectedGroupId?.toString()));

    const sortedRequestTypes = filteredRequestTypes?.sort((a, b) => {
        const groupIdA = a.requestGroupIds[0];
        const groupIdB = b.requestGroupIds[0];

        if (groupIdA === groupIdB) {
            return parseInt(a.id) - parseInt(b.id);
        } else {
            return parseInt(groupIdA) - parseInt(groupIdB);
        }
    });

    const handleGroupChange = (e) => {
        const groupName = e.target.value;
        setSelectedGroup(groupName);
        
        const groupId = getRequestGroups(portalId).find(group => group.name === groupName)?.id;
        setSelectedGroupId(groupId);
    }

    
    return (
        <MainBox>
            <JiraHeader />
            <div className='topBox'>
                <div className='searchBox'>

                </div>
            </div>
            <div className='bottomBox'>
                <div className='contentBox'>
                    <span className='navigationBox'>
                        <span onClick={navigateToLandingPage}>Help Center </span> / <span onClick={navigateToGroups}> RegorTalent</span>
                    </span>
                    <span className='boldBigText'>RegorTalent</span>
                    <span className='text'>Welcome! You can raise a request for RegorTalent using the options provided.</span>
                    <span className='boldText'>Contact us about</span>
                    <FormControl sx={{ backgroundColor: '#ffffff' }} fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedGroup}
                            onChange={(e) => handleGroupChange(e)}
                        >
                            {
                                getRequestGroups(portalId)?.map((request, i) => (
                                    <MenuItem value={request?.name}>{request?.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <span className='boldText1'>What can we help you with?</span>
                    <div className='requestGroupBox'>
                        {
                            sortedRequestTypes?.map((request, i) => (
                                <RequestType requestTypeDetails={request} navigateToCreateIssue={navigateToCreateIssue} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </MainBox>
    )
}

export default JiraRequestTypesSelectionPage

const MainBox = styled.div`

display: flex;
flex-direction: column;
align-items: center;
gap: 2rem;
width: 100%;

.topBox {
    background: rgb(255,255,226);
    background: linear-gradient(90deg, rgba(255,255,226,1) 0%, rgba(24,226,211,1) 50%, rgba(24,226,211,1) 100%);    color: var(--white);
    width: 100%;
    height: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .searchBox {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;

        .title {
            font-size: 1.5rem;
            font-weight: 600;
        }
    }
}


.bottomBox {
    background-color: var(--white);
    color: var(--color);
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10rem;
    top: 3.5rem;
    position: absolute;
    padding: 2rem 0;
    border: 0.05rem solid #CCD3D8;
    border-radius: 0.5rem;

    .contentBox {
        width: 90%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .navigationBox {
            

            span {
                font-size: 0.9rem;
                font-weight: 700;
                color: var(--lightOrange);
                cursor: pointer;
            }

            span:hover {
                text-decoration: underline;
            }
        }
        

        .text {
            font-size: 0.9rem;
            font-weight: 500;
            font-family: var(--font);
        }

        .boldBigText {
            font-size: 1.5rem;
            font-weight: 600;
            font-family: var(--font);
        }

        .boldText {
            font-size: 1rem;
            font-weight: 600;
            font-family: var(--font);
            color: grey;
            padding: 1rem 0;
        }

        .boldText1 {
            font-size: 1.1rem;
            font-weight: 700;
            font-family: var(--font);
            color: var(--color);
            padding: 1rem 0;
        }

        .requestGroupBox {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }
}


`