import React, { useState } from 'react';
import styled from 'styled-components'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import PublicLinkTab from '../commonComponents/PublicLinkTab';
import UploadCandidateToJdManually from './UploadCandidateToJdManually';
import ImportCandidateFromOtherJd from './ImportCandidateFromOtherJd';


const UploadCandidateToJdParent = ({ jdId, jobSummaryHash, open, onClose }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const getSharableLink = (jdId, jobSummaryHash, share) => {
    const currentDomain = window.location.hostname;
    const baseUrl = `https://${currentDomain}`;
    const jobListingUrl = `${baseUrl}/job-listing/${jdId}/${jobSummaryHash}`
    const encodedUrl = encodeURIComponent(jobListingUrl);
    return share ? encodedUrl : jobListingUrl;
  }


  const tabContent = [
    <UploadCandidateToJdManually jdId={jdId} key="add-manually" />,
    <ImportCandidateFromOtherJd jdId={jdId} key="import-from-other-jd" />,
    <PublicLinkTab jobUrl={getSharableLink(jdId, jobSummaryHash, false)} linkedinShareUrl={getSharableLink(jdId, jobSummaryHash, true)} key="public-link" />
  ];

  return (
    <StyledDialog open={open} onClose={handleClose} disableEscapeKeyDown>
      <StyledDialogTitle>
        Upload Candidates
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent>
        <StyledTabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Add Candidates Manually" />
          <Tab label="Import from Other JD" />
          <Tab label="Public Link" />
        </StyledTabs>
        {tabContent[activeTab]}
      </DialogContent>
    </StyledDialog>
  );
};

export default UploadCandidateToJdParent



const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 85%;
    width: 100%;
    height: 80%;
    margin-top: 5rem;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTabs = styled(Tabs)`
  margin-bottom: 1rem;
`;

