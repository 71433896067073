import React from 'react'
import styled from 'styled-components'

const AcademicTestTerms = () => {

    return (
        <Box>
            <span className='text'>Welcome to the RegorTalent online academic test! This is your opportunity to demonstrate your knowledge and skills across various subjects. Before starting, please take a moment to familiarize yourself with the guidelines below.</span>
            <span className='title'>Basic Requirements:</span>
            <span className='text'>To take this test, ensure you have:</span>
            <ol className='orderedList'>
                <li className='text'>A stable internet connection and a computer.</li>
            </ol>

            <span className='title'>Instructions Overview:</span>
            <span className='text'>Before beginning the test, carefully read and understand the provided instructions. Manage your time wisely, focus on the questions you are confident about first, and minimize distractions.</span>

            <span className='title'>During the Test:</span>
            <ol>
                <li className='text'>Focus: Avoid distractions and concentrate solely on the questions.</li>
                <li className='text'>Independence: Complete the test on your own, maintaining academic integrity.</li>
                <li className='text'>Review: Double-check your answers before submission.</li>
                <li className='text'>Timely Submission: Submit within the allocated time frame.</li>
            </ol>
            <span className='title'>Communication Guidelines:</span>
            <span className='text'>Speak clearly, avoid filler words, and enunciate properly during any oral sections of the test.</span>

            <span className='title'>Scoring:</span>
            <span className='text'>Your score will be determined by RegorTalent AI/ML Systems based on multiple-choice questions, coding challenges, written responses, or video responses.</span>

            <span className='title'>Test Duration:</span>
            <span className='text'>The test is expected to last approximately 60 minutes and may include multiple-choice questions, coding challenges, written responses, or video responses.</span>

            <span className='title'>Preparation:</span>
            <span className='text'>Find a quiet, comfortable space. Ensure your computer is fully charged and your internet connection is stable. Close other programs before starting.</span>

            <span className='title'>Initiating the Test:</span>
            <span className='text'>Click on "Start Test" after reviewing and agreeing to the consent.</span>

            <span className='title'>Additional Instructions:</span>
            <span className='text'>No breaks are allowed during the test. Refrain from using any reference materials.</span>

            <span className='title'>Terms of Service:</span>
            <span className='text'>By participating, you agree to:</span>
            <ol>
                <li className='text'>Provide truthful information.</li>
                <li className='text'>Avoid cheating.</li>
                <li className='text'>Not use unauthorized software.</li>
                <li className='text'>Keep test content and results confidential.</li>
            </ol>

            <span className='text'><b>Start the test by reviewing and agreeing to the consent/terms. Good luck!</b></span>
        </Box>
    )
}

export default AcademicTestTerms

const Box = styled.div`
display: flex;
flex-direction: column;
gap: 0.5rem;
height: 60vh;
width: 90%;
font-size: 0.75rem;
font-weight: 400;
line-height: 1rem;
border: 0.075rem solid grey;
border-radius: 1rem;
overflow: auto;
padding: 1rem;
box-sizing: border-box;

.title {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0.5rem 0 -0.25rem 0;
}

ul {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

ol {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin: 0rem 0;
}

&::-webkit-scrollbar {
    width: 0.2rem;
}

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0.2rem;
    margin: 0.5rem 0;
}

  &::-webkit-scrollbar-thumb {
    background: grey;
    width: 0.2rem;
    border-radius: 0.2rem;
}

& {
  scrollbar-width: none;
} 

`
