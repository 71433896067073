import React, { useEffect, useState } from 'react';
import ActivityTrackerItem from '../components/ActivityTrackerItem';
import styled from 'styled-components';
import ActivityFilter from '../components/ActivityFilter';
import useActivityTracker from '../hooks/useActivityTracker';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import useFetchUsers from '../hooks/useFetchUsers';
import moment from 'moment';

const Container = styled.div`
  background-color: #f5f5f5;
  padding: 1.5rem;
  border-radius: 1rem;
  max-width: 96%;
  margin: 0 auto;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;


const ActivityTracker = () => {
  const accessToken = useSelector(state => state.auth.userData?.accessToken);
  const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

  const [fromDate, setFromDate] = useState(dayjs(new Date()));
  const [toDate, setToDate] = useState(dayjs(new Date()));
  const [filters, setFilters] = useState({
    userId: '',
    resourceName: '',
    actionType: '',
    fromDate: '',
    toDate: '',
  });

  const [triggerActivityLogs, setTriggerActivityLogs] = useState(false);

  const { users, userLoading } = useFetchUsers(accessToken, clientCode, 1, 100000);
  const { activities, loading } = useActivityTracker(accessToken, clientCode, filters, 0, 100000, triggerActivityLogs);
  const [expandedId, setExpandedId] = useState(null);

  useEffect(() => {
    const formatedFromDate = moment(fromDate.format("YYYY-MM-DD")).format("YYYY-MM-DD")
    const formatedToDate = moment(toDate.format("YYYY-MM-DD")).format("YYYY-MM-DD")
    const today = moment().format("YYYY-MM-DD");

    setFilters(prev => ({
      ...prev,
      fromDate: formatedFromDate === today ? '' : formatedFromDate,
      toDate: formatedToDate === today ? '' : formatedToDate,
    }));

  }, [fromDate, toDate])

  const handleToggle = (id) => {
    setExpandedId((prevId) => (prevId === id ? null : id));
  };


  return (
    <Container>
      <Header>
        <ActivityFilter fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} filters={filters} setFilters={setFilters} setTriggerActivityLogs={setTriggerActivityLogs} users={users} />
      </Header>
      {activities?.content
        ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort in descending order
        .map((activity) => (
          <ActivityTrackerItem
            key={activity?.id}
            {...activity}
            isExpanded={expandedId === activity?.id}
            onToggle={() => handleToggle(activity?.id)}
          />
        ))}
    </Container>
  );
};

export default ActivityTracker;
