import React from 'react'
import styled from 'styled-components'

const TestBasicDetails = ({ data }) => {


    return (
        <BasicDetailBox>
            <div className='top'>
                <span className='text'><b>Test Name:</b> {data?.name}</span>
                <span className='text'><b>Role:</b> {data?.role}</span>
            </div>
            <div className='bottom'>
                <span className='text'><b>Tags:</b> {data?.tags}</span>
                <span className='text'><b>Topics:</b> {data?.topics}</span>
            </div>
            <div className='bottom'>
                <span className='text'><b>Test Type:</b> {data?.testType}</span>
                <span className='text'><b>Test Creation Type:</b> {data?.aiSupport ? "AI Generated" : "Manual"}</span>
            </div>
            
        </BasicDetailBox>
    )
}

export default TestBasicDetails


const BasicDetailBox = styled.div`
width: 96%;
margin: 1rem auto;
display: flex;
flex-direction: column;
gap: 1rem;
padding: 1rem 1.5rem;
border: 0.1rem solid lightgrey;
border-radius: 0.5rem;
box-sizing: border-box;

.top {
display: flex;
justify-content: space-between;
width: 100%;
}

.bottom {
display: flex;
justify-content: space-between;
width: 100%;
}

.text {
font-size: 0.9rem;
font-weight: 500;
width: 50%;
}

.iconBox {
 display: flex;
 gap: 0.5rem;
 align-items: center;
 align-self: end;
 margin-bottom: -1rem;
}

`