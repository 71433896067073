import React from 'react'
import styled from 'styled-components';

const CheckBoxInput = ({ text, value, handleChange, index }) => {

	const stringText = text === 1 ? "Doesn’t meet expectations" : text === 2 ? "Needs a little more training" : text === 3 ? "Slight fit" : text === 4 ? "Meets expectations" : "Exceeds expectations";
	
	return (
		<Label>
			<input
				type="radio"
				value={text}
				checked={value === text}
				onChange={(e) => handleChange(index, e.target.value)}
			/>
			<span>{stringText}</span>
		</Label>
	)
}

export default CheckBoxInput;


const Label = styled.label`
	display: flex;
	cursor: pointer;
	font-weight: 600;
	position: relative;
	margin-bottom: 0rem;
    font-family: var(--font);

	input {
		position: absolute;
		left: -9999px;
		&:checked + span {
			&:before {
				box-shadow: inset 0 0 0 0.3rem var(--lightOrange);
			}
		}
	}
	span {
		display: flex;
		align-items: center;
        font-size: 0.9rem;
		padding: 0.3rem 0.75rem 0.3rem 0.3rem;
		border-radius: 99rem; // or something higher...
		transition: 0.25s ease;
        gap: 0.5rem;
		&:hover {
			background-color: mix(#fff, var(--lightOrange), 84%);
		}
		&:before {
			display: flex;
			flex-shrink: 0;
			content: "";
			background-color: #fff;
			width: 1rem;
			height: 1rem;
			border-radius: 50%;
			margin-right: 0.375em;
			transition: 0.25s ease;
			box-shadow: inset 0 0 0 0.125em var(--lightOrange);
		}
	}
`