import React from 'react'
import styled from 'styled-components'
import { questionTypes } from '../../../../utils/dummyData/questionTypes'
import QuestionTypeCard from '../components/QuestionTypeCard'

const QuestionTypeSelectionPage = ({onSelect}) => {

    return (
        <Box>
            {questionTypes?.map((type, i) => (
                <QuestionTypeCard key={i} data={type} func={onSelect} />
            ))}
        </Box>
    )
}

export default QuestionTypeSelectionPage

const Box = styled.div`
width: 100%;
padding: 1rem 1%;
display: flex;
flex-flow: row-wrap;
justify-content: center;
box-sizing: border-box;
gap: 5%;
margin: 0 auto;


`