import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import LogoHeader from '../../commonComponents/LogoHeader'
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Collapse, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckBoxInput from './commonComponents/CheckBoxInput';
import { useParams } from "react-router";
import { getTokenDetail } from '../../../functions/api/globalFunctions/getTokenDetail';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateFeedback } from '../../../functions/api/interview/updateFeedback';


const Row = (props) => {
    const { index, row, handleCommentsChange, handleRatingChange, handleToggle, accordions } = props;
    const rowRef = useRef(null);


    return (
        <RowBox>
            <TableRow sx={{ '& > *': { borderBottom: 'unset', width: '100%' } }}>
                <TableCell align='left' className='rowTitle'>{row?.param}</TableCell>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleToggle(index)}
                    >
                        {accordions?.find(item => item?.index === index) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow ref={rowRef}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                    <Collapse in={accordions?.find(item => item?.index === index)} timeout="auto" unmountOnExit>
                        <ContentBox>
                            <span className='desc'>{row?.description}</span>
                            <div className='inputBox'>
                                <CheckBoxInput text={1} value={row?.rating} handleChange={handleRatingChange} index={index} />
                                <CheckBoxInput text={2} value={row?.rating} handleChange={handleRatingChange} index={index} />
                                <CheckBoxInput text={3} value={row?.rating} handleChange={handleRatingChange} index={index} />
                                <CheckBoxInput text={4} value={row?.rating} handleChange={handleRatingChange} index={index} />
                                <CheckBoxInput text={5} value={row?.rating} handleChange={handleRatingChange} index={index} />
                            </div>
                            <div className='commentBox'>
                                <label for="jobDescription" className="label1">Comments</label>
                                <br />
                                <textarea
                                    rows={7}
                                    type="text"
                                    value={row?.comments}
                                    onChange={(e) => handleCommentsChange(index, e.target.value)}
                                />
                            </div>
                        </ContentBox>
                    </Collapse>
                </TableCell>
            </TableRow>
        </RowBox>
    )
}


const EmployerFeedback = () => {
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const [accordions, setAccordions] = useState([]);
    const [feedback, setFeedback] = useState('');
    const [recommendation, setRecommendation] = useState('');
    const { token } = useParams();


    const paramValues = [
        'Education/Training',
        'Work Experience',
        'Skills/Technical',
        'Leadership Skills',
        'Interpersonal Skills',
        'Problem Solving',
        'Cultural Fit',
    ];

    const paramDescriptions = [
        'Candidate has the necessary education and/or training required for the position.',
        'Candidate has prior experience related to the position.',
        'Candidate demonstrates the required technical skills to perform the job successfully.',
        'Candidate has the leadership skills needed to perform the job successfully.',
        'Candidate shows ability to work collaboratively, communicate effectively and displays strong work ethic.',
        'Candidate demonstrates the ability to develop innovative solutions and solve problems.',
        'Candidate’s attitude and behaviors are in alignment with Company’s core values and culture. Displays integrity and will contribute positively to our culture.',
    ];

    const initialState = Array(7)?.fill()?.map((_, index) => ({
        param: paramValues[index % paramValues?.length], // Cycle through paramValues
        description: paramDescriptions[index % paramDescriptions?.length],
        rating: 0,
        comments: ''
    }));

    const [feedbackParams, setFeedbackParams] = useState(initialState)
    const [interviewId, setInterviewId] = useState('');

    useEffect(() => {
        const getTokenDetails = async (token) => {
            try {
                const res = await getTokenDetail(token);
                if (res) {
                    setInterviewId(res?.data?.id);
                }
            } catch (error) {
                const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again."
                toast.error(errMsg);
            }
        }
        getTokenDetails(token)
    }, [token])


    const handleRatingChange = (index, value) => {
        setFeedbackParams(prevState => {
            const updatedFeedbackParams = [...prevState];
            updatedFeedbackParams[index].rating = parseInt(value);
            return updatedFeedbackParams;
        });
    };

    const handleCommentsChange = (index, value) => {
        setFeedbackParams(prevState => {
            const updatedFeedbackParams = [...prevState];
            updatedFeedbackParams[index].comments = value;
            return updatedFeedbackParams;
        });
    };

    const handleToggle = (index) => {
        // Check if the clicked accordion is already open
        const isOpen = accordions?.find(item => item?.index === index);

        // Toggle the state of the clicked accordion
        let updatedAccordions;
        if (isOpen) {
            // If the clicked accordion is open, close it
            updatedAccordions = accordions?.filter(item => item?.index !== index);
        } else {
            // If the clicked accordion is closed, open it and close all other accordions
            updatedAccordions = [{ index: index }];
        }

        // Update the state with the updated accordion array
        setAccordions(updatedAccordions);
    };

    const handleFeedbackSubmission = async () => {
        try {
            const payload = {
                educationRating: feedbackParams[0]?.rating,
                educationComments: feedbackParams[0]?.comments,
                workExperienceRating: feedbackParams[1]?.rating,
                workExperienceComments: feedbackParams[1]?.comments,
                skillsRating: feedbackParams[2]?.rating,
                skillsComments: feedbackParams[2]?.comments,
                leadershipRating: feedbackParams[3]?.rating,
                leadershipComments: feedbackParams[3]?.comments,
                interpersonalRating: feedbackParams[4]?.rating,
                interpersonalComments: feedbackParams[4]?.comments,
                problemSolvingRating: feedbackParams[5]?.rating,
                problemSolvingComments: feedbackParams[5]?.comments,
                culturalFitRating: feedbackParams[6]?.rating,
                culturalFitComments: feedbackParams[6]?.comments,
                overallFeedback: feedback,
                recommendation: recommendation
            }

            const res = await updateFeedback(interviewId, payload, accessToken);

            if (res) {
                toast.success("Feedback submitted successfully", 5000);
                setFeedbackParams(initialState);
                setFeedback("");
                setRecommendation("");
            }

        } catch (error) {
            const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again."
            toast.error(errMsg);
        }
    }

    return (
        <Box>
            <LogoHeader />
            <Content>
                <span className='titleText'>Based on the interview, please evaluate the candidate's qualifications for the position. Provide additional comments for each topic in the tables provided.</span>
                <FeedbackParamBox>
                    {
                        feedbackParams?.map((data, index) => (
                            <Row index={index} row={data} handleCommentsChange={handleCommentsChange} handleRatingChange={handleRatingChange} handleToggle={handleToggle} accordions={accordions} />
                        ))
                    }
                </FeedbackParamBox>
                <div className='textBox'>
                    <label className='label'>Overall Feedback</label>
                    <textarea
                        value={feedback}
                        name='feeback'
                        onChange={(e) => setFeedback(e.target.value)}
                        rows={10}
                        className='textarea'
                    />
                </div>

                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" style={{ fontSize: '0.8rem' }} >Recommendation *</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={recommendation}
                        label="Recommendation"
                        onChange={(e) => setRecommendation(e.target.value)}
                        name="recommendation"
                        size="small"
                        required
                        inputProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "500",
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "500",
                            },
                        }}
                        sx={{
                            padding: "0rem 0 0.5rem 0",
                        }}
                    >
                        <MenuItem value="MOVE_TO_NEXT_ROUND">Move to next round</MenuItem>
                        <MenuItem value="HOLD">Hold</MenuItem>
                        <MenuItem value="REJECT">Reject</MenuItem>
                        <MenuItem value="OFFER">Offer</MenuItem>
                    </Select>
                </FormControl>
                <Button onClick={() => handleFeedbackSubmission()}>Submit Feedback</Button>
            </Content>
        </Box>
    )
}

export default EmployerFeedback

const Box = styled.div`
display: flex;
flex-direction: column;



`

const Content = styled.div`
width: 80%;
margin: 6rem auto 2rem auto;
display: flex;
flex-direction: column;
align-items: center;
gap: 2rem;


.titleText {
    font-size: 1rem;
    font-weight: 600;
    font-family: var(--font);
}
.textBox {
    width: 100%;
    position: relative;


    .label {
        font-size: 0.9rem;
        font-weight: 400;
        position: absolute;
        top: -0.75rem;
        left: 1rem;
        background-color: var(--white);
        color: #757575;
        padding: 0 0.5rem;
      }
}

.textarea {
    width: 100%;
    background-color: var(--white);
    padding: 1rem;
    line-height: 1.2rem;
    font-size: 0.9rem;
    border-radius: 0.75rem;
    outline-color: #1976d2;
    outline-width: 0.01rem;
    border: 0.075rem solid lightgrey;
    box-sizing: border-box;
}

#demo-simple-select-label {
    font-size: 0.75rem;
    font-weight: 400;
    font-family: var(--font);
  }

`

const FeedbackParamBox = styled.div`
width: 100%;
border: 0.1rem solid lightgrey;
border-radius: 0.5rem;



`

const RowBox = styled.div`
width: 100%;


.mainBox1 {
    width: 100% !important;
    background-color: white;
    border-bottom: none;
}

.rowTitle {
    font-size: 1.2rem;
    font-weight: 600;
    font-family: var(--font);
}
`
const ContentBox = styled.div`
width: 96%;
display: flex;
flex-direction: column;
gap: 0.5rem;
padding: 1rem 1rem;

.desc {
    font-size: 1rem;
    font-weight: 500;
    font-family: var(--font);
}

.inputBox {
    display: flex;
    flex-direction: column;
}

.commentBox {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    width: 100%;
    
      .label1 {
        font-size: 0.9rem;
        font-weight: 500;
        position: absolute;
        top: 0.4rem;
        left: 1rem;
        background-color: var(--white);
        color: #757575;
        padding: 0 0.5rem;
        font-family: var(--font);
      }
    
      textarea {
        box-sizing: border-box;
        width: 100%;
        border: 0.08rem solid #C4C4C4;
        border-radius: 0.5rem;
        padding: 0.75rem 0.5rem;
        outline-color: var(--lightOrange);
        outline-width: 0.01rem;
        font-size: 0.9rem;
        box-sizing: border-box;
      }
}

`

const Button = styled.button`
padding: 0.5rem 0.8rem;
  background-color: var(--lightOrange);
  color: var(--white);
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  font-family: var(--font);
  display: flex;
  align-self: center;
  margin: 1rem auto;


`