import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components'
import useFetchOnboardRequests from '../hooks/useFetchOnboardRequests';
import CompanyCard from '../components/CompanyCard';
import useUpdateOnboardStatus from '../hooks/useUpdateOnboardStatus';
import { useJwt } from 'react-jwt';

const OnboardUser = () => {
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);
    const [onboardTrigger, setOnboardTrigger] = useState(false);
    const status = "VERIFICATION_PENDING";
    const { onboardData, onboardLoading } = useFetchOnboardRequests(accessToken, clientCode, status, 1, 100000, onboardTrigger);
    const { onboardUpdateLoading, updateStatus } = useUpdateOnboardStatus(setOnboardTrigger);
    const role = useJwt(accessToken);

    console.log(role);

    return (
        <Box>
            {(role && (role?.decodedToken?.grants === "ROLE_SUSER" || role?.decodedToken?.grants === "ROLE_SYS_ADMIN"))
                ?
                <>{(onboardData?.length === 0 || onboardData === null || !onboardData) ?
                    <span className='title'>No user request found for Onboard Approval.</span>
                    :
                    onboardData?.map((data, i) => (
                        <CompanyCard data={data} updateStatus={updateStatus} />
                    ))}</>
                : <span className='title'>Access Denied. Please login with Super user / System admin account.</span>}
        </Box>
    )
}

export default OnboardUser

const Box = styled.div`
display: flex;
flex-direction: column;
width: 100%;
align-items: center;
gap: 2rem;
margin: 2rem auto;
background-color: "#f4f4f4";

.title {
font-size: 1.5rem;
font-weight: 600;

}

`