import React from 'react';
import { Autocomplete, Paper } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import StyledTextField from './StyledTextField';

const filter = createFilterOptions();

const TagsInput = ({ options, value, onChange, onBlur, inputValue, setInputValue, label }) => (
    <Autocomplete
        multiple
        options={options}
        getOptionLabel={(option) => option}
        filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            const isExisting = options.some((option) => inputValue === option);

            if (inputValue !== '' && !isExisting) {
                filtered.push(inputValue);
            }

            return filtered;
        }}
        onChange={onChange}
        value={value}
        selectOnFocus
        clearOnBlur
        freeSolo
        fullWidth
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }}
        onBlur={onBlur}
        PaperComponent={(props) => <Paper {...props} />}
        renderInput={(params) => (
            <StyledTextField
                inputProps={{
                    sx: {
                        color: '#626264',
                        fontSize: '0.8rem',
                        fontWeight: '400'
                    },
                }}
                InputLabelProps={{
                    sx: {
                        color: '#626264',
                        fontSize: '0.8rem',
                        fontWeight: '400'
                    },
                }}
                {...params}
                label={label}
                sx={{ fontSize: '0.5rem' }}
            />
        )}
    />
);

export default TagsInput;
