import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { Paper, TextField } from '@mui/material'
import { allTags, technicalSkills } from '../../../../utils/contantData';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import { getJds } from '../../../../functions/api/employers/getJds';
import { useSelector } from 'react-redux';
import NumberCounterForTest from '../commonComponents/NumberCouterForTest';
import { createAITest } from '../../../../functions/api/employers/employerTest/createAITest';
import Loader from '../../../commonComponents/Loader';
import TagsInput from '../../../../component/shared/TagsInput';

const filter = createFilterOptions();

const AiBasedTest = ({ toggleView, setTestTrigger }) => {
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);
    const [currentTab, setCurrentTab] = useState("SKILL");
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [skillInputValue, setSkillInputValue] = useState('');
    const [experience, setExperience] = useState(0);
    const [allJdList, setAllJdList] = useState([]);
    const [allJds, setAllJds] = useState([]);
    const [selectedJd, setSelectedJd] = useState([]);
    const [testName, setTestName] = useState("");

    const [subjectiveQueNo, setSubjectiveQueNo] = useState(0);
    const [codingQueNo, setCodingQueNo] = useState(0);
    const [mcqQueNo, setMcqQueNo] = useState(0);
    const [loading, setLoading] = useState(false);

    const [questionsPerSkill, setQuestionsPerSkill] = useState({});

    const [selectedTopics, setSelectedTopics] = useState([]);
    const [topicInputValue, setTopicInputValue] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);
    const [tagInputValue, setTagInputValue] = useState("");

    
    const handleTagsChange = (_, newTags) => {
        setSelectedTags(newTags);
    };

    const handleTopicsChange = (_, newTopics) => {
        setSelectedTopics(newTopics);
    };

    const handleTagInputBlur = () => {
        if (tagInputValue.trim() !== '') {
            setSelectedTags((prevTags) => [...prevTags, tagInputValue]);
            setTagInputValue('');
        }
    };

    const handleTopicInputBlur = () => {
        if (topicInputValue.trim() !== '') {
            setSelectedTopics((prevTopics) => [...prevTopics, topicInputValue]);
            setTopicInputValue('');
        }
    };

    useEffect(() => {
        async function getData() {
            try {
                const res = await getJds(accessToken, clientCode, "", 1, 10000000);
                if (res && res?.data?.data) {
                    setAllJds(res?.data?.data);
                    setAllJdList(res?.data?.data?.map(row => ({
                        jdId: row?.jdId,
                        title: row?.title,
                    })));
                }
            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }
        getData();
    }, []);

    const handleJdChange = (_, newTag) => {
        const selectedJdItem = allJdList?.find(jd => jd?.title === newTag);
        if (selectedJdItem) {
            setSelectedJd({ jdId: selectedJdItem?.jdId, title: selectedJdItem?.title }); // Set jdId with title
        } else {
            setSelectedJd(null);
        }
    };

    // const handleSkillsChange = (_, newSkills) => {
    //     setSelectedSkills(newSkills);
    // };

    const handleSkillInputBlur = () => {
        if (skillInputValue.trim() !== '') {
            setSelectedSkills([...selectedSkills, skillInputValue?.trim()]);
            setSkillInputValue('');
        }
    };


    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);

        setSelectedSkills([]);
        setSelectedJd([]);
        setExperience(0);
        setSubjectiveQueNo(0);
        setCodingQueNo(0);
        setMcqQueNo(0);
    }


    const expArr = [
        { value: '0', text: 'Fresher' },
        { value: '1', text: '1 year' },
        { value: '2', text: '2 years' },
        { value: '3', text: '3 years' },
        { value: '4', text: '4 years' },
        { value: '5', text: '5 years' },
        { value: '6', text: '6 years' },
        { value: '7', text: '7 years' },
        { value: '8', text: '8 years' },
        { value: '9', text: '9 years' },
        { value: '10', text: '10 years' },
        { value: '11', text: '11 years' },
        { value: '12', text: '12 years' },
        { value: '13', text: '13 years' },
        { value: '14', text: '14 years' },
        { value: '15', text: '15 years' },
        { value: '16', text: '16 years' },
        { value: '17', text: '17 years' },
        { value: '18', text: '18 years' },
        { value: '19', text: '19 years' },
        { value: '20', text: '20 years' },
        { value: '21', text: '21 years' },
        { value: '22', text: '22 years' },
        { value: '23', text: '23 years' },
        { value: '24', text: '24 years' },
        { value: '25', text: '25 years' },
        { value: '26', text: '26 years' },
        { value: '27', text: '27 years' },
        { value: '28', text: '28 years' },
        { value: '29', text: '29 years' },
        { value: '30', text: '30 years' },
    ]

    const getJdDescByJdId = (jdId) => {
        return allJds?.filter((jd) => jd?.jdId === jdId)[0]?.description;
    }

    const handleSkillChange = (event, newSkills) => {
        if (newSkills.length <= 5) {
            setSelectedSkills(newSkills);

            const updatedQuestions = { ...questionsPerSkill };
            newSkills.forEach((skill) => {
                if (!updatedQuestions[skill]) {
                    updatedQuestions[skill] = [0, 0, 0];
                }
            });
            Object.keys(updatedQuestions).forEach((skill) => {
                if (!newSkills.includes(skill)) {
                    delete updatedQuestions[skill];
                }
            });
            setQuestionsPerSkill(updatedQuestions);
        }
    };

    const handleQuestionChange = (index, type, value) => {
        const updatedQuestions = { ...questionsPerSkill };
        updatedQuestions[selectedSkills[index]][type] = value; // Type: 0->subjective, 1->coding, 2->mcq
        setQuestionsPerSkill(updatedQuestions);
    };

    const handleIncrease = (skill, type) => {
        const updatedQuestions = { ...questionsPerSkill };
        const currentCount = updatedQuestions[skill][type] || 0;
        updatedQuestions[skill][type] = parseInt(currentCount) + 1;
        setQuestionsPerSkill(updatedQuestions);
    };

    const handleDecrease = (skill, type) => {
        const updatedQuestions = { ...questionsPerSkill };
        const currentCount = updatedQuestions[skill][type] || 0;
        if (currentCount > 0) {
            updatedQuestions[skill][type] = parseInt(currentCount) - 1;
            setQuestionsPerSkill(updatedQuestions);
        }
    };

    const formatQuestionsPerSkill = () => {
        const formatted = {};
        Object.keys(questionsPerSkill).forEach((skill) => {
            formatted[skill] = questionsPerSkill[skill].join(","); // Join array values with commas
        });

        return formatted;
    }

    const handleGenerateTest = async () => {
        if (testName === "") {
            toast.warning("Please fill test name", 3000);
            return;
        } if (selectedSkills?.length === 0 && currentTab === "SKILL") {
            toast.warning("Please add skills", 3000);
            return;
        } else if (selectedJd?.length === 0 && currentTab === "JD") {
            toast.warning("Please select JD", 3000);
            return;
        } else if (experience === 0) {
            toast.warning("Please select experience", 3000);
            return;
        } else if (currentTab === "JD" && (subjectiveQueNo === 0 && mcqQueNo === 0 && codingQueNo === 0)) {
            toast.warning("Number of Questions should be greater than 0 for atleast 1 category")
            return;
        }

        const payload = {
            ai_support: true,
            jobSummary: currentTab === "SKILL" ? "" : getJdDescByJdId(selectedJd?.jdId),
            name: testName,
            questionDetails: `${subjectiveQueNo},${mcqQueNo},${codingQueNo}`,
            resumeText: experience,
            testType: "mix",
            skillsQuestionDetails: formatQuestionsPerSkill(),
            clientCode: clientCode,
            tags: selectedTags?.join(", "),
            topics: selectedTopics?.join(", "),
        }

        if (currentTab === "SKILL") {
            delete payload.questionDetails;
        } else {
            delete payload.skillsQuestionDetails;
        }

        try {
            setLoading(true);
            const res = await createAITest(payload, accessToken, clientCode);
            if (res) {
                setLoading(false);
                toast.success("AI Based Test Generated, Please check Draft Tests", 5000);
                setSelectedSkills([]);
                setSelectedJd([]);
                setExperience(0);
                setSubjectiveQueNo(0);
                setCodingQueNo(0);
                setMcqQueNo(0);
                setTestName("");
                setSelectedTags([]);
                setSelectedTopics([]);
            }
        } catch (error) {
            setLoading(false);
            const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    }

    const handleIncreaseMultipleNumber = (questionType) => {
        if (questionType === 'subjective' && subjectiveQueNo < 30) {
            setSubjectiveQueNo(prevNum => prevNum + 1);
        } else if (questionType === 'coding' && codingQueNo < 30) {
            setCodingQueNo(prevNum => prevNum + 1);
        } else if (questionType === 'mcq' && mcqQueNo < 30) {
            setMcqQueNo(prevNum => prevNum + 1);
        }
    }

    const handleDecreaseMultipleNumber = (questionType) => {
        if (questionType === 'subjective') {
            if (subjectiveQueNo > 0) {
                setSubjectiveQueNo(prevNum => prevNum - 1);
            }
        } else if (questionType === 'coding') {
            if (codingQueNo > 0) {
                setCodingQueNo(prevNum => prevNum - 1);
            }
        } else if (questionType === 'mcq') {
            if (mcqQueNo > 0) {
                setMcqQueNo(prevNum => prevNum - 1);
            }
        }
    }


    return (
        <Box>
            {loading ? <Loader /> :
                <>
                    <div className='btnBox'><Button onClick={() => toggleView('test')}>Back to Manage Tests</Button></div>
                    <Tabs
                        style={{
                            width: '40%',
                            borderRadius: '3rem',
                            backgroundColor: 'var(--lightOrange)'
                        }}
                        value={currentTab}
                        onChange={handleTabChange}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "var(--lightOrange)",
                            },
                        }}
                        variant="fullWidth"
                        aria-label="wrapped label tabs example"
                    >
                        <Tab
                            value="SKILL"
                            label="Skills Based test"
                            sx={{
                                color: "var(--lightOrange)",
                                fontSize: "0.8rem",
                            }}
                            classes={{ root: 'custom-tab', selected: 'custom-tab-selected' }}
                        />
                        <Tab
                            value="JD"
                            label="JD Based test"
                            sx={{
                                color: "var(--lightOrange)",
                                fontSize: "0.8rem",
                            }}
                            classes={{ root: 'custom-tab', selected: 'custom-tab-selected' }}
                        />
                    </Tabs>
                    <Content>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            type="text"
                            label="Test Name"
                            name="name"
                            value={testName}
                            onChange={(e) => setTestName(e.target.value)}
                            inputProps={{
                                sx: {
                                    color: "#626264",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: "#626264",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                },
                            }}
                            required
                            fullWidth
                        />
                        {currentTab === "SKILL" ? <div className='skillBox'>
                            <Stack spacing={3} sx={{ width: "100%", }}>
                                <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    options={technicalSkills}
                                    getOptionLabel={(option) => option}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        const { inputValue } = params;
                                        const isExisting = options.some((option) => inputValue === option);

                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push(inputValue);
                                        }

                                        return filtered;
                                    }}
                                    onChange={handleSkillChange}
                                    value={selectedSkills}
                                    selectOnFocus
                                    clearOnBlur
                                    freeSolo
                                    fullWidth
                                    onInputChange={(event, newInputValue) => {
                                        setSkillInputValue(newInputValue);
                                    }}
                                    onBlur={handleSkillInputBlur}
                                    PaperComponent={(props) => <Paper {...props} />}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Skills *"
                                        />
                                    )}
                                    disabled={selectedSkills?.length >= 5}
                                />
                            </Stack>
                        </div> :
                            <div className="jdBox">
                                <Stack spacing={3} sx={{ width: "100%", }} >
                                    <Autocomplete
                                        id="tags-standard"
                                        options={allJdList ? allJdList?.map(jd => jd?.title) : [{ jdId: "Dummy Jd", title: "Dummy Jd" }]}
                                        getOptionLabel={(option) => option}
                                        onChange={handleJdChange}
                                        value={selectedJd ? selectedJd?.title : null}
                                        selectOnFocus
                                        fullWidth
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select JD *"
                                            />
                                        )}
                                    />
                                </Stack>
                            </div>}

                        <FormControl fullWidth required>
                            <InputLabel id="demo-simple-select-label">Experience</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Min Exp"
                                name="exp"
                                value={experience}
                                onChange={(e) => setExperience(e.target.value)}
                                size="small"
                                inputProps={{
                                    sx: {
                                        color: "#626264",
                                        fontSize: "0.8rem",
                                        fontWeight: "400",
                                    },
                                }}
                                InputLabelProps={{
                                    sx: {
                                        color: "#626264",
                                        fontSize: "0.8rem",
                                        fontWeight: "400",
                                    },
                                }}
                                sx={{
                                    padding: "0rem 0 0.5rem 0",
                                }}
                            >
                                {expArr.map((exp) => (
                                    <MenuItem value={exp.value}>{exp.text}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {currentTab === "SKILL" ? selectedSkills?.map((skill, i) => (<div className="inputBox">
                            <span className="title">Number of Questions for {skill}</span>
                            <div className="childInputBox multipleNumberMainBox">
                                <div className="queTypeCard">
                                    <label className="label">Subjective</label>
                                    <NumberCounterForTest handleDecreaseNumber={() => handleDecrease(skill, 0)} handleIncreaseNumber={() => handleIncrease(skill, 0)} value={questionsPerSkill[skill][0] || 0} handleChange={(value) => handleQuestionChange(i, 0, value)} testType="subjective" style={{ width: '100%' }} />
                                </div>
                                <div className="queTypeCard">
                                    <label className="label">Coding</label>
                                    <NumberCounterForTest handleDecreaseNumber={() => handleDecrease(skill, 2)} handleIncreaseNumber={() => handleIncrease(skill, 2)} value={questionsPerSkill[skill][2] || 0} handleChange={(value) => handleQuestionChange(i, 2, value)} testType="coding" style={{ width: '100%' }} />
                                </div>
                                <div className="queTypeCard">
                                    <label className="label">MCQ</label>
                                    <NumberCounterForTest handleDecreaseNumber={() => handleDecrease(skill, 1)} handleIncreaseNumber={() => handleIncrease(skill, 1)} value={questionsPerSkill[skill][1] || 0} handleChange={(value) => handleQuestionChange(i, 1, value)} testType="mcq" style={{ width: '100%' }} />
                                </div>
                            </div>
                        </div>))
                            :
                            <div className="inputBox">
                                <span className="title">Number of Questions</span>
                                <div className="childInputBox multipleNumberMainBox">
                                    <div className="queTypeCard">
                                        <label className="label">Subjective</label>
                                        <NumberCounterForTest handleDecreaseNumber={() => handleDecreaseMultipleNumber('subjective')} handleIncreaseNumber={() => handleIncreaseMultipleNumber('subjective')} value={subjectiveQueNo} handleChange={(value) => setSubjectiveQueNo(value)} testType="subjective" style={{ width: '100%' }} />
                                    </div>
                                    <div className="queTypeCard">
                                        <label className="label">Coding</label>
                                        <NumberCounterForTest handleDecreaseNumber={() => handleDecreaseMultipleNumber('coding')} handleIncreaseNumber={() => handleIncreaseMultipleNumber('coding')} value={codingQueNo} handleChange={(value) => setCodingQueNo(value)} testType="coding" style={{ width: '100%' }} />
                                    </div>
                                    <div className="queTypeCard">
                                        <label className="label">MCQ</label>
                                        <NumberCounterForTest handleDecreaseNumber={() => handleDecreaseMultipleNumber('mcq')} handleIncreaseNumber={() => handleIncreaseMultipleNumber('mcq')} value={mcqQueNo} handleChange={(value) => setMcqQueNo(value)} testType="mcq" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>}

                        <Stack spacing={3} sx={{ width: "100%" }}>
                            <TagsInput
                                options={allTags}
                                value={selectedTags}
                                onChange={handleTagsChange}
                                onBlur={handleTagInputBlur}
                                inputValue={tagInputValue}
                                setInputValue={setTagInputValue}
                                label="Tags*"
                            />
                        </Stack>
                        <Stack spacing={3} sx={{ width: "100%" }}>
                            <TagsInput
                                options={allTags}
                                value={selectedTopics}
                                onChange={handleTopicsChange}
                                onBlur={handleTopicInputBlur}
                                inputValue={topicInputValue}
                                setInputValue={setTopicInputValue}
                                label="Topics*"
                            />
                        </Stack>

                        <Button onClick={() => handleGenerateTest()}>Generate AI Test</Button>
                    </Content></>}
        </Box>
    )
}

export default AiBasedTest

const Box = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 90%;
margin: 2rem auto;
padding: 1rem 0;
padding-bottom: 3rem;
background-color: var(--white);
box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
border-radius: 0.5rem;



    .custom-tab {
      color: white;
      background-color: var(--lightOrange);
      transition: background-color 0.3s;
      text-decoration: none !important;
    }

.custom-tab-selected {
    background-color: white;
    color: var(--lightOrange) !important;
    border: 0.1rem solid var(--lightOrange);
    border-radius: 3rem;
    text-decoration: none !important;
  }

.custom-tab-selected .MuiTab-label {
  text-transform: none;
}

.btnBox {
display: flex;
width: 100%;
justify-content: end;
padding: 0 1rem;
box-sizing: border-box;
}


`

const Content = styled.div`
width: 80%;
display: flex;
flex-direction: column;
gap: 1.5rem;
align-items: center;
margin-top: 2rem;


.skillBox {
width:100%;

}

.jdBox {
width:100%;

}

 .inputBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        border: 0.08rem solid #C4C4C4;
        padding: 1rem 1rem;
        border-radius: 0.3rem;
        position: relative;
        box-sizing: border-box;
      }

      .title {
        font-size: 0.9rem;
        font-weight: 600;
        position: absolute;
        top: -0.8rem;
        background-color: var(--white);
        padding: 0 0.3rem;
      }

      .childInputBox {
        display: flex;
        gap: 0.5rem;
        flex-flow: row wrap;

        .checkBox {
         display: flex;
         flex-direction: column;
         gap: 0.5rem;

        }

        .extraCheckBox {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          cursor: pointer;

          input {
            cursor: pointer;
          }

          span {
            align-items: center;
            font-size: 0.9rem;
            font-weight: 500;
            font-family: var(--font);
          }
        }
      }

      .multipleNumberMainBox {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        .label {
          font-size: 0.9rem;
      }
      
        .queTypeCard {
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
          align-items: center;
          width: 25%;
        }
      }

`

const Button = styled.button`
padding: 0.5rem 0.8rem;
border: none;
border-radius: 0.5rem;
background-color: var(--lightOrange);
color: var(--white);
font-size: 0.9rem;
font-weight: 600;
cursor: pointer;

`