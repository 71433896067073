import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { addJd } from "../../../../functions/api/employers/addJd";
import { useSelector } from "react-redux";
import { editJd } from "../../../../functions/api/employers/editJd";
import { CircularProgress, Paper, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";

import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { locations, technicalSkills } from "../../../../utils/contantData";
import { setJdTrigger } from "../../../../slices/jdSlice";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import { createFilterOptions } from '@mui/material/Autocomplete';
import Loader from "../../../commonComponents/Loader";
import UploadCandidateToJdParent from "./UploadCandidateToJdParent";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import CloneJDForm from "./CloneJDForm";
import CommonDialog from "../../../commonComponents/CommonDialog";
import ModalHOC from "../../SeekerDashboard/ModalHOC";
import JdForm from "../JdForm";


const filter = createFilterOptions();

const Container = styled.div`
  width: 94%;
  margin: 1rem 0% 2rem 0%;
  padding: 0 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.3rem;
  background-color: var(--white);
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;

  .textEditor {
    background-color: #F6F6FB;
    height: calc(100% - 3rem);
  }

  .formMain {
    display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  gap: 1rem;
  width: 95%;
  margin: 0 auto;

  

  .inputBox {
    width: 100%;
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
    align-items: center;
  }

  @media (max-width: 2000px) {
    #outlined-basic {
      padding: 0.75rem 0.5rem;
      background-color: #F6F6FB;
      font-family: var(--font);
    }
  }

  @media (max-width: 1700px) {
    #outlined-basic {
      padding: 0.85rem 0.5rem;
      background-color: #F6F6FB;
      font-family: var(--font);
    }
  }

  @media (max-width: 1350px) {
    #outlined-basic {
      padding: 0.95rem 0.5rem;
      background-color: #F6F6FB;
      font-family: var(--font);
    }
  }

  @media (max-width: 1200px) {
    #outlined-basic {
      padding: 1rem 0.5rem;
      background-color: #F6F6FB;
      font-family: var(--font);
    }
  }

  #demo-simple-select-label {
    font-size: 0.8rem;
    font-weight: 400;
    font-family: var(--font);
  }
  }

  .mainTitle {
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 2rem;
    display: flex;
    width: 95%;
    box-sizing: border-box;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-top: 1rem;
    margin-left: 3%;
  }

  .relative {
    position: relative;
    width: 100%;
  }

  .helperText {
    display: block;
    font-size: 0.7rem;
    color: rgb(211, 47, 47);
    font-weight: 500;
  }

  .check {
    width: 100%;
    display: flex;
    justify-content: start;
  }

  .fileInputBox {
    position: relative;
    width: 100%;
    height: 12rem;
    background-color: #f6f6fb;

    textarea {
      width: 100%;
      border: 1px solid #ccc;
      background-color: #f6f6fb;
      outline-color: #ccc;
      border-radius: 0.5rem;
      box-sizing: border-box;
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
      font-weight: 400;
      font-family: var(--font);
    }

    textarea:focus {
      outline-color: #1976d2;
    }
  }
`;


const EditButton = styled.button`
  background-color: var(--lightOrange);
  border: 0.1rem solid var(--lightOrange);
  cursor: pointer;
  color: var(--white);
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 600;
  margin-right: 0.6rem;
  padding: 0.5rem 0.8rem;
  border-radius: 0.5rem;
  font-family: var(--font);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  gap: 1rem;

  .inputBox {
    width: 100%;
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
    align-items: center;
  }

  @media (max-width: 2000px) {
    #outlined-basic {
      padding: 0.75rem 0.5rem;
      background-color: #F6F6FB;
      font-family: var(--font);
    }
  }

  @media (max-width: 1700px) {
    #outlined-basic {
      padding: 0.85rem 0.5rem;
      background-color: #F6F6FB;
      font-family: var(--font);
    }
  }

  @media (max-width: 1350px) {
    #outlined-basic {
      padding: 0.95rem 0.5rem;
      background-color: #F6F6FB;
      font-family: var(--font);
    }
  }

  @media (max-width: 1200px) {
    #outlined-basic {
      padding: 1rem 0.5rem;
      background-color: #F6F6FB;
      font-family: var(--font);
    }
  }

  #demo-simple-select-label {
    font-size: 0.8rem;
    font-weight: 400;
    font-family: var(--font);
  }
`;

const Label = styled.label`
  font-size: 0.8rem;
  margin-bottom: 8px;
  font-weight: 500;
  color: grey;
  position: absolute;
  top: -0.5rem;
  left: 1rem;
  background-color: #F6F6FB;
  font-family: var(--font);
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding: 0 0.2rem;
`;

const Button = styled.button`
  padding: 0.5rem 0.8rem;
  background-color: var(--lightOrange);
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  min-width: 5rem;
  border: none;
  border-radius: 0.3rem;
  align-self: center;
  font-family: var(--font);
  display: flex;
  justify-content: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.7 : 1)};

`;

const initialFormData = {
    reqNumber: "",
    numOfReqs: 0,
    title: "",
    description: "",
    skills: "",
    bu: "",
    exp: "",
    location: "",
    certification: "",
    workType: "",
    ctc: "",
    keywords: "",
    jd: "",
    noticePeriod: "",
    companyType: "",
    candidateAvailability: "",
    hiringManager: "",
    recruiter: "",
    tags: "",
    jdUpload: null,
    visibility: "",
    autoReqNumbers: true,
};

function CreateJds() {
    const [array, setArray] = useState([null, "create"]);

    const [mode, setMode] = useState("create");
    const [autoReq, setAutoReq] = useState(false);
    const [description, setDescription] = useState('');
    const [minExp, setMinExp] = useState(0);
    const [maxExp, setMaxExp] = useState(0);
    const [formData, setFormData] = useState(initialFormData);

    const [loading, setLoading] = useState(false);

    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);

    const [initialReqs, setInitialReqs] = useState(0);
    const [reqsError, setReqsError] = useState(false);
    const [skillInputValue, setSkillInputValue] = useState('');
    const [locationInputValue, setLocationInputValue] = useState('');

    const dispatch = useDispatch();
    const jdTrigger = useSelector((state) => state.jd?.JdTrigger);
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

    const [isUploadOpen, setIsUploadOpen] = useState(false);
    const [createdJdData, setCreatedJdData] = useState(null);

    const [openBasic2, setOpenBasic2] = useState(false);
    const [cloneData, setCloneData] = useState(null);
    const [cloneTrigger, setCloneTrigger] = useState(false);


    const handleLocationsChange = (_, newLocations) => {
        setSelectedLocations(newLocations);
    };

    const handleSkillsChange = (_, newSkills) => {
        setSelectedSkills(newSkills);
    };

    console.log(array);

    const handleLocationInputBlur = () => {
        if (locationInputValue.trim() !== '') {
            setSelectedLocations([...selectedLocations, locationInputValue?.trim()]);
            setLocationInputValue('');
        }
    };

    const handleSkillInputBlur = () => {
        if (skillInputValue.trim() !== '') {
            setSelectedSkills([...selectedSkills, skillInputValue?.trim()]);
            setSkillInputValue('');
        }
    };



    useEffect(() => {
        setFormData({
            ...formData,
            skills: selectedSkills?.join(", "),
        });
    }, [selectedSkills]);

    useEffect(() => {
        setFormData({
            ...formData,
            location: selectedLocations?.join(", "),
        });
    }, [selectedLocations]);


    useEffect(() => {
        setFormData({
            ...formData,
            exp: minExp + "-" + maxExp
        })
    }, [minExp, maxExp])


    useEffect(() => {
        if (array[0]) {
            setFormData(array[0]);
            setDescription(array[0]?.description);
            setInitialReqs(array[0]?.numOfReqs);
            { array[0]?.skills && setSelectedSkills(array[0]?.skills?.split(", ")) };
            { array[0]?.location && setSelectedLocations(array[0]?.location?.split(", ")) };
            if (array[0]?.exp) {
                setMinExp(array[0]?.exp?.split("-")[0]);
                setMaxExp(array[0]?.exp?.split("-")[1]);
            }
        }
        setMode(array[1]);
    }, [cloneTrigger]);



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        if (description !== '') {
            setFormData({
                ...formData,
                description: description,
            })
        }
    }, [description])


    useEffect(() => {
        // Add custom validation rule for special characters
        ValidatorForm.addValidationRule('noSpecialCharacters', (value) => {
            return !/[!@#$%^&*(),.?":{}|<>+=\[\];'\\/.]/g.test(value);
        });

        // Cleanup the rule when component unmounts
        return () => {
            ValidatorForm.removeValidationRule('noSpecialCharacters');
        };
    }, []);

    const checkReqs = () => {
        if (formData?.numOfReqs < initialReqs) {

            setReqsError(true);
            setFormData({
                ...formData,
                numOfReqs: initialReqs,
            });
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            jdUpload: file,
        });
    };

    const handleResetData = () => {
        setFormData(initialFormData);
        setSelectedLocations([]);
        setSelectedSkills([]);
        setMinExp(0);
        setMaxExp(0);
        setDescription('');
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (mode === "create" || mode === "clone") {
                if (description?.trim()?.length === 0) {
                    toast.warning("Job Description shouldn't be blank", 5000)
                    return;
                } else if (selectedSkills?.length === 0) {
                    toast.warning("Select atleast one skill", 5000)
                    return;
                }
                else if (selectedLocations?.length === 0) {
                    toast.warning("Select atleast one location", 5000)
                    return;
                }

                setLoading(true)
                const resObj = await addJd(formData, accessToken, clientCode);
                if (resObj) {
                    setLoading(false);
                    toast.success("JD successfully created", 5000);
                    dispatch(setJdTrigger(!jdTrigger));
                    setCreatedJdData(resObj?.data);
                    handleResetData();
                    setIsUploadOpen(true);
                }
            } else {
                if (description?.trim()?.length === 0) {
                    toast.warning("Job Description shouldn't be blank", 5000)
                    return;
                } else if (selectedSkills?.length === 0) {
                    toast.warning("Select atleast one skill", 5000)
                    return;
                } else if (selectedLocations?.length === 0) {
                    toast.warning("Select atleast one location", 5000)
                    return;
                }

                setLoading(true);
                const editRes = await editJd(formData, accessToken, clientCode);
                if (editRes) {
                    setLoading(false);
                    toast.success("JD successfully updated", 5000)
                    dispatch(setJdTrigger(!jdTrigger));
                }
            }
        } catch (error) {
            setLoading(false);
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    };


    const expArr = [
        { value: '0', text: 'Fresher' },
        { value: '1', text: '1 year' },
        { value: '2', text: '2 years' },
        { value: '3', text: '3 years' },
        { value: '4', text: '4 years' },
        { value: '5', text: '5 years' },
        { value: '6', text: '6 years' },
        { value: '7', text: '7 years' },
        { value: '8', text: '8 years' },
        { value: '9', text: '9 years' },
        { value: '10', text: '10 years' },
        { value: '11', text: '11 years' },
        { value: '12', text: '12 years' },
        { value: '13', text: '13 years' },
        { value: '14', text: '14 years' },
        { value: '15', text: '15 years' },
        { value: '16', text: '16 years' },
        { value: '17', text: '17 years' },
        { value: '18', text: '18 years' },
        { value: '19', text: '19 years' },
        { value: '20', text: '20 years' },
        { value: '21', text: '21 years' },
        { value: '22', text: '22 years' },
        { value: '23', text: '23 years' },
        { value: '24', text: '24 years' },
        { value: '25', text: '25 years' },
        { value: '26', text: '26 years' },
        { value: '27', text: '27 years' },
        { value: '28', text: '28 years' },
        { value: '29', text: '29 years' },
        { value: '30', text: '30 years' },
    ]


    return (
        <Container>
            <CommonDialog
                open={openBasic2}
                handleClose={() => setOpenBasic2(false)}
                component={
                    <CloneJDForm array={[setCloneTrigger, setOpenBasic2, setArray]} />
                }
            />

            <UploadCandidateToJdParent jdId={createdJdData?.jdId} jobSummaryHash={createdJdData?.jobSummaryHash} open={isUploadOpen} onClose={() => {
                setIsUploadOpen(false);
            }} />
            {loading && <Loader message={(mode === "create" || mode === "clone" ? "Creating JD..." : "Updating JD...")} />}
            <span className='mainTitle'>JD Registration
                <EditButton onClick={() => setOpenBasic2(true)}>
                    Clone Existing JD
                </EditButton>
            </span>
            <ValidatorForm onSubmit={handleSubmit} className="formMain">
                <div className="inputBox">
                    <TextField
                        id="outlined-basic"
                        label="JD Title"
                        variant="outlined"
                        type="text"
                        name="title"
                        value={formData?.title}
                        onChange={handleChange}
                        sx={{ backgroundColor: "#F6F6FB" }}
                        inputProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "400",
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "400",
                            },
                        }}
                        required
                        fullWidth
                    />

                </div>

                <div className="inputBox">
                    <TextField
                        id="outlined-basic"
                        label="Number of Reqs"
                        variant="outlined"
                        type="text"
                        name="numOfReqs"
                        value={formData?.numOfReqs}
                        onChange={handleChange}
                        onBlur={() => checkReqs()}
                        onFocus={() => setReqsError(false)}
                        sx={{ backgroundColor: "#F6F6FB" }}
                        inputProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "400",
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "400",
                            },
                        }}
                        required
                        fullWidth
                    />

                    <FormControl sx={{ backgroundColor: "#F6F6FB" }} fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            Candidate Availability
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Candidate Availability"
                            name="candidateAvailability"
                            value={formData?.candidateAvailability}
                            onChange={handleChange}
                            size="small"
                            inputProps={{
                                sx: {
                                    color: "#626264",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: "#626264",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                },
                            }}
                            sx={{
                                padding: "0rem 0 0.5rem 0",
                            }}
                        >
                            <MenuItem value="immediate">Immediate</MenuItem>
                            <MenuItem value="1week">1 Week</MenuItem>
                            <MenuItem value="2weeks">2 Weeks</MenuItem>
                            <MenuItem value="1month">1 Month</MenuItem>
                            <MenuItem value="2months">2 Months</MenuItem>
                            <MenuItem value="3months">3 Months</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className="inputBox">
                    <FormControl sx={{ backgroundColor: "#F6F6FB" }} fullWidth required>
                        <InputLabel id="demo-simple-select-label">Min Exp</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Min Exp"
                            name="exp"
                            value={minExp && minExp}
                            onChange={(e) => setMinExp(e.target.value)}
                            size="small"
                            inputProps={{
                                sx: {
                                    color: "#626264",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: "#626264",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                },
                            }}
                            sx={{
                                padding: "0rem 0 0.5rem 0",
                            }}

                        >
                            {expArr.map((exp) => (
                                <MenuItem value={exp.value}>{exp.text}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ backgroundColor: "#F6F6FB" }} fullWidth required>
                        <InputLabel id="demo-simple-select-label">Max Exp</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Max Exp"
                            name="exp"
                            value={maxExp && maxExp}
                            onChange={(e) => setMaxExp(e.target.value)}
                            size="small"
                            inputProps={{
                                sx: {
                                    color: "#626264",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: "#626264",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                },
                            }}
                            sx={{
                                padding: "0rem 0 0.5rem 0",
                            }}

                        >
                            {expArr.map((exp) => (
                                <MenuItem value={exp.value}>{exp.text}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <div className="fileInputBox">
                    <Label>Job Description</Label>
                    <ReactQuill theme="snow" name='description' value={description} onChange={setDescription} className="textEditor" />
                </div>

                <div className="inputBox">
                    <Stack spacing={3} sx={{ width: "100%", }}>
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={technicalSkills}
                            getOptionLabel={(option) => option}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const { inputValue } = params;
                                const isExisting = options.some((option) => inputValue === option);

                                if (inputValue !== '' && !isExisting) {
                                    filtered.push(inputValue);
                                }

                                return filtered;
                            }}
                            onChange={handleSkillsChange}
                            value={selectedSkills}
                            selectOnFocus
                            clearOnBlur
                            freeSolo
                            fullWidth
                            onInputChange={(event, newInputValue) => {
                                setSkillInputValue(newInputValue);
                            }}
                            onBlur={handleSkillInputBlur}
                            PaperComponent={(props) => <Paper {...props} sx={{ backgroundColor: '#F0F0F6' }} />}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Skills *"
                                    sx={{ backgroundColor: "#F6F6FB", }}
                                />
                            )}
                        />
                    </Stack>

                    <Stack spacing={3} sx={{ width: "100%" }}>
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={locations}
                            getOptionLabel={(option) => option}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const { inputValue } = params;
                                const isExisting = options.some((option) => inputValue === option);

                                if (inputValue !== '' && !isExisting) {
                                    filtered.push(inputValue);
                                }

                                return filtered;
                            }}
                            onChange={handleLocationsChange}
                            value={selectedLocations}
                            selectOnFocus
                            clearOnBlur
                            freeSolo

                            onInputChange={(event, newInputValue) => {
                                setLocationInputValue(newInputValue);
                            }}
                            onBlur={handleLocationInputBlur}
                            PaperComponent={(props) => <Paper {...props} sx={{ backgroundColor: '#F0F0F6' }} />}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Location *"
                                    sx={{ backgroundColor: "#F6F6FB" }}

                                />
                            )}
                            fullWidth
                        />
                    </Stack>

                </div>



                <div className="inputBox">
                    <TextField
                        id="outlined-basic"
                        label="Business unit"
                        variant="outlined"
                        type="text"
                        name="bu"
                        value={formData?.bu}
                        onChange={handleChange}
                        sx={{ backgroundColor: "#F6F6FB" }}
                        inputProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "400",
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "400",
                            },
                        }}
                        fullWidth
                    />

                    <TextField
                        id="outlined-basic"
                        label="Certification"
                        variant="outlined"
                        type="text"
                        name="certification"
                        value={formData?.certification}
                        onChange={handleChange}
                        sx={{ backgroundColor: "#F6F6FB" }}
                        inputProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "400",
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "400",
                            },
                        }}
                        fullWidth
                    />
                </div>

                <div className="inputBox">
                    <FormControl sx={{ backgroundColor: "#F6F6FB" }} fullWidth required>
                        <InputLabel id="demo-simple-select-label">CTC</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="CTC"
                            name="ctc"
                            value={formData?.ctc}
                            onChange={handleChange}
                            size="small"
                            inputProps={{
                                sx: {
                                    color: "#626264",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: "#626264",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                },
                            }}
                            sx={{
                                padding: "0rem 0 0.5rem 0",
                            }}

                        >
                            <MenuItem value="0-3">0-3 lakhs</MenuItem>
                            <MenuItem value="3-6">3-6 lakhs</MenuItem>
                            <MenuItem value="6-10">6-10 lakhs</MenuItem>
                            <MenuItem value="10-20">10-20 lakhs</MenuItem>
                            <MenuItem value="20+">20+ lakhs</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        id="outlined-basic"
                        label="Keywords"
                        variant="outlined"
                        type="text"
                        name="keywords"
                        value={formData?.keywords}
                        onChange={handleChange}
                        sx={{ backgroundColor: "#F6F6FB" }}
                        fullWidth
                        inputProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "400",
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "400",
                            },
                        }}
                    />
                </div>

                <div className="inputBox">
                    <TextField
                        id="outlined-basic"
                        label="Hiring Manager"
                        variant="outlined"
                        type="text"
                        name="hiringManager"
                        value={formData?.hiringManager}
                        onChange={handleChange}
                        sx={{ backgroundColor: "#F6F6FB" }}
                        fullWidth
                        inputProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "400",
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "400",
                            },
                        }}
                    />

                    <TextField
                        id="outlined-basic"
                        label="Recruiter"
                        variant="outlined"
                        type="text"
                        name="recruiter"
                        value={formData?.recruiter}
                        onChange={handleChange}
                        sx={{ backgroundColor: "#F6F6FB" }}
                        inputProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "400",
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "400",
                            },
                        }}
                        fullWidth
                    />
                </div>

                <div className="inputBox">
                    <FormControl sx={{ backgroundColor: "#F6F6FB" }} fullWidth required>
                        <InputLabel id="demo-simple-select-label">Work Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData?.workType}
                            label="Work Type"
                            onChange={handleChange}
                            name="workType"
                            size="small"
                            inputProps={{
                                sx: {
                                    color: "#626264",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: "#626264",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                },
                            }}
                            sx={{
                                padding: "0rem 0 0.5rem 0",
                            }}
                        >
                            <MenuItem value="fullTime">Full-time</MenuItem>
                            <MenuItem value="partTime">Part-time</MenuItem>
                            <MenuItem value="contract">Contract</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ backgroundColor: "#F6F6FB" }} fullWidth required>
                        <InputLabel id="demo-simple-select-label">Notice Period</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Notice Period"
                            name="noticePeriod"
                            value={formData?.noticePeriod}
                            onChange={handleChange}
                            size="small"
                            inputProps={{
                                sx: {
                                    color: "#626264",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: "#626264",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                },
                            }}
                            sx={{
                                padding: "0rem 0 0.5rem 0",
                            }}
                        >
                            <MenuItem value="immediate">Immediate</MenuItem>
                            <MenuItem value="1week">1 Week</MenuItem>
                            <MenuItem value="2weeks">2 Weeks</MenuItem>
                            <MenuItem value="1month">1 Month</MenuItem>
                            <MenuItem value="2months">2 Months</MenuItem>
                            <MenuItem value="3months">3 Months</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className="inputBox">
                    <FormControl sx={{ backgroundColor: "#F6F6FB" }} fullWidth required>
                        <InputLabel id="demo-simple-select-label">Company Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Company Type"
                            name="companyType"
                            value={formData?.companyType}
                            onChange={handleChange}
                            size="small"
                            inputProps={{
                                sx: {
                                    color: "#626264",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: "#626264",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                },
                            }}
                            sx={{
                                padding: "0rem 0 0.5rem 0",
                            }}
                        >
                            <MenuItem value="service">Service</MenuItem>
                            <MenuItem value="product">Product</MenuItem>
                            <MenuItem value="softwareService">Software Service</MenuItem>
                            <MenuItem value="serviceStartup">Service Start up</MenuItem>
                            <MenuItem value="productStartup">Product Start up</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ backgroundColor: "#F6F6FB" }} fullWidth required>
                        <InputLabel id="demo-simple-select-label">Visibility</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Visibility"
                            name="visibility"
                            value={formData?.visibility}
                            onChange={handleChange}
                            size="small"
                            inputProps={{
                                sx: {
                                    color: "#626264",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: "#626264",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                },
                            }}
                            sx={{
                                padding: "0rem 0 0.5rem 0",
                            }}
                        >
                            <MenuItem value="PUBLIC">Public</MenuItem>
                            <MenuItem value="PRIVATE">Private</MenuItem>
                        </Select>
                    </FormControl>

                </div>

                <Button type="submit" disabled={loading}>
                    {(mode === "create" ? "Create JD" : mode === "clone" ? "Create JD" : "Update JD")}
                </Button>
            </ValidatorForm>
        </Container >
    );
}

export default CreateJds;
