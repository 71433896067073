import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getStatusWiseCount } from '../../../functions/api/interview/getStatusWiseCount';
import { useSelector } from 'react-redux';

import metric1 from '../../../assets/icons/metric1.png'
import metric2 from '../../../assets/icons/metric2.png'
import metric3 from '../../../assets/icons/metric3.png'
import metric4 from '../../../assets/icons/metric4.png'
import metric5 from '../../../assets/icons/metric5.png'
import metric6 from '../../../assets/icons/metric6.png'
import metric7 from '../../../assets/icons/metric7.png'
import metric8 from '../../../assets/icons/metric8.png'
import metric9 from '../../../assets/icons/metric2.1.png'

import InterviewList from '../InterviewList';
import ScheduledInterviewList from '../ScheduledInterviewList';
import AppliedJobs from './sidebarPages/AppliedJobs';
import MockInterviews from './sidebarPages/MockInterviews';
import { useDispatch } from 'react-redux';
import { setCurrentMetric } from '../../../slices/configSlice';
import { getAppliedJobsCount } from '../../../functions/api/interview/getAppliedJobsCount';
import { toast } from 'react-toastify';
import InterviewInvites from '../InterviewInvites';
import { getInvitesCount } from '../../../functions/api/interview/getInvitesCount';
import { getUserSettingByKey } from '../../../functions/api/configSettings/getUserSettingByKey';
import { CircularProgress } from '@mui/material';


const MainContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 0rem;
width: 96%;
padding: 0 2%;


`

const Container = styled.div`
display: flex;
flex-direction: row;
width: 95%;
justify-content: space-between;
align-items: center;
padding: 0rem 0% 0rem 0%;
gap: 2%;


.selected {
  background: linear-gradient(to bottom, #f0f0f0, #d9fbf9);
}

  .achievedNumberBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1.7rem;
    background-color: var(--white);
    padding: 1rem 0 1.5rem 0;
    width: 22%;
    height: 6rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.5);


    .top {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      img {
        width: 3rem;
        height: 3rem;
      }
    }
  }

  .achievedNumberBox:hover {
    cursor: pointer;
  }
  
  .achievedNumberDigit {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--color);
  }
  
  .achievedNumberText {
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--color);
    text-align: center;
  } 

  .hrLine {
    width: 100%;
    border-top: 0.1rem groove lightgrey;
    margin: -0.2rem 0 -0.9rem 0;
    box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.25);
  }

  .loaderBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 17rem);
    height: calc(100% - 4.1rem);
    background-color: #f4f4f4;
    position: absolute;
    z-index: 1000;
    right: 0;
    top: 4.1rem;
    color: var(--lightOrange);
  
  }
`;


const Metrics = () => {
  const dispatch = useDispatch();
  const currentMetric = useSelector((state) => state.config?.currentMetric);
  const [metrics, setMetrics] = useState([]);
  const [mockMetrics, setMockMetrics] = useState([]);
  const [scheduled, setScheduled] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [mockCompleted, setMockCompleted] = useState(0);
  const [appliedJobsMetric, setAppliedJobsMetric] = useState(0);
  const [inviteCounts, setInviteCounts] = useState(0);
  const accessToken = useSelector(state => state.auth.userData?.accessToken)
  const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

  const [dashboardMetrics, setDashboardMetrics] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getDashboardConfiguration = async () => {
      try {
        setLoading(true);
        const dashboard = await getUserSettingByKey(accessToken, clientCode, "seekerDashboardMetrics")
        const dashboardOptional = await getUserSettingByKey(accessToken, clientCode, "seekerDashboardOptionalMetrics")

        if (dashboard) {
          setDashboardMetrics(dashboard?.data?.itemValue)
          setLoading(false)
        }

      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
        setLoading(false)
      }
    }
    getDashboardConfiguration()
  }, []);


  useEffect(() => {
    const getCount = async () => {
      try {
        const res = await getStatusWiseCount(accessToken);
        const res2 = await getStatusWiseCount(accessToken, true);
        if (res2) setMockMetrics(res2?.data);
        if (res) setMetrics(res?.data);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }

    const getAppliedJobsCounts = async () => {
      try {
        const res = await getAppliedJobsCount(accessToken);
        setAppliedJobsMetric(res?.data?.count);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }

    const getInvites = async () => {
      try {
        const res = await getInvitesCount(accessToken);
        if (res) setInviteCounts(res?.data);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }

    getCount();
    getAppliedJobsCounts();
    getInvites();
  }, [currentMetric])

  useEffect(() => {
    if (metrics?.length) {
      setCompleted(metrics?.find((item) => item?.status == "COMPLETED")?.count);
      setScheduled(metrics?.find((item) => item?.status == "SCHEDULED")?.count)
    }
  }, [metrics])

  useEffect(() => {
    if (mockMetrics?.length) {
      setMockCompleted(mockMetrics?.find((item) => item?.status == "COMPLETED")?.count);
    }
  }, [mockMetrics])


  const handleCurrentMetricChange = (metric) => {
    dispatch(setCurrentMetric(metric));
  }

  // interviewsUpcoming,interviewsInProgress,profileViews,myProfile

  const getMetricDisplayText = (text) => {
    switch (text) {
      case "interviewsScheduled":
        return "Interviews Scheduled";
      case "interviewsCompleted":
        return "Interviews Completed";
      case "mockInterviews":
        return "Mock Interviews";
      case "appliedJobs":
        return "Applied Jobs";
      case "interviewInvites":
        return "Interview Invites";
      case "interviewsUpcoming":
        return "";
      case "interviewsInProgress":
        return "";
      case "profileViews":
        return "";
      case "myProfile":
        return "";
      default:
        return "";
    }
  }


  const getMetricImage = (text) => {
    switch (text) {
      case "interviewsScheduled":
        return metric1;
      case "interviewsCompleted":
        return metric2;
      case "mockInterviews":
        return metric9;
      case "appliedJobs":
        return metric3;
      case "interviewInvites":
        return metric4;
      case "interviewsUpcoming":
        return metric5;
      case "interviewsInProgress":
        return metric6;
      case "profileViews":
        return metric7;
      case "myProfile":
        return metric8;
      default:
        return "";
    }
  }

  const getMetricCount = (text) => {
    switch (text) {
      case "interviewsScheduled":
        return scheduled ? scheduled : 0;
      case "interviewsCompleted":
        return completed ? completed : 0;
      case "mockInterviews":
        return mockCompleted ? mockCompleted : 0;
      case "appliedJobs":
        return appliedJobsMetric ? appliedJobsMetric : 0;
      case "interviewInvites":
        return inviteCounts ? inviteCounts : 0;
      case "interviewsUpcoming":
        return 0;
      case "interviewsInProgress":
        return 0;
      case "profileViews":
        return 0;
      case "myProfile":
        return 0;
      default:
        return 0;
    }
  }


  return (
    <MainContainer>
      <Container>
        {loading ? <div className='loaderBox'>
          <CircularProgress color="inherit" />
        </div> :
          dashboardMetrics?.split(",")?.map((metric, i) => (
            <div className={`achievedNumberBox ${currentMetric === metric ? 'selected' : ''}`} onClick={() => handleCurrentMetricChange(metric)}>
              <div className='top'>
                <img src={getMetricImage(metric)} />
                <span className='achievedNumberDigit'>{getMetricCount(metric)}</span>
              </div>
              <span className='hrLine'></span>
              <span className='achievedNumberText'>{getMetricDisplayText(metric)}</span>
            </div>
          ))
        }
      </Container>


      {currentMetric === 'interviewInvites' && <InterviewInvites />}

      {currentMetric === 'appliedJobs' && <AppliedJobs />}
      {currentMetric === 'interviewsCompleted' && <InterviewList />}
      {currentMetric === 'interviewsScheduled' && <ScheduledInterviewList />}
      {currentMetric === 'mockInterviews' && <MockInterviews />}
    </MainContainer>
  );
};


export default Metrics;
