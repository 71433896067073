import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateTestStatus } from "../functions/api/academicAPIs/updateTestStatus";
import { getAcademicTestQuestion } from "../functions/api/academicAPIs/getAcademicTestQuestion";

export const updateTest = createAsyncThunk('test/status', async ({ id, status, accessToken }) => {
    let response;
    try {
        response = await updateTestStatus(id, status, accessToken);
        if (response.response?.data?.status == "FAILED") {
            throw new Error(JSON.stringify(response?.response?.data));
        }
        return response.data;
    } catch (error) {
        throw new Error(error?.response?.data?.notify?.message || error?.message);
    }
})

export const fetchTestQuestion = createAsyncThunk('test/question', async ({ id, accessToken, restart }) => {
    const response = await getAcademicTestQuestion(id, accessToken, restart);
    return response.data;
})

export const submitTest = createAsyncThunk('test/answer', async ({ input,
    id,
    lastQuestion,
    interviewId,
    accessToken }) => {
    const response = await submitAcademicAnswer(input,
        id,
        lastQuestion,
        interviewId,
        accessToken);
    return response.data;
})

const initialState = {
    testData: null,
    questionData: null,
    answerData: null,
    status: 'idle',
    error: null,
}

const academicTestSlice = createSlice({
    name: 'test',
    initialState,
    reducers: {
        clearError: (state, action) => {
            state.error = null;
            state.testData = null;
        },
        resetTestData: (state) => {
            state.testData = null;
        }
    },

    extraReducers: (builder) => {
        builder
            .
            addCase(updateTest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateTest.fulfilled, (state, action) => {
                console.log(action);
                state.testData = action.payload;
                state.status = 'succeeded';
            })
            .addCase(updateTest.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action?.error?.message;
            })
            .
            addCase(fetchTestQuestion.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTestQuestion.fulfilled, (state, action) => {
                state.questionData = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchTestQuestion.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .
            addCase(submitTest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(submitTest.fulfilled, (state, action) => {
                state.answerData = action.payload;
                state.status = 'succeeded';
            })
            .addCase(submitTest.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export const { clearError, resetTestData } = academicTestSlice.actions;
export default academicTestSlice.reducer;


