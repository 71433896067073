import React from 'react'
import styled from 'styled-components'


const courseList = ['My Course 1','My Course 2','My Course 3','My Course 4']

const MyCourses = () => {

    return (
        <Box>
            <span className='title'>My Courses</span>
            <div className='cardBox'>
                {
                    courseList?.map((course, i) => (
                        <div className='card' key={i}>
                            <div className='cardImg'></div>
                            <span className='cardText'>{course}</span>
                            <btn className='cardBtn'>View Details</btn>
                        </div>
                    ))
                }
            </div>
        </Box>
    )
}

export default MyCourses


const Box = styled.div`
display: flex;
flex-direction: column;
justify-content: start;
width: 94%;
margin: 1rem auto;
background-color: var(--white);
border-radius: 1rem;
padding: 2rem 3rem;
box-sizing: border-box;
gap: 2rem;
box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.20);




.title {
    font-size: 1.5rem;
    font-weight: 700;
    font-family: var(--font);
    color: var(--color);

}

.cardBox {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: start;
    gap: 1rem;


    .card {
        min-width: 20%;
        padding: 0.75rem 0.75rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.75rem;


        .cardImg {
            width: 100%;
            border-radius: 1rem;
            height: 15rem;
            background: rgb(206,203,239);
background: linear-gradient(0deg, rgba(206,203,239,1) 0%, rgba(239,236,247,1) 100%);
            box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.25);
        }
        
        .cardText {
            font-size: 1rem;
            font-weight: 600;
        }

        .cardBtn {
            background-color: var(--white);
            border: 0.08rem solid var(--color);
            padding: 0.25rem 0.75rem;
            border-radius: 0.25rem;
            font-size: 0.9rem;
            font-weight: 600;
            cursor: pointer;
        }
    }
}



`