import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import styled from 'styled-components'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { editCandidate } from '../../../../functions/api/employers/editCandidate';
import Loader from '../../../commonComponents/Loader';


const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 65%;
    width: 60%;
    height: 70%;
    margin-top: 5rem;
  }

  .btn {
   background-color: var(--lightOrange);
   margin-top: 1rem;
   font-size: 0.9rem;
   font-weight: 600;
   padding: 0.5rem 0.8rem;
   border: none;
   border-radius: 0.5rem;
   color: var(--white);
   align-self: center;
   cursor: pointer;
  }

  .box {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 1.5rem;
   margin: 1.5rem auto;
  
  }

  .form {
   display: flex;
   flex-direction: column;
   gap: 1.5rem;
   width: 100%;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;



const UpdateMatchedResumeDetails = ({ open, onClose, data, handleTrigger }) => {
    const accessToken = useSelector((state) => state.auth.userData.accessToken);
    const clientCode = useSelector(
        (state) => state.auth.userData.user.clientCode
    );
    const [correctedEmail, setCorrectedEmail] = useState(data?.email);
    const [correctedName, setCorrectedName] = useState(data?.name);
    const [correctedContact, setCorrectedContact] = useState(data?.contact);
    const [correctedNameTouched, setCorrectedNameTouched] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const formData = {
                clientCode: clientCode,
                email: data?.email,
                correctedEmail: correctedEmail,
                correctedName: correctedName,
                correctedContact: correctedContact,
                deleteAttachedInterview: false
            }

            const res = await editCandidate(formData, accessToken, clientCode);
            if(res){
                setLoading(false);
                handleTrigger();
                toast.success("Candidate details successfully updated", 5000);
                onClose();
            }
        } catch (error) {
            setLoading(false);
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            onClose();
        }
    };

    return (
        <StyledDialog open={open} onClose={handleClose} disableEscapeKeyDown>
            {loading && <Loader message="Updating Candidate Details..." />}

            <StyledDialogTitle>
                Update Matched Candidate Details.
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </StyledDialogTitle>
            <DialogContent>
                <ValidatorForm onSubmit={handleSubmit} className='form'>
                    <TextValidator id="outlined-basic" label="Email" variant="outlined"
                        type='email'
                        value={correctedEmail}
                        onChange={(e) => setCorrectedEmail(e.target.value)}
                        errorMessages={["This field is required", 'Email is not valid']}
                        validators={['required', 'isEmail']}
                        inputProps={{
                            sx: {
                                color: '#626264',
                                fontSize: '0.8rem',
                                fontWeight: '400'
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                color: '#626264',
                                fontSize: '0.8rem',
                                fontWeight: '400'
                            },
                        }}
                        fullWidth />

                    <TextValidator id="outlined-basic" label="Name" variant="outlined"
                        type='text'
                        value={correctedName}
                        onChange={(e) => {
                            setCorrectedName(e.target.value)
                            setCorrectedNameTouched(true);
                        }}
                        errorMessages={[correctedNameTouched ? "This field is required" : "", correctedNameTouched ? 'Must be a least 3 characters long' : "", correctedNameTouched ? 'Must be less than 30 chatacters long' : ""]}
                        validators={['required', correctedNameTouched ? 'minStringLength:3' : 'minStringLength:0', 'maxStringLength:29']}
                        fullWidth
                        inputProps={{
                            sx: {
                                color: '#626264',
                                fontSize: '0.8rem',
                                fontWeight: '400'
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                color: '#626264',
                                fontSize: '0.8rem',
                                fontWeight: '400'
                            },
                        }} />

                    <TextValidator id="outlined-basic" label="Phone" variant="outlined"
                        type='tel'
                        value={correctedContact}
                        onChange={(e) => {
                            setCorrectedContact(e.target.value)
                        }}
                        errorMessages={["This field is required",]}
                        validators={['required',]}
                        fullWidth
                        inputProps={{
                            sx: {
                                color: '#626264',
                                fontSize: '0.8rem',
                                fontWeight: '400'
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                color: '#626264',
                                fontSize: '0.8rem',
                                fontWeight: '400'
                            },
                        }}
                    />
                    <button className='btn' type='submit'>Update Candidate Details</button>

                </ValidatorForm>
            </DialogContent>
        </StyledDialog>
    );
};

export default UpdateMatchedResumeDetails;