import React from 'react'
import styled from 'styled-components'

const QuestionTypeCard = ({data, func}) => {

    return (
        <Box onClick={() => func(data?.questionType)}>
            <span className='title'>{data?.title}</span>
            <span className='desc'>{data?.description}</span>
        </Box>
    )
}

export default QuestionTypeCard

const Box = styled.div`
width: 25%;
display: flex;
flex-direction: column;
gap: 1rem;
align-items: center;
padding: 2rem 2.5rem;
border: 0.075rem solid lightgrey;
border-radius: 0.25rem;
box-sizing: border-box;
cursor: pointer;


.title {
font-size: 1rem;
font-weight: 700;
}

.desc {
font-size: 0.9rem;
font-weight: 500;
text-align: center;
}

`