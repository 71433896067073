import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton, Box } from '@mui/material';
import { Mic, CircleStop } from 'lucide-react';
// import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { styled } from "styled-components";
import { toast } from 'react-toastify';
import { addManagerAnswers } from '../../../../functions/api/employers/addManagerAnswers';
import { useSelector } from 'react-redux';


const AddManagerAnswersToJD = ({ open, onClose, jdId }) => {
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

    const initialAnswers = {
        technicalSkills: '',
        nonTechnicalSkills: '',
        culturalFit: '',
        teamDynamics: '',
        roleDevelopment: ''
    };

    const [answers, setAnswers] = useState(initialAnswers);

    const [activeQuestion, setActiveQuestion] = useState(null);

    // const {
    //     transcript,
    //     listening,
    //     resetTranscript,
    //     browserSupportsSpeechRecognition
    // } = useSpeechRecognition();

    // useEffect(() => {
    //     if (activeQuestion && transcript) {
    //         setAnswers(prev => ({
    //             ...prev,
    //             [activeQuestion]: transcript
    //         }));
    //     }
    // }, [transcript, activeQuestion]);

    const handleTextChange = useCallback((question, value) => {
        setAnswers(prev => ({ ...prev, [question]: value }));
    }, []);

    // const handleStartRecording = useCallback((question) => {
    //     setActiveQuestion(question);
    //     resetTranscript();
    //     SpeechRecognition.startListening({ continuous: true });
    // }, [resetTranscript]);

    // const handleStopRecording = useCallback(() => {
    //     SpeechRecognition.stopListening();
    //     setActiveQuestion(null);
    // }, []);


    const handleSubmit = async () => {
        try {
            const technicalSkills = answers?.technicalSkills ? answers?.technicalSkills : "Not Available";
            const nonTechnicalSkills = answers?.nonTechnicalSkills ? answers?.nonTechnicalSkills : "Not Available";
            const culturalFit = answers?.culturalFit ? answers?.culturalFit : "Not Available";
            const teamDynamics = answers?.teamDynamics ? answers?.teamDynamics : "Not Available";
            const roleDevelopment = answers?.roleDevelopment ? answers?.roleDevelopment : "Not Available";

            const payloadData = [technicalSkills, nonTechnicalSkills, culturalFit, teamDynamics, roleDevelopment];
            const res = await addManagerAnswers(payloadData, jdId, accessToken, clientCode);
            if (res) {
                toast.success("Manager Answers added to JD", 5000);
                onClose();
                setAnswers(initialAnswers)
            }
        } catch (error) {
            const errMsg =
                error.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    }

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            onClose();
        }
    };

    const questions = [
        { key: 'technicalSkills', text: 'What are the essential technical skills and experience required for this role, including specific technologies, programming languages, and tools?' },
        { key: 'nonTechnicalSkills', text: 'What key non-technical skills are important for this role, such as communication, problem-solving, and project management?' },
        { key: 'culturalFit', text: 'Can you describe the company culture and the type of personality traits and values that would thrive in this environment?' },
        { key: 'teamDynamics', text: 'What are the specific needs and dynamics of the team this role will be part of, and how should the candidate complement them?' },
        { key: 'roleDevelopment', text: 'What opportunities for growth and development does this role offer, and how does it fit into the broader career path within the organization?' }
    ];

    return (
        <StyledDialog open={open} onClose={handleClose} disableEscapeKeyDown>
            <DialogTitle>Job Description By Hiring Manager</DialogTitle>
            <DialogContent>
                <MainBox>
                    {questions?.map(({ key, text }) => (
                        <Box key={key} sx={{ mb: 3 }}>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                label={text}
                                value={answers[key]}
                                onChange={(e) => handleTextChange(key, e.target.value)}
                                variant="outlined"
                            />
                            {/* <IconButton
                                color={listening && activeQuestion === key ? "secondary" : "primary"}
                                onClick={() => listening && activeQuestion === key ? handleStopRecording() : handleStartRecording(key)}
                            >
                                {listening && activeQuestion === key ? <CircleStop /> : <Mic />}
                            </IconButton> */}
                        </Box>
                    ))}
                </MainBox>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    onClose();
                    setAnswers(initialAnswers);
                }} sx={{ color: "var(--lightOrange)" }} >Cancel</Button>
                <Button onClick={handleSubmit} variant="contained" sx={{ backgroundColor: "var(--lightOrange)" }}>Submit</Button>
            </DialogActions>
        </StyledDialog>
    );
};

export default AddManagerAnswersToJD;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 85%;
    width: 100%;
    height: 80%;
    margin-top: 5rem;
  }

    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 0.4rem;
    }
    &::-webkit-scrollbar-track {
        background: lightgrey;
        border-radius: 0.4rem;
    }
    &::-webkit-scrollbar-thumb {
        background: grey;
        width: 0.4rem;
        border-radius: 0.4rem;
    }
    scrollbar-width: thin;
    scrollbar-color: grey lightgrey;
`;

const MainBox = styled.div`
    padding: 2rem 0;
`;