import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { addFailedScreenshotUpload, removeAllFailedUploads, removeFailedScreenshotUpload } from '../../../slices/uploadSnapSlice';
import { uploadMedia } from '../../../functions/api/interview/uploadMedia';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

const useScreenCapture = () => {
    const [mediaStream, setMediaStream] = useState(null);
    const [capturedImages, setCapturedImages] = useState([]);
    const [intervalId, setIntervalId] = useState(null);
    const [permissionGranted, setPermissionGranted] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const intervalTime = 60000;
    const dispatch = useDispatch();
    const accessToken = useSelector((state) => state.auth.userData?.accessToken);
    const clientCode = useSelector((state) => state.auth.userData?.user?.clientCode);

    const { interviewId } = useParams();
    const failedScreenShots = useSelector((state) => state.uploadSnap.failedScreenshotUploadsQueue);


    useEffect(() => {
        let timeoutIds = [];

        if (failedScreenShots?.length > 0) {
            failedScreenShots?.forEach((shot, i) => {
                const timeoutId = setTimeout(() => {
                    uploadScreenShot(shot?.image, interviewId, 'screenshot', shot?.image?.name);
                }, i * 3 * 1000);
                timeoutIds.push(timeoutId);
            });
        }

        return () => {
            timeoutIds.forEach((timeoutId) => {
                clearTimeout(timeoutId);
            });
        };
    }, [failedScreenShots]);


    let a = 0;

    useEffect(() => {
        if (!permissionGranted && a != 0) {
            setShowModal(true);
        }
    }, [permissionGranted]);

    useEffect(() => {
        return () => {
            stopCapturingScreenshots();
            dispatch(removeAllFailedUploads());
        };
    }, []);

    const uploadScreenShot = async (file, id, type, fileName) => {

        try {
            const formData = new FormData();
            formData.append('file', file);

            const res = await uploadMedia(formData, id, type, accessToken, clientCode)

            if (res) {
                dispatch(removeFailedScreenshotUpload({ id: `${id}${fileName}` }))
            }
        }
        catch (error) {
            dispatch(addFailedScreenshotUpload({ id: `${id}${fileName}`, image: file }))
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
        }
    }

    const stopCapturingScreenshots = () => {
        if (mediaStream) {
            mediaStream.getTracks().forEach(track => track.stop());
        }

        if (intervalId) {
            clearInterval(intervalId);
        }
        setCapturedImages([]);
    };

    let i = 1;


    let screenshotIndex = 1;

    const takeScreenshot = (stream) => {
        const videoTrack = stream.getVideoTracks()[0];

        if (!videoTrack || videoTrack.readyState === 'ended') {
            if (i === 1) {
                setShowModal(true);
                toast.warning('Please share screen to proceed with interview!');
                i--;
            }
            return;
        }

        const imageCapture = new ImageCapture(videoTrack);

        imageCapture.grabFrame()
            .then(imageBitmap => {
                const canvas = document.createElement('canvas');
                canvas.width = imageBitmap.width;
                canvas.height = imageBitmap.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(imageBitmap, 0, 0, imageBitmap.width, imageBitmap.height);

                canvas.toBlob(blob => {
                    const screenshotName = `screenshot${screenshotIndex++}.jpg`;
                    const imageFile = new File([blob], screenshotName, { type: 'image/jpeg' });

                    if (imageFile) {
                        uploadScreenShot(imageFile, interviewId, 'screenshot', screenshotName);
                    }
                    setCapturedImages(prevImages => [...prevImages, imageFile]);
                }, 'image/jpeg', 1);
            }).catch(error => {
                setShowModal(true);
                toast.error('Error grabbing frame:', error);
            });
    };

    const handleScreenShotAccess = async () => {
        try {
            if (mediaStream) {
                mediaStream.getTracks().forEach(track => track.stop());
            }

            await navigator.mediaDevices
                .getDisplayMedia({ video: { displaySurface: 'monitor' } })
                .then((strm) => {
                    let displaySurface = strm.getVideoTracks()[0].getSettings().displaySurface;

                    if (displaySurface !== 'monitor') {
                        stopCapturingScreenshots();
                        setShowModal(true);
                        throw 'Please Select entire screen to proceed with interview!';
                    }
                    setMediaStream(strm);

                    const id = setInterval(() => takeScreenshot(strm), intervalTime);
                    a++;
                    setIntervalId(id);
                    setPermissionGranted(true);
                    setShowModal(false);
                }).catch((err) => {
                    toast.warning(err);
                });
        } catch (error) {
            toast.error('Error requesting screen sharing permission:', error);
        }
    };

    return { capturedImages, showModal, setShowModal, handleScreenShotAccess, stopCapturingScreenshots, permissionGranted };
};

export default useScreenCapture;


