import axios from "axios";

export const importCandidatesFromJds = async (jdId, jdIdStrings, accessToken, clientCode) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            "X-Client-Code": clientCode,
        },
    };

    console.log(config);

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/candidates/importCandidatesFromJds/${jdId}?jdIds=${jdIdStrings}`, "", config);

        console.log("Data:", response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
};
