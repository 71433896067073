import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    block-size: 100%;
    inline-size: 100%;
    background: #f8fcff;
  }

  body {
    min-block-size: 100%;
    min-inline-size: 100%;
    box-sizing: border-box;
    display: grid;
    place-content: center;
    font-family: var(--font);
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

`;

const RadialProgress = styled.div`
  --hue: 176;
  --holesize: 65%;
  --track-bg: hsl(233 34% 92%);
  block-size: 5vmin;
  inline-size: 5vmin;
  min-inline-size: 100px;
  min-block-size: 100px;
  display: grid;
  place-items: center;
  position: relative;
  font-weight: 700;
  font-size: max(4vmin, 1rem);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    z-index: -1;
    background: conic-gradient(
      hsl(var(--hue) 100% 70%),
      hsl(var(--hue) 100% 40%),
      hsl(var(--hue) 100% 70%) var(--progress, 0%),
      var(--track-bg) var(--progress, 0%) 100%
    );

    mask-image: radial-gradient(
      transparent var(--holesize),
      black calc(var(--holesize) + 0.5px)
    );
  }
`;

const ProgressText = styled.div`
  margin-top: 1rem;
  font-size: 0.9rem;
  font-weight: 600;
  text-align: center;
`;

const ProgressTextBold = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
`;


function InterviewLoader({ message }) {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(prevSeconds => (prevSeconds < 180 ? prevSeconds + 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const progress = Math.round((seconds / 180) * 100);

  useEffect(() => {
    const radialProgress = document.querySelector('.RadialProgress');
    const value = `${progress}%`;
    radialProgress.style.setProperty('--progress', value);
    radialProgress.innerHTML = value;
    radialProgress.setAttribute('aria-valuenow', value);
  }, [progress]);

  return (
    <div className="App">
      <GlobalStyle />
      <Container>
      <RadialProgress
          className="RadialProgress"
          role="progressbar"
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
        ></RadialProgress>
        <ProgressText>{message}</ProgressText>
        <ProgressTextBold>It can take upto 3 minutes.</ProgressTextBold>
      </Container>
    </div>
  );
}

export default InterviewLoader;