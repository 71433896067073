import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL

export const getTests = async (accessToken, clientCode, published) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'x-client-code': clientCode
        }
    };

    try {
        const response = await axios.get(`${API_URL}/api/tests?publish=${published}`, config);
        return response?.data;
    } catch (error) {
        throw error;
    }
}


export const createTest = async (payload, accessToken, clientCode) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'x-client-code': clientCode
        }
    };

    try {
        const response = await axios.post(`${API_URL}/api/tests`, payload, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const updateTest = async (payload, id, accessToken, clientCode) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'x-client-code': clientCode
        }
    };

    try {
        const response = await axios.put(`${API_URL}/api/tests/${id}`, payload, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const deleteTest = async (id, accessToken, clientCode) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'x-client-code': clientCode
        }
    };

    try {
        const response = await axios.delete(`${API_URL}/api/tests/${id}`, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const publishTest = async (accessToken, clientCode, id, published) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'x-client-code': clientCode
        }
    };

    try {
        const response = await axios.patch(`${API_URL}/api/tests/${id}?publish=${published}`, "", config);
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const createAITest = async (payload, accessToken, clientCode) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'x-client-code': clientCode
        }
    };

    try {
        const response = await axios.post(`${API_URL}/api/tests`, payload, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}

