
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentItem: "dashboard",
    currentMetric: "",
    defaultResume: {resumeId:"",templateId:""},
    currentTrackerTab: "MATCHED",
};

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setCurrentItem: (state, action) => {
            state.currentItem = action.payload;
        },
        setCurrentMetric: (state, action) => {
            state.currentMetric = action.payload;
        },
        setDefaultResumeDetails: (state,action) => {
            state.defaultResume = action.payload;
        },
        setCurrentTrackerTab: (state, action) => {
            state.currentTrackerTab = action.payload;
        }
    },
});

export const { setCurrentItem, setCurrentMetric,setDefaultResumeDetails, setCurrentTrackerTab } = configSlice.actions;
export default configSlice.reducer;
