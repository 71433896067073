import React from 'react';
import styled from 'styled-components';
import img1 from '../../../assets/Academic/middleSchool.webp'
import img2 from '../../../assets/Academic/highSchool.webp'
import img3 from '../../../assets/Academic/competitiveExam.webp'
import img4 from '../../../assets/Academic/governmentExam.webp'

const GroupSectionWrapper = styled.section`
  padding: 2rem;
  background: rgb(239,236,247);
background: linear-gradient(90deg, rgba(239,236,247,1) 0%, rgba(239,236,247,1) 35%, rgba(168,162,226,1) 100%);
  // background-color: #f0f8ff;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  gap: 5%;
`;

const Group = styled.div`
  margin: 2rem 0;
  padding: 2.5rem 1rem;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-size: 1.5rem;
    font-size: 600;
    line-height: 1.5rem;
    margin-bottom: 0rem;
  }

  p {
    margin-bottom: 1.5rem;
  }

  .img {
    width: 7.5rem;
    heigth: 7.5rem;
  }

`;

const Button = styled.button`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #515290;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #442f90;
  }
`;

const groups = [
  {
    img: img1,
    title: 'Middle School',
    description: 'Our curated courses and resources for grades 6-8 are designed to build a strong academic foundation. These materials focus on fundamental concepts in core subjects, preparing students for higher education while fostering a love for learning.',
    courses: ['Math', 'Science', 'Social Science'],
  },
  {
    img: img2,
    title: 'High School',
    description: 'We provide advanced study materials and resources for students in grades 9-12, aimed at helping them excel in board exams. Our content covers a wide range of subjects, with detailed explanations and practice exercises to ensure comprehensive understanding and top performance.',
    courses: ['Math', 'Science', 'Social Science', 'English'],
  },
  {
    img: img3,
    title: 'Competitive Exams',
    description: 'Our intensive preparation resources are tailored for competitive exams like JEE, NEET, and other significant entrance tests. We offer targeted study plans, expert guidance, and extensive practice materials to help students achieve high scores and secure their desired seats.',
    courses: ['JEE', 'NEET', 'NDA'],
  },
  {
    img: img4,    
    title: 'Government Exams',
    description: 'We provide comprehensive study materials for various government exams including NDA, CDS, SSB, and more. Our resources are designed to cover all aspects of the syllabus, with practice tests and expert insights to boost confidence and ensure thorough preparation.',
    courses: ['NDA', 'CDS', 'SSB'],
  },
];

const scrollToCategory = (categoryName) => {
  const element = document.getElementById(categoryName);
  if (element) {
    const offset = element.offsetTop - 5 * 16; // 5rem converted to pixels (assuming 1rem = 16px)
    window.scrollTo({
      top: offset,
      behavior: 'smooth'
    });
  }
};


const GroupSection = () => (
    <GroupSectionWrapper>
        {groups.map((group, index) => (
            <Group key={index} onClick={() => scrollToCategory(group.title)}>
                <img src={group.img} alt={group.title} className='img'/>
                <h2>{group.title}</h2>
                <p>{group.description}</p>
                <Button>Explore Resources</Button>
            </Group>
        ))}
    </GroupSectionWrapper>
);

export default GroupSection;
