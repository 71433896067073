import React from 'react';
import styled from 'styled-components';
import { AlertCircle, CalendarCheck, ArrowBigRight, MessageSquareX } from 'lucide-react';

const MessageContainer = styled.div`
  width: 75%;
  margin: 1rem auto;
  padding: 1rem;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0rem;
  color: #f59e0b;
`;

const Title = styled.h2`
  margin-left: 0.75rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937;
`;

const Message = styled.p`
  margin-bottom: 1rem;
  line-height: 1.25;
  color: #4b5563;
`;

const InstructionList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 1.5rem 0;
`;

const InstructionItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #f3f4f6;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e5e7eb;
  }
`;

const IconWrapper = styled.span`
  margin-right: 1rem;
  color: #059669;
`;

const Emphasis = styled.span`
  font-weight: bold;
  color: #1f2937;
`;

const BtnBox = styled.div`
display: flex;
width: 75%;
align-self: center;
gap: 1rem;
justify-content: center;
margin-bottom: 2rem;
min-height: 2rem;
`

const Btn = styled.button`
padding: 0.5rem 0.8rem;
border-radius: 0.5rem;
background-color: var(--lightOrange);
border: none;
color: var(--white);
cursor: pointer;

`

const InstructionForInProcessCandidateCheck = ({jdId, email, handleNavigateToScheduled, handleNavigateToCompleted, handleClose}) => {
    return (
        <MessageContainer>
            <Header>
                <AlertCircle size={28} />
                <Title>Important: Candidate is already in process with this JD</Title>
            </Header>
            <Message>
                The Candidate you're trying to schedule Interview for is already in Process with Email ID: {email} and JD ID: {jdId}, So you can choose from below 2 options as per your requirements and proceed.
            </Message>
            <Message>
                <Emphasis>
                    You can perform any action from below 3:
                </Emphasis>
            </Message>
            <InstructionList>
                <InstructionItem>
                    <IconWrapper><CalendarCheck size={22} /></IconWrapper>
                    <div>
                        <Message as="p"><Emphasis>If you want to reschedule the interview, then click on the "Reschedule" button below.</Emphasis></Message>
                    </div>
                </InstructionItem>
                <InstructionItem>
                    <IconWrapper><ArrowBigRight size={22} /></IconWrapper>
                    <div>
                        <Message as="p"><Emphasis>If you want to move the candidate to the next round, then click on the "Move to Next Round" button below.</Emphasis></Message>
                    </div>
                </InstructionItem>
                <InstructionItem>
                    <IconWrapper><MessageSquareX size={22} /></IconWrapper>
                    <div>
                        <Message as="p"><Emphasis>If you want to close this modal and continue with other candidates Email and schedule their Interview, then click on the "Close & Continue" button below.</Emphasis></Message>
                    </div>
                </InstructionItem>
            </InstructionList>
            <BtnBox>
                <Btn onClick={handleNavigateToScheduled}>Reschedule</Btn>
                <Btn onClick={handleNavigateToCompleted}>Move to Next Round</Btn>
                <Btn onClick={handleClose}>Close & Continue</Btn>
            </BtnBox>
            <BtnBox></BtnBox>
        </MessageContainer>
    );
};

export default InstructionForInProcessCandidateCheck;

