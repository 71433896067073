import axios from "axios";


export const updateFeedback = async (interviewId, requestData, accessToken) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/interviews/${interviewId}/feedbacks`, requestData, config);
        return response?.data;
    } catch (error) {
        throw error;
    }
}