
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import logo from "../../../assets/regorTalentLogo.webp";
import { useSelector } from "react-redux";
import profileIcon from '../../../assets/icons/userWhiteIcon.png'
import { persistor } from "../../../store";
import { logout } from "../../../slices/authSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getAllIssuesByJqlQuery } from "../../../functions/api/AtlassianAPIs/getAllIssuesByJqlQuery";


const JiraHeader = () => {
  const authenticatedUserEmail = useSelector(state => state.auth.userData?.user?.email);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector(state => state.auth?.userData?.accessToken);
  const clientCode = useSelector((state) => state.auth.userData?.user?.clientCode);
  const user = useSelector(state => state.auth?.userData?.user);
  const [requestCounts, setRequestCounts] = useState(0);
  const [jqlQuery, setJqlQuery] = useState(`reporter = "${authenticatedUserEmail}" ORDER BY Created DESC`);
  const {portalId} = useParams();


  const handleLogout = () => {
    persistor.purge();
    dispatch(logout())
    navigate(`/servicedesk/customer/portal/${portalId}`);
  };

  const handleNavigateToIssues = (reporterEmail) => {
    navigate(`/servicedesk/customer/user/requests/${portalId}?reporter=${reporterEmail}`)
  }

  useEffect(() => {
    const getAllIssues = async () => {
        try {
            const res = await getAllIssuesByJqlQuery(accessToken, clientCode, jqlQuery);
            if (res) {
                setRequestCounts(res?.data?.length);
            }
        }
        catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    }
    getAllIssues();
}, [])


  return (
    <StyledDiv>
      <div id="left">
        <img src={logo} onClick={() => navigate(`/servicedesk/customer/portal/${portalId}`)} />
      </div>

      <div id="right">
        <span onClick={() => handleNavigateToIssues(user?.email)} className="coloredText"><span className="coloredCount">{requestCounts}</span> Requests</span>

        <div className="profileIcon">
          <img src={profileIcon} className="profileImg" />
        </div>

        <div class="dropdown" id="dropdown">
          <span onClick={handleLogout} className="span">Logout</span>
        </div>
      </div>
    </StyledDiv>
  );
};

export default JiraHeader;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color);
  width: 95%;
  padding: 0% 2.5% 0.5rem 2.5%;
  height: 4rem;
  position: absolute;
  z-index: 2000;
  background-color: transparent;

  #left > img {
    height: 3rem;
    margin-left: -5%;
    cursor: pointer;
  }

  h1 {
    margin: 0;
    color: #add8e6;
  }

  #right {
    display: flex;
    align-items: center;
    gap: 1.25rem;
  }

  .coloredText {
    font-size: 0.9rem;
    color: var(--color);
    font-weight: 600;
    height: 2.5rem;
    cursor: pointer;
    background-color: var(--white);
    padding: 0.5rem 0.75rem;
    border: 0.05rem solid var(--white);
    border-radius: 1.25rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;


    .coloredCount {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--lightOrange);
        padding: 0.5rem;
        border: 0.1rem solid var(--white);
        font-size: 0.9rem;
        font-weight: 600;
        color: var(--white);
        border-radius: 50%;
        height: 2rem;
        width: 2rem;
        box-sizing: border-box;

    }
  }

  .coloredText:hover {
    background-color: #f6f8f8;
  }


  .demoIcon {
    display: flex;
    align-items: center;
    justify-content: center;



    img {
      width: 1.5rem;
      height: 1.5rem;
    }

    .altText {
      color: var(--color);
      font-size: 0.6rem;
      position: absolute;
      top: 3rem;
      display: none;
    }
  }

  .demoIcon:hover .altText {
    display: block;
  }

  .demo:hover + {
    background: linear-gradient(to bottom, #8ACCDC, var(--lightOrange));
  }
  

  .link {
    text-decoration: none;
  }


  .Icon {
    color: var(--white);
  }

  .notificationIcon {
    width: 1.75rem;
    height: 1.75rem;
    cursor: pointer;
    margin-top: 0.1rem;
    display: flex;
    position: relative;


    .notifyIcon {
      width: 100%;
      height: 100%;
    }

    .notifyCount {
      position: absolute;
      font-size: 0.85rem;
      font-weight: 600;
      top: -0.45rem;
      right: -0.45rem;
      margin-top: 0;
      display: flex;
      background-color: red;
      color: var(--white);
      border-radius: 50%;
      min-width: 1.25rem;
      min-height: 1.25rem;
      align-items: center;
      justify-content: center;
    }
  }

  .profileIcon {
    position: relative;
    cursor: pointer;
    border: 0.1rem solid var(--white);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .profileImg {
    width: 2rem;
    height: 2rem;
    padding: 0.25rem;
    box-sizing: border-box;
  }

  .profileImgNew {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
  }

  .dropdown {
    display: none;
    position: absolute;
    top: 75%;
    right: 0.5vw;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    padding: 1rem;
    font-weight: 400;
    font-size: 0.8rem;
    border-radius: 0.5rem;

    .titleText {
      font-weight: 400;
      font-size: 0.9rem;
    }

    img {
      width: 1rem;
    }
}

.dropdown .span {
    display: flex;
    padding: 8px 10px;
    text-decoration: none;
    color: #333;
    transition: background-color 0.3s ease;
    gap: 0.5rem;
    align-items: center;
}

.dropdown .span:hover {
    background-color: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
}

.profileIcon:hover+#dropdown {
    display: block;
}

.dropdown:hover {
    display: block;
}

`;

