import { useState } from 'react';
import { toast } from 'react-toastify';
import { deleteTest } from '../services/testService';

const useTestActions = (accessToken, clientCode, setTestTrigger) => {
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deletingTestId, setDeletingTestId] = useState('');

    const handleDeleteClick = (testId) => {
        setDeleteOpen(true);
        setDeletingTestId(testId);
    };

    const handleDeleteTest = async () => {
        try {
            const res = await deleteTest(deletingTestId, accessToken, clientCode);
            if (res) {
                toast.success('Question deleted successfully', { autoClose: 5000 });
                setTestTrigger(prev => !prev);
            }
        } catch (error) {
            const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        } finally {
            setDeleteOpen(false);
        }
    };


    return {
        deleteOpen,
        handleDeleteClick,
        handleDeleteTest,
        setDeleteOpen
    };
};

export default useTestActions;
