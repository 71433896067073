import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getPortalRequestTypes } from "../functions/api/AtlassianAPIs/getPortalRequestTypes";

export const fetchPortalRequestTypes = createAsyncThunk(
    'atlassian/fetchPortalRequestTypes',
    async ({accessToken, clientCode, portalId}, thunkAPI) => {
        try {
            const response = await getPortalRequestTypes(accessToken, clientCode, portalId);
            return response?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


const initialState = {
    portalRequestTypes: [],
};

const atlassianSlice = createSlice({
    name: 'atlassian',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPortalRequestTypes.pending, (state) => {
            })
            .addCase(fetchPortalRequestTypes.fulfilled, (state, action) => {
                state.portalRequestTypes = action.payload;
            })
            .addCase(fetchPortalRequestTypes.rejected, (state, action) => {
            });
    },
});

export default atlassianSlice.reducer;
