import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import RegisteredCandidates from './sidebarPages/RegisteredCandidates';
import ActiveJds from './sidebarPages/ActiveJds';
import EmpScheduledInterviews from './sidebarPages/EmpScheduledInterviews';
import EmpScheduledCandidateList from './sidebarPages/EmpScheduledCandidateList';
import { useSelector } from 'react-redux';
import { getStatusWiseCount } from '../../../functions/api/interview/getStatusWiseCount';
import { getJdsForMatching } from '../../../functions/api/employers/match/getJdsForMatching';
import { getProfiles } from '../../../functions/api/resume/getProfiles';

import metric1 from '../../../assets/icons/metric2.1.png'
import metric2 from '../../../assets/icons/metric2.2.png'
import metric3 from '../../../assets/icons/metric2.3.png'
import metric4 from '../../../assets/icons/metric2.4.png'
import metric5 from '../../../assets/icons/metric2.5.png'
import metric6 from '../../../assets/icons/metric2.6.png'
import metric7 from '../../../assets/icons/metric2.7.png'
import metric8 from '../../../assets/icons/metric2.8.png'

import EmpApplications from './sidebarPages/EmpApplications';
import EmpApplicationCandidatesList from './sidebarPages/EmpApplicationCandidatesList';
import scheduledInterviewImg from '../../../assets/icons/metric2.1.png'
import activeJdsImg from '../../../assets/icons/metric2.2.png'
import candidatePipelinesImg from '../../../assets/icons/metric2.4.png'
import reportsImg from '../../../assets/icons/metric2.9.png'
import JdListingTabs from '../../../pages/agency/JdListingTabs';
import { useDispatch } from 'react-redux';
import { setCurrentMetric } from '../../../slices/configSlice';
import { getJobApplicationCount } from '../../../functions/api/jobApplication/getJobApplicationCount';
import { toast } from 'react-toastify';
import { getAgencyMetrics } from '../../../functions/api/agency/getAgencyMetrics';
import AgencyScheduledInterviews from '../EmployerAgency/AgencyScheduledInterviews';
import AgencyCandidatesInPipeline from '../EmployerAgency/AgencyCandidatesInPipeline';
import Report from '../EmployerDashboard/sidebarPages/Report'
import { getUserSettingByKey } from '../../../functions/api/configSettings/getUserSettingByKey';
import { CircularProgress } from '@mui/material';

const MainContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 0rem;
width: 96%;
padding: 0 2%;



`

const Container = styled.div`

display: flex;
flex-direction: row;
width: 98%;
justify-content: space-between;
align-items: center;
padding: 1rem 0% 2rem 0%;
gap: 2%;

.selected {
  background: linear-gradient(to bottom, #f0f0f0, #d9fbf9);
}

.achievedNumberBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.7rem;
  background-color: var(--white);
  padding: 1rem 0 1.5rem 0;
  width: 22.5%;
  height: 6rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.5);

  .top {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    img {
      width: 3rem;
      height: 3rem;
    }
  }
}

.achievedNumberBox:hover {
  cursor: pointer;
}

.achievedNumberDigit {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--color);
}

.achievedNumberText {
  font-size: 0.9rem;
    font-weight: 600;
    color: var(--color);
    text-align: center;
}

.hrLine {
  width: 100%;
  border-top: 0.1rem groove lightgrey;
  margin: -0.2rem 0 -0.9rem 0;
  box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.25);
}


.loaderBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 17rem);
  height: calc(100% - 4.1rem);
  background-color: #f4f4f4;
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 4.1rem;
  color: var(--lightOrange);

}

`;


const EmployeMetrics = ({ page, setPage, setCurrentItem, setEmpClientCode, setSelectedJDID }) => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.auth.userType);
  const currentMetric = useSelector((state) => state.config?.currentMetric);
  const [metrics, setMetrics] = useState([]);
  const [count, setCount] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [scheduled, setScheduled] = useState(0);
  const [poolCount, setPoolCount] = useState(0);
  const [applicationCount, setApplicationCount] = useState(0);
  const [agencyMetrics, setAgencyMetrics] = useState({});

  const [dashboardMetrics, setDashboardMetrics] = useState('');
  const [loading, setLoading] = useState(false);

  const accessToken = useSelector(state => state.auth.userData?.accessToken)
  const clientCode = useSelector(state => state.auth.userData?.clientCode)

  useEffect(() => {
    const getDashboardConfiguration = async () => {
      try {
        setLoading(true);
        const dashboard = await getUserSettingByKey(accessToken, clientCode, userType === "EMPLOYER" ? "employerDashboardMetrics" : "agencyDashboardMetrics")

        if (dashboard) {
          setDashboardMetrics(dashboard?.data?.itemValue)
          setLoading(false);
        }

      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
        setLoading(false);
      }
    }
    getDashboardConfiguration()
  }, []);

  useEffect(() => {
    dispatch(setCurrentMetric(userType === 'EMPLOYER' ? 'interviews' : 'jdListings'))
  }, []);

  useEffect(() => {
    const getCount = async () => {
      try {
        const res = await getStatusWiseCount(accessToken);
        setMetrics(res?.data);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }
    getCount();

    async function getData() {
      try {
        const res = await getJdsForMatching(accessToken, clientCode);
        if (res?.data?.data?.length) setCount(res?.data?.data?.length);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }
    getData();

    const getCandidates = async () => {
      try {
        const res = await getProfiles(accessToken, clientCode);
        if (res) {
          setPoolCount(res?.data?.data?.length);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }
    getCandidates();

    const getApplicationsCount = async () => {
      try {
        const res = await getJobApplicationCount(accessToken, clientCode);
        if (res) setApplicationCount(res?.data);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }
    getApplicationsCount();

    const getMetricsCount = async () => {
      try {
        const res = await getAgencyMetrics(accessToken, clientCode);
        if (res) setAgencyMetrics(res?.data);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }
    getMetricsCount();

  }, [currentMetric])


  useEffect(() => {
    if (metrics?.length) {
      setCompleted(metrics?.find((item) => item?.status == "COMPLETED")?.count);
      setScheduled(metrics?.find((item) => item?.status == "SCHEDULED")?.count)
    }
  }, [metrics])


  useEffect(() => {
    setPage({ index: 1, jdId: null });
  }, []);

  const handleCurrentMetricChange = (metric) => {
    dispatch(setCurrentMetric(metric))
  }


  const getMetricDisplayText = (text) => {
    switch (text) {
      case "interviews":
        return "Active JDs";
      case "activeJds":
        return "Open Reqs";
      case "applications":
        return "Applications";
      case "candidatesPool":
        return "Candidates Pool";
      case "inviteSent":
        return "Invite Sent (Last 30 Days)";
      case "reviewedProfiles":
        return "Reviewed Profiles (Last 30 Days)";
      case "jdViews":
        return "JD Views (Last 30 Days)";
      case "offers":
        return "Offers (Last 30 Days)";
      case "jdListings":
        return "JD Listings";
      case "scheduledInterviews":
        return "Scheduled Interviews";
      case "candidatesInPipeline":
        return "Candidates in Pipeline";
      case "reports":
        return "Reports";
      default:
        return "";
    }
  }


  const getMetricImage = (text) => {
    switch (text) {
      case "interviews":
        return metric1;
      case "activeJds":
        return metric2;
      case "applications":
        return metric3;
      case "candidatesPool":
        return metric4;
      case "inviteSent":
        return metric5;
      case "reviewedProfiles":
        return metric6;
      case "jdViews":
        return metric7;
      case "offers":
        return metric8;
      case "jdListings":
        return activeJdsImg;
      case "scheduledInterviews":
        return scheduledInterviewImg;
      case "candidatesInPipeline":
        return candidatePipelinesImg;
      case "reports":
        return reportsImg;
      default:
        return "";
    }
  }

  const getMetricCount = (text) => {
    switch (text) {
      case "interviews":
        return scheduled ? scheduled : 0;
      case "activeJds":
        return count ? count : 0;
      case "applications":
        return applicationCount ? applicationCount : 0;
      case "candidatesPool":
        return poolCount ? poolCount : 0;
      case "inviteSent":
        return 0;
      case "reviewedProfiles":
        return 0;
      case "jdViews":
        return 0;
      case "offers":
        return 0;
      case "jdListings":
        return agencyMetrics?.activeJds ?? 0;
      case "scheduledInterviews":
        return agencyMetrics?.scheduled ?? 0;
      case "candidatesInPipeline":
        return agencyMetrics?.pipeline ?? 0;
      case "reports":
        return 0;
      default:
        return 0;
    }
  }


  return (
    <MainContainer>
      <Container>
        {loading ? <div className='loaderBox'>
          <CircularProgress color="inherit" />
        </div> : dashboardMetrics?.split(",")?.map((metric, i) => (
          <div className={`achievedNumberBox ${currentMetric === metric ? 'selected' : ''}`} onClick={() => handleCurrentMetricChange(metric)}>
            <div className='top'>
              <img src={getMetricImage(metric)} />
              <span className='achievedNumberDigit'>{getMetricCount(metric)}</span>
            </div>
            <span className='hrLine'></span>
            <span className='achievedNumberText'>{getMetricDisplayText(metric)}</span>
          </div>
        ))}
      </Container>

      {currentMetric === 'interviews' && <>{page?.index === 1 && <EmpScheduledInterviews page={page} setPage={setPage} />}  {page?.index === 2 && <EmpScheduledCandidateList page={page} setPage={setPage} />}</>}
      {currentMetric === 'applications' && <>{page?.index === 1 && <EmpApplications page={page} setPage={setPage} />}  {page?.index === 2 && <EmpApplicationCandidatesList page={page} setPage={setPage} />}</>}
      {currentMetric === 'activeJds' && <ActiveJds />}
      {currentMetric === 'candidatesPool' && <RegisteredCandidates />}

      {currentMetric === 'jdListings' && <JdListingTabs setCurrentItem={setCurrentItem} setEmpClientCode={setEmpClientCode} setSelectedJDID={setSelectedJDID} />}
      {currentMetric === 'scheduledInterviews' && <AgencyScheduledInterviews />}
      {currentMetric === 'candidatesInPipeline' && <AgencyCandidatesInPipeline />}
      {currentMetric === 'reports' && <Report />}

    </MainContainer>

  );
};

export default EmployeMetrics;

