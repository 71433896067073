import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAllQuestions, setFormData } from '../../../../slices/testFormSlice';

export const useTestForm = (editingIndex, editingTestId, editingTestData, testQuestionData, isReview) => {
    const dispatch = useDispatch();
    const formData = useSelector((state) => state.testForm.formData);
    const allQuestions = useSelector((state) => state.testForm.allQuestions);

    const [selectedTopics, setSelectedTopics] = useState([]);
    const [topicInputValue, setTopicInputValue] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);
    const [tagInputValue, setTagInputValue] = useState("");


    useEffect(() => {
        if (editingIndex != -1) {
            dispatch(setFormData({
                testType: editingTestData?.testType,
                name: editingTestData?.name,
                role: editingTestData?.role,
                questionIds: editingTestData?.testQuestions?.map((question) => question.id),
            }));
            { editingTestData?.tags && setSelectedTags(editingTestData?.tags?.split(", ")) };
            { editingTestData?.topics && setSelectedTopics(editingTestData?.topics?.split(", ")) };
            { editingTestData?.testQuestions && dispatch(setAllQuestions(editingTestData?.testQuestions)); }
        } else {
            
        }
    }, [editingIndex, editingTestData, editingTestId, testQuestionData])

    useEffect(() => {
        dispatch(setFormData({
            tags: selectedTags.join(", "),
            topics: selectedTopics.join(", "),
        }));
    }, [selectedTags, selectedTopics]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        dispatch(setFormData({ [name]: value }));
    };

    const handleDeleteQue = (id) => {
        const updatedQuestionIds = formData?.questionIds?.filter(questionId => questionId !== id);
        const updatedQue = allQuestions?.filter(ques => ques?.id !== id);
        dispatch(setFormData({
            questionIds: updatedQuestionIds,
        }))
        dispatch(setAllQuestions(updatedQue));
    }

    const handleTagsChange = (_, newTags) => {
        setSelectedTags(newTags);
    };

    const handleTopicsChange = (_, newTopics) => {
        setSelectedTopics(newTopics);
    };

    const handleTagInputBlur = () => {
        if (tagInputValue.trim() !== '') {
            setSelectedTags((prevTags) => [...prevTags, tagInputValue]);
            setTagInputValue('');
        }
    };

    const handleTopicInputBlur = () => {
        if (topicInputValue.trim() !== '') {
            setSelectedTopics((prevTopics) => [...prevTopics, topicInputValue]);
            setTopicInputValue('');
        }
    };

    return {
        formData,
        allQuestions,
        handleChange,
        handleDeleteQue,
        handleTagsChange,
        handleTopicsChange,
        handleTagInputBlur,
        handleTopicInputBlur,
        selectedTags,
        selectedTopics,
        tagInputValue,
        setTagInputValue,
        topicInputValue,
        setTopicInputValue,
    };
};
