import React, { useState } from 'react';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setCurrentItem } from '../../../slices/configSlice';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from "react-router";


const SidebarContainer = styled.div`
  width: 17rem;
height: calc(98% - 5rem);
padding-top: 1.5rem;
padding-bottom: 1.5rem;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
font-size: 0.9rem;
font-weight: 500;
background-color: var(--white);
position: fixed;
left: 0;
overflow-y: scroll;
  & {
  scrollbar-width: none;
  }
  &::-webkit-scrollbar {
    width: 0rem;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  
//   box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.5);

  .div {
  width: 100%;
  }
`;


const MenuItem = styled.div`
  width: 100% !important;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--color);

`;

const MainMenuItem = styled.div`
  width: calc(17rem - 2.5rem);
  margin-bottom: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--color);  
  border: none;
`;


const MenuItemTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  color: ${(props) => (props.isExpanded ? 'var(--lightOrange)' : 'var(--color)')};

`;

const MainMenuItemTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  color: var(--color);
  padding-left: 0.5rem;

`;

const SubMenuItemTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  color: ${(props) => (props.isActive ? 'var(--lightOrange)' : 'var(--color)')};

  .arrowLink {
  width: 1.1rem;
  height: 1.1rem;
  padding-left: 0.5rem;
  }
`;

const Icon = styled.img`
    width: 1.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
`;

const SubMenu = styled.div`
  font-size: 0.9rem;
  font-weight: 500;
  padding-left: 20px;
  display: ${(props) => (props.isExpanded ? 'block' : 'none')};
`;

const SubSubMenu = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  padding-left: 0.2rem;
  display: ${(props) => (props.isExpanded ? 'block' : 'none')};
`;

const Arrow = styled.span`
  transform: ${(props) => (props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
`;

const HrLine = styled.span`
    display: block;
    width: 95%;
    border-top: 0.1rem groove #f6f6f6;
    border-radius: 0.2rem;
    margin: 0rem 0 1rem 0;
    align-self: center;
    box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.25);
`

const EmployerSidebarTrial = ({ menuItems }) => {
    const currentItem = useSelector(state => state.config?.currentItem);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentDomain = window.location.hostname;

    const [expandedSubMenu, setExpandedSubMenu] = useState(null);

    const handleExpand = (title) => {
        setExpandedSubMenu((prevState) => (prevState === title ? null : title));
    };

    const handleItemClick = (key) => {
        dispatch(setCurrentItem(key));
    };

    const handleMenuItemClick = (key) => {
        dispatch(setCurrentItem(key));
    }

    const handleTitleClick = (key) => {
        if (key === "Home") {
            dispatch(setCurrentItem('dashboard'));
        }
    }

    const handleOpenMenuLink = (url) => {
        const baseUrl = currentDomain === 'localhost' ? 'http://localhost:3000' : `https://${currentDomain}`;
        const employerTicketUrl = `${baseUrl}/${url}`
        window.open(employerTicketUrl, '_blank', 'noopener,noreferrer');
    }

    const handleOpenLink = (url) => {
        navigate(url);
    }

    return (
        <SidebarContainer>
            {menuItems?.map((item, index) => (
                <div key={index} className='div'>
                    <MenuItem>
                        <MainMenuItemTitle onClick={() => handleTitleClick(item?.title)}>
                            {item?.icon && <Icon src={item?.icon} alt={item?.title} />}
                            {item?.title}
                        </MainMenuItemTitle>
                    </MenuItem>

                    {item?.subItems && (
                        <SubMenu isExpanded={true}>
                            {item?.subItems?.map((subItem, subIndex) => (
                                <div key={subIndex}>
                                    <MainMenuItem
                                        onClick={() => {
                                            { subItem?.subItems && handleExpand(subItem?.title) }
                                            { !subItem?.subItems && handleMenuItemClick(subItem?.key) }
                                            { subItem?.url && handleOpenMenuLink(subItem?.url) }
                                        }}
                                        isActive={expandedSubMenu === subItem?.title}
                                    >
                                        <MenuItemTitle isExpanded={expandedSubMenu === subItem?.title}>
                                            {subItem?.icon && <Icon src={subItem?.icon} alt={subItem?.title} />}
                                            {subItem?.title}
                                        </MenuItemTitle>
                                        {subItem?.subItems && (
                                            <Arrow isExpanded={expandedSubMenu === subItem?.title}><ExpandMoreIcon /> </Arrow>
                                        )}
                                    </MainMenuItem>
                                    {subItem?.subItems && (
                                        <SubSubMenu isExpanded={expandedSubMenu === subItem?.title}>
                                            {subItem?.subItems?.map((subSubItem, subSubIndex) => (
                                                <MenuItem
                                                    key={subSubIndex}
                                                    onClick={() => {
                                                        handleItemClick(subSubItem?.key)
                                                        { subSubItem?.url && handleOpenLink(subSubItem?.url) }
                                                    }}
                                                >
                                                    <SubMenuItemTitle
                                                        isActive={currentItem === subSubItem?.key}
                                                    >
                                                        {subSubItem?.icon && <Icon src={subSubItem?.icon} alt={subSubItem?.title} />}
                                                        {subSubItem?.title} {subSubItem?.isExternalLink && <OpenInNewIcon className='arrowLink' />}
                                                    </SubMenuItemTitle>
                                                </MenuItem>
                                            ))}
                                        </SubSubMenu>
                                    )}
                                </div>
                            ))}
                        </SubMenu>
                    )}
                    {index !== (menuItems?.length - 1) && <HrLine></HrLine>}
                </div>
            ))}
        </SidebarContainer>
    );
};

export default EmployerSidebarTrial;
