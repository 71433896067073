export const features = [
    {
        title: 'AI-Driven Candidate Matching',
        description: 'Leverage the power of artificial intelligence to instantly identify the best talent for your roles.',
        image: 'https://img.freepik.com/free-photo/colleagues-doing-team-work-project_23-2149361536.jpg?semt=ais_hybrid',
        features: [
            'Smart Resume Parsing: Automatically analyze resumes and rank candidates based on job-fit.',
            'AI-Powered Matching: Match candidates to job descriptions using advanced algorithms.',
            'Eliminate Bias: Ensure fair and unbiased hiring with data-driven recommendations.'
        ],
    },
    {
        title: 'Seamless Collaboration & Workflow',
        description: 'Empower your HR team with tools that improve communication, transparency, and efficiency.',
        image: 'https://img.freepik.com/free-vector/progress-concept-illustration_114360-2976.jpg?semt=ais_hybrid',
        features: [
            'Team Collaboration: Easily share candidate profiles, feedback, and notes in real-time.',
            'Customizable Workflow: Tailor workflows to fit your hiring process, from application to offer.',
            'Role-Based Access: Securely control who sees what with role-specific permissions.',
        ],
    },
    {
        title: 'Data-Driven Insights for Smarter Decisions',
        description: 'Turn data into actionable insights to optimize your recruitment strategies.',
        image: 'https://img.freepik.com/free-photo/top-view-break-time-written-sticky-note-binder-clip-pen-biscuits-dark_140725-145628.jpg?semt=ais_hybrid',
        features: [
            'Real-Time Analytics: Track key metrics such as time-to-hire, candidate quality, and engagement.',
            'Hiring Dashboards: Visualize your hiring pipeline and performance at a glance.',
            'Predictive Analytics: Make informed decisions with insights into future hiring needs and trends.',
        ],
    },
    {
        title: 'Automated Recruitment, Simplified',
        description: 'Automate tedious recruitment tasks to save time and improve productivity.',
        image: 'https://img.freepik.com/free-photo/colleagues-doing-team-work-project_23-2149361536.jpg?semt=ais_hybrid',
        features: [
            'Resume Parsing & Storage: Automatically parse resumes and store them in secure cloud storage.',
            'Automated Communication: Schedule and send candidate emails, interviews, and reminders automatically.',
            'Job Board Integration: Post job openings across multiple platforms with a single click.',
        ],
    },
    {
        title: 'Scale Your Hiring Effortlessly',
        description: 'Adapt and grow with a solution that scales as your business grows.',
        image: 'https://img.freepik.com/free-vector/progress-concept-illustration_114360-2976.jpg?semt=ais_hybrid',
        features: [
            'Flexible for All Team Sizes: Whether you’re a startup or an enterprise, RegorTalent grows with you.',
            'Easy Integration: Seamlessly integrate with your existing HR tools, ATS, and other systems.',
            'Customizable Features: Tailor RegorTalent to meet your unique recruiting needs.',
        ],
    },
];

export const howRegorTalentWork = [
    {
        title: 'Upload Resumes and Job Descriptions',
        description:
            'Kick off your hiring process by simply uploading resumes and job descriptions to RegorTalent.',
        details: [
            'Resume Parsing: Automatically extracts key data such as skills, experience, and education.',
            'Job Description Matching: Provides an AI-based fit score for each candidate.',
        ],
    },
    {
        title: 'AI Matching & Ranking',
        description:
            'Intelligently match candidates with job requirements using AI.',
        details: [
            'Smart Candidate Scoring: Ranks candidates based on their fit.',
            'AI-Powered Recommendations: Top candidates to save time on screening.',
        ],
    },
    {
        title: 'Seamless Collaboration & Feedback',
        description:
            'Collaborate seamlessly with your hiring team.',
        details: [
            'Candidate Profiles: Share profiles, resumes, and feedback.',
            'Team Collaboration: Provide real-time feedback easily.',
        ],
    },
    {
        title: 'Automate Communication & Interviews',
        description:
            'Ensure smooth candidate communication.',
        details: [
            'Automated Emails: Send reminders and follow-ups.',
            'Interview Scheduling: Integrates with your calendar.',
        ],
    },
    {
        title: 'Real-Time Insights & Analytics',
        description:
            'Monitor the recruitment process with data insights.',
        details: [
            'Hiring Dashboards: Track metrics such as time-to-hire.',
            'Predictive Analytics: Forecast hiring needs.',
        ],
    },
    {
        title: 'Easy Integration with Your Existing Systems',
        description:
            'Fits seamlessly into your recruitment process.',
        details: [
            'ATS and CRM Integration.',
            'Scalable Solution: Adapts to hiring needs.',
        ],
    },
];

export const caseStudies = [
    {
        title: "FortuneTech Solutions",
        sections: [
            {
                heading: "Challenge",
                text: "Struggling to efficiently screen and hire top tech talent for their growing enterprise."
            },
            {
                heading: "Solution",
                text: "With RegorTalent’s AI-powered resume parsing and candidate scoring, FortuneTech streamlined their hiring process, reducing time-to-hire by 40%."
            },
            {
                heading: "Results",
                list: [
                    "Time-to-hire reduced from 30 days to 18 days",
                    "95% candidate-job fit accuracy",
                    "Improved collaboration between recruitment and hiring managers"
                ]
            }
        ]
    },
    {
        title: "Global Retail Hub",
        sections: [
            {
                heading: "Challenge",
                text: "High volume of applications overwhelmed the HR team, making it difficult to identify quality candidates quickly."
            },
            {
                heading: "Solution",
                text: "RegorTalent’s automated candidate ranking and collaborative dashboard enabled faster decision-making."
            },
            {
                heading: "Results",
                list: [
                    "Processed 10,000+ applications within 2 weeks",
                    "Increased interview-to-hire ratio by 60%",
                    "Significant reduction in manual screening time"
                ]
            }
        ]
    }
];
