import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const RequestType = ({ requestTypeDetails, navigateToCreateIssue, requestId }) => {

    return (
        <MainBox onClick={() => navigateToCreateIssue(requestTypeDetails?.requestId)}>
            <span className='textBox'>
                <span className='typeName'>{requestTypeDetails?.requestName}</span>
                <span className='typeDesc'>{requestTypeDetails?.requestDescription}</span>
            </span>
        </MainBox>
    )
}

export default RequestType


const MainBox = styled.div`
display: flex;
flex-direction: column;
gap: 0.1rem;
padding: 0.75rem 1rem;
cursor: pointer;
min-height: 2rem;
justify-content: center;
position: relative;
border: 0.1rem solid #eef0f2;
border-radius: 0.5rem;

&:hover {
    background-color: #f6f8f8;
  }

  .textBox {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .typeName {
        font-size: 0.9rem;
        font-weight: 700;
        color: var(--lightOrange);
        font-family: var(--font);
    }

    .typeDesc {
        font-size: 0.9rem;
        font-weight: 600;
        color: var(--color);
        font-family: var(--font);
    }
  }



`