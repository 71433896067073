export const getRequestGroups = (portalId) => {
    let requestGroups = [];
    if (parseInt(portalId) === 1) {
        requestGroups = [
            {
                "id": 1,
                "name": "Common Requests"
            },
            {
                "id": 3,
                "name": "Logins and Accounts"
            },
            {
                "id": 4,
                "name": "Applications"
            }
        ];
    } else if(parseInt(portalId) === 4) {
            requestGroups = [
                {
                    "id": 12,
                    "name": "Common Requests"
                },
                {
                    "id": 14,
                    "name": "Logins and Accounts"
                },
                {
                    "id": 15,
                    "name": "Applications"
                }
            ];
    } else if(parseInt(portalId) === 5) {
        requestGroups = [
            {
                "id": 19,
                "name": "Common Requests"
            },
            {
                "id": 17,
                "name": "Logins and Accounts"
            },
            {
                "id": 18,
                "name": "Applications"
            }
        ];
}
    return requestGroups;
};


export const requestStatus = [
    {
        "statusName": "Under investigation",
        "categoryKey": "indeterminate",
        "statusInfo": [
            {
                "statusId": "10016"
            }
        ]
    },
    {
        "statusName": "Published",
        "categoryKey": "done",
        "statusInfo": [
            {
                "statusId": "10021"
            }
        ]
    },
    {
        "statusName": "Awaiting approval",
        "categoryKey": "indeterminate",
        "statusInfo": [
            {
                "statusId": "10019"
            }
        ]
    },
    {
        "statusName": "Failed",
        "categoryKey": "done",
        "statusInfo": [
            {
                "statusId": "10013"
            }
        ]
    },
    {
        "statusName": "Completed",
        "categoryKey": "done",
        "statusInfo": [
            {
                "statusId": "10012"
            }
        ]
    },
    {
        "statusName": "Under review",
        "categoryKey": "new",
        "statusInfo": [
            {
                "statusId": "10017"
            }
        ]
    },
    {
        "statusName": "Open",
        "categoryKey": "new",
        "statusInfo": [
            {
                "statusId": "1"
            }
        ]
    },
    {
        "statusName": "Work in progress",
        "categoryKey": "indeterminate",
        "statusInfo": [
            {
                "statusId": "10015"
            }
        ]
    },
    {
        "statusName": "Draft",
        "categoryKey": "indeterminate",
        "statusInfo": [
            {
                "statusId": "10020"
            }
        ]
    },
    {
        "statusName": "Closed",
        "categoryKey": "done",
        "statusInfo": [
            {
                "statusId": "6"
            }
        ]
    },
    {
        "statusName": "Waiting for approval",
        "categoryKey": "new",
        "statusInfo": [
            {
                "statusId": "10006"
            }
        ]
    },
    {
        "statusName": "Implementing",
        "categoryKey": "indeterminate",
        "statusInfo": [
            {
                "statusId": "10008"
            }
        ]
    },
    {
        "statusName": "Waiting for support",
        "categoryKey": "indeterminate",
        "statusInfo": [
            {
                "statusId": "10000"
            }
        ]
    },
    {
        "statusName": "Authorize",
        "categoryKey": "indeterminate",
        "statusInfo": [
            {
                "statusId": "10010"
            }
        ]
    },
    {
        "statusName": "Pending",
        "categoryKey": "indeterminate",
        "statusInfo": [
            {
                "statusId": "10022"
            }
        ]
    },
    {
        "statusName": "Resolved",
        "categoryKey": "done",
        "statusInfo": [
            {
                "statusId": "5"
            }
        ]
    },
    {
        "statusName": "In Progress",
        "categoryKey": "indeterminate",
        "statusInfo": [
            {
                "statusId": "3"
            }
        ]
    },
    {
        "statusName": "Waiting for customer",
        "categoryKey": "indeterminate",
        "statusInfo": [
            {
                "statusId": "10001"
            }
        ]
    },
    {
        "statusName": "Planning",
        "categoryKey": "indeterminate",
        "statusInfo": [
            {
                "statusId": "10009"
            }
        ]
    },
    {
        "statusName": "Declined",
        "categoryKey": "done",
        "statusInfo": [
            {
                "statusId": "10011"
            }
        ]
    },
    {
        "statusName": "Review",
        "categoryKey": "new",
        "statusInfo": [
            {
                "statusId": "10007"
            }
        ]
    },
    {
        "statusName": "Canceled",
        "categoryKey": "done",
        "statusInfo": [
            {
                "statusId": "10004"
            }
        ]
    },
    {
        "statusName": "Escalated",
        "categoryKey": "indeterminate",
        "statusInfo": [
            {
                "statusId": "10005"
            }
        ]
    },
    {
        "statusName": "Awaiting implementation",
        "categoryKey": "indeterminate",
        "statusInfo": [
            {
                "statusId": "10014"
            }
        ]
    }
]