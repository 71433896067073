import React from 'react'
import { styled } from 'styled-components'
import Header from '../components/Header'
import HeroSection from '../components/HeroSection'
import FeatureSection from '../components/FeatureSection'
import { caseStudies, features, howRegorTalentWork } from '../utils/allHomepageData.js'
import HowItWorkSection from '../components/HowItWorkSection.jsx'
import CaseStudy from '../components/CaseStudy.jsx'
import CTA from '../components/CTA.jsx'
import Footer from '../components/Footer.jsx'

const HomePage = () => {
    
    return (
        <StyledLanding>
            <StyledLandingTop>
                <Header/>
                <HeroSection/>
                <FeatureSection features={features}/>
                <HowItWorkSection steps={howRegorTalentWork}/>
                <CaseStudy studyData={caseStudies} />
                <CTA/>
            </StyledLandingTop>
            <Footer/>
        </StyledLanding>
    )
}

export default HomePage

export const StyledLandingTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    color: var(--color);
    background-color: var(--backgroundColor);
    gap: 3rem;

`
export const StyledLanding = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    color: var(--color);
    background-color: var(--backgroundColor);
`

