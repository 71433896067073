import React from 'react'
import styled from 'styled-components'

const TestHeader = ({data}) => {
    return (
        <Header>
            <div className='left'>
                    <span className="child">Total Score: {data?.totalScore}</span>
                    |
                    <span className="child">Questions: {data?.noOfQuestions}</span>
                </div>
                <div className='right'>
                </div>
        </Header>
    )
}

export default TestHeader

const Header = styled.div`
width: 96%;
margin: 0 auto;
display: flex;
justify-content: space-between;
align-items: center;
gap: 2%;
font-size: 1rem;
font-weight: 600;

.left {
 display: flex;
 gap: 0.5rem;
 align-items: center;

 .child {
display: flex;
align-items: center;
gap: 0.5rem;
}
}

.right {
display: flex;
gap: 1rem;
align-items: center;

.child {
display: flex;
align-items: center;
gap: 0.5rem;
}
}

`