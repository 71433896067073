import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSelector } from 'react-redux';
import { getJds } from '../../../../functions/api/employers/getJds';
import { toast } from 'react-toastify';

const CloneJDForm = ({ array }) => {
    const [selectedJd, setSelectedJd] = useState(null);
    const jdData = useSelector(state => state?.jd?.cloneSpecificData);
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

    const [allJdList, setAllJdList] = useState([]);

    useEffect(() => {
        array[2]([selectedJd, "clone"]);
    }, [selectedJd])

    useEffect(() => {
        async function getData() {
            try {
                const res = await getJds(accessToken, clientCode, "", 1, 10000000);
                if (res && res?.data?.data) {
                    setAllJdList(res?.data?.data);
                }
            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }
        getData();
    }, []);


    const handleClone = () => {
        array[0](prev => !prev);
        array[1](false);
    }

    return (
        <Box>
            <span className='title'>Clone Existing JD</span>
            <div className='box'>
                <FormControl sx={{ backgroundColor: '#F6F6FB' }} fullWidth className='select'>
                    <InputLabel id="demo-simple-select-label" sx={{ fontStyle: 'Quicksand, sans-serif', fontSize: '0.9rem' }}>All JDs</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedJd?.jdId}
                        label="All JDs"
                        onChange={(e) => setSelectedJd(e.target.value)}
                        sx={{ fontStyle: 'Quicksand, sans-serif', fontSize: '0.9rem' }}
                    >
                        {
                            allJdList?.map((item, i) => (
                                <MenuItem value={item} sx={{ fontStyle: 'Quicksand, sans-serif', fontSize: '0.9rem' }}>{item?.title}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                <div className='btnBox'>
                    <Button onClick={handleClone}>Clone JD</Button>
                </div>
            </div>
        </Box>
    )
}

export default CloneJDForm

const Box = styled.div`
flex-direction: column;
align-items: center;
gap: 2rem;
width: 30rem;
padding: 2rem 5rem;
box-sizing: border-box;


.box {
    display: grid;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: 3fr 2.5fr;

    .btnBox {
        display: flex;
        width: 100%;
        justify-content: end;
        align-items: center;
    }
}


.title {
    align-self: start;
    font-size: 0.9rem;
    font-weight: 600;
    display: block;
    margin-bottom: 2rem;
}

`

const Button = styled.button`
background-color: var(--lightOrange);
color: var(--white);
padding: 0.5rem 0.8rem;
font-size: 0.9rem;
font-weight: 600;
border-radius: 0.3rem;
border: none;
cursor: pointer;
font-family: var(--font);


`