import React from 'react'
import { toast } from 'react-toastify';
import styled from 'styled-components'
import { useSelector } from "react-redux";
import { getBlobData } from '../../../../functions/api/resume/getBlobData';


const InviteReviewListDetails = ({ data, resumeId, resumeFileName }) => {
    const accessToken = useSelector((state) => state.auth.userData.accessToken);
    const clientCode = useSelector(
        (state) => state.auth.userData.user.clientCode
    );

    const handleDownload = async (id, name) => {
        try {
            if (!id) {
                toast.warning("No resume found or invalid resumeId", 5000);
                return;
            }
            const res = await getBlobData(
                `api/media/downloadById?fileType=resume&id=${id}`,
                accessToken,
                clientCode
            );

            const a = document.createElement("a");
            a.href = res;
            a.setAttribute("download", `${name}.pdf`);
            a.click();
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    }

    return (
        <Box>
            <div className='box1'>
                <span className='title'>Basic Details</span>
                <div className='textBox'>
                    <div className='childBox'>
                    <span className='text'><span className='boldText'>Interview Type:</span> {data?.interviewType || "..."}</span>
                        <span className='text'><span className='boldText'>Difficulty Level:</span> {data?.difficultyLevel || "..."}</span>
                    </div>
                    <div className='childBox'>
                        <span className='text'><span className='boldText'>Product Type:</span> {data?.productType || "..."}</span>
                        <span className='text'><span className='boldText'>No. of Questions:</span> {data?.numberOfQue || "..."}</span>
                    </div>
                    <div className='childBox'>
                        <span className='text'><span className='boldText'>TestType:</span> {data?.testType?.toUpperCase() || "..."}</span>
                        <span className='resumeText'><span className='boldText'>Resume:</span><button onClick={() => handleDownload(resumeId, resumeFileName)}>view resume</button></span>
                    </div>
                </div>
                <div className='textBox'>
                    <div className='childBox'>


                    </div>
                </div>
            </div>
        </Box>
    )
}

export default InviteReviewListDetails


const Box = styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap: 2rem;



.box1 {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .title {
        font-size: 1rem;
        font-weight: 600;

    }

    .textBox {
        display: flex;
        flex-direction: row;
        gap: 3%;
        justify-content: space-between;

        .childBox {
            width: 30%;
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
        }


        .text {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
            font-size: 0.75rem;
            font-weight: 400;

            .boldText {
                font-size: 0.8rem;
                font-weight: 600;
            }
        }

        .resumeText {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
            font-size: 0.75rem;
            font-weight: 400;
            justify-content: start;
            align-items: start;

            .boldText {
                font-size: 0.8rem;
                font-weight: 600;
            }

            button {
                background-color: var(--white);
                border: 0.05rem solid  var(--color);
                border-radius: 0.3rem;
                cursor: pointer;
                padding: 0.25rem 0.5rem;
            }
        }
    }
}

`