import React from 'react';
import styled from 'styled-components';
import { ChevronDown, ChevronUp, Users } from 'lucide-react';

const Content = styled.div`
  margin-left: 3rem;
`;

const ChangesWrapper = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const ChangeLabel = styled.span`
  font-size: 0.9rem;
  color: var(--color);
  font-weight: 600;
`;

const ChangeContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 500;
`;

const ChangeFrom = styled.span`
  color: red;
`;

const ChangeTo = styled.span`
  color: green;
`;



const Card = styled.div`
  background-color: #fff;
  border-radius: 0.75rem;
  padding: 1rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const CardContent = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  background-color: lightgrey;
  border-radius: 50%;
  border: 0.075rem solid grey;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
  padding: 0.5rem;
`;


const ExpandButton = styled.button`
  padding: 0.5rem;
  border-radius: 50%;
  color: var(--color);
  cursor: pointer;
  border: 0.1rem solid grey;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 1rem;
    height: 1rem;
  }

  &:hover {
    background-color: var(--backgroundColor);
  }
`;

const DateText = styled.div`
font-size: 0.9rem;
forn-weight: 500;
padding: 0.25rem 0;

`

const Title = styled.div`
font-size: 1rem;
font-weight: 500;


`

const Bold = styled.span`
font-weight: 700;

`


const ActivityTrackerItem = ({ id, description, createdAt, action, userName, fieldUpdateInfos, isExpanded, onToggle }) => {


  return (
    <Card>
      <CardHeader>
        <CardContent>
          <IconWrapper><Users /> </IconWrapper>
          <div>
            <Title><Bold>{userName} </Bold> {description}</Title>
            <DateText>{new Date(createdAt).toLocaleString()}</DateText>
          </div>
        </CardContent>
        {action !== "CREATE" && <ExpandButton onClick={onToggle}>
          {isExpanded ? <ChevronUp size="1.25rem" /> : <ChevronDown size="1.25rem" />}
        </ExpandButton>}
      </CardHeader>

      {isExpanded && (<Content>
        {fieldUpdateInfos?.map((change, index) => (
          <ChangesWrapper key={index}>
            <ChangeLabel>{change?.fieldName}</ChangeLabel>
            <ChangeContent>
              <ChangeFrom>{change?.oldValue || '(Empty)'}</ChangeFrom>
              <span style={{ margin: '0 0.5rem' }}>→</span>
              <ChangeTo>{change?.newValue}</ChangeTo>
            </ChangeContent>
          </ChangesWrapper>
        ))}
      </Content>)}
    </Card>
  );
};

export default ActivityTrackerItem;
