import React from 'react';
import styled from 'styled-components';

const FeaturesSectionWrapper = styled.section`
  padding: 2rem;
  background: rgb(239,236,247);
background: linear-gradient(90deg, rgba(239,236,247,1) 0%, rgba(239,236,247,1) 35%, rgba(168,162,226,1) 100%);
  // background-color: #ebedf0;
  text-align: center;
`;

const FeatureList = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

const Feature = styled.div`
display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.5rem 1rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  width: 16%;
  align-items: center;
  justify-content: flex-start;

  h3 {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    font-weight: 600;
  }

  p {
    margin: 0;
  }

  .sNo {
  display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    color: white;
    width: 2.5rem;
    height: 2.5rem;
    background-color: var(--academicLavendar);
    border-radius: 50%;
    box-shadow: 0 0 1rem var(--academicLavendar);

    }
`;

const features = [
  { sNo: 1, title: 'Customized Learning Paths', description: 'Tailor your study plan according to your needs.' },
  { sNo: 2, title: 'AI-Powered Study Assistance', description: 'Receive real-time help and suggestions from an AI tutor.' },
  { sNo: 3, title: 'AI-Based Mock Tests', description: 'Practice with AI-driven tests tailored to your progress.' },
  { sNo: 4, title: 'Interactive Quizzes and Tests', description: 'Regular assessments to track your progress.' },
  { sNo: 5, title: '24/7 Access', description: 'Study anytime, anywhere.' },
];


const FeaturesSection = () => (
  <FeaturesSectionWrapper>
    <h2>Why Choose Us?</h2>
    <FeatureList>
      {features.map((feature, index) => (
        <Feature key={index}>
          <div className='sNo'>{feature?.sNo}</div>
          <h3>{feature.title}</h3>
          <p>{feature.description}</p>
        </Feature>
      ))}
    </FeatureList>
  </FeaturesSectionWrapper>
);

export default FeaturesSection;
