import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getAgencies } from '../services/customReportService';

const useFetchAgencies = (accessToken, clientCode, page, size) => {
    const [agencies, setAgencies] = useState(null);
    const [agencyLoading, setAgencyLoading] = useState(true);

    useEffect(() => {
        const fetchAgencies = async () => {

            try {
                const data = await getAgencies(accessToken, clientCode, page, size);
                if(data){
                    setAgencies(data?.data);
                }
            } catch (error) {
                const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            } finally {
                setAgencyLoading(false);
            }
        };

        fetchAgencies();
    }, [accessToken, clientCode, page, size]);

    return { agencies, agencyLoading };
};

export default useFetchAgencies;
