import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { requestStatus } from '../../../utils/JiraHelpCenter'
import { useNavigate, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import JiraHeader from './JiraHeader'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSearchParams } from 'react-router-dom'
import { getAllIssuesByJqlQuery } from '../../../functions/api/AtlassianAPIs/getAllIssuesByJqlQuery'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

function Row(props) {

    const { row, index, navigateToIssueDetails } = props;

    return (
        <React.Fragment>
            <TableRow
                className={`${index % 2 == 1 ? 'colored' : ''}`} onClick={() => navigateToIssueDetails(row?.issueKey)}>
                <TableCell component="th" scope="row" align='center' className='tableCell'>
                    {row?.requestType}
                </TableCell>
                <TableCell component="th" scope="row" align="center" className='tableCell'>
                    {row?.issueKey}
                </TableCell>
                <TableCell component="th" scope="row" align="center" className='tableCell'>
                    {row?.summary}
                </TableCell>
                <TableCell component="th" scope="row" align="center" className='tableCell'>
                    {row?.status}
                </TableCell>
                <TableCell component="th" scope="row" align="center" className='tableCell'>
                    {row?.reporterDisplayName}
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const AllJiraIssueRequests = () => {
    const requestTypes = useSelector(state => state.atlassian?.portalRequestTypes);
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {portalId} = useParams();
    const reporterEmail = searchParams.get('reporter');
    const authenticatedUserEmail = useSelector(state => state.auth.userData?.user?.email);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedRequestType, setSelectedRequestType] = useState('');
    const [filteredIssues, SetFilteredIssues] = useState([]);
    const [jqlQuery, setJqlQuery] = useState(`reporter = "${authenticatedUserEmail}"`);

    useEffect(() => {
        setJqlQuery(`reporter = "${authenticatedUserEmail}"`);
    }, [authenticatedUserEmail]);

    useEffect(() => {
        const getAllIssues = async () => {
            try {
                const res = await getAllIssuesByJqlQuery(accessToken, clientCode, `${jqlQuery} ORDER BY Created DESC`);
                if (res) {
                    SetFilteredIssues(res?.data);
                }
            }
            catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }
        getAllIssues();
    }, [jqlQuery])


    const navigateToLandingPage = () => {
        navigate(`/servicedesk/customer/portal/${portalId}`)
    }

    const navigateToIssueDetails = (issueKey) => {
        navigate(`/servicedesk/customer/portal/${portalId}/${issueKey}`)
    }

    const handleInputChange = (value, name) => {
        setJqlQuery((prevQuery) => {
            if (name === 'requestType') {
                const updatedQuery = prevQuery.includes('"Request Type"') ?
                    prevQuery.replace(/"Request Type" = ".+?"/, `"Request Type" = "${value}"`) :
                    `${prevQuery} AND "Request Type" = "${value}"`;
                return updatedQuery;
            } else if (name === 'status') {
                const updatedQuery = prevQuery.includes('status') ?
                    prevQuery.replace(/status = ".+?"/, `status = "${value}"`) :
                    `${prevQuery} AND status = "${value}"`;
                return updatedQuery;
            }
            return prevQuery;
        });
    };



    return (
        <MainBox>
            <JiraHeader />
            <div className='topBox'>
                <div className='searchBox'>

                </div>
            </div>
            <div className='bottomBox'>
                <div className='contentBox'>
                    <span className='navigationBox'>
                        <span onClick={navigateToLandingPage}>Help Center </span>
                    </span>
                    <span className='boldBigText'>Requests</span>

                    <div className='tableTopBox'>
                        <FormControl sx={{ backgroundColor: '#ffffff' }} fullWidth>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedStatus}
                                label="Status"
                                name='status'
                                onChange={(e) => {
                                    setSelectedStatus(e.target.value);
                                    handleInputChange(e.target.value, e.target.name);
                                }}>
                                {
                                    requestStatus?.map((request, i) => (
                                        <MenuItem value={request?.statusInfo[0]?.statusId}>{request?.statusName}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>

                        <FormControl sx={{ backgroundColor: '#ffffff' }} fullWidth>
                            <InputLabel id="demo-simple-select-label">Request type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedRequestType}
                                label="Request type"
                                name='requestType'
                                onChange={(e) => {
                                    setSelectedRequestType(e.target.value);
                                    handleInputChange(e.target.value, e.target.name);
                                }}>
                                {
                                    requestTypes?.map((request, i) => (
                                        <MenuItem value={request?.requestName}>{request?.requestName}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </div>

                    <div className="tableBox">
                        <Table aria-label="collapsible table">
                            <TableHead className="tableHead">
                                <TableRow>
                                    <TableCell align='center' className='tableCell'>Type</TableCell>
                                    <TableCell align='center' className='tableCell'>Reference</TableCell>
                                    <TableCell align='center' className='tableCell'>Summary</TableCell>
                                    <TableCell align='center' className='tableCell'>Status</TableCell>
                                    <TableCell align='center' className='tableCell'>Requester</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="tableBody">
                                {filteredIssues?.map((row, index) => (
                                    <Row key={index} row={row} index={index} navigateToIssueDetails={navigateToIssueDetails} />
                                ))}
                            </TableBody>
                        </Table>
                    </div>

                </div>
            </div>
        </MainBox>
    )
}

export default AllJiraIssueRequests;

const MainBox = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 2rem;
width: 100%;


.tableHead {
    width: 100%;
  
    .tableCell {
      font-size: 0.9rem;
      font-weight: 600;
      font-family: var(--font);
      color: var(--color);
    }
    
  }
  
  .tableBody {
    width: 100%;
  
    .tableCell {
      font-size: 0.9rem;
      font-weight: 500;
      font-family: var(--font);
      color: var(--color);
      cursor: pointer;
    }
  }

  .rowText {
    font-size: 0.75rem;
  }

.topBox {
    background: rgb(255,255,226);
    background: linear-gradient(90deg, rgba(255,255,226,1) 0%, rgba(24,226,211,1) 50%, rgba(24,226,211,1) 100%);    color: var(--white);
    width: 100%;
    height: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .searchBox {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;

        .title {
            font-size: 1.5rem;
            font-weight: 600;
        }
    }
}


.bottomBox {
    background-color: var(--white);
    color: var(--color);
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10rem;
    top: 3.5rem;
    position: absolute;
    padding: 2rem 0 7.5rem 0;
    border: 0.05rem solid #CCD3D8;
    border-radius: 0.5rem;


    .contentBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: start;

        .navigationBox {
            width: 90%;
            margin: 0 5%;

            span {
                font-size: 0.9rem;
                font-weight: 700;
                color: var(--lightOrange);
                cursor: pointer;

            }

            span:hover {
                text-decoration: underline;
            }
        }

        .boldBigText {
            font-size: 1.5rem;
            font-weight: 600;
            font-family: var(--font);
            width: 90%;
            margin: 0.25rem 5% 0.75rem 5%;

        }

        .tableTopBox {
            display: flex;
            gap: 2rem;
            width: 50%;
            self-align: start;
            margin: 0 5%;




            select {
                padding: 0.5rem 0.75rem;
                border: 0.05rem solid var(--color);
                border-radius: 0.25rem;
                outline-color: var(--lightOrange);

                option {
                    display: block;
                    padding: 0.5rem 0.75rem;
                }
            }
        }

        .tableBox {
            width: 100%;
        }
    }
}

`