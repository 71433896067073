import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const SelectInput = ({ label, name, value, onChange, options, required }) => (
    <FormControl required={required} fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
            label={label}
            value={value}
            onChange={onChange}
            size='small'
            name={name}
            inputProps={{
                sx: {
                    color: '#626264',
                    fontSize: '0.8rem',
                    fontWeight: '400'
                },
            }}
            InputLabelProps={{
                sx: {
                    color: '#626264',
                    fontSize: '0.8rem',
                    fontWeight: '400'
                },
            }}
            sx={{
                padding: '0.5rem 0 0.5rem 0',
            }}
        >
            {options?.map((option) => (
                <MenuItem key={option?.value} value={option?.value}>
                    {option?.label}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

export default SelectInput;
