import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { CheckSquare, Filter, GitBranch } from 'lucide-react';
import FilterButton from '../../../../component/shared/FilterButton';
import FilterPopover from './FilterPopover';
import Button from '../../../../component/shared/Button';
import useFilters from '../hooks/useFilters';
import usePopover from '../hooks/usePopover';
import SearchBar from './SearchBar';

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: white;
  gap: 1rem;
  border-radius: 0.5rem;
  width: 100%;
`;



const Card = styled.div`
position: relative;


`

const QuestionFilter = () => {
    const { filters, handleCheckboxChange, handleApply, handleReset } = useFilters();
    const { showPopover, togglePopover } = usePopover();
    const [searchValue, setSearchValue] = useState('');

    return (
        <FilterContainer>
            <SearchBar value={searchValue} setValue={setSearchValue} />
            <Card>
                <FilterButton onClick={() => togglePopover('category')}>
                    <Filter />
                    <span>Category</span>
                </FilterButton>
                {showPopover === 'category' && (
                    <FilterPopover
                        title="Category"
                        options={['Technical', 'Non-technical', 'Aptitude', 'Cultural']}
                        selectedOptions={filters.category}
                        onOptionChange={(option, checked) => handleCheckboxChange('category', option, checked)}
                    />
                )}
            </Card>

            <Card>
                <FilterButton onClick={() => togglePopover('questionType')}>
                    <CheckSquare />
                    <span>Question Type</span>
                </FilterButton>
                {showPopover === 'questionType' && (
                    <FilterPopover
                        title="Question Type"
                        options={['Subjective', 'Objective', 'Coding']}
                        selectedOptions={filters.questionType}
                        onOptionChange={(option, checked) => handleCheckboxChange('questionType', option, checked)}
                    />
                )}
            </Card>

            <Card>
                <FilterButton onClick={() => togglePopover('difficultyLevel')}>
                    <GitBranch />
                    <span>Difficulty</span>
                </FilterButton>
                {showPopover === 'difficultyLevel' && (
                    <FilterPopover
                        title="Difficulty Level"
                        options={['Easy', 'Medium', 'Difficult']}
                        selectedOptions={filters.difficultyLevel}
                        onOptionChange={(option, checked) => handleCheckboxChange('difficultyLevel', option, checked)}
                    />
                )}
            </Card>

            <Button text="Apply Filters" func={handleApply} disabled={false}  />
            <Button text="Reset" func={handleReset} disabled={false}  />

        </FilterContainer>
    );
};

export default QuestionFilter;
