import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, TextField } from '@mui/material';
import dayjs from 'dayjs';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import styled from 'styled-components'
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { checkInterest } from '../../../../functions/api/employers/match/checkInterest';



const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 55%;
    width: 50%;
    height: 60%;
    margin-top: 5rem;
  }

  .btn {
   background-color: var(--lightOrange);
   margin-top: 1rem;
  }

  .btn:hover {
     background-color: var(--lightOrange);
  }

  .box {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 1.5rem;
   margin: 1.5rem auto;

  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;



const CheckInterestSlotModal = ({ open, onClose, ids }) => {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

    const formatDate = (date) => {
        return dayjs(date).format('YYYY-MM-DD');
    };

    const getDatesInRange = (start, end) => {
        const dates = [];
        let currentDate = dayjs(start);
        const endDate = dayjs(end);

        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
            dates.push(formatDate(currentDate));
            currentDate = currentDate.add(1, 'day');
        }

        return dates;
    };

    const handleSubmit = async () => {
            const dateRange = getDatesInRange(fromDate, toDate);
            const formattedDateString = dateRange.join(',');

            try{
                const res = await checkInterest(formattedDateString, ids, accessToken, clientCode);
                if(res){
                    toast.success("Interest Check sent successfully");
                    setFromDate(null);
                    setToDate(null);
                    onClose();
                }
            } catch (error) {
                const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again."
                toast.error(errMsg);
            }
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            onClose();
        }
    };

    return (
        <StyledDialog open={open} onClose={handleClose} disableEscapeKeyDown>
            <StyledDialogTitle>
                Select Slot Date Range for Interviews.
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </StyledDialogTitle>
            <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="box">
                            <DatePicker
                                label="From Date"
                                value={fromDate}
                                onChange={(newValue) => setFromDate(newValue)}
                                renderInput={(params) => <TextField {...params} required />}
                            />
                            <DatePicker
                                label="To Date"
                                value={toDate}
                                onChange={(newValue) => setToDate(newValue)}
                                renderInput={(params) => <TextField {...params} required />}
                            />
                            <Button
                                variant="contained"
                                className="btn"
                                onClick={handleSubmit}
                                disabled={!fromDate || !toDate}
                            >
                                Submit Interest Check
                            </Button>
                        </div>
                    </LocalizationProvider>
            </DialogContent>
        </StyledDialog>
    );
};

export default CheckInterestSlotModal;