import React from 'react';
import styled from 'styled-components';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getRandomDarkColor } from '../../../../utils/ReportsData';

const COLORS = ['var(--lightOrange)', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const DashboardContainer = styled.div`
  padding: 1rem;
`;

const DashboardTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  align-self: center;
`;

const SectionContainer = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const ChartContainer = styled.div`
  height: 300px;
  width: 100%;
`;

const InterviewDashboardReport = ({ data }) => {
    const { openReqs, totalReqs, source, trackerStatus, jdRoundsInfos } = data;

    const candidateStatusData = Object.entries(trackerStatus)?.map(([status, count]) => ({
        name: status,
        value: count
    }));

    const candidateSource = Object.entries(source)?.map(([key, value]) => ({
        name: key,
        value: value
    }))

    const roundsData = jdRoundsInfos?.map(round => ({
        name: `Round ${round?.stage + 1}`,
        candidates: round?.count
    }));

    return (
        <DashboardContainer>
            <DashboardTitle>Interviews Report for JD ID: {data?.jdId}</DashboardTitle>

            <SectionContainer>
                <SectionTitle>1. Open Reqs / Total Reqs</SectionTitle>
                <ChartContainer>
                    <ResponsiveContainer>
                        <PieChart>
                            <Pie
                                data={[
                                    { name: 'Open Reqs', value: openReqs },
                                    { name: 'Total Reqs', value: totalReqs }
                                ]}
                                cx="50%"
                                cy="50%"
                                innerRadius={60}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                                label={({ name, percent }) => `${name} ${(percent * 100)?.toFixed(0)}%`}
                            >
                                {
                                    [0, 1].map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))
                                }
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    </ResponsiveContainer>
                </ChartContainer>
            </SectionContainer>

            <SectionContainer>
                <SectionTitle>2. Candidate Source</SectionTitle>
                <ChartContainer>
                    <ResponsiveContainer>
                        <PieChart>
                            <Pie
                                data={candidateSource}
                                cx="50%"
                                cy="50%"
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                            >
                                {
                                    candidateSource?.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))
                                }
                            </Pie>
                            <Tooltip />
                            <Legend />
                        </PieChart>
                    </ResponsiveContainer>
                </ChartContainer>
            </SectionContainer>

            <SectionContainer>
                <SectionTitle>3. Candidate Status</SectionTitle>
                <ChartContainer>
                    <ResponsiveContainer>
                        <PieChart>
                            <Pie
                                data={candidateStatusData}
                                cx="50%"
                                cy="50%"
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                            >
                                {
                                    candidateStatusData?.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))
                                }
                            </Pie>
                            <Tooltip />
                            <Legend />
                        </PieChart>
                    </ResponsiveContainer>
                </ChartContainer>
            </SectionContainer>

            <SectionContainer>
                <SectionTitle>4. Candidates in Each Round</SectionTitle>
                <ChartContainer>
                    <ResponsiveContainer>
                        <BarChart data={roundsData}>
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="candidates">
                                {
                                    roundsData?.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={getRandomDarkColor()} />
                                    ))
                                }
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </ChartContainer>
            </SectionContainer>
        </DashboardContainer>
    );
};

export default InterviewDashboardReport;