import { getRandomDarkColor } from "./ReportsData";

export const generateCandidatesStatusChartData = (apiResponse) => {
    const candidatesStatusData = [
        ["Stage", "ratio"] // Static value for the first element
    ];
    const candidatesStatusOptions = {
        pieHole: 0.3,
        is3D: false,
        backgroundColor: "transparent",
        legend: {
            position: "top",
            textStyle: {
                fontSize: 9,
            }
        },
        colors: [],
        chartArea: {
            left: "1rem",
            right: "1rem",
            top: "4.75rem",
            bottom: "3rem",
            width: "90%",
            height: "90%",
        },
        pieSliceTextStyle: {
            color: "white",
        },
        pieSliceBorderColor: "transparent",
        sliceVisibilityThreshold: 0,
        shadow: "none",
    };

    if (apiResponse) {
        const keys = Object.keys(apiResponse);
        keys.forEach((key, index) => {
            candidatesStatusData.push([key, apiResponse[key]]);
            candidatesStatusOptions.colors.push(getRandomDarkColor());
        });
    }

    return { candidatesStatusData, candidatesStatusOptions };
};


export const generateReqsChartData = (jdStatus, reqStatus) => {
    const reqsData = [
        ["Stage", "JD: Active", "JD: InActive", "Reqs: Open", "Reqs: Closed", { role: "style" }]
    ];

    const reqsOptions = {
        isStacked: true,
        legend: {
            position: "top",
            textStyle: {
                fontSize: 9,
            }
        },
        chartArea: {
            left: "1rem",
            right: "1rem",
            top: "3rem",
            bottom: "3rem",
            width: "85%",
            height: "85%",
        },
        colors: [],
        sliceVisibilityThreshold: 0,
        shadow: "none",
    };

    reqsData.push([
        "JD Status",
        jdStatus["ACTIVE_JD"] || 0,
        jdStatus["INACTIVE_JD"] || 0,
        0,
        0,
        getRandomDarkColor()
    ]);

    reqsData.push([
        "Req Status",
        0,
        0,
        reqStatus["OPEN Reqs"] || 0,
        reqStatus["Closed Reqs"] || 0,
        "#d62728"
    ]);

    reqsOptions.colors.push('#1f77b4');
    reqsOptions.colors.push('#ff7f0e');
    reqsOptions.colors.push('#2ca02c');
    reqsOptions.colors.push('#d62728');

    return { reqsData, reqsOptions };
};


export const generateCandidateByRoundChartData = (apiResponse) => {
    const candidateByRoundData = [
        ["Round", "SHORTLISTED", "Scheduled", "Completed", { role: "style" }] // Updated to handle stacking
    ];
    const candidateByRoundOptions = {
        isStacked: true,
        legend: {
            position: "top",
            textStyle: {
                fontSize: 9,
            }
        },
        chartArea: {
            left: '1rem',
            right: '1rem',
            top: '3rem',
            bottom: '3rem',
            width: "85%",
            height: "85%",
        },
        colors: ['#4285F4', 'orange', '#34A853']
    };

    if (apiResponse && Object.keys(apiResponse).length > 0) {
        apiResponse.sort((a, b) => a.stage - b.stage);
        apiResponse.forEach((entry) => {
            if (entry.data !== undefined) {
                const scheduled = entry.data.SCHEDULED || 0;
                const completed = entry.data.COMPLETED || 0;
                candidateByRoundData.push([`Round ${entry?.stage}`, 0, scheduled, completed, null]);
            } else if (entry.count) {
                candidateByRoundData.push([`Shortlisted`, entry.count, 0, 0, "#cccccc"]);
            }
        });
    } else {
        candidateByRoundData.push(["No Data", 0, 0, 0, "#cccccc"]);
    }

    console.log(candidateByRoundData)
    return { candidateByRoundData, candidateByRoundOptions };
};



export const generateSourceChartData = (apiResponse) => {
    console.log(apiResponse);
    const agencyData = apiResponse?.agency || {};
    const agencyKeys = Object.keys(agencyData);
    const numAgencyKeys = agencyKeys.length;

    const sourceData = [
        ["Source", "Candidates", ...agencyKeys, { role: "style" }]
    ];

    const sourceOptions = {
        isStacked: true,
        legend: {
            position: "top",
            textStyle: {
                fontSize: 9,
            }
        },
        chartArea: {
            left: '1rem',
            right: '1rem',
            top: '3rem',
            bottom: '3rem',
            width: "85%",
            height: "85%",
        },
    };

    if (apiResponse && Object.keys(apiResponse)?.length > 0) {
        const keys = Object.keys(apiResponse);
        keys.forEach((key) => {
            if (key === "agency" && typeof apiResponse[key] === 'object') {
                const agencyData = apiResponse[key];
                const agencyDataRow = agencyKeys?.map(segment => agencyData[segment] || 0);

                sourceData.push([`agency`, 0, ...agencyDataRow, getRandomDarkColor()]);
            } else if (typeof apiResponse[key] === 'number') {
                console.log(apiResponse[key]);
                const row = [key, apiResponse[key], ...Array(numAgencyKeys).fill(0), getRandomDarkColor()];

                sourceData.push(row);
            } else {
            }
        });
    } else {
        sourceData.push(["No Data", 0, ...Array(numAgencyKeys).fill(0), getRandomDarkColor()]);
    }
    console.log(sourceData);
    return { sourceData, sourceOptions };
};
