import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { styled } from "styled-components";
import validate from "../../../../functions/validate";
import { logout, performLogin, performLoginWithGoogle, setUserType } from "../../../../slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";
import { useJwt } from "react-jwt";
import { navigateBasedOnUserRole } from "../../../../utils/globalFunctions";
import { toast } from "react-toastify";
import { GoogleLogin } from "@react-oauth/google";
import { googleAuthentication } from "../../../../functions/api/authentication/googleAuthentication";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

const JobListingLogin = ({ handleClose, setTrigger, setOpenSignup }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.auth.userData);
    const accessToken = useSelector((state) => state.auth.userData?.accessToken);
    const role = useJwt(accessToken);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [clientCode, setClientCode] = useState("");

    const [passwordVisible, setPasswordVisible] = useState(false);
    const error = useSelector((state) => state.auth?.error);

    const captchaRef = useRef(null);
    const [captchaError, setCaptchaError] = useState(false);

    const [searchParams] = useSearchParams();
    const [portalType, setPortalType] = useState("REGORTALENT");

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = captchaRef.current.getValue();

            if (!token) {
                setCaptchaError(true);
            } else {
                setCaptchaError(false);
                captchaRef.current.reset();

                let val = validate(email, password);

                if (val) {
                    {
                        dispatch(performLogin({ password, email, clientCode, portalType }));
                    }
                }
            }
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    };

    useEffect(() => {
        const currentUser = role?.decodedToken?.type;

        if (accessToken && currentUser) {
            dispatch(setUserType(currentUser));
            handleClose();
            setTrigger(prev => !prev);
        }
    }, [role]);

    useEffect(() => {
        if (error) {
            const errMsg = error || "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
            handleRetryFunc();
        }
    }, [error]);

    const handleRetryFunc = () => {
        dispatch(logout());
    };

    const handleNavigateSignup = () => {
        handleClose();
        setOpenSignup(true);
    }

    return (
        <>
            <StyledLogin>
                <Box
                    sx={{
                        width: "100%",
                        position: "relative",
                        top: "8rem",
                        margin: "0 1rem",
                        marginBottom: "7rem",
                    }}
                    className="box"
                >
                    <div id="form">
                        <ValidatorForm onSubmit={handleSubmit}>
                            <span className="title">Login to apply</span>
                            <div className="inputBox">
                                <TextValidator
                                    label="Email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    errorMessages={["This field is required", 'Email is not valid']}
                                    validators={['required', 'isEmail']}
                                    fullWidth
                                    required
                                    inputProps={{
                                        sx: {
                                            color: '#626264',
                                            fontSize: '0.9rem',
                                            fontWeight: '400'
                                        },
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            color: '#626264',
                                            fontSize: '0.9rem',
                                            fontWeight: '400'
                                        },
                                    }}
                                />
                            </div>

                            <div className="inputBox">
                                <TextValidator
                                    label="Password"
                                    type={passwordVisible ? "text" : "password"}
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    fullWidth
                                    required
                                    inputProps={{
                                        sx: {
                                            color: '#626264',
                                            fontSize: '0.9rem',
                                            fontWeight: '400'
                                        },
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            color: '#626264',
                                            fontSize: '0.9rem',
                                            fontWeight: '400'
                                        },
                                    }}
                                />
                                <FontAwesomeIcon
                                    icon={faEye}
                                    id="eye"
                                    onClick={togglePasswordVisibility}
                                    className={`eye-icon ${passwordVisible ? "visible" : ""}`}
                                />
                            </div>

                            <div className="resetBox">
                                <span className="remember">
                                    <input type="checkbox" />
                                    Remember me
                                </span>
                                <span>
                                    <Link to="/reset/seeker" className="reset">
                                        Forgot Password
                                    </Link>
                                </span>
                            </div>

                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                                ref={captchaRef}
                                size="normal"
                            />
                            {captchaError && (
                                <span className="captchaErrorText">
                                    Error: please verify captcha
                                </span>
                            )}
                            <a className="terms" onClick={() => navigate("/terms")}>
                                By logging in, you agree to our Terms and Conditions.
                            </a>

                            <button type="submit" className="btn">
                                Login
                            </button>
                        </ValidatorForm>

                        <div className="bottomBox">
                            <div className="textBox">
                                <span className="or">OR</span>
                                <span className="loginWith">Login with</span>
                            </div>

                            <div className="logoBox">
                                <GoogleLogin
                                    className="logo"
                                    onSuccess={(credentialResponse) => {
                                        delete credentialResponse.select_by;
                                        try {
                                            const getData = async () => {
                                                const res = await googleAuthentication(
                                                    credentialResponse,
                                                    clientCode
                                                );
                                                if (res) {
                                                    dispatch(performLoginWithGoogle(res?.data?.data));
                                                };
                                            };

                                            getData();
                                        } catch (error) {
                                            const errMsg =
                                                error?.response?.data?.notify?.message ||
                                                "An error occurred. Please try again.";
                                            toast.error(errMsg, 8000);
                                        }
                                    }}
                                    onError={() => {
                                        toast.error("Login Failed", 8000);
                                    }}
                                />
                                {/* <img src={linkedinAuthIcon} className="logo" /> */}
                            </div>
                        </div>

                        <p>
                            <span className="link" onClick={handleNavigateSignup}>
                                Don't have an account ? <span className="link1">Sign Up</span>
                            </span>
                        </p>
                    </div>
                </Box>
            </StyledLogin>
        </>
    );
};

export default JobListingLogin;

const StyledLogin = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .captchaErrorText {
    font-size: 0.8rem;
    font-weight: 500;
    color: red;
    margin-top: -0.5rem;
  }

  p {
    font-size: 0.9rem;
  }

  .terms {
    font-size: 0.7rem;
    font-weight: 400;
    color: #000;
    line-height: 0.8rem;
    text-decoration: none;
    cursor: pointer;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    margin-top: 2rem;
  }

  #form {
    width: 80%;
    background-color: var(--backgroundColor);
    // height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

  .link {
    text-decoration: none;
    color: var(--color);
  }

  .link1 {
    color: var(--color);
    cursor: pointer;
  }

  .link1:hover {
    font-weight: 600;
    text-decoration: underline;
  }

  .resetBox {
    display: flex;
    width: 60%;
    justify-content: space-between;
    align-items: center;
  }

  .reset {
    font-size: 0.9rem;
    text-decoration: none;
    font-weight: 500;
  }

  .remember {
    display: flex;
    font-size: 0.9rem;
    text-decoration: none;
    font-weight: 500;
    align-items: center;
    gap: 0.5rem;
    height: 1.4rem;

    input {
      width: 1rem;
      margin: 0rem;
      cursor: pointer;
    }
  }

  .inputBox {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-top: 1rem;

  }

  input {
    width: 100%;
    border-radius: 0.3rem;
    background-color: var(--white);
    font-size: 0.9rem;
  }

  input:focus {
    outline-color: var(--lightOrange);
  }


  label {
    color: var(--color);
    font-size: 0.8rem;
  }

  .btn {
    background-color: var(--lightOrange);
    color: var(--backgroundColor);
    font-size: 1.1rem;
    font-weight: 600;
    font-family: var(--font);
    border-radius: 0.4rem;
    border: none;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
  }

  .prev {
    background-color: var(--lightOrange);
    padding: 0.1rem;
    position: absolute;
    top: 6rem;
    left: 3rem;
    color: var(--white);
  }

  .prev:hover {
    color: var(--color);
  }

  .eye-icon {
    position: absolute;
    top: 60%;
    right: 2%;
    transform: translateY(-50%);
    cursor: pointer;
    color: lightgray;
  }

  .eye-icon.visible {
    color: blue; /* Change to your desired color when password is visible */
  }

  @media (max-width: 800px) {
    justify-content: center;

    #form {
      width: 100%;
    }
  }

  // Custom styled for tabs

  .custom-tab {
    color: white;
    background-color: var(--lightOrange);
    transition: background-color 0.3s;
    text-decoration: none !important;
  }

  .custom-tab-selected {
    background-color: white;
    color: var(--lightOrange) !important;
    border: 0.1rem solid var(--lightOrange);
    border-radius: 3rem;
    text-decoration: none !important;
  }

  .custom-tab-selected .MuiTab-label {
    text-transform: none;
  }

  .bottomBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
    gap: 0.5rem;

    .textBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      .or {
        font-size: 1rem;
        font-weight: 600;
      }

      .loginWith {
        font-size: 0.8rem;
        font-weight: 400;
      }
    }

    .logoBox {
      display: flex;
      gap: 1rem;
      margin-top: 0.3rem;
      margin-bottom: 0.5rem;

      img {
        width: 3rem;
        cursor: pointer;
      }
    }
  }
`;
