import { useState } from 'react';

const usePopover = () => {
    const [showPopover, setShowPopover] = useState(null); // Track the open popover

    const togglePopover = (type) => {
        setShowPopover((prev) => (prev === type ? null : type));
    };

    return { showPopover, togglePopover };
};

export default usePopover;
