import axios from "axios";

export const getDashboardInterviewData = async (
    accessToken,
    clientCode,
    page = 1,
    size = 1000000
) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            "x-client-code": clientCode,
            "Access-Control-Allow-Origin": "*",
        },
    };

    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/jds/get-dashboard-data?page=${page}&size=${size}`,
            config
        );
        console.log("Data:", response.data);
        console.log("Status:", response.status);
        return response.data;
    } catch (error) {
        throw error;
    }
};
