import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, CircularProgress, MenuItem, TextField } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import { toast } from 'react-toastify'
import { publicCreateIssue } from '../../../../functions/api/AtlassianAPIs/publicCreateIssue'
import CustomPriceRequestSuccessMessage from '../../../commonComponents/InfoMessageComp/CustomPriceRequestSuccessMessage'



const ContactUsFormForPricing = ({handleClose}) => {
    const [clientName, setClientName] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [clientContact, setClientContact] = useState();
    const [companyName, setCompanyName] = useState('');
    const [comments, setComments] = useState('');
    const [loading, setLoading] = useState(false);

    const [success, setSuccess] = useState(false);

    const handleContactChange = (newInput) => {
        setClientContact(newInput)
    }

    const handleSubmit = async () => {

        if (clientName === '' || clientName.length === 0) {
            toast.warning("Please fill Name", 5000);
            return;
        }
        if (clientEmail === '' || clientEmail.length === 0) {
            toast.warning("Please fill Email", 5000);
            return;
        }
        if (companyName === '' || companyName.length === 0) {
            toast.warning("Please fill Organization Name", 5000);
            return;
        }

        setLoading(true);
        try {
            const payload = {
                summary: "Request Pricing",
                description: `${clientName ? `Client Name: ${clientName}\n` : ''}${clientEmail ? `Client Email: ${clientEmail}\n` : ''}${clientContact ? `Client Contact: ${clientContact}\n` : ''}${comments ? `Comments: ${comments}\n` : ''}${companyName ? `Company Name: ${companyName}\n` : ''}`,
                reporterEmail: clientEmail,
                issueTypeId: "10003",
                projectId: "10003",
                urgencyId: "10023",
                impactId: "10003",
                requestType: "85",
            }

            const issueRes = await publicCreateIssue("regortalent", payload);
            if (issueRes) {
                setLoading(false);
                setSuccess(true);
                // handleClose();
                setClientName("");
                setClientEmail("");
                setClientContact("");
                setCompanyName("");
                setComments("");
            }
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
            setLoading(false);
        }
    }

    return (
        <>{!success ? <StyledDiv>
            {loading ? <div className='loaderBox'>
                <CircularProgress color="inherit" />
            </div> :
                <div className='content'>
                        <span className='title'>Contact Us for Pricing</span>

                        <TextField id="outlined-basic" className='textInput' label="Name" variant="outlined" value={clientName} onChange={(e) => setClientName(e.target.value)} required />
                        <TextField id="outlined-basic" className='textInput' label="Email" variant="outlined" type='email' value={clientEmail} onChange={(e) => setClientEmail(e.target.value)} required />
                        <MuiTelInput defaultCountry="IN" className='textInput' forceCallingCode preferredCountries={['IN', 'US']} value={clientContact} onChange={handleContactChange} required />
                        <TextField id="outlined-basic" className='textInput' label="Organization Name" variant="outlined" value={companyName} onChange={(e) => setCompanyName(e.target.value)} required />
                        <TextField id="outlined-basic" className='textInput' label="Comment" variant="outlined" value={comments} onChange={(e) => setComments(e.target.value)} />

                        <button className='btn' onClick={handleSubmit}>Submit Request</button>
                </div>}
        </StyledDiv> : <CustomPriceRequestSuccessMessage/> }</>
    )
}

export default ContactUsFormForPricing



const StyledDiv = styled.div`
display: flex;
flex-direction: column;
width: 100%;

.loaderBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    position: absolute;
    z-index: 1000;
    right: 0;
    top: 4.05rem;
    color: var(--lightOrange);
  
  }

.content {
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0rem;
}

.textInput {
    width: 80%;
}


.title {
    font-size: 1.5rem;
    font-weight: 600;
}


.btn {
    background-color: var(--lightOrange);
    color: var(--backgroundColor);
    font-size: 1.2rem;
    border-radius: 0.4rem;
    border: none;
    padding: 0.7rem 1rem;
    cursor: pointer;
  }

  @media(max-width: 800px) {

    .left {
        display: none;
    }

    .right {
        width: 100%;
    }

    form {
        width: 90%;
    }

    select {
        width: 80%;
    }

  }


`



