import axios from "axios"


export const addAttachments = async (accessToken, formdata, issueKey) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };


    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/jira/addAttachments/${issueKey}`, formdata, config);
        console.log('Data:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
}

