
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import logo from "../../assets/regorTalentLogo.webp";
import { useSelector } from "react-redux";
import profileIcon from '../../assets/profileIcon.webp'
import notificationIcon from '../../assets/icons/notification.png'

import { persistor } from "../../store";
import { logout } from "../../slices/authSlice";
import { useDispatch } from "react-redux";

import profileFeedback from '../../assets/icons/profileFeedback.png'
import profileHelp from '../../assets/icons/profileHelp.png'
import profileReset from '../../assets/icons/profileReset.png'
import profileLogout from '../../assets/icons/profileLogout.png'
import { setCurrentItem } from "../../slices/configSlice";
import { getResourceById } from "../../functions/api/jobSeekers/getResource";
import { toast } from "react-toastify";
import { getUnreadCount } from "../../functions/api/employers/notifications/getUnreadCount";

const JobSeekerHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector(state => state.auth.userData?.accessToken);
  const clientCode = useSelector((state) => state.auth.userData?.user?.clientCode);
  const user = useSelector(state => state.auth.userData.user);
  const [userProfile, setUserProfile] = useState('');
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);


  useEffect(() => {
    const getResourceData = async () => {
      try {
        const res = await getResourceById(user?.id, accessToken);
        setUserProfile(res?.profilePhoto);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }

    const getNotifyCount = async () => {
      try {
        const res = await getUnreadCount(accessToken, clientCode);
        if (res) {
          setUnreadNotificationCount(res?.data);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }

    getResourceData();
    getNotifyCount();
  }, [])

  const handleLogout = () => {
    persistor.purge();
    dispatch(logout())
    navigate("/");
  };


  return (
    <StyledDiv>
      <div id="left">
        <img src={logo} onClick={() => navigate("/")} />
      </div>

      <div id="right">
        <div className="notificationIcon" onClick={() => dispatch(setCurrentItem('inbox'))}>
        <img src={notificationIcon} className="notifyIcon" /> <span className="notifyCount">{unreadNotificationCount}</span>
        </div>

        <div className="profileIcon">
          {userProfile ?
            <img src={userProfile} className="profileImgNew" />
            :
            <img src={profileIcon} className="profileImg" />
          }
        </div>

        <div class="dropdown" id="dropdown">
          <span className="titleText span" style={{ marginBottom: '0rem', border: 'none' }} onClick={() => dispatch(setCurrentItem('profile'))}>Signed In as <b>{user?.firstName}</b></span>
          <span onClick={() => navigate('/feedback')} className="span"><img src={profileFeedback} /> Feedback</span>
          <span onClick={() => navigate('/support')} className="span"><img src={profileHelp} /> Help</span>
          <span onClick={() => navigate('/reset/seeker')} className="span"><img src={profileReset} /> Reset Password</span>
          <span onClick={handleLogout} className="span"><img src={profileLogout} /> Logout</span>
        </div>
      </div>
    </StyledDiv>
  );
};

export default JobSeekerHeader;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color);
  width: 90%;
  padding: 0% 5%;
  height: 4rem;
  position: fixed;
  z-index: 2000;
  background-color: var(--white);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

  #left > img {
    height: 3.5rem;
    margin-left: -5%;
    cursor: pointer;
  }

  h1 {
    margin: 0;
    color: #add8e6;
  }

  #right {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  

  .link {
    text-decoration: none;
  }


  .Icon {
    color: var(--white);
  }

  .notificationIcon {
    width: 1.75rem;
    height: 1.75rem;
    cursor: pointer;
    margin-top: 0.1rem;
    display: flex;
    position: relative;

    .notifyIcon {
      width: 100%;
      height: 100%;
    }

    .notifyCount {
      position: absolute;
      font-size: 0.85rem;
      font-weight: 600;
      top: -0.45rem;
      right: -0.45rem;
      margin-top: 0;
      display: flex;
      background-color: red;
      color: var(--white);
      border-radius: 50%;
      min-width: 1.25rem;
      min-height: 1.25rem;
      align-items: center;
      justify-content: center;
    }
  }

  .profileIcon {
    position: relative;
    cursor: pointer;
  }

  .profileImg {
    width: 1.5rem;
    height: 1.5rem;
  }

  .profileImgNew {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
  }

  .dropdown {
    display: none;
    position: absolute;
    top: 75%;
    right: 0.5vw;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    padding: 1rem;
    font-weight: 400;
    font-size: 0.8rem;
    border-radius: 0.5rem;

    .titleText {
      font-weight: 400;
      font-size: 0.9rem;
    }

    img {
      width: 1rem;
    }
}

.dropdown span {
    display: flex;
    padding: 8px 10px;
    text-decoration: none;
    color: #333;
    transition: background-color 0.3s ease;
    border-bottom: 0.1rem groove #f6f6f6;
    gap: 0.5rem;
    align-items: center;
}

.dropdown span:hover {
    background-color: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
}

.profileIcon:hover+#dropdown {
    display: block;
}

.dropdown:hover {
    display: block;
}

`;

