
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState, useEffect } from "react";
import InterviewList from "./InterviewList";
import '../../App.css';
import styled from "styled-components";
import ScheduledInterviewList from "./ScheduledInterviewList";
import MockInterviews from "./SeekerDashboard/sidebarPages/MockInterviews";
import InProgressMock from "./SeekerDashboard/sidebarPages/InProgressMock";

export default function InterviewTabs() {
  const [value, setValue] = useState("COMPLETED");
  const [mock, setMock] = useState(false);


  const handleChange = (event, newValue) => {
    if (newValue == "MOCK") {
      setMock(true);
      setValue("COMPLETED");
    } else {
      setMock(false);
      setValue(newValue);
    }
  };


  return (
    <>
      <StyledBox>
        <Tabs
          style={{
            width: '60%',
            borderRadius: '3rem',
            backgroundColor: 'var(--lightOrange)'
          }}
          value={mock ? "MOCK" : value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "var(--lightOrange)",
            },
          }}
          variant="fullWidth"
          aria-label="wrapped label tabs example"
        >
          <Tab
            value="COMPLETED"
            label="Completed"
            sx={{
              color: "var(--lightOrange)",
              fontSize: "0.8rem",
            }}
            classes={{ root: 'custom-tab', selected: 'custom-tab-selected' }}
          />
          <Tab
            value="NOT_STARTED"
            label="Scheduled"
            sx={{
              color: "var(--lightOrange)",
              fontSize: "0.8rem",
            }}
            classes={{ root: 'custom-tab', selected: 'custom-tab-selected' }}
          />
          <Tab
            value="MOCK"
            label="Completed Mock"
            sx={{
              color: "var(--lightOrange)",
              fontSize: "0.8rem",
            }}
            classes={{ root: 'custom-tab', selected: 'custom-tab-selected' }}
          />
          <Tab
            value="STARTED"
            label="In Progress Mock"
            sx={{
              color: "var(--lightOrange)",
              fontSize: "0.8rem",
            }}
            classes={{ root: 'custom-tab', selected: 'custom-tab-selected' }}
          />
        </Tabs>
        {(value === 'COMPLETED' && !mock) && <InterviewList />}
        {value === 'NOT_STARTED' && <ScheduledInterviewList />}
        {mock && <MockInterviews />}
        {value === "STARTED" && <InProgressMock />}
      </StyledBox>
    </>
  );
}

// NOT_STARTED,CANCELED,STARTED,COMPLETED,EXPIRED;


const StyledBox = styled.div`
    width: 99%;
    min-height: 30rem;
    margin: 0 auto;
    margin-top: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;



    // Custom styled for tabs

    .custom-tab {
      color: white;
      background-color: var(--lightOrange);
      transition: background-color 0.3s;
      text-decoration: none !important;
    }

.custom-tab-selected {
    background-color: white;
    color: var(--lightOrange) !important;
    border: 0.1rem solid var(--lightOrange);
    border-radius: 3rem;
    text-decoration: none !important;
  }

.custom-tab-selected .MuiTab-label {
  text-transform: none;
}
`

