import React from 'react'
import styled from 'styled-components';
import HeroSection from './LandingPage/HeroSection';
import FeaturesSection from './LandingPage/FeaturesSection';
import GroupSection from './LandingPage/GroupSection';
import AIPracticeToolsSection from './LandingPage/AIPracticeToolsSection';
import BrowseCategory from './LandingPage/BrowseCategory';
import TestimonialsSection from './LandingPage/TestimonialsSection';
import Footer from './LandingPage/Footer';
import AcademicLandingHeader from './LandingPage/AcademicLandingHeader';
import Favicon from "react-favicon";
import testFavicon from "../../assets/Academic/examPrep.ico"

const AcademicLandingPage = () => {
    return (
        <AppWrapper>
            <Favicon url={testFavicon}/>
            <AcademicLandingHeader/>
            <HeroSection />
            <FeaturesSection />
            <GroupSection />
            <AIPracticeToolsSection />
            <BrowseCategory />
            {/* <TestimonialsSection /> */}
            <Footer />
        </AppWrapper>
    )
}

export default AcademicLandingPage


const AppWrapper = styled.div`
  font-family: var(--font);
  color: #333;
`;