import React, { useState } from 'react';
import styled from 'styled-components';
import TextInput from '../../../../component/shared/TextInput';
import SelectInput from '../../../../component/shared/SelectInput';
import TagsInput from '../../../../component/shared/TagsInput';
import Button from '../../../../component/shared/Button';
import { Stack } from '@mui/system';
import { allTags } from '../../../../utils/contantData';
import { useTestForm } from '../hooks/useTestForm';
import QuestionCard from '../../questions/components/QuestionCard';
import TestQuestionsLibrary from '../../../../components/Interviews/EmployerDashboard/sidebarPages/TestQuestionsLibrary';
import ModalHOC from '../../../../components/Interviews/SeekerDashboard/ModalHOC';
import { useCreateTest } from '../hooks/useCreateTest';
import { useSelector } from 'react-redux';
import { useUpdateTest } from '../hooks/useUpdateTest';
import { usePublishTest } from '../hooks/usePublishTest';
import { useUpdateTestForPublish } from '../hooks/useUpdateTestForPublish';

const TestFormPage = ({ editingIndex, editingTestId, editingTestData, testQuestionData, isReview, toggleView, setTestTrigger }) => {
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

    const {
        formData,
        allQuestions,
        handleChange,
        handleDeleteQue,
        handleTagsChange,
        handleTopicsChange,
        handleTagInputBlur,
        handleTopicInputBlur,
        selectedTags,
        selectedTopics,
        tagInputValue,
        setTagInputValue,
        topicInputValue,
        setTopicInputValue,
    } = useTestForm(editingIndex, editingIndex != -1 && editingTestId, editingIndex != -1 && editingTestData, editingIndex != -1 && testQuestionData, isReview);

    const { handleCreateTest, loadingCreate } = useCreateTest(accessToken, clientCode, toggleView, setTestTrigger);
    const { handleUpdateTest, loadingUpdate } = useUpdateTest(accessToken, clientCode, toggleView, setTestTrigger);
    const { handlePublishTest, loadingPublish } = usePublishTest(accessToken, clientCode, toggleView, setTestTrigger);
    const { handleUpdateTestForPublish, loadingUpdatePublish } = useUpdateTestForPublish(accessToken, clientCode);

    const [openQueLibrary, setOpenQueLibrary] = useState(false);

    const handleImportQuestion = () => {
        setOpenQueLibrary(true);
    }

    console.log(formData);

    const createTest = () => {
        const payload = {
            name: formData?.name,
            questionIds: formData?.questionIds,
            role: formData?.role,
            clientCode: clientCode,
            testType: formData?.testType,
            tags: formData?.tags,
            topics: formData?.topics,
        }

        handleCreateTest(payload);
    }

    const updateTest = () => {
        handleUpdateTest(editingTestId, formData);
    }

    const publishTest = async () => {
        await handleUpdateTestForPublish(editingTestId, formData);
        handlePublishTest(editingTestId, true);
    }

    return (
        <Container>
            <ModalHOC openNewInterviewModal={openQueLibrary} setOpenNewInterviewModal={setOpenQueLibrary} component={<TestQuestionsLibrary onClose={() => setOpenQueLibrary(false)} />} />
            <div className='titleBox'>
                <span className='title'>{isReview ? "Review and Publish Test" : editingIndex === -1 ? 'Create Test' : 'Update Test'} Form</span>
                <Button text="Back to Manage Tests" func={() => toggleView('test')} disabled={false} />
            </div>
            <Form>
                <div className="inputBox">
                    <TextInput
                        label="Test Title"
                        name="name"
                        type="text"
                        value={formData?.name}
                        onChange={handleChange}
                        required
                    />
                    <TextInput
                        label="Role"
                        type="text"
                        value={formData?.role}
                        onChange={handleChange}
                        name="role"
                        required
                    />
                </div>

                <div className="inputBox">
                    <SelectInput
                        label="Test Type"
                        name="testType"
                        value={formData?.testType}
                        onChange={handleChange}
                        options={[
                            { value: "mix", label: "General (Includes all types of Questions)" },
                            { value: "general", label: "Subjective" },
                            { value: "mcq", label: "Objective" },
                            { value: "coding", label: "Coding" },
                        ]}
                        required
                    />

                    <Stack spacing={3} sx={{ width: "100%" }}>
                        <TagsInput
                            options={allTags}
                            value={selectedTags}
                            onChange={handleTagsChange}
                            onBlur={handleTagInputBlur}
                            inputValue={tagInputValue}
                            setInputValue={setTagInputValue}
                            label="Tags*"
                        />
                    </Stack>
                </div>

                <div style={{ width: 'calc(50% - 1rem)' }}>
                    <Stack spacing={3} sx={{ width: "100%" }}>
                        <TagsInput
                            options={allTags}
                            value={selectedTopics}
                            onChange={handleTopicsChange}
                            onBlur={handleTopicInputBlur}
                            inputValue={topicInputValue}
                            setInputValue={setTopicInputValue}
                            label="Topics*"
                        />
                    </Stack>
                </div>
            </Form>

            <div className='titleBox'>
                <span className="title">Questions</span>
                <Button text="Import Question from Library" func={() => handleImportQuestion()} disabled={false} />
            </div>

            <div className='queBox'>
                {
                    allQuestions?.map((question, index) => (
                        <QuestionCard data={question} isTestForm={true} handleDeleteTestQuestion={handleDeleteQue} />
                    ))
                }
            </div>
            <div className='btnBox'>
                <Button text={isReview ? "Save Changes" : editingIndex === -1 ? `Create Test` : `Update Test`} func={() => (!isReview && editingIndex === -1) ? createTest() : updateTest()} disabled={false} />
                {isReview && <Button text="Publish Test" func={() => publishTest()} />}
            </div>
        </Container>
    );
};

export default TestFormPage;

const Container = styled.div`
    width: 96%;
    margin: 0 auto 1rem auto;
    padding: 1rem;
    box-sizing: border-box;
    gap: 1rem;
    background-color: var(--white);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;


    .titleBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        width: 100%;
    }

    .title {
        font-size: 0.9rem;
        font-weight: 700;
        display: block;
    }

    .textAreaBox {
        width: 100%;
        position: relative;
        margin-top: 0.5rem;
        margin-bottom: 1rem;

        label {
            font-size: 0.7rem;
            font-weight: 600;
            position: absolute;
            top: -0.5rem;
            left: 0.5rem;
            padding: 0 0.5rem;
            background-color: var(--white);
            z-index: 10000;
        }
    }

    .queBox {
     display: flex;
     flex-direction: column;
     gap: 0.5rem;
     width: 100%;
    }

    .btnBox {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 1rem 0;
    }
`;

const Form = styled.form`
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-bottom: 1rem;
    gap: 0.7rem;

    .inputBox {
        width: 100%;
        display: flex;
        gap: 2rem;
        margin-bottom: 1rem;
        align-items: center;
    }

    .inputBoxMap {
        width: 100%;
        display: flex;
        gap: 2rem;
        margin-bottom: 1rem;
        flex-flow: row wrap;
    }

    .box {
        width: calc(50% - 1rem);
    }
`;

const Label = styled.label`
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    margin-top: 0.75rem;
    font-weight: 600;
`;
