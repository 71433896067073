import { useEffect, useState } from 'react';

export const useQuestionForm = (editingIndex, editingQuestionData, questionType) => {
    const [formData, setFormData] = useState({
        difficultyLevel: '',
        tags: '',
        questionText: '',
        questionType: questionType,
        mcqOptions: ['', '', '', ''],
        mcqAnswer: '',
        category: '',
        score: 0,
        topics: '',
    });

    const [selectedTags, setSelectedTags] = useState([]);
    const [tagInputValue, setTagInputValue] = useState("");
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [topicInputValue, setTopicInputValue] = useState("");

    useEffect(() => {
        if (editingIndex !== -1) {
            const updatedMcqOptions = [...editingQuestionData?.mcqOptions];
            setFormData({
                questionText: editingQuestionData?.questionText,
                difficultyLevel: editingQuestionData?.difficultyLevel,
                category: editingQuestionData?.category,
                mcqOptions: updatedMcqOptions,
                mcqAnswer: editingQuestionData?.mcqAnswer?.trim(),
                score: editingQuestionData?.score || 0,
                questionType: editingQuestionData?.questionType,
            });
            setSelectedTags(editingQuestionData?.tags?.split(", ") || []);
            setSelectedTopics(editingQuestionData?.topics?.split(", ") || []);
        }
    }, [editingIndex, editingQuestionData]);

    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            tags: selectedTags.join(", "),
            topics: selectedTopics.join(", "),
        }));
    }, [selectedTags, selectedTopics]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleChoiceChange = (e, index) => {
        const newChoices = [...formData?.mcqOptions];
        newChoices[index] = e.target.value;
        setFormData((prev) => ({
            ...prev,
            mcqOptions: newChoices,
        }));
    };

    const handleTagsChange = (_, newTags) => {
        setSelectedTags(newTags);
    };

    const handleTopicsChange = (_, newTopics) => {
        setSelectedTopics(newTopics);
    };

    const handleTagInputBlur = () => {
        if (tagInputValue.trim() !== '') {
            setSelectedTags((prevTags) => [...prevTags, tagInputValue]);
            setTagInputValue('');
        }
    };

    const handleTopicInputBlur = () => {
        if (topicInputValue.trim() !== '') {
            setSelectedTopics((prevTopics) => [...prevTopics, topicInputValue]);
            setTopicInputValue('');
        }
    };

    const resetFormData = () => {
        setFormData({
            difficultyLevel: '',
            tags: '',
            questionText: '',
            questionType: questionType,
            mcqOptions: ['', '', '', ''],
            mcqAnswer: '',
            category: '',
            score: 0,
            topics: '',
        })
        setSelectedTags([]);
        setSelectedTopics([]);
    }

    return {
        formData,
        handleChange,
        handleChoiceChange,
        handleTagsChange,
        handleTopicsChange,
        handleTagInputBlur,
        handleTopicInputBlur,
        selectedTags,
        selectedTopics,
        tagInputValue,
        setTagInputValue,
        topicInputValue,
        setTopicInputValue,
        resetFormData,
    };
};
