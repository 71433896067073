import React, { useEffect, useState } from 'react'
import { getTests } from '../../../../functions/api/employers/employerTest/getTests';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { dateConversion } from '../../../../utils/timeZoneConversation';
import { useSelector } from 'react-redux';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import attachIcon from '../../../../assets/icons/attach.png'
import { attachJobInfo } from '../../../../functions/api/employers/employerTest/attachJobInfo';

function Row(props) {
    const { row, index, setTestTrigger, jdId, handleClose } = props;
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

    const handleAttachTest = async (id) => {
        try {
            const res = await attachJobInfo(jdId, id, false, accessToken, clientCode);
            if (res) {
                toast.success(`Test Successfully attached with JdId ${jdId}`, 5000);
                handleClose();
            }
        } catch (error) {
            const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again."
            toast.error(errMsg);
        }
    }

    return (
        <React.Fragment>
            <TableRow
                sx={{ "& > *": { borderBottom: "unset" } }} className={`${index % 2 == 1 ? 'colored' : ''}`}>
                <TableCell component="th" scope="row" align="center" className="tableCell">
                    {row?.name}
                </TableCell>
                <TableCell component="th" scope="row" align="center" className="tableCell">
                    {row?.testType}
                </TableCell>{" "}
                <TableCell align="center" className="tableCell">{row?.role}</TableCell>
                <TableCell align="center" className="tableCell tagBox">
                    {row?.tags?.length !== 0 && row?.tags?.split(", ")?.map((tag, i) => (
                        <div className="tag">{tag}</div>
                    ))}
                </TableCell>
                <TableCell align="center" className="tableCell">{row?.createdAt && dateConversion(row?.createdAt)}</TableCell>
                <TableCell align="center" className="tableCell">
                    <span onClick={() => handleAttachTest(row?.id)} className='threeDotBox'>
                        <img src={attachIcon} className="threeDotIcon" /> Attach Test
                    </span>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


const AttachTestForm = ({ jdId, handleClose }) => {
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);
    const [tests, setTests] = useState(null);
    const [testTrigger, setTestTrigger] = useState(false);

    useEffect(() => {
        const getAllTests = async () => {
            try {
                const res = await getTests(accessToken, clientCode, true);
                if (res) {
                    setTests(res);
                }
            } catch (error) {
                const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again."
                toast.error(errMsg);
            }
        }
        getAllTests();
    }, [testTrigger])

    return (
        <Content>
            <TableContainer component={Paper} className="tableBox">
                <span className="title">Available Tests to Attach</span>
                <Table aria-label="collapsible table">
                    <TableHead className="tableHead">
                        <TableRow>
                            <TableCell align="center" className="tableCell">Name</TableCell>
                            <TableCell align="center" className="tableCell">Test Type</TableCell>
                            <TableCell align="center" className="tableCell">Role</TableCell>
                            <TableCell align="center" className="tableCell">Tags</TableCell>
                            <TableCell align="center" className="tableCell">Date of Creation</TableCell>
                            <TableCell align="center" className="tableCell">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="tableBody">
                        {tests && tests?.map((row, index) => (
                            <Row key={row?.id} row={row} index={index} setTestTrigger={setTestTrigger} jdId={jdId} handleClose={handleClose} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Content>
    )
}

export default AttachTestForm


const Content = styled.div`
margin: 1rem 0% 2rem 0%;
width: 100%;
padding: 0%;
display: flex;
flex-direction: column;
align-items: center;


.colored {
  background-color: #ececec;
}

.tableBox {
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.20);
  border-radius: 0.5rem;
  padding-top: 1rem;

  
  &::-webkit-scrollbar {
    width: 0.5rem;
}

  &::-webkit-scrollbar-track {
    background: lightgrey;
    border-radius: 0.4rem;
}

  &::-webkit-scrollbar-thumb {
    background: grey;
    width: 0.5rem;
    border-radius: 0.4rem;
}

& {
  scrollbar-width: 0.5rem;
} 


  .title {
    display: block;
    padding: 0 1.2rem 1rem 1.2rem;
    font-size: 0.9rem;
    font-weight: 600;
  }
}

.MuiTableCell-root {
  border: none;
}

.MuiTableRow-root {
  border-bottom: none;
}

.selected {
  background-color: #d9fbf9;
  color: white;
}

.tableHead {
  background-color: #d1fff0;
  width: 100%;

  .tableCell {
    font-size: 0.9rem;
    font-weight: 500;
    font-family: var(--font);
    color: var(--color);
  }
  
}

.tableBody {
  width: 100%;

  .tagBox {
    display: flex;
    flex-flow: row wrap;
    column-gap: 0.25rem;
    row-gap: 0.25rem;
    align-items: center;
    justify-content: center;
    max-width: 15rem;

    .tag {
      background-color: var(--lightOrange);
      margin: 0.25rem 0.25rem 0 0;
      white-space: nowrap;
      color: var(--white);
      font-weight: 500;
      padding: 0.2rem 0.4rem;
      border-radius: 0.2rem;
    }
  }

  .tableCell {
    font-size: 0.8rem;
    font-weight: 400;
    font-family: var(--font);
    color: var(--color);
  }
}

.threeDotBox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: var(--white);
    padding: 0.5rem 0.8rem;
    border: 0.075rem solid var(--lightOrange);
    border-radius: 0.5rem;
    cursor: pointer;
}

.threeDotIcon {
    width: 0.6rem;
    height: 0.6rem;
    cursor: pointer;
    border: 0.08rem solid grey;
    padding: 0.15rem;
    border-radius: 0.2rem;
  }


.checkBox {
  cursor: pointer;
}
`
