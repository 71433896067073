import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chart from "react-google-charts";


function ReportsGraphAccordian({ proctoringResults }) {
  const [expanded, setExpanded] = useState("panel" + (proctoringResults?.filter((data) => data?.report !== undefined && data?.report !== null)?.length - 1));

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const chartOptions = {
    width: 600,
    height: 400,
    bar: { groupWidth: "95%" },
    legend: { position: "none" },
  };

  const getChartData = (averages) => {
    let chartData = [
      [
        "Marker",
        "Average",
        { role: "style" },
        {
          sourceColumn: 0,
          role: "annotation",
          type: "string",
          calc: "stringify",
        },
      ]
    ];
    let keys = Object.keys(averages);
    for (let i = 0; i < Object.keys(averages)?.length; i++) {
      chartData[i + 1] = [keys[i], averages[keys[i]], "red", null]
    }
    return chartData;
  }


  return (
    <div>
      {proctoringResults && proctoringResults?.filter((data) => data?.report !== undefined && data?.report !== null)?.map((result, i) => (
        <Accordion
          expanded={expanded === "panel" + i}
          onChange={handleChange("panel" + i)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={"panel" + i + "bh-content"}
            id={"panel" + i + "bh-header"}
          >
            <Typography>Round {result?.report?.stage} | Credibility ({result?.report?.score}%)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Chart
                chartType="BarChart"
                width="100%"
                height="400px"
                data={getChartData(result?.report?.averages)}
                options={chartOptions}
              />
              <a href={result?.report?.link} target="_blank">Click for detailed Report.</a>
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))

      }
    </div>
  );
}

export default ReportsGraphAccordian;

/*
[
    {
      identifier: "d2483455-980b-43d3-89ab-81169994d93b",
      link: "https://miledgetech.proctoring.app/api/report/d2483455-980b-43d3-89ab-81169994d93b",
      status: "stopped",
      duration: 1,
      startedAt: "2024-06-06T13:17:59.821Z",
      stoppedAt: "2024-06-06T13:19:34.803Z",
      score: 50,
      student: "ab8aecd1-23bd-4752-bd92-90a1ca65f017",
      jdId: "abhi14",
      stage: 1,
      averages: {
        b1: 0,
        b2: 0,
        b3: 0,
        c1: 0,
        c2: 5,
        c3: 0,
        c4: 0,
        c5: 45,
        k1: 0,
        m1: 0,
        m2: 0,
        n1: 0,
        n2: 0,
        s1: 0,
        s2: 0,
      },
    },
  ];
*/






