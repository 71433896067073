import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getAllCourses, getSubjectsByCourseName, getTopicsByCourseAndSubject, academicLeaderboardDateRange } from '../../../utils/academicData';
import PerformanceContentTable from './PerformanceContentTable';
import { getLeaderboard } from '../../../functions/api/academicAPIs/getLeaderboard';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const MyPerformance = () => {
    const accessToken = useSelector(state => state?.auth?.userData?.accessToken)

    const [course, setCourse] = useState("");
    const [subject, setSubject] = useState("");
    const [chapter, setChapter] = useState("");
    const [testType, setTestType] = useState("");
    const [dateRange, setDateRange] = useState("allTime");

    const [page, setPage] = useState(1);
    const [size, setSize] = useState(100);
    const [total, setTotal] = useState(0);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        async function getLeaderboardData() {
            try {
                const response = await getLeaderboard(accessToken, course, subject, chapter, testType, dateRange, page, size);
                if (response) {
                    setFilteredData(response?.data?.data);
                    setTotal(response?.data?.total);
                }
            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }
        getLeaderboardData()
    }, [page, size, course, subject, chapter, testType, dateRange]);

    return (
        <Box>
            <TopBox>
                    <FormControl sx={{ backgroundColor: '#F6F6FB' }} fullWidth>
                        <InputLabel id="demo-simple-select-label" style={{ fontSize: '0.8rem' }}>Course</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Course"
                            name="course"
                            value={course}
                            onChange={(e) => setCourse(e.target.value)}
                            size='small'
                            inputProps={{
                                sx: {
                                    color: '#626264',
                                    fontSize: '0.8rem',
                                    fontWeight: '400'
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: '#626264',
                                    fontSize: '0.8rem',
                                    fontWeight: '400'
                                },
                            }}
                            sx={{
                                padding: '0.5rem 0 0.5rem 0',
                            }}
                        >
                            {
                                getAllCourses()?.map((course, i) => (
                                    <MenuItem key={i} value={course}>{course}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl sx={{ backgroundColor: '#F6F6FB' }} fullWidth>
                        <InputLabel id="demo-simple-select-label" style={{ fontSize: '0.8rem' }}>Subject</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Subject"
                            name="subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            size='small'
                            inputProps={{
                                sx: {
                                    color: '#626264',
                                    fontSize: '0.8rem',
                                    fontWeight: '400'
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: '#626264',
                                    fontSize: '0.8rem',
                                    fontWeight: '400'
                                },
                            }}
                            sx={{
                                padding: '0.5rem 0 0.5rem 0',
                            }}
                        >
                            {
                                getSubjectsByCourseName(course)?.map((subject, i) => (
                                    <MenuItem key={i} value={subject}>{subject}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl sx={{ backgroundColor: '#F6F6FB' }} fullWidth>
                        <InputLabel id="demo-simple-select-label" style={{ fontSize: '0.8rem' }}>Chapter</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Chapter"
                            name="chapter"
                            value={chapter}
                            onChange={(e) => setChapter(e.target.value)}
                            size='small'
                            inputProps={{
                                sx: {
                                    color: '#626264',
                                    fontSize: '0.8rem',
                                    fontWeight: '400'
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: '#626264',
                                    fontSize: '0.8rem',
                                    fontWeight: '400'
                                },
                            }}
                            sx={{
                                padding: '0.5rem 0 0.5rem 0',
                            }}
                        >
                            {
                                getTopicsByCourseAndSubject(course, subject)?.map((topic, i) => (
                                    <MenuItem key={i} value={topic}>{topic}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl sx={{ backgroundColor: '#F6F6FB' }} fullWidth>
                        <InputLabel id="demo-simple-select-label" style={{ fontSize: '0.8rem' }}>Test Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Test Type"
                            name="testType"
                            value={testType}
                            onChange={(e) => setTestType(e.target.value)}
                            size='small'
                            inputProps={{
                                sx: {
                                    color: '#626264',
                                    fontSize: '0.8rem',
                                    fontWeight: '400'
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: '#626264',
                                    fontSize: '0.8rem',
                                    fontWeight: '400'
                                },
                            }}
                            sx={{
                                padding: '0.5rem 0 0.5rem 0',
                            }}
                        >
                            <MenuItem value="mcq">MCQ</MenuItem>
                            <MenuItem value="subjective">Subjective</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ backgroundColor: '#F6F6FB' }} fullWidth>
                        <InputLabel id="demo-simple-select-label" style={{ fontSize: '0.8rem' }}>Date Range</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Date Range"
                            name="dateRange"
                            value={dateRange}
                            onChange={(e) => setDateRange(e.target.value)}
                            size='small'
                            inputProps={{
                                sx: {
                                    color: '#626264',
                                    fontSize: '0.8rem',
                                    fontWeight: '400'
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: '#626264',
                                    fontSize: '0.8rem',
                                    fontWeight: '400'
                                },
                            }}
                            sx={{
                                padding: '0.5rem 0 0.5rem 0',
                            }}
                        >
                            {
                                academicLeaderboardDateRange?.map((dateRange, i) => (
                                    <MenuItem key={i} value={dateRange?.value}>{dateRange?.text}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
            </TopBox>
            <ContentBox>
                <PerformanceContentTable data={filteredData} total={total} size={size} setSize={setSize} page={page} setPage={setPage} />
            </ContentBox>
        </Box>
    )
}

export default MyPerformance

const Box = styled.div`
display: flex;
flex-direction: column;
justify-content: start;
width: 94%;
margin: 1rem auto;
background-color: var(--white);
border-radius: 1rem;
padding: 2rem 1rem 1rem 1rem;
box-sizing: border-box;
gap: 2rem;
box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.20);


`

const TopBox = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
width: 100%;
gap: 1rem;



`

const ContentBox = styled.div`



`