import React from 'react';
import styled from 'styled-components';
import heroSectionImg from "../../../assets/Academic/heroSectionImg.webp"
import { useNavigate } from 'react-router';

const HeroSectionWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  background: rgb(239,236,247);
  background: linear-gradient(90deg, rgba(239,236,247,1) 0%, rgba(239,236,247,1) 35%, rgba(168,162,226,1) 100%);
  // background-color: #f0f8ff;
  text-align: center;
  height: 400px;
  width: 100%;
  box-sizing: border-box;
  padding-top: 7rem;


`;

const Content = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: rgb(93, 93, 93);
    font-size: 2rem;
    font-weight: 600;
  }

  p {
    color: rgb(93, 93, 93);
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1.4rem;
  }
`;

const ImageBox = styled.div`
width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;

.heroImg {
    width: 70%;
    height: 100%;
}

`

const Buttons = styled.div`
  margin-top: 1.5rem;
`;

const Button = styled.button`
  margin: 0 0.5rem;
  padding: 0.75rem 1rem;
  background-color: #515290;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;

  &:hover {
    background-color: #442f90;
  }
`;

const HeroSection = () => {
  const navigate = useNavigate();


  return (
    <HeroSectionWrapper>
      <Content>
        <h1>Prepare for Your Exams with AI-Powered Precision!</h1>
        <p>
          Experience Practice Tests That Mirror the Real Exam. <br /> Start Today and Ace Your Test!
        </p>

        <Buttons>
          <Button onClick={() => navigate("/academic/signup")}>Get Started</Button>
          <Button>Learn More</Button>
        </Buttons>
      </Content>
      <ImageBox>
        <img src={heroSectionImg} className='heroImg' />
      </ImageBox>
    </HeroSectionWrapper>
  );
}

export default HeroSection;
