import React, { useState } from 'react';
import styled from 'styled-components';
import { Play } from 'lucide-react';
import videoBanner from "../../../assets/HomePage/herovideobanner.webp"
import VideoDialog from './VideoDialog';
import { useNavigate } from 'react-router';

const Container = styled.div`
  text-align: center;
  margin-top: 0rem;
  padding: 0rem 0rem;
  width: 100%;
  background: linear-gradient(
  180deg, 
  rgba(132, 255, 253, 0.1) 30%,
  rgba(245, 255, 255, 1) 61%,
  rgba(132, 255, 253, 0.5) 73%,
  rgba(255, 255, 255, 1) 100%);
  box-sizing: border-box;
`;

const Title = styled.h1`
  font-size: 4rem;
  font-weight: 600;
  margin-top: 8rem;
  margin-bottom: 2rem;
  background: linear-gradient(
    90deg, 
    #000000 24%, 
    #84FFFD 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-family: 'Inria Serif', serif;


  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const DottedBackground = styled.div`
  background-image: radial-gradient(grey 0.5px, transparent 0.5px);
  background-size: 25px 25px;
  padding-bottom: 5rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

`;

const VideoWrapper = styled.div`
  position: relative;
  display: inline-block;
  max-width: 800px;
  width: 100%;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
  }

  button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 4rem;
    height: 4rem;
    background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 30%,
    rgba(132, 255, 253, 1) 100%);
    color: var(--white);
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    img {
      width: 2rem;
      height: 2rem;
    }

    &:hover {
    background: linear-gradient(
    90deg, 
    rgba(132, 255, 253, 1) 30%,
    rgba(0, 0, 0, 1) 100%);
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  }
  }
`;

const CTAButton = styled.button`
 padding: 0.8rem 1.5rem;
  font-size: 1rem;
  color: #000;
  background: #fff;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  margin-top: 2rem;
  border: 0.075rem solid black;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const HeroSection = () => {
  const [videoOpen, setVideoOpen] = useState(false);
  const Navigate = useNavigate();

  const handleScheduleDemo = () => {
    Navigate("/demo");
  }

  return (
    <Container>
      <VideoDialog isOpen={videoOpen} setIsOpen={setVideoOpen} />
      <DottedBackground>
        <Title>
          AI-Powered Talent Acquisition for <br /> Fast-Paced Enterprises
        </Title>
        <VideoWrapper onClick={() => setVideoOpen(true)}>
          <img src={videoBanner} alt='videoBanner' />
          <button aria-label='playButton' >
            <Play alt="play" />
          </button>
        </VideoWrapper>
        <CTAButton onClick={() => handleScheduleDemo()}>Schedule a Demo</CTAButton>
      </DottedBackground>
    </Container>
  );
};

export default HeroSection;
