import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from '../../../component/shared/Button'
import { useSelector } from 'react-redux'
import PaperCard from './PaperCard'
import { getSampleTests } from '../../../functions/api/academicAPIs/getSampleTests'
import { toast } from 'react-toastify'
import { CircularProgress } from '@mui/material'

const AvailablePapers = () => {
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const [papers, setPapers] = useState([]);
    const [paperLoading, setPaperLoading] = useState(false);

    useEffect(() => {
        async function getData() {
            setPaperLoading(true);
            try {
                const response = await getSampleTests(accessToken, 1, 100);
                if (response) {
                    setPapers(response?.data?.data);
                }
            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            } finally {
                setPaperLoading(false);
            }
        }
        getData();
    }, []);

    return (
        <Container>
            <TopBox>
                Available Tests
            </TopBox>
            <ContentBox>
                {paperLoading ? <div className='loaderBox'>
                    <CircularProgress color="inherit" />
                </div> :
                    papers && papers?.map((test, i) => (
                        <PaperCard key={test?.id} data={test} index={i} />
                    ))
                }
            </ContentBox>
        </Container>
    )
}

export default AvailablePapers

const Container = styled.div`
width: 100%;
margin: 0 0;
padding: 1% 1rem;
display: flex;
flex-direction: column;
box-sizing: border-box;
gap: 2rem;


`

const TopBox = styled.div`
width: 96%;
margin: 0 auto;
display: flex;
justify-content: start;
align-items: center;
gap: 2%;
font-size: 1rem;
font-weight: 700;



`


const ContentBox = styled.div`
display: flex;
flex-direction: column;
width: 96%;
margin: 0 auto;
gap: 0.5rem;

.loaderBox {
display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 17rem);
    height: calc(100% - 4.1rem);
    background-color: #f4f4f4;
    position: absolute;
    z-index: 1000;
    right: 0;
    top: 4.1rem;
    color: var(--academicLavendar);
}




`