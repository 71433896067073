import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { styled } from "styled-components";
import landingPageTopImg from '../../assets/homepageBannerImageAI.webp';

const Content = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    preloadImage(landingPageTopImg);
  }, []);

  const handleSubmit = () => {
    navigate("/signup");
  };

  const preloadImage = (src) => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = src;
    link.as = 'image';
    document.head.appendChild(link);
  };

  return (
    <StyledContent>
      <section className="landing-page">
        <div className="landingContent">
          <article className="left">
            <div className="textContainer">
              <h1 className="title">
                <span className="titleText1">WELCOME TO THE </span>
                <span className="titleText2">FUTURE <span className="titleText3">OF </span></span>
                <span className="titleText4">Recruitment!</span>
              </h1>
              <p className="desc">AI-Powered, Smarter,<br /> Structured and More Efficient!</p>
            </div>
            <button onClick={handleSubmit} className="btn" aria-label="Try now">Try Now</button>
          </article>
          <aside className="right">
            <img 
              src={landingPageTopImg} 
              alt="AI-powered recruitment banner" 
              fetchpriority="high" 
            />
          </aside>
        </div>
      </section>
    </StyledContent>
  );
};

export default Content;

export const StyledContent = styled.div`
  background-color: var(--white);
  width: 100%;

  .landing-page {
    margin-top: 6rem;
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .landing-page::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--lightOrange);
    clip-path: polygon(0% 0%, 100% -30%, -15% 100%, 0% 100%);
    transform: rotate(180deg);
  }

  .landingContent {
    z-index: 11;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 2rem;
    margin: 3rem 0rem;
  }

  .left {
    width: 100%;
    grid-column: 1/2;
    grid-row: 1/3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    @media(max-width: 600px) {
      grid-column: 1/3;
      grid-row: 1/2;
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .desc {
        font-size: 1.3rem;
        font-weight: 600;
        color: var(--grey);
        text-align: center;
        line-height: 1.7rem;
      }
    }

    .title {
      display: flex;
      flex-direction: column;

      .titleText1 {
        font-size: 2.3rem;
        font-weight: 600;
        color: var(--grey);
      }

      .titleText2 {
        font-size: 3.2rem;
        font-weight: 600;
        color: var(--grey);

        .titleText3 {
          font-size: 2.3rem;
          font-weight: 400;
          color: var(--grey);
        }
      }

      .titleText4 {
        font-size: 3.2rem;
        font-weight: 600;
        color: var(--grey);
      }
    }

    .btn {
      background-color: var(--lightOrange);
      border: none;
      padding: 0.5rem 0.8rem;
      color: var(--white);
      font-size: 0.9rem;
      font-weight: 600;
      font-family: var(--font);
      border-radius: 0.3rem;
      cursor: pointer;
    }

    .btn:hover {
      background-color: var(--white);
      color: black;
      border: 0.1rem solid var(--lightOrange);
      padding: 0.4rem 0.7rem;
    }
  }

  .right {
    width: 100%;
    grid-column: 2/3;
    grid-row: 1/3;

    @media(max-width: 600px) {
      grid-column: 1/3;
      grid-row: 2/3;
    }
  }

  .right > img {
    width: 100%;
  }
`;
