import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import TableSearchBar from "../commonComponents/TableSearchBar";
import { getPreFilteredData } from "../../../../functions/api/interview/getPreFilteredData";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Pagination, PaginationSizeFilter } from "../../../commonComponents/Pagination";
import { timeZoneConversion } from '../../../../utils/timeZoneConversation'
import { getProctoringResults } from "../../../../functions/api/interview/getProctoringResults";
import visibleIcon from '../../../../assets/icons/visible.png'
import CommonDrawer from "../../../commonComponents/CommonDrawer";
import SeekerInterviewDetails from "../../SeekerDashboard/sidebarPages/SeekerInterviewDetails";
import { getAllProctoringResults } from "../../../../functions/api/interview/getAllProctoringResults";


function Row(props) {
  const { row, index, page, size } = props;
  const accessToken = useSelector(state => state.auth.userData?.accessToken);

  const [state, setState] = React.useState({
    right: false,
  });

  const [proctoringResults,setProctoringResults] = useState([]);

  useEffect(() => {
    const getResults = async () => {
      try {
        const res = await getAllProctoringResults(row?.jdId, row?.userEmail, accessToken);
        if (res) {
          setProctoringResults(res?.data);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        // toast.error(errMsg);
      }
    };
 if(row?.userEmail){
  getResults();
 }
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }} className={`${index % 2 == 1 ? 'colored' : ''}`}>
        <TableCell align="center" className="tableCell">{row?.jdTitle}</TableCell>
        <TableCell align="center" className="tableCell">{((page-1)*size) + (index+1)}</TableCell>
        <TableCell align="center" className="tableCell">{row?.userName}</TableCell>
        <TableCell align="center" className="tableCell">{row?.userEmail}</TableCell>
        <TableCell align="center" className="tableCell">{row?.scheduledAt && timeZoneConversion(row?.scheduledAt)}</TableCell>
        <TableCell align="center" className="tableCell">{row?.testType?.toUpperCase()}</TableCell>
        <TableCell align="center" className="tableCell">{proctoringResults?.length?proctoringResults[proctoringResults.length-1]?.score + "%":"..."}</TableCell>
        <TableCell align="center" className="tableCell">{row?.score} / {row?.maxScore}</TableCell>
        <TableCell component="th" scope="row" align="center" className="tableCell">
          <div style={{ display: 'flex', gap: '0.6rem', justifyContent: 'center', alignItems: 'center' }}>
            <CommonDrawer toggleDrawer={toggleDrawer} state={state} component={<SeekerInterviewDetails jdId={row?.jdId} data={row} employer={true} proctoringResults={proctoringResults}/>} />
            <img src={visibleIcon} className="icon" onClick={toggleDrawer("right", true)}/>
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


const CompletedLast7Days = () => {
  const [tableRows, setTableRows] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const accessToken = useSelector((state) => state.auth.userData?.accessToken);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);

  const handleSizeChange = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handlePageChange = (change) => {
    if (change && page < Math.ceil(+total / +size)) {
      setPage((prev) => prev + 1);
    } else if (!change && page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    const filteredData = async () => {
      try {
        const res = await getPreFilteredData(accessToken, 'COMPLETED', page, size);

        if (res) {
          setTableRows(res?.data?.data);
          setTotal(res?.data?.total)
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }

    filteredData();
  }, [page, size, total])



  return (
    <Content>
      <TableContainer component={Paper} className="tableBox">
        <div className="titleBox">
          <span className="title">Last 7 days Completed Interviews</span>
        </div>

        <SearchBarContainer>
          <TableSearchBar value={searchValue} setValue={setSearchValue} />
        </SearchBarContainer>
        <Table aria-label="collapsible table">
          <TableHead className="tableHead">
            <TableRow>
              <TableCell align="center" className="tableCell">S. No.</TableCell>
              <TableCell align="center" className="tableCell">JD Title</TableCell>
              <TableCell align="center" className="tableCell">Candidate Name</TableCell>
              <TableCell align="center" className="tableCell">Email</TableCell>
              <TableCell align="center" className="tableCell">Scheduled Date/Time</TableCell>
              <TableCell align="center" className="tableCell">Test Type</TableCell>
              <TableCell align="center" className="tableCell">Credibility</TableCell>
              <TableCell align="center" className="tableCell">Score</TableCell>
              <TableCell align="center" className="tableCell">Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {tableRows?.map((row, index) => (
              <Row key={row?.id} row={row} index={index} page={page} size={size} />
            ))}
          </TableBody>
        </Table>
        <div className="paginationBox">
          <PaginationSizeFilter
            size={size}
            handleSizeChange={handleSizeChange}
          />
          <Pagination
            total={total}
            size={size}
            page={page}
            handlePageChange={handlePageChange}
            setPage={setPage}
          />
        </div>
      </TableContainer>
    </Content>
  )
}

export default CompletedLast7Days;


const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  margin: 1rem auto 0.5rem auto;
  height: 3rem;
  background-color: var(--white);
  border-radius: 0.5rem;;
  padding: 0rem 1rem;
  gap: 1rem;

`

const Content = styled.div`
margin: 1rem 0% 2rem 0%;
width: 98%;
padding: 0 1%;
display: flex;
flex-direction: column;
align-items: center;


.colored {
  background-color: #ececec;
}

.tableBox {
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.20);
  border-radius: 0.5rem;
  padding-top: 1rem;


  .title {
    padding-left: 1.2rem;
    font-size: 0.9rem;
    font-weight: 600;
  }

  .titleBox {
  width: 99%; 
  padding: 0.5rem 0rem;;
  display: flex;
  align-items: center;
  justify-content: space-between;
  }


}

.MuiTableCell-root {
  border: none;
}

.MuiTableRow-root {
  border-bottom: none;
}

.selected {
  background-color: #d9fbf9;
  color: white;
}

.tableHead {
  background-color: #d1fff0;
  width: 100%;

  .tableCell {
    font-size: 0.9rem;
    font-weight: 500;
    font-family: var(--font);
    color: var(--color);

    
  }
  
}

.tableBody {
  width: 100%;

  .icon {
  width: 0.8rem;
  height: 0.8rem;
  cursor: pointer;
  border: 0.075rem solid grey;
  padding: 0.3rem;
  border-radius: 0.3rem;
}

  .tableCell {
    font-size: 0.8rem;
    font-weight: 400;
    font-family: var(--font);
    color: var(--color);
  }
}


.btn {
  padding: 0.3rem 0.2rem;
  background-color: var(--lightOrange);
  border: none;
  color: var(--white);
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 0.5rem;
  cursor: pointer;
  
}

.paginationBox {
  display: flex;
  justify-content: end;
  gap: 2rem;
  margin: 1rem 3rem 1.5rem 0;
}
`
