import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getQuestions } from '../services/questionService';

const useFetchQuestions = (accessToken, clientCode, questionTrigger) => {
    const [questions, setQuestions] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const data = await getQuestions(accessToken, clientCode);
                if(data){
                    console.log(data);
                    setQuestions(data);
                }
            } catch (error) {
                const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            } finally {
                setLoading(false);
            }
        };

        fetchQuestions();
    }, [accessToken, clientCode, questionTrigger]);

    return { questions, loading };
};

export default useFetchQuestions;
