import axios from "axios";

export const getLeaderboard = async (accessToken, course, subject, chapter, testType, dateFilter, page = 1, size = 1000000) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        },
        params: {
            page: page,
            size: size,
        }
    };

    // Dynamically add non-empty parameters
    if (course) config.params.course = course;
    if (subject) config.params.subject = subject;
    if (chapter) config.params.chapter = chapter;
    if (testType) config.params.testType = testType;
    if (dateFilter) config.params.dateFilter = dateFilter;

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/academic/leaderboard`, config);
        console.log('Data:', response?.data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}



