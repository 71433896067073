
import React from 'react';
import styled from 'styled-components';
import { Mail, AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router';

const Container = styled.div`
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const Title = styled.h2`
  color: #343a40;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  color: #495057;
  margin-bottom: 1.5rem;
`;

const InfoBox = styled.div`
  background-color: #F8F9FA;
  border: 0.1rem solid var(--lightOrange);
  border-radius: 4px;
  padding: 1rem;
  margin-top: 1.5rem;
`;

const InfoTitle = styled.h3`
  color: var(--lightOrange);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;

  svg {
    margin-right: 0.5rem;
  }
`;

const InfoText = styled.p`
  color: #495057;
  margin: 0;
  font-size: 1rem;
`;

const Button = styled.button`
  background-color: var(--lightOrange);
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  margin-top: 1.5rem;
  cursor: pointer;
  font-size: 1rem;

`;

const PasswordResetRequestSuccessMessage = () => {

    const Navigate = useNavigate();

    return (
        <Container>
            <Mail size={48} color="var(--lightOrange)" />
            <Title>Check Your Email</Title>
            <Message>
                We've sent a password reset link to your email address. Please check your inbox and click on the link to reset your password.
            </Message>
            <InfoBox>
                <InfoTitle>
                    <AlertCircle size={20} />
                    Important
                </InfoTitle>
                <InfoText>
                    If you don't see the password reset email in your inbox, please check your spam or junk folder. Make sure to mark the email as safe or move it to your inbox to enable the reset link.
                </InfoText>
            </InfoBox>
            <Button onClick={() => Navigate("/")}>Go to Homepage</Button>
        </Container>
    );
};

export default PasswordResetRequestSuccessMessage;