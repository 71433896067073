import React, { useState } from 'react'
import styled from 'styled-components'
import Label from '../../../../component/shared/Label'
import IconButton from '../../../../component/shared/IconButton'
import editIcon from '../../../../assets/icons/editBlack.png'
import deleteIcon from '../../../../assets/icons/delete.png'
import visibleIcon from '../../../../assets/icons/visible.png'
import QuestionDetailsPage from '../pages/QuestionDetailsPage'
import CommonDrawer from '../../../../component/global/Drawer'
import { useSelector } from 'react-redux'
import CommonDialog from '../../../../component/global/CommonDialog'
import DeleteDialogContent from '../../../../component/global/DeleteDialogContent'
import { difficultyStyles } from '../../../../utils/dummyData/difficultyStyles'
import useQuestionActions from '../hooks/useQuestionActions'

const QuestionCard = ({ data, onEditQuestion, isTestForm, handleDeleteTestQuestion, index, viewOnly, setQuestionTrigger }) => {
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);


    const {
        deleteOpen,
        handleDeleteClick,
        handleDeleteQuestion,
        setDeleteOpen
    } = useQuestionActions(accessToken, clientCode, "", "", "", setQuestionTrigger);

    const [state, setState] = useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };


    return (
        <>
            <CommonDrawer toggleDrawer={toggleDrawer} state={state} component={<QuestionDetailsPage data={data} />} width='60vw' />
            <CommonDialog
                open={deleteOpen}
                handleClose={() => setDeleteOpen(false)}
                component={
                    <DeleteDialogContent
                        text="Question"
                        handleClose={() => setDeleteOpen(false)}
                        handleDelete={handleDeleteQuestion}
                    />
                }
            />
            <Card>
                <TopBox>
                    <span className="title">{data?.questionText?.length > 150 ? data?.questionText?.slice(0, 150) + "..." : data?.questionText}</span>
                    {!isTestForm ? (!viewOnly && <div className='iconBox'>
                        <IconButton
                            icon={editIcon}
                            width="1.5rem"
                            func={() => onEditQuestion(index, data)}
                            isBorder={true}
                            altTitle="EditButton" />
                        <IconButton
                            icon={deleteIcon}
                            width="1.5rem"
                            func={() => handleDeleteClick(data?.id)}
                            isBorder={true}
                            altTitle="DeleteButton" />
                        <IconButton
                            icon={visibleIcon}
                            width="1.5rem"
                            func={toggleDrawer('right', true)}
                            isBorder={true}
                            altTitle="viewDetailButton" />
                    </div>)
                    :
                    <div className='iconBox'>
                        {!viewOnly && <IconButton
                            icon={deleteIcon}
                            width="1.5rem"
                            func={() => handleDeleteTestQuestion(data?.id)}
                            isBorder={true}
                            altTitle="DeleteButton" />}
                        <IconButton
                            icon={visibleIcon}
                            width="1.5rem"
                            func={toggleDrawer('right', true)}
                            isBorder={true}
                            altTitle="viewDetailButton" />
                    </div>
                    }
                    
                </TopBox>
                <BottomBox>
                    <div className='left'>
                        <Label
                            bgColor="transparent"
                            borderColor="grey"
                            borderRadius="1rem"
                            text={data?.score}
                            title="Score: "
                            isTitle={true}
                            fontType="bold" />
                        <Label
                            bgColor="transparent"
                            borderColor="grey"
                            borderRadius="1rem"
                            text={data?.questionType}
                            title="Question Type: "
                            isTitle={true}
                            fontType="bold" />
                        <Label
                            bgColor="transparent"
                            borderColor="grey"
                            borderRadius="1rem"
                            text={data?.category}
                            title="Section: "
                            isTitle={true}
                            fontType="bold" />
                        <Label
                            bgColor="transparent"
                            borderColor="grey"
                            borderRadius="1rem"
                            text={data?.topics?.split(", ")?.map((topic, i) => (topic) + ", ")}
                            title="Topics: "
                            isTitle={true}
                            fontType="bold" />
                    </div>
                    <div className='right'>
                        <Label
                            bgColor={difficultyStyles[data?.difficultyLevel]?.bgColor}
                            borderColor={difficultyStyles[data?.difficultyLevel]?.borderColor}
                            borderRadius="0.25rem"
                            text={data?.difficultyLevel?.toUpperCase()}
                            isTitle={false}
                            fontType="bold"
                            width="5rem" />
                    </div>
                </BottomBox>
            </Card>
        </>
    )
}

export default QuestionCard

const Card = styled.div`
width: 100%;
margin: 0 auto;
padding: 0.75rem 1rem;
box-sizing: border-box;
border: 0.1rem solid lightgrey;
border-radius: 0.25rem;
display: flex;
flex-direction: column;
gap: 2rem;
background-color: var(--white);

`

const TopBox = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: start;
gap: 2rem;

.title {
 font-size: 0.9rem;
 font-weight: 700;
}

.iconBox {
 display: flex;
 gap: 0.5rem;
 align-items: center;
}
`

const BottomBox = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
align-items: center;
gap: 5%;

.left {
display: flex;
justify-content: space-between;
align-items: center;
gap: 2rem;
}

.right {
display: flex;
justify-content: space-between;
align-items: center;
gap: 2rem;
}
`

