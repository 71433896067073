import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getUsers } from '../services/ActivityTrackerService';

const useFetchUsers = (accessToken, clientCode, page, size) => {
    const [users, setUsers] = useState(null);
    const [userLoading, setUserLoading] = useState(true);

    useEffect(() => {
        const fetchUsers = async () => {

            try {
                const data = await getUsers(accessToken, clientCode, page, size);
                if(data){
                    setUsers(data?.data?.data);
                }
            } catch (error) {
                const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            } finally {
                setUserLoading(false);
            }
        };

        fetchUsers();
    }, [accessToken, clientCode, page, size]);

    return { users, userLoading };
};

export default useFetchUsers;
