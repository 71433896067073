import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { styled } from "styled-components";
import logo from "../../../assets/Academic/logo.png";
import { toast } from "react-toastify";
import { updateTestStatus } from "../../../functions/api/academicAPIs/updateTestStatus";
import { getAcademicTestQuestion } from "../../../functions/api/academicAPIs/getAcademicTestQuestion";
import { submitAcademicAnswer } from "../../../functions/api/academicAPIs/submitAcademicAnswer";
import AcademicLoader from "../CommonComponents/AcademicLoader";
import AcademicTimer from "../CommonComponents/AcademicTimer";
import AcademicCommonButton from "../CommonComponents/AcademicCommonButton";
import AcademicTestTerms from "../CommonComponents/AcademicTestTerms";
import AcademicMcqQueInterface from "../CommonComponents/AcademicMcqQueInterface";
import Favicon from "react-favicon";
import testFavicon from "../../../assets/Academic/examPrep.ico"


const OngoingTest = () => {
    const accessToken = useSelector((state) => state.auth.userData?.accessToken);
    const { testId } = useParams();
    const { testState } = useParams();
    const [start, setStart] = useState(false);
    const [data, setData] = useState(null);
    const [id, setId] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [loaderMessage, setLoaderMessage] = useState("");
    const [input, setInput] = useState("");

    const [agreed, setAgreed] = useState(false);
    const navigate = useNavigate();


    // TIMER CODE
    const initialMinutes = 60;
    const [minutes, setMinutes] = useState(initialMinutes);
    const [seconds, setSeconds] = useState(0);
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
        let timer;

        if (isRunning) {
            timer = setInterval(() => {
                if (minutes === 0 && seconds === 0) {
                    setIsRunning(false);
                    clearInterval(timer);
                } else {
                    if (seconds === 0) {
                        setMinutes(minutes - 1);
                        setSeconds(59);
                    } else {
                        setSeconds(seconds - 1);
                    }
                }
            }, 1000);
        } else {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [isRunning, minutes, seconds]);

    const startTimer = () => {
        setIsRunning(true);
    };

    const stopTimer = () => {
        setIsRunning(false);
    };

    useEffect(() => {
        if (!accessToken) navigate("/login");
    }, []);

    const handleChange = (e) => {
        setInput(e.target.value);
    };

    const handleSubmitAnswer = async (id, lastQuestion) => {
        try {
            setLoaderMessage("Submitting Answer... please wait");
            setIsLoading(true);
            setId(id + 1);
            const res = await submitAcademicAnswer(
                input,
                id,
                lastQuestion,
                testId,
                accessToken
            );
            setInput("");
            setIsLoading(false);
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    };

    const handleSubmitTest = async () => {
        try {
            setLoaderMessage("Evaluating the Score... please wait");
            setIsLoading(true);
            const submitRes = await updateTestStatus(testId, "completed", accessToken);
            if (submitRes) {
                navigate(`/academic/score/${testId}`)
            }
            setIsLoading(false);
            localStorage.setItem("time", JSON.stringify({ minutes, seconds }));
            stopTimer();
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    };

    const startTest = async () => {
        setStart(true);
        await getData(true, testState);
    }

    async function getData(flag, state = 'start') {
        try {
            setLoaderMessage("Getting new Question... please wait");
            setIsLoading(true);
            const fetchedData = await getAcademicTestQuestion(testId, accessToken, state === 'resume');
            setData(fetchedData?.data);
            setIsLoading(false);
            setInput("");
            startTimer();
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    }


    return (
        <>
            <Favicon url={testFavicon}/>
            {isLoading ? (
                <AcademicLoader message={loaderMessage} />
            ) : (
                <StyledInterview>
                    <div className="head">
                        <img src={logo} className="logo" />

                        <div className="topLastBox">
                            <AcademicTimer minutes={minutes} seconds={seconds} />
                        </div>
                    </div>

                    {start ? (
                        <>
                            {(data?.questionType === "mcq" || data?.mcqOptions) ? (
                                <AcademicMcqQueInterface
                                    queComp={<div className="questionText">{data?.question}</div>}
                                    options={data?.mcqOptions}
                                    value={input}
                                    setValue={setInput}
                                    seqOrder={data?.seqOrder}
                                />
                            ) : (
                                <div className="subjectiveBox">
                                    <div className="textBox"><span className='seqOrder'>{data?.seqOrder}. </span><div dangerouslySetInnerHTML={{ __html: (data?.question) }} className="questionText questionText2"></div></div>
                                    <textarea
                                        rows={10}
                                        value={input}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                            )}

                            {(data?.lastQuestion) ? (
                                <div className="generalSubmitBox">
                                    <AcademicCommonButton text='Submit Test' func={async () => {
                                        await handleSubmitAnswer(data?.id, data?.lastQuestion);
                                        await handleSubmitTest();
                                    }} />
                                </div>
                            ) : (
                                <>
                                    <div className="btnBox1">
                                        <AcademicCommonButton
                                            text='Finish Test'
                                            func={async () => {
                                                await handleSubmitAnswer(data?.id, data?.lastQuestion);
                                                await handleSubmitTest();
                                            }}
                                            width='12%'
                                        />


                                        <AcademicCommonButton
                                            text='Next Question'
                                            func={async () => {
                                                await handleSubmitAnswer(data?.id, data?.lastQuestion);
                                                await getData(false);
                                            }}
                                            width='12%'
                                            className="btn"
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <div className="startInterviewBox">
                            <AcademicTestTerms />
                            <label><input type="checkbox" onClick={() => setAgreed(!agreed)} className="checkbox" />I agree</label>
                            <div className="btnBoxLast">
                                <AcademicCommonButton text={`${testState?.charAt(0)?.toUpperCase()}${testState?.slice(1)?.toLowerCase()} Test`} func={() => startTest()} disabled={!agreed} />
                            </div>
                        </div>
                    )}
                </StyledInterview>
            )}
        </>
    );
};

export default OngoingTest;


const StyledInterview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  margin: 0rem auto;
  gap: 0.5rem;

  label {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .checkbox {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }

  .startInterviewBox {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;


    .btnBoxLast {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
    }
  }

  .questionText {
    font-size: 0.9rem;
    font-weight: 500;
  }

  .subjectiveBox {
    margin-top: 3rem;
    width: 100%;

    .textBox {
      display: flex;
      gap: 1rem;
    }

    .seqOrder {
      font-weight: 600;
    }

    .questionText2 {
      margin-bottom: 1.5rem;
    }
  }

  .codingMainBox {
    display: flex;
    width: 100%;
  }

  .statusIcon {
    width: 1.5rem;
  }

  .timer {
    width: 3rem;
    background-color: var(--white);
    color: var(--color);
    border: 0.1rem solid var(--academicLavendar);
    border-radius: 0.5rem;
    font-size: 1.1rem;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
  }

  .head {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 0 0.25rem 0;
    width: 100%;
    align-items: center;
    height: 2.5rem;

    .middleBox {
      display: flex;
      gap: 1rem;
      font-size: 0.9rem;
      font-weight: 600;
      font-family: var(--font);

      .warningText {

      }
    }

    .topLastBox {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .logo {
      height: 100%;
    }

    .title {
      font-size: 0.9rem;
      font-weight: 600;
      font-family: var(--font);

      .titleBox {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 0.9rem;
        font-family: var(--font);

        .text {
          font-size: 0.9rem;
          font-weight: 600;
          font-family: var(--font);
        }

        b{
          font-weight: 500;
          font-family: var(--font);
        }

        .companyLogo {
          width: 2rem;
          height: 2rem;
        }

        .companyName {
          font-size: 0.9rem;
          font-weight: 500;
        }
      }
    }
  }

  .generalSubmitBox {
    display: flex;
    width: calc(100% - 2rem);
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    padding: 0 0rem 0 2rem;
  }

  .btnBox1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }

  .btnBox2 {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .btn2Box {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    align-items: center;
  }

  .btn1, .btn2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .btn1Text, .btn2Text {
    font-size: 0.65rem;
    font-weight: 400;
    position: absolute;
    top: 2.5rem;
    display: none;
    width: 9rem;
  }

  .btn1:hover {
    
    .btn1Text {
      display: flex;
    }
  }

  .btn2:hover {
    
    .btn2Text {
      display: flex;
      left: -1.3rem;
    }
  }


  .btnImg {
    width: 2rem;
    cursor: pointer;
  }

  input {
    height: 7rem;
    width: 100%;
    border-radius: 0.5rem;
  }

  p,
  h3 {
    font-size: 1.5rem;
  }

  textarea {
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    box-sizing: border-box;
  }
`;
