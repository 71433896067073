import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { persistor } from "../store";
import { logout } from "../slices/authSlice";
import { useSelector } from 'react-redux';
import { useJwt } from 'react-jwt';


const AccessDenied = () => {
  const accessToken = useSelector(state => state.auth.userData?.accessToken);
  const jwtData = useJwt(accessToken);
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    if (jwtData?.decodedToken?.type === "ACADEMIC_STUDENT" || jwtData?.decodedToken?.type === "ACADEMIC_INSTITUTE") {
      persistor.purge();
      dispatch(logout())
      Navigate("/login", { replace: true, state: { isFixed: true } });
    } else {
      persistor.purge();
      dispatch(logout())
      Navigate("/academic/login", { replace: true, state: { isFixed: true } });
    }
  };


  return (
    <Container>
      <ErrorCode>
        <Number>4</Number>
        <HandIcon>
          <Hand>✋</Hand>
        </HandIcon>
        <Number>3</Number>
      </ErrorCode>
      <Message>Access Denied</Message>
      <Description>
        The page you are trying to access has restricted access.
        <br />
        <br />
        <Button onClick={() => handleLogout()}>Login with Different Credential</Button>
      </Description>
    </Container>
  );
}

export default AccessDenied;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #f9f9f9;
  color: #333;
  text-align: center;


  .logo {
  position: absolute;
  top: 1rem;
  left: 2rem;
  height: 3.5rem;

  img {
  height: 100%;

  }
  }
`;

const ErrorCode = styled.div`
  display: flex;
  align-items: center;
  font-size: 8rem;
  font-weight: bold;
  color: #ccc;
`;

const Number = styled.span`
  font-size: 8rem;
`;

const HandIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  background-color: #D83838;
  color: white;
  border-radius: 1rem;
  margin: 0 1rem;
  position: relative;
`;

const Hand = styled.div`
  font-size: 4rem;
`;

const Message = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  color: #333;
  margin-top: 0.5rem;
`;

const Description = styled.p`
  font-size: 1rem;
  color: #777;
  margin-top: 0rem;
  line-height: 1.5;
`;

const Button = styled.button`
background-color: var(--lightOrange);
  color: var(--white);
  padding: 0.75rem 1rem;
  border: none;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 0.5rem;
  cursor: pointer;

`