import { useState } from 'react';
import { createTest } from '../services/testService';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { clearFormData } from '../../../../slices/testFormSlice';

export const useCreateTest = (accessToken, clientCode, toggleView, setTestTrigger) => {
    const dispatch = useDispatch();

    const [loadingCreate, setLoadingCreate] = useState(false);

    const handleCreateTest = async (payload) => {
        setLoadingCreate(true);
        try {
            const res = await createTest(payload, accessToken, clientCode);
            if(res){
                toast.success("Test created successfully!", 5000);
                toggleView('test');
                setTestTrigger(prev => !prev);
                dispatch(clearFormData());
            }
        } catch (error) {
            const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        } finally {
            setLoadingCreate(false);
        }
    };

    return { handleCreateTest, loadingCreate };
};
