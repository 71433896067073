import React, { useState } from 'react';
import styled from 'styled-components';
import {
    Building2, Mail, Phone, MapPin, Users, Globe, Building,
    Calendar, Briefcase, Clock, User, FileCode, Flag
} from 'lucide-react';
import Button from '../../../../component/shared/Button';
import { useSelector } from 'react-redux';
import TextInput from '../../../../component/shared/TextInput';
import { toast } from 'react-toastify';

const StyledCard = styled.div`
  width: 95%;
  background-color: white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  border: 0.1rem solid lightgrey;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const CardHeader = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CardContent = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.5rem;
  padding: 1rem;
  box-sizing: border-box;
`;

const CardFooter = styled.div`
  padding: 1rem;
  display: flex;
  width: 30%;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  align-self: end;
`;

const CompanyLogo = styled.div`
  width: 3rem;
  height: 3rem;
  background-color: #fafbfb;
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-size: 0.5rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Section = styled.div`
  background-color: #fafbfb;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0rem;
  border: 0.1rem solid lightgrey;

  
`;

const SectionTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Text = styled.p`
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StatusBadge = styled.div`
  font-size: 0.9rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-weight: 600;
`;

const BoldText = styled.span`
font-weight: 500;

`

const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
};

const CompanyCard = ({ data, updateStatus }) => {
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);
    const [assignedClientCode, setAssignedClientCode] = useState("");

    const approveOnboardRequest = async (id) => {
        if(assignedClientCode === "" || assignedClientCode === null){
            toast.warning("Please assign a unique ClientCode");
            return;
        }
        const payload = {
            data: {
                onboardStatus: "APPROVED",
                clientCode: assignedClientCode
            },
            id: id
        }

        await updateStatus(accessToken, clientCode, id, payload, setAssignedClientCode);
    }

    return (
        <StyledCard>
            <CardHeader>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <CompanyLogo>
                        <img
                            src={data?.companyLogo || "/api/placeholder/80/80"}
                            alt={`${data?.companyName} logo`}
                        />
                    </CompanyLogo>
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            <h2 style={{ fontSize: '1.25rem', fontWeight: '600' }}>{data?.companyName}</h2>
                            <StatusBadge>
                                {data?.onboardStatus}
                            </StatusBadge>
                        </div>
                        <Text>ID: {data?.id}</Text>
                    </div>
                </div>
                <StatusBadge>
                    {data?.agencyFeature ? 'Agency Feature Enabled' : 'Employer Account'}
                </StatusBadge>
            </CardHeader>

            <CardContent>
                <Section>
                    <SectionTitle>
                        <User size={25} /> Contact Information
                    </SectionTitle>
                    <Text><Building2 size={20} /> <BoldText>Name:</BoldText> {data?.spocName}</Text>
                    <Text><Mail size={20} /> <BoldText>Email:</BoldText> {data?.spocEmail}</Text>
                    <Text><Phone size={20} /> <BoldText>Contact:</BoldText> {data?.spocContact}</Text>
                </Section>

                <Section>
                    <SectionTitle>
                        <MapPin size={25} /> Location Details
                    </SectionTitle>
                    <Text><Building size={20} /> <BoldText>Address:</BoldText> {data?.address}</Text>
                    <Text><Globe size={20} /> <BoldText>City/State:</BoldText> {data?.city}, {data?.state}</Text>
                    <Text><Flag size={20} /> <BoldText>Country:</BoldText> {data?.country}</Text>
                </Section>

                <Section>
                    <SectionTitle>
                        <Building2 size={25} /> Company Details
                    </SectionTitle>
                    <Text><Users size={20} /> <BoldText>Company Size:</BoldText> {data?.companySize} employees</Text>
                    <Text><Briefcase size={20} /> <BoldText>Industry:</BoldText> {data?.industry}</Text>
                    <Text><FileCode size={20} /> <BoldText>Onboard Method:</BoldText> {data?.onboardMethod}</Text>
                </Section>

                <Section>
                    <SectionTitle>
                        <Clock size={25} /> Timestamps
                    </SectionTitle>
                    <Text><Calendar size={20} /> <BoldText>Created:</BoldText> {formatDate(data?.createdAt)}</Text>
                    <Text><Calendar size={20} /> <BoldText>Last Updated:</BoldText> {formatDate(data?.updatedAt)}</Text>
                </Section>
            </CardContent>

            <CardFooter>
                <TextInput
                    label="Client Code to be Assigned"
                    type="text"
                    value={assignedClientCode}
                    onChange={(e) => setAssignedClientCode(e.target.value)}
                    name="assignedClientCode"
                    required
                />
                <Button text="Approve" func={() => approveOnboardRequest(data?.id)} disabled={false} />
            </CardFooter>
        </StyledCard>
    );
};

export default CompanyCard;
