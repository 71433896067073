import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateInterview } from "../slices/interviewSlice";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import InterviewLoader from "../components/commonComponents/InterviewLoader"
import styled from "styled-components";

function CreateInterview() {
  const { interviewState } = useParams();
  const stringsArray = localStorage.getItem("currentInterview") == "profile"
    ?
    ['Analyzing JD', 'Analyzing Resume', 'Creating Questions', 'Creating Interview', 'Preparing Interview']
    :
    ['Analyzing skills', 'Creating Questions', 'Creating Interview', 'Preparing Interview'];

  const [currentString, setCurrentString] = useState(stringsArray[0]);
  const { interviewId } = useParams();
  const accessToken = useSelector(state => state.auth.userData?.accessToken);

  const interviewData = useSelector((state => state?.interview?.interviewData));
  const interviewErrorData = useSelector((state => state?.interview?.error));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let currentIndex = 1;
    const intervalId = setInterval(() => {
      setCurrentString(stringsArray[currentIndex]);
      currentIndex++;

      if (currentIndex === stringsArray.length) {
        clearInterval(intervalId);
        setCurrentString(stringsArray[stringsArray.length - 1])
      }
    }, 10000);

    dispatch(updateInterview({ id: interviewId, status: "started", accessToken }))

    return () => clearInterval(intervalId);
  }, []); //

  useEffect(() => {
    if (interviewData?.status == "STARTED" && interviewData?.id == interviewId) {
      navigate(`/ongoing-interview/${interviewId}/${interviewState}`)
    }
    else if (interviewErrorData) {
      setIsError(true);
    }
  }, [interviewData, interviewErrorData])

  const handleGoBack = () => {
    navigate('/dashboard/jobseeker');
    toast.success("Successfully navigated to dashboard");
  }

  if (isError) {
    return (<ErrorBox>
      <span>Failed to Create Interview, Please try again after some times</span>
      <button onClick={() => {
        handleGoBack()
        setIsError(false)
      }}>Go Back to Dashboard</button>
    </ErrorBox>)
  }

  return (
    <InterviewLoader message={currentString} />
  );
}

export default CreateInterview;


const ErrorBox = styled.div`
width: 100vw;
height: 100vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 2rem;

span {
  font-size: 0.9rem;
  font-weight: 600;
}

button {
  background-color: var(--lightOrange);
  color: var(--white);
  padding: 0.5rem 0.8rem;
  border: none;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 0.5rem;
  cursor: pointer;
}
`