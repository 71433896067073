import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import searchBlack from '../../../../assets/icons/searchBlack.png'
import TableSearchBar from "../commonComponents/TableSearchBar";
import { getPreFilteredData } from "../../../../functions/api/interview/getPreFilteredData";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Pagination, PaginationSizeFilter } from "../../../commonComponents/Pagination";
import { timeZoneConversion } from '../../../../utils/timeZoneConversation'

function Row(props) {
  const { row, index, page, size } = props;

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }} className={`${index % 2 == 1 ? 'colored' : ''}`}>
        <TableCell align="center" className="tableCell">{((page-1)*size) + (index+1)}</TableCell>
        <TableCell align="center" className="tableCell">{row?.jdTitle}</TableCell>
        <TableCell align="center" className="tableCell">{row?.userName}</TableCell>
        <TableCell align="center" className="tableCell">{row?.userEmail}</TableCell>
        <TableCell align="center" className="tableCell">{row?.scheduledAt && timeZoneConversion(row?.scheduledAt)}</TableCell>
        <TableCell align="center" className="tableCell">{row?.testType?.toUpperCase()}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}


const InProgressInterview = () => {
  const [tableRows, setTableRows] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const accessToken = useSelector((state) => state.auth.userData?.accessToken);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);

  const handleSizeChange = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handlePageChange = (change) => {
    if (change && page < Math.ceil(+total / +size)) {
      setPage((prev) => prev + 1);
    } else if (!change && page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    const filteredData = async () => {
      try {
        const res = await getPreFilteredData(accessToken, 'IN_PROGRESS', page, size);

        if (res) {
          setTableRows(res?.data?.data);
          setTotal(res?.data?.total)
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }

    filteredData();
  }, [page, size, total])


  return (
    <Content>
      <TableContainer component={Paper} className="tableBox">
        <div className="titleBox">
          <span className="title">In Progress Interviews</span>
        </div>

        <SearchBarContainer>
          <TableSearchBar value={searchValue} setValue={setSearchValue} />
        </SearchBarContainer>
        <Table aria-label="collapsible table">
          <TableHead className="tableHead">
            <TableRow>
              <TableCell align="center" className="tableCell">S. No.</TableCell>
              <TableCell align="center" className="tableCell">JD Title</TableCell>
              <TableCell align="center" className="tableCell">Candidate Name</TableCell>
              <TableCell align="center" className="tableCell">Email</TableCell>
              <TableCell align="center" className="tableCell">Scheduled Date/Time</TableCell>
              <TableCell align="center" className="tableCell">Test Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {tableRows?.map((row, index) => (
              <Row key={row?.id} row={row} index={index} page={page} size={size} />
            ))}
          </TableBody>
        </Table>
        <div className="paginationBox">
          <PaginationSizeFilter
            size={size}
            handleSizeChange={handleSizeChange}
          />
          <Pagination
            total={total}
            size={size}
            page={page}
            handlePageChange={handlePageChange}
            setPage={setPage}
          />
        </div>
      </TableContainer>
    </Content>
  )
}

export default InProgressInterview


const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  margin: 1rem auto 0.5rem auto;
  height: 3rem;
  background-color: var(--white);
  border-radius: 0.5rem;;
  padding: 0rem 1rem;
  gap: 1rem;




`

const Content = styled.div`
margin: 1rem 0% 2rem 0%;
width: 98%;
padding: 0 1%;
display: flex;
flex-direction: column;
align-items: center;


.colored {
  background-color: #ececec;
}

.tableBox {
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.20);
  border-radius: 0.5rem;
  padding-top: 1rem;


  .title {
    padding-left: 1.2rem;
    font-size: 0.9rem;
    font-weight: 600;
  }

  .titleBox {
  width: 99%; 
  padding: 0.5rem 0rem;;
  display: flex;
  align-items: center;
  justify-content: space-between;
  }


}

.MuiTableCell-root {
  border: none;
}

.MuiTableRow-root {
  border-bottom: none;
}

.selected {
  background-color: #d9fbf9;
  color: white;
}

.tableHead {
  background-color: #d1fff0;
  width: 100%;

  .tableCell {
    font-size: 0.9rem;
    font-weight: 500;
    font-family: var(--font);
    color: var(--color);
  }
  
}

.tableBody {
  width: 100%;

  .tableCell {
    font-size: 0.8rem;
    font-weight: 400;
    font-family: var(--font);
    color: var(--color);
  }
}


.btn {
  padding: 0.3rem 0.2rem;
  background-color: var(--lightOrange);
  border: none;
  color: var(--white);
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 0.5rem;
  cursor: pointer;
  
}

.paginationBox {
  display: flex;
  justify-content: end;
  gap: 2rem;
  margin: 1rem 3rem 1.5rem 0;
}

`
