import axios from "axios";

export const getReportsCount = async (accessToken, clientCode, jdIds = [], fromDate = null, toDate = null) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            "X-Client-Code": clientCode,
        },
    };

    let url = `${process.env.REACT_APP_API_URL}/api/jds/report?`;
    if (fromDate) {
        url += `fromDate=${encodeURIComponent(fromDate)}&`;
    }
    if (toDate) {
        url += `toDate=${encodeURIComponent(toDate)}&`;
    }
    if (jdIds.length > 0) {
        url += jdIds.map(id => `jdIds=${encodeURIComponent(id)}`).join('&');
    }

    try {
        const response =
            await axios.get(url, config);

        console.log("Data:", response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
};
