import { useState, useEffect } from 'react';
import { getTests } from '../services/testService';
import { toast } from 'react-toastify';

const useFetchTests = (accessToken, clientCode, published, testTrigger) => {
    const [tests, setTests] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTests = async () => {
            try {
                const data = await getTests(accessToken, clientCode, published);
                if(data){
                    setTests(data);
                }
            } catch (error) {
                const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            } finally {
                setLoading(false);
            }
        };

        fetchTests();
    }, [accessToken, clientCode, published, testTrigger]);

    return { tests, loading };
};

export default useFetchTests;
