import React from 'react'
import styled from 'styled-components'

const AcademicNumberCounter = ({ handleDecreaseNumber, handleIncreaseNumber, value, handleChange, testType, style }) => {
    return (
        <Box style={style}>
            <button className="numberBtn btn1" onClick={handleDecreaseNumber}>-</button>
            <input className="numberInput" type="number" value={value} onChange={() => handleChange(value)} max={testType === 'coding' ? 5 : 30} />
            <button className="numberBtn btn2" onClick={handleIncreaseNumber}>+</button>
        </Box>
    )
}

export default AcademicNumberCounter


const Box = styled.div`
width: 40%;
height: 3rem;
display: flex;

    .numberBtn {
      height: 100%;
      width: 3.5rem;
      border: none;
      background-color: var(--academicLavendar);
      color: var(--white);
      font-size: 1.4rem;
      cursor: pointer;
    }

    .btn1 {
      border-top-left-radius: 0.4rem;
      border-bottom-left-radius: 0.4rem;
      font-family: var(--font);
    }

    .btn2 {
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
      font-family: var(--font);
    }

    .numberInput {
      height: 100%;
      width: 6rem;
      padding: 0 2.3rem;
      box-sizing: border-box;
      font-size: 0.9rem;
      font-weight: 500;
      border: none;
      outline: none;
      background-color: #F0F0F0;
      font-family: var(--font);
    }

`