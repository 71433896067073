import React, { useState } from 'react';
import Button from '../../../../component/shared/Button';
import styled from 'styled-components';
import SelectInput from '../../../../component/shared/SelectInput';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const StyledCard = styled.div`
  width: 100%;
  background-color: var(--backgroundColor);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  align-items: flex-end;
  width: 100%;
  box-sizing: border-box;


  .calendar {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  }
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  .calendar {
  display: flex;
  }
`;

const FilterItem = styled.div`
  min-width: 150px;
  flex: 1;
`;


const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-self: right;
`;


const ActivityFilter = ({fromDate, setFromDate, toDate, setToDate, filters, setFilters, setTriggerActivityLogs, users}) => {

    const filterData = {
        users: users,
        features: ['Job Info', 'interview'],
        actions: ['CREATE', 'UPDATE', 'DELETE', 'VIEW'],
        roles: ['Admin', 'Manager', 'Recruiter']
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: value
        }));

    };

    const handleApplyFilters = () => {
        setTriggerActivityLogs(prev => !prev);
    };

    const handleResetFilters = () => {
        setFilters({
            userId: '',
            resourceName: '',
            actionType: '',
            fromDate: '',
            toDate: '',
        });
        
        setFromDate(dayjs(new Date()));
        setToDate(dayjs(new Date()));
    };

    return (
        <StyledCard>
                <FilterContainer>
                    <InputContainer>
                    <FilterItem>
                        <SelectInput
                            label="Select User"
                            name="userId"
                            value={filters?.userId}
                            onChange={handleFilterChange}
                            options={filterData?.users?.map(user => ({
                                value: user?.id,
                                label: user?.name
                            }))}
                            required
                        />
                    </FilterItem>

                    <FilterItem>
                        <SelectInput
                            label="Select feature"
                            name="resourceName"
                            value={filters?.resourceName}
                            onChange={handleFilterChange}
                            options={filterData?.features?.map(feature => ({
                                value: feature,
                                label: feature
                            }))}
                            required
                        />
                    </FilterItem>

                    <FilterItem>
                        <SelectInput
                            label="Select action"
                            name="actionType"
                            value={filters?.actionType}
                            onChange={handleFilterChange}
                            options={filterData?.actions?.map(action => ({
                                value: action,
                                label: action
                            }))}
                            required
                        />
                    </FilterItem>

                    {/* <FilterItem>
                        <SelectInput
                            label="Select role"
                            name="role"
                            value={filters?.role}
                            onChange={handleFilterChange}
                            options={filterData?.roles?.map(role => ({
                                value: role,
                                label: role
                            }))}
                            required
                        />
                    </FilterItem> */}

                    <span className='calendar'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="From-Date" value={fromDate} onChange={(newDate) => setFromDate(dayjs(newDate))} />
                            </DemoContainer>
                        </LocalizationProvider>
                        -
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="To-Date" value={toDate} onChange={(newDate) => setToDate(dayjs(newDate))} />
                            </DemoContainer>
                        </LocalizationProvider>
                    </span>
                    </InputContainer>

                    <ButtonContainer>
                        <Button text="Reset" func={handleResetFilters} disabled={false} />
                        <Button text="Apply Filters" func={handleApplyFilters} disabled={false} />
                    </ButtonContainer>
                </FilterContainer>
        </StyledCard>
    );
};

export default ActivityFilter;
