import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { uploadMedia } from '../../../functions/api/interview/uploadMedia';
import { addFailedWebcamUpload, removeAllFailedUploads, removeFailedWebcamUpload } from '../../../slices/uploadSnapSlice';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const useWebcamCapture = () => {
    const [webcamStream, setWebcamStream] = useState(null);
    const [webcamShots, setWebcamShots] = useState([]);
    const [webcamIntervalId, setWebcamIntervalId] = useState(null);
    const [webcamAccessGranted, setWebcamAccessGranted] = useState(false);
    const intervalTime = 60000;
    const accessToken = useSelector((state) => state.auth.userData?.accessToken);
    const clientCode = useSelector((state) => state.auth.userData?.user?.clientCode);

    const dispatch = useDispatch();
    const { interviewId } = useParams();
    const failedSnapShots = useSelector((state) => state.uploadSnap.failedWebcamUploadsQueue);

    useEffect(() => {
        let timeoutIds = [];

        if (failedSnapShots?.length > 0) {
            failedSnapShots?.forEach((shot, i) => {
                const timeoutId = setTimeout(() => {
                    uploadSnapShot(shot?.image, interviewId, 'snapshot', shot?.image?.name);
                }, i * 3 * 1000);
                timeoutIds.push(timeoutId);
            });
        }

        return () => {
            timeoutIds.forEach((timeoutId) => {
                clearTimeout(timeoutId);
            });
        };
    }, [failedSnapShots]);

    useEffect(() => {
        return () => {
            stopCapturingWebcamScreenshots();
            dispatch(removeAllFailedUploads());
        };
    }, []);

    const uploadSnapShot = async (file, id, type, fileName) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const res = await uploadMedia(formData, id, type, accessToken, clientCode)

            if (res) {
                dispatch(removeFailedWebcamUpload({ id: `${id}${fileName}` }));
            }
        }
        catch (error) {
            dispatch(addFailedWebcamUpload({ id: `${id}${fileName}`, image: file }))
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
        }
    }

    const stopCapturingWebcamScreenshots = () => {
        if (webcamStream) {
            webcamStream.getTracks().forEach(track => track.stop());
        }

        if (webcamIntervalId) {
            clearInterval(webcamIntervalId);
        }
        setWebcamShots([]);
    };

    let snapshotIndex = 1;

    const takeWebcamScreenshot = (stream) => {
        const videoTrack = stream.getVideoTracks()[0];
        const imageCapture = new ImageCapture(videoTrack);

        imageCapture.grabFrame()
            .then(imageBitmap => {
                const canvas = document.createElement('canvas');
                canvas.width = imageBitmap.width;
                canvas.height = imageBitmap.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(imageBitmap, 0, 0, imageBitmap.width, imageBitmap.height);

                canvas.toBlob(blob => {
                    const snapshotName = `snapshot${snapshotIndex++}.jpg`;
                    const imageFile = new File([blob], snapshotName, { type: 'image/jpeg' });

                    if (imageFile) {
                        uploadSnapShot(imageFile, interviewId, 'snapshot', snapshotName);
                    }
                    setWebcamShots(prevImages => [...prevImages, imageFile]);
                }, 'image/jpeg', 1);
            }).catch(error => {
                toast.error('Error grabbing webcam frame:', error);
            });
    };

    const handleWebcamAccess = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            setWebcamStream(stream);

            const id = setInterval(() => takeWebcamScreenshot(stream), intervalTime);
            setWebcamIntervalId(id);
            setWebcamAccessGranted(true);
        } catch (error) {
            toast.error('Error starting webcam capture:', error);
        }
    };

    return { webcamShots, webcamAccessGranted, handleWebcamAccess, stopCapturingWebcamScreenshots };
};

export default useWebcamCapture;
