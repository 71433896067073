import axios from "axios"

export const academicRegister = async (requestData, clientCode) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-client-code': clientCode
        }
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/academic/auth/register`, requestData, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}