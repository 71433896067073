import React from 'react'
import styled from 'styled-components'
import nextIcon from '../../../assets/icons/next.png'
import { useSelector } from 'react-redux';

const RequestGroup = ({ groupId, groupName, handleGroupClickNavigation }) => {
    const requestTypes = useSelector(state => state.atlassian?.portalRequestTypes);

    const filteredRequestTypes = requestTypes?.filter(requestType => requestType?.requestGroupIds?.some(id => id === groupId.toString()));

    const sortedRequestTypes = filteredRequestTypes?.sort((a, b) => {
        const groupIdA = a.requestGroupIds[0];
        const groupIdB = b.requestGroupIds[0];

        if (groupIdA === groupIdB) {
            return parseInt(a.id) - parseInt(b.id);
        } else {
            return parseInt(groupIdA) - parseInt(groupIdB);
        }
    });


    return (
        <MainBox onClick={() => handleGroupClickNavigation(groupId)}>
            <span className='groupName'>{groupName}</span>
            <span className='typesBox'>{sortedRequestTypes?.map(requestType => (
                <span key={requestType.requestId} className='typesText'>
                    {requestType.requestName},
                </span>
            ))}
            </span>
            <img src={nextIcon} className='icon' />
        </MainBox>
    )
}

export default RequestGroup

const MainBox = styled.div`
display: flex;
flex-direction: column;
gap: 0.5rem;
padding: 1.5rem 2rem;
border: 0.05rem solid #CCD3D8;
border-radius: 0.5rem;
box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
transition: box-shadow 1s cubic-bezier(0.25, 0.8, 0.25, 1);
cursor: pointer;
min-height: 4rem;
justify-content: center;
position: relative;

&:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    transform: translate(0, -2px);
  }


.groupName {
    font-size: 0.9rem;
    font-weight: 700;
    color: var(--lightOrange);
    font-family: var(--font);
}

.typesBox {
    display: flex;
    gap: 0.3rem;
    flex-flow: row wrap;
}

.typesText {
    font-size: 0.8rem;
    font-weight: 500;
    color: var(--color);
    font-family: var(--font);
}

.icon {
    position: absolute;
    width: 1rem;
    height: 1rem;
    right: 1rem;
}

`