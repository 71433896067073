import axios from "axios";

export const getTestsByStatus = async (status, accessToken, page, size) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        },
        params: {
            page: page,
            size: size,
            status: status
        }
    };

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/academic/tests`, config);
        console.log('Data:', response?.data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}



