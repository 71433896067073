import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import browseIcon from '../../../../assets/icons/folder.png'
import excelIcon from '../../../../assets/icons/xls.png'
import { bulkUpload } from '../../../../functions/api/resume/bulkUpload';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import LoaderDialog from '../../../commonComponents/infoDialog/LoaderDialog';
import sampleCandidateUpload from '../../../../assets/files/candidates_upload_sample.xlsx';
import { bulkAddSync } from '../../../../functions/api/resume/bulkAddSync';


const UploadCandidateToJdManually = ({jdId}) => {
    const [files, setFiles] = useState([]);
    const [excelFile, setExcelFile] = useState(null);

    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

    const [loading, setLoading] = useState(false);

    const handleFileChange = (event) => {
        const selectedFiles = event.target.files;
        setFiles((prevFiles) => [...prevFiles, ...Array.from(selectedFiles)]);
    };

    const handleExcelFileChange = (event) => {
        const selectedExcelFile = event.target.files[0];
        setExcelFile(selectedExcelFile);
    };


    const handleFileUpload = async (e) => {
        e.preventDefault()

        try {
            if (files?.length == 0) {
                toast.warning("Please select files/folder");
                return;
            } else {
                setLoading(true);
                const formData = new FormData();
                files?.forEach((file, index) => {
                    formData.append(`files`, file);
                });

                formData.append("jdIds", jdId);

                const res = await bulkUpload(formData, accessToken, clientCode);

                if (res) {
                    toast.success("Profiles uploaded successfully");
                    setFiles([]);
                    setLoading(false);
                }
            }
        } catch (error) {
            const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again."
            toast.error(errMsg);
            setLoading(false);
            setFiles([]);
        }
    };

    const handleExcelFileUpload = async (e) => {
        e.preventDefault();

        try {
            if (excelFile === null) {
                toast.warning("Please select file.");
                return;
            } else {
                setLoading(true);
                const formData = new FormData();
                formData.append(`file`, excelFile);

                formData.append("jdIds", jdId);

                const res = await bulkAddSync(formData, accessToken, clientCode);

                if (res) {
                    toast.success("Profiles uploaded successfully");
                    setExcelFile(null);
                    setLoading(false);
                }
            }
        } catch (error) {
            const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again."
            toast.error(errMsg);
            setLoading(false);
            setExcelFile(null);
        }
    }

    const handleSampleDownload = (e) => {

        e.preventDefault();

        const link = document.createElement('a');
        link.href = sampleCandidateUpload;
        link.download = '';

        link.click();
    }


    return (
        <Box>
            {loading && <LoaderDialog />}
            <div className='mainBox'>
                <div className='box1'>
                    <span className='title'>Upload Resumes</span>
                    <form onSubmit={handleFileUpload}>
                        <Label htmlFor='input'><img src={browseIcon} />
                            <span>{files?.map((item) => <p>{item?.name?.length > 50 ? item?.name?.slice(0, 50) : item?.name}</p>)}</span>
                        </Label>
                        <input
                            id='input'
                            type="file"
                            accept='*'
                            onChange={handleFileChange}
                            className='fileInput'
                            multiple
                        />
                        <span>Browse Folder or Zip File</span>
                        <button className='registerBtn' type='submit'>Upload</button>
                    </form>
                </div>

                <div className='box2'>
                    <span className='title'>Upload Excel Mapping</span>
                    <form onSubmit={handleExcelFileUpload}>
                        <Label htmlFor='excelInput'><img src={excelIcon} />
                            <span><p>{excelFile?.name?.length > 50 ? excelFile?.name?.slice(0, 50) : excelFile?.name}</p></span>
                        </Label>
                        <input
                            id='excelInput'
                            type="file"
                            accept=".xlsx, .xls, .csv"
                            onChange={handleExcelFileChange}
                            className='fileInput'
                        />
                        <span>Browse Excel File</span>
                        <div className='btnBox'><button className='registerBtn' onClick={handleSampleDownload}>Download Sample</button> <button className='registerBtn' type='submit'>Upload</button></div>
                    </form>
                </div>
            </div>
        </Box>
    )
}

export default UploadCandidateToJdManually;



const Box = styled.div`
  width: 88%;
  // border: 1px solid #ccc;
  padding: 2.5rem 1rem;;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.7rem;
  font-size: 0.9rem;
  background-color: var(--white);
  margin: 1rem 0;
  gap: 3rem;

  #tags-standard {
    font-size: 0.8rem;
    font-weight: 400;
    font-family: var(--font);
  }

  .mainBox {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .box1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .box2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .secondaryBox {
    width: 60%;
  }

  .btnBox {
    display: flex;
    gap: 0.5rem;
  }

  .registerBtn {
    padding: 0.5rem 0.8rem;
    background-color: var(--lightOrange);
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    color: var(--white);
    font-size: 0.9rem;
    font-weight: 600;
    margin-top: 1.5rem;
    font-family: var(--font);
  }

  .title {
    margin-bottom: 2rem;
    font-size: 0.9rem;
    font-weight: 600;

  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
    margin-top: 0rem;

    span {
      font-size: 0.8rem;
    }

    .fileInput {
      position: absolute;
    left: -9999px;
    }
  }
`;


const Label = styled.label`
  margin: 0.7rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;


  img {
    width: 2.5rem;
  }
  
  span {
    color: var(--color);
    font-weight: 400;
    font-size: 0.8rem;
  }
`;
