import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faEnvelope, faPhone, } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import { resumeExampleData } from "../../../../utils/contantData";
import { useSelector } from "react-redux";

const Resume3 = ({ personalDetails, professionalSummary, employmentHistory, education, social, skills, languages, projects, profileImg }) => {

    const mode = useSelector(state => state?.cvBuilder?.mode);

    return (
        <Wrapper>
            <PrintStyles />
            <Header>
                <span className="name">{(!personalDetails?.firstName && mode === 'create') ? resumeExampleData?.personalDetails?.firstName : personalDetails?.firstName} {(!personalDetails?.lastName && mode === 'create') ? resumeExampleData?.personalDetails?.lastName : personalDetails?.lastName}</span>
                <div className="headerBox">
                    <span className="infoItem">
                        {(((personalDetails?.address && mode === 'edit') || (resumeExampleData?.personalDetails?.address && mode === 'create')) || ((personalDetails?.city && mode === 'edit') || (resumeExampleData?.personalDetails?.city && mode === 'create')) || ((personalDetails?.country && mode === 'edit') || (resumeExampleData?.personalDetails?.country && mode === 'create'))) && <FontAwesomeIcon icon={faLocationDot} className="icon" />}
                        {(!personalDetails?.address && mode === 'create') ? resumeExampleData?.personalDetails?.address : personalDetails?.address} {(!personalDetails?.city && mode === 'create') ? resumeExampleData?.personalDetails?.city : personalDetails?.city} {(!personalDetails?.country && mode === 'create') ? resumeExampleData?.personalDetails?.country : personalDetails?.country}
                    </span>
                    <span className="infoItem">
                        {((personalDetails?.email && mode === 'edit') || (resumeExampleData?.personalDetails?.email && mode === 'create')) && <FontAwesomeIcon icon={faEnvelope} className="icon" />}
                        {(!personalDetails?.email && mode === 'create') ? resumeExampleData?.personalDetails?.email : personalDetails?.email}
                    </span>
                    <span className="infoItem">
                        {((personalDetails?.phone && mode === 'edit') || (resumeExampleData?.personalDetails?.phone && mode === 'create')) && <FontAwesomeIcon icon={faPhone} className="icon" />}
                        {(!personalDetails?.phone && mode === 'create') ? resumeExampleData?.personalDetails?.phone : personalDetails?.phone}
                    </span>
                    <span className="infoItem">
                        <a href={(!social?.linkedin && mode === 'create') ? resumeExampleData?.social?.linkedin : social?.linkedin} target="_blank" rel="noopener noreferrer">
                            {((social?.linkedin && mode === 'edit') || (resumeExampleData?.social?.linkedin && mode === 'create')) && <><FontAwesomeIcon icon={faLinkedin} className="icon" />
                                Linkedin </>}
                        </a>
                    </span>
                </div>
            </Header>

            {((professionalSummary?.length > 0 && mode === 'edit') || (resumeExampleData?.professionalSummary && mode === 'create')) && <HLine />}

            <Content>

                <Summary>
                    {((professionalSummary?.length > 0 && mode === 'edit') || (resumeExampleData?.professionalSummary && mode === 'create')) &&
                        <h2>
                            Professional Summary
                        </h2>}
                    <span dangerouslySetInnerHTML={{ __html: (!professionalSummary && mode === 'create') ? resumeExampleData?.professionalSummary : professionalSummary }} className='textarea' />
                </Summary>

                {((employmentHistory?.length > 0 && mode === 'edit') || (resumeExampleData?.employmentHistory?.length > 0 && mode === 'create')) && <HLine />}

                <Employment>
                    {((employmentHistory?.length > 0 && mode === 'edit') || (resumeExampleData?.employmentHistory?.length > 0 && mode === 'create')) &&
                        <h2>
                            Employment History
                        </h2>}
                    <div className="experienceMainBox">
                        {
                            (employmentHistory?.length === 0 && mode === 'create') ?
                                resumeExampleData?.employmentHistory?.map((exp, index) => (
                                    <div className="experienceBox" key={index}>
                                        <div className="experienceBoxTop">
                                            <span className="title">{exp?.jobTitle}</span>
                                            <span className="company">{exp?.companyName}</span>
                                        </div>
                                        <span className="location">{exp?.city}</span>
                                        <span className="date">{exp?.startDate} {exp?.startDate?.length > 0 && 'to'} {exp?.current ? "Present" : exp?.endDate}</span>
                                        <span dangerouslySetInnerHTML={{ __html: exp?.description }} className='textarea' />
                                    </div>
                                )) : employmentHistory?.map((exp, index) => (
                                    <div className="experienceBox" key={index}>
                                        <div className="experienceBoxTop">
                                            <span className="title">{exp?.jobTitle}</span>
                                            <span className="company">{exp?.companyName}</span>
                                        </div>
                                        <span className="location">{exp?.city}</span>
                                        <span className="date">{exp?.startDate} {exp?.startDate?.length > 0 && 'to'} {exp?.current ? "Present" : exp?.endDate}</span>
                                        <span dangerouslySetInnerHTML={{ __html: exp?.description }} className='textarea' />
                                    </div>
                                ))
                        }
                    </div>
                </Employment>

                {((projects?.length > 0 && mode === 'edit') || (resumeExampleData?.projects?.length > 0 && mode === 'create')) && <HLine />}

                <Projects>
                    {((projects?.length > 0 && mode === 'edit') || (resumeExampleData?.projects?.length > 0 && mode === 'create')) &&
                        <h2>
                            Projects
                        </h2>}
                    <div className="experienceMainBox">
                        {
                            (projects?.length === 0 && mode === 'create') ?
                                resumeExampleData?.projects?.map((project, index) => (
                                    <div className="projectBox" key={index}>
                                        <div className="educationBoxTop">
                                            <span className="title">{project?.title}</span>
                                            <span className="date">{project?.startDate} {project?.startDate?.length > 0 && 'to'} {project?.current ? "Present" : project?.endDate}</span>
                                        </div>
                                        <span dangerouslySetInnerHTML={{ __html: project?.description }} className='textarea' />
                                    </div>
                                )) : projects?.map((project, index) => (
                                    <div className="projectBox" key={index}>
                                        <div className="educationBoxTop">
                                            <span className="title">{project?.title}</span>
                                            <span className="date">{project?.startDate} {project?.startDate?.length > 0 && 'to'} {project?.current ? "Present" : project?.endDate}</span>
                                        </div>
                                        <span dangerouslySetInnerHTML={{ __html: project?.description }} className='textarea' />
                                    </div>
                                ))
                        }
                    </div>
                </Projects>


                {((skills?.length > 0 && mode === 'edit') || (resumeExampleData?.skills?.length > 0 && mode === 'create')) && <HLine />}

                <Skills>
                    {((skills?.length > 0 && mode === 'edit') || (resumeExampleData?.skills?.length > 0 && mode === 'create')) &&
                        <h2>
                            Skills
                        </h2>}
                    <div className="skillBox">
                        {
                            (skills?.length === 0 && mode === 'create') ?
                                resumeExampleData?.skills?.map((skill, index) => (
                                    (skill?.skill && <span className="skill" key={index}>{skill?.skill}</span>)
                                )) : skills?.map((skill, index) => (
                                    (skill?.skill && <span className="skill" key={index}>{skill?.skill}</span>)
                                ))
                        }
                    </div>
                </Skills>

                {((education?.length > 0 && mode === 'edit') || (resumeExampleData?.education?.length > 0 && mode === 'create')) && <HLine />}

                <Education>
                    {((education?.length > 0 && mode === 'edit') || (resumeExampleData?.education?.length > 0 && mode === 'create')) &&
                        <h2>
                            Education
                        </h2>}
                    <div className="experienceMainBox">
                        {
                            (education?.length === 0 && mode === 'create') ?
                                resumeExampleData?.education?.map((edu, index) => (
                                    <div className="educationBox" key={index}>
                                        <div className="educationBoxTop">
                                            <span className="title">{edu?.degree}</span>
                                            <span className="date">{edu?.startDate} {edu?.startDate?.length > 0 && 'to'} {edu?.current ? "Present" : edu?.endDate}</span>
                                        </div>
                                        <span className="company">{edu?.university}</span>
                                        <span dangerouslySetInnerHTML={{ __html: edu?.description }} className='textarea' />
                                    </div>
                                )) : education?.map((edu, index) => (
                                    <div className="educationBox" key={index}>
                                        <div className="educationBoxTop">
                                            <span className="title">{edu?.degree}</span>
                                            <span className="date">{edu?.startDate} {edu?.startDate?.length > 0 && 'to'} {edu?.current ? "Present" : edu?.endDate}</span>
                                        </div>
                                        <span className="company">{edu?.university}</span>
                                        <span dangerouslySetInnerHTML={{ __html: edu?.description }} className='textarea' />
                                    </div>
                                ))
                        }
                    </div>
                </Education>

                {((languages?.length > 0 && mode === 'edit') || (resumeExampleData?.languages?.length > 0 && mode === 'create')) && <HLine />}

                <Language>
                    {((languages?.length > 0 && mode === 'edit') || (resumeExampleData?.languages?.length > 0 && mode === 'create')) &&
                        <h2>
                            Languages
                        </h2>}
                    <div className="skillBox">
                        {
                            (languages?.length === 0 && mode === 'create') ?
                                resumeExampleData?.languages?.map((lang, index) => (
                                    (lang?.language && <span className="skill" key={index}>{lang?.language}</span>)
                                )) : languages?.map((lang, index) => (
                                    (lang?.language && <span className="skill" key={index}>{lang?.language}</span>)
                                ))
                        }
                    </div>
                </Language>

                <br />
                <br />
            </Content>
        </Wrapper>
    );
};

export default Resume3;



const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: calc(100% - 2rem);
  min-height: 600px;
  margin: 0rem auto;
  gap: 1rem;
  padding: 1rem;
  background-color: var(--white);

  .textarea {
    padding: 0;
    margin: 0;
    margin-top: -1rem;
    font-size: 0.75rem;
    line-height: 0.9rem;
  }


  h2 {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 0rem;
  }

  .experienceMainBox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

}

ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
`;

const Content = styled.div`
display: flex;
flex-direction: column;
gap: 0.5rem;
width: 100%;




`

const Header = styled.div`

display: flex;
flex-direction: column;
gap: 0.7rem;
width: 100%;
align-items: center;


.name {
    font-size: 2rem;
    font-weight: 600;
    padding-bottom: 0.5rem;
}

.infoItem {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.8rem;
}

a {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.8rem;
    text-decoration: none;
    color: var(--color);
}

.icon {
    color: grey;
}

.headerBox {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;
    gap: 2rem;
}



`


const Summary = styled.div`
display: grid;
grid-template-columns: 2fr 5fr;
align-items: start;

.textarea {
    margin-top: -0.5rem;
    display: block;
    font-size: 0.75rem;
    line-height: 0.9rem;
}

.text {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.05rem;
    padding-left: 2rem;
}

`

const Employment = styled.div`
display: grid;
grid-template-columns: 2fr 5fr;
align-items: start;


.experienceBox {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    padding-left: 2rem;


    .experienceBoxTop {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 0.5rem;
    }
}


.title {
    font-size: 0.9rem;
    font-weight: 600;
}

.company {
    font-size: 0.8rem;
    font-weight: 500;
    font-style: italic;
}

.location {
    font-size: 0.8rem;
    font-weight: 500;
}

.date {
    font-size: 0.75rem;
    font-weight: 500;
    color: grey;
}

.desc {
    font-size: 0.9rem;
    line-height: 1.3rem;
    letter-spacing: 0.03rem;
}

`


const Education = styled.div`
display: grid;
grid-template-columns: 2fr 5fr;
align-items: start;

.educationBox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-left: 2rem;


    .educationBoxTop {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 0.6rem;
    }
}

.title {
    font-size: 0.9rem;
    font-weight: 600;
}

.company {
    font-size: 0.9rem;
    font-weight: 500;
    color: grey;
}

.date {
    font-size: 0.75rem;
    font-weight: 500;
    color: grey;
}


`


const Skills = styled.div`
display: grid;
grid-template-columns: 2fr 5fr;
align-items: start;

.skillBox {
    display: flex;
    flex-flow: row wrap;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
    padding-left: 1.5rem;
}

.skill {
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
    font-weight: 500;
}

`


const Language = styled.div`
display: grid;
grid-template-columns: 2fr 5fr;
align-items: start;

.skillBox {
    display: flex;
    flex-flow: row wrap;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
    padding-left: 1.5rem;
}

.skill {
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
    font-weight: 500;
}

`


const Certification = styled.div`
display: grid;
grid-template-columns: 2fr 5fr;
align-items: start;

.certificateBox {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    padding-left: 2rem;

    .educationBoxTop {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 0.6rem;
    }
}

.title {
    font-size: 0.9rem;
    font-weight: 600;
}

.company {
    font-size: 0.85rem;
    font-weight: 500;
    color: grey;
}

.date {
    font-size: 0.75rem;
    font-weight: 500;
    color: grey;
}

`


const Projects = styled.div`
display: grid;
grid-template-columns: 2fr 5fr;
align-items: start;


.projectBox {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-left: 2rem;
    
}

.title {
    font-size: 0.9rem;
    font-weight: 600;
}

.date {
    font-size: 0.75rem;
    font-weight: 500;
    color: grey;
}

.desc {
    font-size: 0.9rem;
    line-height: 1.3rem;
    letter-spacing: 0.03rem;
}

.educationBoxTop {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: -1rem;
}
.textarea {
    margin-top: -0.5rem;
    display: block;
    font-size: 0.75rem;
    line-height: 0.9rem;
}
`
const HLine = styled.hr`
border: none;
height: 0.1rem;
width: 100%;
background-color: var(--color);
margin: 0.5rem 0 0 0;


`

const PrintStyles = createGlobalStyle`
  @media print {
    @page {
      margin: 8mm 5mm 5mm 5mm;
    }

    body {
      zoom: 1.8;
    }
  }
`;