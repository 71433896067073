import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components'
import { getTokenUsageByDateRange } from '../../../../functions/api/billing/getTokenUsageByDateRange';
import { formatDate, getDateRange } from '../../../../utils/BillingDateRangeFilter';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

function getLast12Months() {
  const months = [];
  const now = new Date();

  for (let i = 0; i < 12; i++) {
    const currentMonth = new Date(now.getFullYear(), now.getMonth() - i, 1);
    const nextMonth = new Date(now.getFullYear(), now.getMonth() - i + 1, 1);
    const lastDayOfCurrentMonth = new Date(nextMonth - 1); // Last day of the current month

    const monthName = currentMonth.toLocaleString('default', { month: 'long' });
    const year = currentMonth.getFullYear();

    const startDate = currentMonth.toISOString().split('T')[0]; // Format: YYYY-MM-DD
    const endDate = lastDayOfCurrentMonth.toISOString().split('T')[0]; // Format: YYYY-MM-DD

    months.push({
      name: `${monthName} ${year}`,
      startDate: startDate,
      endDate: endDate
    });
  }
  return months;
}

function getCurrentMonthAndYear() {
  const now = new Date();
  const monthName = now.toLocaleString('default', { month: 'long' });
  const year = now.getFullYear();
  
  return `${monthName} ${year}`;
}


const PostpaidBillingTop = () => {
  const clientCode = useSelector(state => state?.auth?.userData?.user?.clientCode);
  const accessToken = useSelector(state => state?.auth?.userData?.accessToken);

  const now = new Date();
  const startDateRaw = new Date(now.getFullYear(), now.getMonth(), 1);  
  const endDateRaw = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  const startDateString = startDateRaw.toISOString().split('T')[0];
  const endDateString = endDateRaw.toISOString().split('T')[0];

  const [tokenUsageData, setTokenUsageData] = useState(null);

  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonthAndYear());
  const months = getLast12Months();
  const [startDate, setStartDate] = useState(startDateString)
  const [endDate, setEndDate] = useState(endDateString)


  useEffect(() => {
    const getToken = async () => {
      try {
        const res = await getTokenUsageByDateRange(accessToken, clientCode, startDate, endDate);
        if (res) {
          setTokenUsageData(res?.data);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }
    getToken();

    const interval = setInterval(() => {
      getToken();
    }, 30000)

    return () => clearInterval(interval);

  }, [startDate, endDate])


  function calculateTotalCost(data) {
    let totalCost = 0;
    for (const [key, value] of Object.entries(data)) {
      if (key.endsWith('Cost')) {
        totalCost += value;
      }
    }
    return totalCost?.toFixed(3);
  }

  const generateStructuredData = (data) => {
    return Object.entries(data)?.map(([key, value]) => {
      if (key.endsWith('Count')) {
        const feature = key.replace('Count', '');
        const featureText = feature === "questionsCreation" ? "Interview Created" : feature === "questionsScoring" ? "Interview Evaluated" : feature === "resumeParsing" ? "Resume Parsing" : feature === "jdResumeMatching" ? "JD-Resume Matching" : feature === "interviewProctoring" ? "Interview Proctored" : "";
        const cost = data[`${feature}Cost`] || 0;
        return { featureText, count: value, cost };
      }
      return null;
    })?.filter(item => item !== null);
  };

  const handleChange = (event) => {
    const selectedMonthName = event.target.value;
    setSelectedMonth(selectedMonthName);

    const selectedMonthDetails = months.find(month => month.name === selectedMonthName);
    if (selectedMonthDetails && handleMonthSelect) {
      handleMonthSelect(selectedMonthDetails);
    }
  };

  const handleMonthSelect = (monthDetails) => {
    setStartDate(monthDetails?.startDate)
    setEndDate(monthDetails?.endDate)
  };

  return (
    <TopBox>
      <Card width="100%">
        <div className='titleBox1'>
          <Title>Business Plan</Title>
          <div className='titleChild'>
            <FormControl sx={{ backgroundColor: '#F6F6FB' }} required fullWidth>
              <InputLabel id="demo-simple-select-label" style={{ fontSize: '0.8rem' }}>Billing Period</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Billing Period"
                name="billingPeriod"
                value={selectedMonth}
                onChange={handleChange}
                size='small'
                inputProps={{
                  sx: {
                    color: '#626264',
                    fontSize: '0.8rem',
                    fontWeight: '400'
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: '#626264',
                    fontSize: '0.8rem',
                    fontWeight: '400'
                  },
                }}
                sx={{
                  padding: '0.5rem 0 0.5rem 0',
                }}
              >
                {months?.map((month, index) => (
                  <MenuItem key={index} value={month?.name}>
                    {month?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Amount>UnBilled amount: ${tokenUsageData && calculateTotalCost(tokenUsageData)}</Amount>
          </div>
        </div>

        <Table>
          <thead>
            <tr>
              <TableHeader>Usage</TableHeader>
              <TableHeader>Usage Count</TableHeader>
              <TableHeader>Cost</TableHeader>
            </tr>
          </thead>
          <tbody>
            {tokenUsageData ?
              (generateStructuredData(tokenUsageData).map((item, index) => (
                <tr key={index}>
                  <TableCell>{item?.featureText}</TableCell>
                  <TableCell>{item?.count}</TableCell>
                  <TableCell>${item?.cost?.toFixed(3)}</TableCell>
                </tr>
              )))
              : (
                <p>No data available</p>
              )}
          </tbody>
        </Table>
      </Card>

    </TopBox >
  )
}

export default PostpaidBillingTop


const TopBox = styled.div`
display: flex;
width: 94%;
flex-direction: row;
justify-content: space-between;
margin-bottom: 0.75rem;
gap: 1rem;


.rightCardBox {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 gap: 1rem;

}

`

const Card = styled.div`
  background-color: var(--white);
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 1.5rem;
  width: ${props => props.width};
  box-shadow: 0 2px 3px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: 0.5rem;

  .titleBox1 {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .titleChild {
  
  }
  }

  .cardBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .cardDetail {
   display: flex;
   width: 100%;
   gap: 2rem;
  }
  }

  .calculatorBox {
   display: flex;
   width: 100%;
   flex-direction: column;

  p {
    font-size: 0.9rem;
    font-weight: 500;
  }
  }

  .creditTitle {
   font-size: 0.9rem;
   font-weight: 600;
   margin-bottom: -0.5rem;
  }
`;

const Title = styled.h2`
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: var(--color);
`;

const Subtitle = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: var(--color);
  text-align: right;
`;

const Amount = styled.h3`
  font-size: 1.25rem;
  color: #333;
  text-align: right;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 0rem;
`;

const TableHeader = styled.th`
  padding: 0.75rem;
  background: var(--white);
  border-bottom: 0.1rem solid #ddd;
  text-align: center;  // Align text to the center

`;

const TableCell = styled.td`
  padding: 0.75rem;
  border-bottom: 0.1rem solid #ddd;
  text-align: center;  // Align text to the center

`;


const Button = styled.button`
  background: ${props => props.bg || '#FFB74D'};
  color: white;
  border: none;
  padding: 0.5rem 1.25rem;
  border-radius: 0.35rem;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: ${props => props.hover || '#FFA726'};
  }
`;


