import React from 'react';
import StyledTextField from './StyledTextField';

const TextInput = ({ label, value, onChange, required, type = 'text', ...props }) => (
    <StyledTextField
        inputProps={{
            sx: {
                color: '#626264',
                fontSize: '0.8rem',
                fontWeight: '400'
            },
        }}
        InputLabelProps={{
            sx: {
                color: '#626264',
                fontSize: '0.8rem',
                fontWeight: '400'
            },
        }}
        id='outline-basic'
        label={label}
        variant="outlined"
        type={type}
        value={value}
        onChange={onChange}
        required={required}
        fullWidth
        {...props}
    />
);

export default TextInput;
