import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Autocomplete, Paper, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/system';
import { allTags } from '../../../utils/contantData';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { createTest } from '../../../functions/api/employers/employerTest/createTest';
import { updateTest } from '../../../functions/api/employers/employerTest/updateTest';
import deleteIcon from '../../../assets/icons/delete.png'
import editIcon from '../../../assets/icons/edit.png'
import Loader from '../../commonComponents/Loader';
import { publishTest } from '../../../functions/api/employers/employerTest/publishTest';
import ModalHOC from '../SeekerDashboard/ModalHOC';
import CreateQuestionForm from './CreateQuestionForm';
import TestQuestionsLibrary from './sidebarPages/TestQuestionsLibrary';
import { useDispatch } from 'react-redux';
import { clearFormData, setAllQuestions, setFormData, setSelectedTags } from '../../../slices/testFormSlice';

const filter = createFilterOptions();

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1rem;

  .title {
    font-size: 0.9rem;
    font-weight: 600;
    display: block;
    margin-bottom: 2rem;
  }

  .titleBox {
   display: flex;
   justify-content: space-between;
   margin-bottom: 1rem;
  }

  .titleBtn {
   background-color: var(--lightOrange);
   font-size: 0.9rem;
   font-weight: 600;
   padding: 0.5rem 0.8rem;
   border-radius: 0.25rem;
   color: var(--white);
   cursor: pointer;
   align-items: center;
  }

  @media (max-width: 2000px) {
    #outlined-basic {
      padding: 0.75rem 0.5rem;
      background-color: #F6F6FB;
    }
  }

  @media (max-width: 1700px) {
    #outlined-basic {
      padding: 0.85rem 0.5rem;
      background-color: #F6F6FB;
    }
  }

  @media (max-width: 1350px) {
    #outlined-basic {
      padding: 0.95rem 0.5rem;
      background-color: #F6F6FB;
    }
  }

  @media (max-width: 1200px) {
    #outlined-basic {
      padding: 1rem 0.5rem;
      background-color: #F6F6FB;
    }
  }

  .textAreaBox {
    width: 100%;
    position: relative;
    margin-top: 0.5rem;

    label {
      font-size: 0.7rem;
  font-weight: 600;
  position: absolute;
  top: -0.5rem;
  left: 0.5rem;
  padding: 0 0.5rem;
  background-color: var(--white);
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  gap: 0.7rem;

  .inputBox {
    width: 100%;
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
    align-items: center;
  }

  .inputBoxMap {
    width: 100%;
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
    flex-flow: row wrap;
  }

  .box {
    width: calc(50% - 1rem);
  }

  .btnBox {
  margin-top: 2rem;
   display: flex;
   gap: 2rem;
   align-items: center;
   align-self: center;
   justify-content: center;
  }
`;


const Button = styled.button`
  padding: 0.5rem 0.8rem;
  background-color: var(--lightOrange);
  color: var(--white);
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  font-family: var(--font);
  display: flex;
  align-self: center;
  margin: 1rem auto;
`;

const QueBox = styled.div`
display: flex;
flex-direction: column;
gap: 1rem;

.title {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: -0.5rem;
}

.questionBox {
    display: flex;
    justify-content: space-between;
    align-items: start;
    border: 0.05rem solid lightgrey;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;

    .iconBox {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-self: center;
    }

    img {
      width: 1rem;
      height: 1rem;
      cursor: pointer;
      padding: 0.3rem;
      border: 0.05rem solid lightgrey;
      border-radius: 0.25rem;
    }

    .questionBox1 {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .questionTop {
      display: flex;
      justify-content: start;
      gap: 2rem;
    }

    .bold {
      font-weight: 600;
    }

  }

`

function CreateTestsForm({ editingIndex, setEditingIndex, handleClose, editingTestId, editingTestData, setTestTrigger, testQuestionData, isReview }) {
    const dispatch = useDispatch();
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

    const [openBasic, setOpenBasic] = useState(false);
    const [queEditingIndex, setQueEditingIndex] = useState(-1);
    const [editingQuestionId, setEditingQuestionId] = useState('');
    const [editingQuestionData, setEditingQuestionData] = useState(null);
    const [questionTrigger, setQuestionTrigger] = useState(false);

    const [openQueLibrary, setOpenQueLibrary] = useState(false);

    const formData = useSelector((state) => state.testForm.formData);
    const allQuestions = useSelector((state) => state.testForm.allQuestions);

    const [selectedTags, setSelectedTags] = useState([]);

    const [loading, setLoading] = useState(false);

    const [tagInputValue, setTagInputValue] = useState("");


    useEffect(() => {
        dispatch(setFormData({
            tags: selectedTags?.join(", "),
        }))
    }, [selectedTags]);

    useEffect(() => {
        if (editingIndex != -1) {

            dispatch(setFormData({
                testType: editingTestData?.testType,
                name: editingTestData?.name,
                role: editingTestData?.role,
                questionIds: editingTestData?.testQuestions?.map((question) => question.id),
            }));
            { editingTestData?.tags && setSelectedTags(editingTestData?.tags?.split(", ")) };
            { editingTestData?.testQuestions && dispatch(setAllQuestions(editingTestData?.testQuestions)); }
        } else {
            { testQuestionData && dispatch(setAllQuestions(testQuestionData)) }
            dispatch(setFormData({
                ...formData,
                questionIds: testQuestionData?.map((question) => question.id),
            }));
        }
    }, [editingIndex, editingTestData, editingTestId, testQuestionData])


    const handleChange = (e) => {
        const { name, value } = e.target;
        dispatch(setFormData({ [name]: value }));
    };

    const handleAddTest = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);

            const payload = {
                testType: formData?.testType,
                tags: formData?.tags,
                questionIds: formData?.questionIds,
                role: formData?.role,
                name: formData?.name,
                clientCode: clientCode,
            }
            
            const res = await createTest(payload, accessToken, clientCode);

            if (res) {
                setLoading(false);
                toast.success("Test added successfully", 5000);
                dispatch(clearFormData());
                setTestTrigger(prev => !prev);
                handleClose();
            }
        } catch (error) {
            setLoading(false);
            const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again."
            toast.error(errMsg);
        }
    };

    const handleUpdateTest = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const res = await updateTest(formData, editingTestId, accessToken, clientCode);

            if (res) {
                setLoading(false);
                toast.success("Test updated successfully", 5000);
                setEditingIndex(-1);
                setTestTrigger(prev => !prev);
                dispatch(clearFormData());
                handleClose();
            }
        } catch (error) {
            setLoading(false);
            const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again."
            toast.error(errMsg);
        }
    };

    const handleUpdateTestForPublish = async () => {
        try {
            const res = await updateTest(formData, editingTestId, accessToken, clientCode);
        } catch (error) {
            setLoading(false);
            const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again."
            toast.error(errMsg);
        }
    };

    const handlePublishTest = async () => {
        try {
            setLoading(true);
            await handleUpdateTestForPublish();
            const res = await publishTest(accessToken, clientCode, editingTestId, true);

            if (res) {
                setLoading(false);
                toast.success("Test Published successfully", 5000);
                setEditingIndex(-1);
                setTestTrigger(prev => !prev);
                dispatch(clearFormData())
                handleClose();
            }
        } catch (error) {
            setLoading(false);
            const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again."
            toast.error(errMsg);
        }
    };

    const handleTagsChange = (_, newTags) => {
        setSelectedTags(newTags);
    };

    const handleTagInputBlur = () => {
        if (tagInputValue.trim() !== '') {
            setSelectedTags([...selectedTags, tagInputValue?.trim()]);
            setTagInputValue('');
        }
    };

    const handleDeleteQue = (id) => {
        const updatedQuestionIds = formData?.questionIds?.filter(questionId => questionId !== id);
        const updatedQue = allQuestions?.filter(ques => ques?.id !== id);
        dispatch(setFormData({
            questionIds: updatedQuestionIds,
        }))
        dispatch(setAllQuestions(updatedQue));
    }

    const handleImportQuestion = () => {
        setOpenQueLibrary(true);
    }

    console.log(formData);

    console.log(allQuestions);

    return (
        <Container>
            <ModalHOC openNewInterviewModal={openBasic} setOpenNewInterviewModal={setOpenBasic} component={<CreateQuestionForm editingIndex={queEditingIndex} setEditingIndex={setQueEditingIndex} handleClose={() => setOpenBasic(false)} editingQuestionId={editingQuestionId} editingQuestionData={editingQuestionData} setQuestionTrigger={setQuestionTrigger} />} />
            <ModalHOC openNewInterviewModal={openQueLibrary} setOpenNewInterviewModal={setOpenQueLibrary} component={<TestQuestionsLibrary onClose={() => setOpenQueLibrary(false)} />} />

            {loading && <Loader message={editingIndex === -1 ? "Creating Test..." : "Updating Test..."} />}

            <span className='title'>{isReview ? "Review and Publish" : editingIndex === -1 ? 'Create' : 'Update'} Test</span>
            <Form onSubmit={(!isReview && editingIndex === -1) ? handleAddTest : handleUpdateTest}>

                <div className="inputBox">
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        label="Test Name"
                        name="name"
                        value={formData?.name}
                        onChange={handleChange}
                        sx={{ backgroundColor: "#F6F6FB" }}
                        inputProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "400",
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "400",
                            },
                        }}
                        required
                        fullWidth
                    />

                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        label="Role"
                        name="role"
                        value={formData?.role}
                        onChange={handleChange}
                        sx={{ backgroundColor: "#F6F6FB" }}
                        inputProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "400",
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                color: "#626264",
                                fontSize: "0.8rem",
                                fontWeight: "400",
                            },
                        }}
                        required
                        fullWidth
                    />
                </div>

                <div className="inputBox">
                    <Stack spacing={3} sx={{ width: "100%", }}>
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={allTags}
                            getOptionLabel={(option) => option}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const { inputValue } = params;
                                const isExisting = options.some((option) => inputValue === option);

                                if (inputValue !== '' && !isExisting) {
                                    filtered.push(inputValue);
                                }

                                return filtered;
                            }}
                            onChange={handleTagsChange}
                            value={selectedTags}
                            selectOnFocus
                            clearOnBlur
                            freeSolo
                            fullWidth
                            onInputChange={(event, newInputValue) => {
                                setTagInputValue(newInputValue);
                            }}
                            onBlur={handleTagInputBlur}
                            PaperComponent={(props) => <Paper {...props} sx={{ backgroundColor: '#F0F0F6' }} />}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Tags*"
                                    sx={{ backgroundColor: "#F6F6FB", fontSize: '0.5rem' }}
                                />
                            )}
                        />
                    </Stack>

                    <FormControl sx={{ backgroundColor: '#F6F6FB' }} required fullWidth>
                        <InputLabel id="demo-simple-select-label" style={{ fontSize: '0.8rem' }}>Test Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Test Type"
                            name="testType"
                            value={formData?.testType}
                            onChange={handleChange}
                            size='small'
                            inputProps={{
                                sx: {
                                    color: '#626264',
                                    fontSize: '0.8rem',
                                    fontWeight: '400'
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: '#626264',
                                    fontSize: '0.8rem',
                                    fontWeight: '400'
                                },
                            }}
                            sx={{
                                padding: '0.5rem 0 0.5rem 0',
                            }}
                        >
                            <MenuItem value="mix">General (Includes all types of Questions)</MenuItem>
                            <MenuItem value="general">Subjective</MenuItem>
                            <MenuItem value="mcq">Objective</MenuItem>
                            <MenuItem value="coding">Coding</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <QueBox>
                    <div className="titleBox"><span className='title'>Questions</span> {editingIndex !== -1 && <span className='titleBtn' onClick={handleImportQuestion}>Import Questions</span>}</div>
                    {allQuestions?.map((que, i) => (
                        <span className='questionBox'>
                            <span className='questionBox1'>
                                <span className='questionTop'>
                                    <span><span className='bold'>Que Type:</span> {que?.questionType}</span>
                                    <span><span className='bold'>Difficulty Level:</span> {que?.difficultyLevel}</span>
                                </span>
                                <span className='questionText'><span className='bold'>Que.</span> {que?.questionText}</span>
                            </span>
                            <span className='iconBox'>
                                <img src={editIcon} onClick={() => {
                                    setOpenBasic(true);
                                    setQueEditingIndex(i);
                                    setEditingQuestionId(que?.id);
                                    setEditingQuestionData(que);
                                }} />
                                <img src={deleteIcon} onClick={() => handleDeleteQue(que?.id)} />
                            </span>
                        </span>
                    ))}
                </QueBox>

                <div className='btnBox'>
                    <Button type="submit" disabled={loading}>
                        {isReview ? "Save Changes" : editingIndex === -1 ? 'Create Test' : 'Update Test'}
                    </Button>
                    {isReview && <Button disabled={loading} onClick={() => handlePublishTest()}>
                        Publish Test
                    </Button>}
                </div>
            </Form>
        </Container>
    );
}

export default CreateTestsForm;
