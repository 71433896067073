import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import styled from "styled-components";
import { sendInvite } from "../../../../functions/api/employers/match/sendInvite";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Autocomplete, IconButton, Paper, ratingClasses } from "@mui/material";
import moment from "moment-timezone";
import LogoHeader from "../../../commonComponents/LogoHeader";
import InviteSteps from "./InviteSteps";
import InviteReviewList from "./InviteReviewList";
import { TextField } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import TimeSlot from "../commonComponents/TimeSlot";
import { Stack } from "@mui/system";
import { technicalSkills } from "../../../../utils/contantData";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useDispatch } from "react-redux";
import { updateInviteStatus } from "../../../../slices/invitationSlice";
import AttachTestBox from "./AttachTestBox";
import NumberCounter from "../commonComponents/NumberCounter";
import ConfigurableBackdropModal from "../../SeekerDashboard/seekerCommonComponents/ConfigurableBackdropModal";
import Loader from "../../../commonComponents/Loader";
import InPersonModalTabs from "./InPersonModalTabs";
import { setCurrentTrackerTab } from "../../../../slices/configSlice";
import InterviewDurationConfig from "../commonComponents/InterviewDurationConfig";

const filter = createFilterOptions();

export default function Invite() {
  const { jdId } = useParams();
  const [value, setValue] = useState(dayjs(new Date()));
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(dayjs(new Date()));
  const [productTypes, setProductTypes] = useState([]);
  const [testTypes, setTestTypes] = useState([]);
  const [productType, setProductType] = useState("");
  const [interviewType, setInterviewType] = useState('');
  const [difficultyLevel, setDifficultyLevel] = useState('');
  const [numberOfQue, setNumberOfQue] = useState(0);
  const [interviewerEmail, setInterviewerEmail] = useState('');
  const [meetUrl, setMeetUrl] = useState('');
  const [testType, setTestType] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [interviewDuration, setInterviewDuration] = useState({ hours: 1, minutes: 0 });

  const accessToken = useSelector((state) => state.auth.userData.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData.user.clientCode
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isTime, setIsTime] = useState(false);
  const [step, setStep] = useState(1);

  const [isTestAttached, setIsTestAttached] = useState(false);
  const [attachedTestData, setAttachedTestData] = useState(null);

  // State for managing mix testType, where it will contain Subjective, Coding, MCQ.
  const [subjectiveQueNo, setSubjectiveQueNo] = useState(0);
  const [codingQueNo, setCodingQueNo] = useState(0);
  const [mcqQueNo, setMcqQueNo] = useState(0);
  const [isIncludeAptitude, setIsIncludeAptitude] = useState(false);
  const [isIncludeCulturalfit, setIsIncludeCulturalfit] = useState(false);
  const [autoGenerateMeet, setAutoGenerateMeet] = useState(false);
  const [isTestTypeInPerson, setIsTestTypeInPerson] = useState(false);
  const [loading, setLoading] = useState(false);

  const minDate = dayjs().startOf('day'); // Prevent going to previous months

  useEffect(() => {
    dispatch(setCurrentTrackerTab("MATCHED"));
  }, [dispatch])

  const handleCheckboxChange = () => {
    setIsTime(!isTime);
  };

  const handleIncludeAptitudeChange = () => {
    setIsIncludeAptitude(!isIncludeAptitude);
  }

  const handleIncludeCulturalfitChange = () => {
    setIsIncludeCulturalfit(!isIncludeCulturalfit);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    if (testType == 'InPerson') {
      setIsTestTypeInPerson(true);
    } else {
      setIsTestTypeInPerson(false);
    }
  }, [testType])

  useEffect(() => {
    if (!accessToken || !clientCode) {
      toast.error("Login First");
      navigate("/login");
    }
    setProductTypes(["JD", "Resume", "JD + Resume", "Skill"]);
    setTestTypes(["mcq", "general", "coding"]);
  }, []);

  const handleSkillsChange = (_, newSkills) => {
    setSelectedSkills(newSkills);
  };

  const handleInputBlur = () => {
    if (inputValue.trim() !== '') {
      setSelectedSkills([...selectedSkills, inputValue?.trim()]);
      setInputValue('');
    }
  };

  const handleProductTypeChange = (inp) => {
    setProductType(inp);
  };

  const handleTestTypeChange = (inp) => {
    setTestType(inp);
    setNumberOfQue(0);
    setSubjectiveQueNo(0);
    setCodingQueNo(0);
    setMcqQueNo(0);
  };

  const handleInterviewTypeChange = (inp) => {
    setInterviewType(inp);
  }

  const selectedResume = useSelector((state) => state?.invite?.selectedResumes);
  const selectedJd = useSelector((state) => state.invite?.selectedJd);

  const jdIdToIdsMap = selectedResume.reduce((accumulator, resume) => {
    const key = resume?.jdId || selectedJd;
    if (!accumulator[key]) {
      accumulator[key] = [];
    }
    if (!accumulator[key].includes(resume.id)) {
      accumulator[key].push(resume.id);
    }
    return accumulator;
  }, {});

  
  const handleInvite = async () => {
    setLoading(true)
    const makeApiCall = async (jdId) => {
      const dateTime = moment(value?.format("YYYY-MM-DD") + "T" + (selectedTimeSlot.$H < 10 ? '0' + selectedTimeSlot.$H : selectedTimeSlot.$H) + ":" + (selectedTimeSlot.$m < 10 ? '0' + selectedTimeSlot.$m : selectedTimeSlot.$m) + ":" + "00.000").utc().format('YYYY-MM-DD HH:mm');
      const date = dateTime?.slice(0, 10);
      const time = dateTime?.slice(11);

      const durationInMin = (interviewDuration?.hours*60) + interviewDuration?.minutes;

      const payload = {
        jdId: jdId,
        productType: productType,
        trackerIds: jdIdToIdsMap[jdId],
        testType: testType,
        interviewType: interviewType,
        noOfQuestions: numberOfQue,
        difficultyLevel: difficultyLevel,
        slotDate: date,
        slotTime: time,
        timeZone: "UTC",
        welcomeMessage: "string",
        durationInMins: durationInMin,
      };

      const mixQuestionTestPayload = {
        jdId: jdId,
        productType: productType,
        trackerIds: jdIdToIdsMap[jdId],
        testType: testType,
        interviewType: interviewType,
        difficultyLevel: difficultyLevel,
        questionDetails: `${subjectiveQueNo},${mcqQueNo},${codingQueNo}`,
        slotDate: date,
        slotTime: time,
        timeZone: "UTC",
        welcomeMessage: "string",
        durationInMins: durationInMin,
      }

      const employerTestPayload = {
        jdId: jdId,
        productType: productType,
        trackerIds: jdIdToIdsMap[jdId],
        testType: attachedTestData?.testType,
        slotDate: date,
        slotTime: time,
        timeZone: "UTC",
        welcomeMessage: "string",
        durationInMins: durationInMin,
      }

      if (isTime) {
        {
          productType === "Employer" ? delete employerTestPayload?.slotTime : delete payload?.slotTime;
        }
      }
      if (testType == 'InPerson' && productType === "Employer") {
        employerTestPayload.interviewerEmail = interviewerEmail;
        if (!autoGenerateMeet) {
          employerTestPayload.meetingLink = meetUrl;
        } else {
          delete employerTestPayload?.meetingLink;
        }
        delete employerTestPayload?.difficultyLevel;
        delete employerTestPayload?.noOfQuestions;

      } else if (testType == 'InPerson' && productType !== "Employer") {
        payload.interviewerEmail = interviewerEmail;
        if (!autoGenerateMeet) {
          payload.meetingLink = meetUrl;
        } else {
          delete payload?.meetingLink;
        }
        delete payload?.difficultyLevel;
        delete payload?.noOfQuestions;
      }

      try {
        const response = await sendInvite(productType === "Employer" ? employerTestPayload : testType === "mix" ? mixQuestionTestPayload : payload, accessToken, clientCode);
        return response;
      } catch (error) {
        throw error;
      }
    };

    try {
      const jdIds = Object.keys(jdIdToIdsMap);
      const promises = jdIds?.map(jdId => makeApiCall(jdId));
      const responses = await Promise.allSettled(promises);

      responses.forEach((result, index) => {
        if (result?.status === 'fulfilled') {
          result?.value?.data?.forEach((jdData, i) => {
            const ids = jdIdToIdsMap[jdData?.jdId];
            if (ids) {
              ids?.forEach((id) => {
                dispatch(updateInviteStatus({ id: id, status: { status: result?.value?.status, msg: 'Invite Sent' } }));
              })
            } else {
              console.error(`No ids found for jdId: ${jdData?.jdId}`);
            }
          });

        } else {
          const error = result?.reason?.response?.data;
          JSON.parse(result?.reason?.config?.data)?.trackerIds?.forEach((id, i) => {
            dispatch(updateInviteStatus({ id: id, status: { status: error?.status, msg: error?.notify?.message } }))
          })
        }
      });

      const allSettled = responses.every(result => result.status !== 'pending');
      if (allSettled) {
        setLoading(false);
        handleSuccessFunc();
      }
    } catch (error) {
      setLoading(false);
      const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };


  const handlePrev = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  }


  const handleNext = () => {
    if (step < 3) {
      if (step === 2) {
        if (dayjs(new Date()).$D > value.$D && dayjs(new Date()).$M >= value.$M && dayjs(new Date()).$y >= value.$y) {
          toast.warning("Please select future dates");
          return;
        } else if (clientCode !== "CHIRAG03" && !isTime && (dayjs(new Date()).$D === value.$D && dayjs(new Date()).$M === value.$M && dayjs(new Date()).$y === value.$y) && (((dayjs(new Date()).$H * 60) + (dayjs(new Date()).$m) + 60) > ((selectedTimeSlot?.$H * 60) + selectedTimeSlot?.$m))) {
          toast.warning("Slot should be 60 minutes after current date and time", 8000)
          return;
        } else if (!isTime && ((dayjs(new Date()).$D == value.$D && dayjs(new Date()).$M == value.$M) && (dayjs(new Date()).$H >= selectedTimeSlot.$H && dayjs(new Date()).$m >= selectedTimeSlot.$m))) {
          toast.warning("Please select future time slots");
          return;
        }
      } else if (step === 1) {
        if (productType === "Employer") {
          if (!isTestAttached || attachedTestData === null) {
            toast.warning("Please Attach Test to Proceed with Employer test", 5000)
            return;
          }
        } else {
          if (productType?.trim()?.length == 0) {
            toast.warning("Please select product type", 5000)
            return;
          } else if (interviewType?.trim()?.length == 0) {
            toast.warning("Please select interview based on", 5000)
            return;
          } else if (testType?.trim()?.length == 0) {
            toast.warning("Please select interview type", 5000)
            return;
          } else if (testType !== 'InPerson' && difficultyLevel?.trim()?.length == 0) {
            toast.warning("Please select difficulty level", 5000)
            return;
          } else if ((testType !== 'mix' && testType !== 'InPerson') && numberOfQue == 0) {
            toast.warning("Please select number of Questions", 5000)
            return;
          } else if (testType === 'mix' && (subjectiveQueNo === 0 && codingQueNo === 0 && mcqQueNo === 0)) {
            toast.warning("Please select number of Questions", 5000)
            return;
          } else if (testType === 'InPerson' && interviewerEmail?.trim()?.length === 0) {
            toast.warning("Interviewer Email is mandatory", 5000)
            return;
          } else if (testType === 'InPerson' && !isValidEmail(interviewerEmail)) {
            toast.warning("Please enter a valid interviewer email", 5000)
            return;
          } else if (testType === 'InPerson' && meetUrl?.trim()?.length === 0 && !autoGenerateMeet) {
            toast.warning("Meet link is mandatory", 5000)
            return;
          }
        }
      }
      setStep(step + 1);
    }
  }

  const handleSuccessFunc = () => {
    toast.success("Invite sent", 5000);
    navigate("/schedule/invite/success");
  }

  const handleIncreaseNumber = () => {
    if (numberOfQue < 5 && testType === 'coding') {
      setNumberOfQue(prevNum => prevNum + 1);
    } else if (numberOfQue < 30 && testType !== 'coding') {
      setNumberOfQue(prevNum => prevNum + 1);
    }
  }

  const handleDecreaseNumber = () => {
    if (numberOfQue > 0) {
      setNumberOfQue(prevNum => prevNum - 1);
    }
  }

  const handleIncreaseMultipleNumber = (questionType) => {
    if (questionType === 'subjective' && subjectiveQueNo < 30) {
      setSubjectiveQueNo(prevNum => prevNum + 1);
    } else if (questionType === 'coding' && codingQueNo < 30) {
      setCodingQueNo(prevNum => prevNum + 1);
    } else if (questionType === 'mcq' && mcqQueNo < 30) {
      setMcqQueNo(prevNum => prevNum + 1);
    }
  }

  const handleDecreaseMultipleNumber = (questionType) => {
    if (questionType === 'subjective') {
      if (subjectiveQueNo > 0) {
        setSubjectiveQueNo(prevNum => prevNum - 1);
      }
    } else if (questionType === 'coding') {
      if (codingQueNo > 0) {
        setCodingQueNo(prevNum => prevNum - 1);
      }
    } else if (questionType === 'mcq') {
      if (mcqQueNo > 0) {
        setMcqQueNo(prevNum => prevNum - 1);
      }
    }
  }

  // Function to check if a string is a valid email address
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  // Function to check if a string is a valid URL
  const isValidUrl = (url) => {
    const urlRegex = /^(https?:\/\/)?([^\s\/]+\.[^\s\/]+)+$/;
    return urlRegex.test(url);
  }

  const productParamsData = {
    productType,
    testType,
    interviewType,
    difficultyLevel,
    numberOfQue
  };

  return (
    <>
      <MainContainer>
        {loading && <Loader message="Sending Invites..." />}
        {isTestTypeInPerson && <ConfigurableBackdropModal component={<InPersonModalTabs handleClose={() => setIsTestTypeInPerson(false)} />} open={isTestTypeInPerson} setOpen={setIsTestTypeInPerson} style={{ width: '90%', height: '85%' }} />}
        <LogoHeader />
        <Container>
          <InviteSteps step={step} setStep={setStep} />
          <IconButton onClick={() => navigate(`/schedule/matches/${jdId}`)} className="prev">
            <ArrowBackIcon sx={{ fontSize: "30px" }} />
          </IconButton>
          <div className="mainBox">
            {step === 2 &&
              <div className="step1Box">
                <div className="step1ChildBox">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="calendarBox">
                      <DateCalendar
                        value={value}
                        onChange={(newValue) => setValue(dayjs(newValue))}
                        views={['day']}
                        minDate={minDate} // Disables navigation to previous months
                        sx={{
                          height: '100%',
                          display: 'flex',
                        }}
                      />
                    </div>
                  </LocalizationProvider>

                  <div className="slotBox">
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                      <DemoContainer components={['TimePicker', 'TimePicker']} className='slotChildBox' sx={{ width: '40%' }}>
                        {!isTime && <TimeSlot
                          selectedTimeSlot={selectedTimeSlot}
                          setSelectedTimeSlot={setSelectedTimeSlot}
                          selectedDate={moment(value?.format("YYYY-MM-DD") + "T" + (selectedTimeSlot.$H < 10 ? '0' + selectedTimeSlot.$H : selectedTimeSlot.$H) + ":" + (selectedTimeSlot.$m < 10 ? '0' + selectedTimeSlot.$m : selectedTimeSlot.$m) + ":" + "00.000").format('YYYY-MM-DD HH:mm')?.slice(0, 10)}
                          isReschedule={false}
                        />}
                      </DemoContainer>
                    </LocalizationProvider>
                    {testType !== "InPerson" && <label className="smallTextBox">
                      <input
                        type="checkbox"
                        checked={isTime}
                        onChange={handleCheckboxChange}
                      />
                      <span className="smallText">Allow slot selection to candidate (Interview Date will be fixed)</span>
                    </label>}
                  </div>
                </div>
              </div>
            }

            {step === 1 &&
              <div className="step2Box">
                <div className="inputBox">
                  <span className="title">Product Type</span>
                  <div className="childInputBox">
                    <label className="label">
                      <input
                        type="radio"
                        value="AI"
                        checked={productType === 'AI'}
                        onChange={() => handleProductTypeChange('AI')}
                      />
                      <span>AI</span>
                    </label>
                    <label className="label">
                      <input
                        type="radio"
                        value="Employer"
                        checked={productType === 'Employer'}
                        onChange={() => handleProductTypeChange('Employer')}
                      />
                      <span>Employer</span>
                    </label>
                    <label className="label">
                      <input
                        type="radio"
                        value="AI + Employer"
                        checked={productType === 'AI + Employer'}
                        onChange={() => handleProductTypeChange('AI + Employer')}
                      />
                      <span>AI + Employer</span>
                    </label>
                  </div>
                </div>

                {productType === "Employer"
                  ?
                  <div className="childBox1">

                    <AttachTestBox jdId={jdId} setAttachedTestData={setAttachedTestData} setIsTestAttached={setIsTestAttached} />

                  </div>
                  :
                  <div className="childBox2">
                    <div className="inputBox">
                      <span className="title">Interview Based on</span>
                      <div className="childInputBox">
                        <label className="label">
                          <input
                            type="radio"
                            value="JD"
                            checked={interviewType === 'JD'}
                            onChange={() => handleInterviewTypeChange('JD')}
                          />
                          <span>JD</span>
                        </label>
                        <label className="label">
                          <input
                            type="radio"
                            value="Resume"
                            checked={interviewType === 'Resume'}
                            onChange={() => handleInterviewTypeChange('Resume')}
                          />
                          <span>Resume</span>
                        </label>
                        <label className="label">
                          <input
                            type="radio"
                            value="JD + Resume"
                            checked={interviewType === 'JD + Resume'}
                            onChange={() => handleInterviewTypeChange('JD + Resume')}
                          />
                          <span>JD + Resume</span>
                        </label>
                        <label className="label">
                          <input
                            type="radio"
                            value="Skill"
                            checked={interviewType === 'Skill'}
                            onChange={() => handleInterviewTypeChange('Skill')}
                          />
                          <span>Skill</span>
                        </label>
                      </div>
                    </div>

                    {interviewType === 'Skill' &&
                      <div className="textBox">
                        <Stack spacing={3} sx={{ width: '100%', marginBottom: '1rem' }}>
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            options={technicalSkills}
                            getOptionLabel={(option) => option}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);
                              const { inputValue } = params;
                              const isExisting = options.some((option) => inputValue === option);

                              if (inputValue !== '' && !isExisting) {
                                filtered.push(inputValue);
                              }

                              return filtered;
                            }}
                            onChange={handleSkillsChange}
                            value={selectedSkills}
                            selectOnFocus
                            clearOnBlur
                            freeSolo
                            onInputChange={(event, newInputValue) => {
                              setInputValue(newInputValue);
                            }}
                            onBlur={handleInputBlur}
                            PaperComponent={(props) => <Paper {...props} sx={{ backgroundColor: '#F0F0F6' }} />}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                placeholder="Skills..."

                              />
                            )}
                          />
                        </Stack>
                      </div>}

                    <div className="inputBox">
                      <span className="title">Interview Type</span>
                      <div className="childInputBox">
                        <label className="label">
                          <input
                            type="radio"
                            value="mcq"
                            checked={testType === 'mcq'}
                            onChange={() => handleTestTypeChange('mcq')}
                          />
                          <span>MCQs</span>
                        </label>
                        <label className="label">
                          <input
                            type="radio"
                            value="general"
                            checked={testType === 'general'}
                            onChange={() => handleTestTypeChange('general')}
                          />
                          <span>Subjective</span>
                        </label>
                        <label className="label">
                          <input
                            type="radio"
                            value="coding"
                            checked={testType === 'coding'}
                            onChange={() => handleTestTypeChange('coding')}
                          />
                          <span>Coding</span>
                        </label>
                        <label className="label">
                          <input
                            type="radio"
                            value="mix"
                            checked={testType === 'mix'}
                            onChange={() => handleTestTypeChange('mix')}
                          />
                          <span>General (Includes all types of Questions)</span>
                        </label>
                        <label className="label">
                          <input
                            type="radio"
                            value="InPerson"
                            checked={testType === 'InPerson'}
                            onChange={() => handleTestTypeChange('InPerson')}
                          />
                          <span>In Person</span>
                        </label>
                        <div className="checkBox">
                          <label className="extraCheckBox">
                            <input
                              type="checkbox"
                              checked={isIncludeAptitude}
                              onChange={handleIncludeAptitudeChange}
                            />
                            <span>Include Aptitude Interview</span>
                          </label>
                          <label className="extraCheckBox">
                            <input
                              type="checkbox"
                              checked={isIncludeCulturalfit}
                              onChange={handleIncludeCulturalfitChange}
                            />
                            <span>Include Cultural fit check</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    {testType === 'InPerson' &&
                      <>
                        <div className="textBox" style={{ alignItems: "center" }}>
                          <TextField id="outlined-basic" label="Interviewer Email" variant="outlined" fullWidth
                            type='email'
                            value={interviewerEmail}
                            onChange={(e) => setInterviewerEmail(e.target.value)}
                            inputProps={{
                              sx: {
                                color: '#626264',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: '#626264',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                              },
                            }}
                            required
                          />
                          <label className="smallTextBox">
                            <input
                              type="checkbox"
                              checked={autoGenerateMeet}
                              onChange={() => setAutoGenerateMeet(!autoGenerateMeet)}
                            />
                            <span className="smallText">Auto-generate google meet link</span>
                          </label>
                        </div>

                        <div className="textBox" style={{ width: "47.5%", alignSelf: "start" }}>
                          {!autoGenerateMeet && <TextField id="outlined-basic" label="Meet Link" variant="outlined" fullWidth
                            type='url'
                            value={meetUrl}
                            onChange={(e) => setMeetUrl(e.target.value)}
                            inputProps={{
                              sx: {
                                color: '#626264',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: '#626264',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                              },
                            }}
                            required
                          />}
                        </div>
                      </>
                    }

                    {testType !== 'InPerson' && <div className="textBox">
                      <div className="inputBox">
                        <span className="title">Difficulty Level</span>
                        <div className="childInputBox">
                          <label className="label">
                            <input
                              type="radio"
                              value="easy"
                              checked={difficultyLevel === 'easy'}
                              onChange={() => setDifficultyLevel('easy')}
                            />
                            <span>Easy</span>
                          </label>
                          <label className="label">
                            <input
                              type="radio"
                              value="moderate"
                              checked={difficultyLevel === 'moderate'}
                              onChange={() => setDifficultyLevel('moderate')}
                            />
                            <span>Moderate</span>
                          </label>
                          <label className="label">
                            <input
                              type="radio"
                              value="difficult"
                              checked={difficultyLevel === 'difficult'}
                              onChange={() => setDifficultyLevel('difficult')}
                            />
                            <span>Difficult</span>
                          </label>
                        </div>
                      </div>

                      {testType !== 'mix' && <div className="numberMainBox">
                        <label className="label">Number of Questions</label>
                        <NumberCounter handleDecreaseNumber={handleDecreaseNumber} handleIncreaseNumber={handleIncreaseNumber} value={numberOfQue} handleChange={(value) => setNumberOfQue(value)} testType={testType} style={{ width: '40%' }} />
                      </div>}
                    </div>}

                    {testType === 'mix' &&
                      <div className="inputBox">
                        <span className="title">Number of Questions</span>
                        <div className="childInputBox multipleNumberMainBox">
                          <div className="queTypeCard">
                            <label className="label">Subjective</label>
                            <NumberCounter handleDecreaseNumber={() => handleDecreaseMultipleNumber('subjective')} handleIncreaseNumber={() => handleIncreaseMultipleNumber('subjective')} value={subjectiveQueNo} handleChange={(value) => setSubjectiveQueNo(value)} testType={testType} style={{ width: '75%' }} />
                          </div>
                          <div className="queTypeCard">
                            <label className="label">Coding</label>
                            <NumberCounter handleDecreaseNumber={() => handleDecreaseMultipleNumber('coding')} handleIncreaseNumber={() => handleIncreaseMultipleNumber('coding')} value={codingQueNo} handleChange={(value) => setCodingQueNo(value)} testType={testType} style={{ width: '75%' }} />
                          </div>
                          <div className="queTypeCard">
                            <label className="label">MCQ</label>
                            <NumberCounter handleDecreaseNumber={() => handleDecreaseMultipleNumber('mcq')} handleIncreaseNumber={() => handleIncreaseMultipleNumber('mcq')} value={mcqQueNo} handleChange={(value) => setMcqQueNo(value)} testType={testType} style={{ width: '75%' }} />
                          </div>
                        </div>
                      </div>
                    }
                  </div>}
                  <InterviewDurationConfig duration={interviewDuration} setDuration={setInterviewDuration} />
              </div>
            }

            {
              step === 3 &&
              <div className="step3Box">
                <InviteReviewList data={productParamsData} />
              </div>
            }
          </div>

          <ButtonBox>
            {step <= 2 && <Button onClick={() => handleNext()}>Next</Button>}
            {step === 3 && <Button onClick={handleInvite} disabled={loading}>Send Invite</Button>}
          </ButtonBox>
        </Container>
      </MainContainer>
    </>
  );
}


const MainContainer = styled.div`



`


const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6.5rem 3rem 2rem 3rem;
  align-items: center;
  width: calc(100% - 6rem);
  // height: calc(100vh - 8rem);
  justify-content: center;

  /* Hide the up and down arrows in number input */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.numberMainBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: -0.75rem;

  .label {
    font-size: 0.9rem;
    font-weight: 500;
  }
}

  .prev {
    background-color: var(--lightOrange);
    padding: 0.1rem;
    position: fixed;
    top: 5rem;
    left: 1.5rem;
    color: var(--white);
    font-family: var(--font);
  }

  .prev:hover {
    color: var(--color);
  }

  .btn {
    padding: 0.5rem 1rem;
    margin-top: 0rem;
    background-color: var(--lightOrange);
    border: none;
    color: var(--white);
    font-size: 1.1rem;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: pointer;
    font-family: var(--font);
  }

  .smallTextBox {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: start;
    padding-left: 2rem;
  }

  .textBox {
    display: flex;
    align-items: start;
    width: 100%;
    gap: 2rem;

  }
  
  .smallText {
    font-size: 0.8rem;
  }

  .mainBox {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    align-items: center;
    width: 100%;


    .step1Box {
      display: flex;
      width: 50%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      margin-top: 3rem;

      .step1ChildBox {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
      }

      .calendarBox {
        border: 0.08rem solid lightgrey;
        border-radius: 0.5rem;
        width: 100%;
      }

      .slotBox {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .slotChildBox {
          width: 50%;
        }

        .smallTextBox {
          width: 50%;
          display: flex;
          align-items: start;
          gap: 1rem;
          height: 2rem;
        }
      }


    }

    .step2Box {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      padding: 0rem 1rem;
      margin-top: 4rem;
      margin-bottom: 0rem;
      box-sizing: border-box;

      .childBox1 {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        padding: 0rem;
        margin-bottom: 1rem;
        box-sizing: border-box;
      }

      .childBox2 {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        padding: 0rem;
        margin-bottom: 0rem;
        box-sizing: border-box;
      }

      .inputBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        border: 0.08rem solid #C4C4C4;
        padding: 1rem 1rem;
        border-radius: 0.3rem;
        position: relative;
        box-sizing: border-box;
      }

      .childInputBox {
        display: flex;
        gap: 0.5rem;
        flex-flow: row wrap;

        .checkBox {
         display: flex;
         flex-direction: column;
         gap: 0.5rem;

        }

        .extraCheckBox {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          cursor: pointer;

          input {
            cursor: pointer;
          }

          span {
            align-items: center;
            font-size: 0.9rem;
            font-weight: 500;
            font-family: var(--font);
          }
        }
      }

      .multipleNumberMainBox {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        .label {
          font-size: 0.9rem;
      }
      
        .queTypeCard {
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
          align-items: center;
          width: 25%;
        }
      }

      .title {
        font-size: 0.9rem;
        font-weight: 600;
        position: absolute;
        top: -0.8rem;
        background-color: var(--white);
        padding: 0 0.3rem;
      }
    }


    .step3Box {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    
  }
  
.label {
	display: flex;
	cursor: pointer;
	font-weight: 500;
	position: relative;
	margin-bottom: 0rem;

	input {
		position: absolute;
		left: -9999px;
		&:checked + span {
			background-color: #f0f0f6;
			&:before {
				box-shadow: inset 0 0 0 0.3rem var(--lightOrange);
			}
		}
	}
	span {
		display: flex;
		align-items: center;
    font-size: 0.9rem;
		padding: 0.3rem 0.75rem 0.3rem 0.3rem;
		border-radius: 99rem; 
		transition: 0.25s ease;
		&:hover {
			background-color: mix(#fff, var(--lightOrange), 84%);
		}
		&:before {
			display: flex;
			flex-shrink: 0;
			content: "";
			background-color: #fff;
			width: 1rem;
			height: 1rem;
			border-radius: 50%;
			margin-right: 0.375em;
			transition: 0.25s ease;
			box-shadow: inset 0 0 0 0.125em var(--lightOrange);
		}
	}
}
`;



const ButtonBox = styled.div`
display: flex;
margin: 2rem 0;
gap: 2rem;


`


const Button = styled.button`
background-color: var(--lightOrange);
color: var(--white);
border: none;
padding: 0.5rem 0.8rem;
font-size: 0.9rem;
font-weight: 600;
border-radius: 0.5rem;
cursor: pointer;
font-family: var(--font);

`

