import React from 'react';
import styled from 'styled-components';
import img1 from '../../../assets/Academic/aiMock.webp'
import img2 from '../../../assets/Academic/performanceAnalytics.webp'
import img3 from '../../../assets/Academic/personalizedRecomendation.webp'
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

const AIPracticeSectionWrapper = styled.section`
  padding: 2rem;.
  background: rgb(239,236,247);
background: linear-gradient(90deg, rgba(239,236,247,1) 0%, rgba(239,236,247,1) 35%, rgba(168,162,226,1) 100%);
  // background-color: #d4edda;
  text-align: center;

  h2 {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
  }

  p {
    font-size: 1rem;
    font-weight: 500;
  }
`;

const Features = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 2rem 0;
`;

const Feature = styled.div`
  flex: 1;
  margin: 1rem;
  padding: 2.5rem 1.5rem;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h3 {
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0;
  }

  .img {
    width: 5rem;
    height: 5rem;
  }
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: #515290;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #442f90;
  }
`;



const AIPracticeToolsSection = () => {
  const accessToken = useSelector(state => state.auth.userData?.accessToken);
  const Navigate = useNavigate();

  const handleLinkClick = (name) => {
    if (accessToken) {
      window.location.href = `/academic/practice-test/${name}/all`;
    } else {
      Navigate("/academic/login")
    }
  };


  return (<AIPracticeSectionWrapper>
    <h2>Enhance Your Preparation with AI</h2>
    <p>Leverage our cutting-edge AI technology to get personalized practice tests, mock exams, and performance analytics.</p>
    <Features>
      <Feature>
        <img src={img1} className='img' />
        <h3>AI Mock Tests</h3>
        <p>Simulate real exam conditions with adaptive AI tests that adjust to your skill level. These tests mimic the actual exam format, helping you get used to the pressure and timing of the real test.</p>
      </Feature>
      <Feature>
        <img src={img2} className='img' />
        <h3>Performance Analytics</h3>
        <p>Receive detailed reports that analyze your performance in various subjects. These analytics help you identify strengths and areas for improvement, allowing you to focus your study efforts more effectively.</p>
      </Feature>
      <Feature>
        <img src={img3} className='img' />
        <h3>Personalized Recommendations</h3>
        <p>Get personalized study tips and resources based on your performance data. These recommendations help you address weaknesses and build on strengths, optimizing your study plan.</p>
      </Feature>
    </Features>
    <Button onClick={() => handleLinkClick("Middle School")}>Start Practicing with AI</Button>
  </AIPracticeSectionWrapper>
  )
};

export default AIPracticeToolsSection;
