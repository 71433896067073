import React, { useState } from 'react';
import styled from 'styled-components'

const Button = ({ text, func, disabled = false, width, bgColor  }) => {

    return (
        <ButtonBox onClick={func} disabled={disabled} style={{ width: width ? width : '' }} bgColor={bgColor}>{text}</ButtonBox>
    )
}

export default Button;

const ButtonBox = styled.button`
    background-color: ${({ bgColor }) => bgColor || 'var(--lightOrange)'};
    color: ${(props) => (props.disabled ? '#e9e9e9' : 'var(--white)')};
    border-radius: 0.25rem;
    font-size: 0.9rem;
    font-weight: 600;
    border: none;
    cursor: ${(props) => (props.disabled ? '' : 'pointer')};
    padding: 0.5rem 0.75rem;
    display: flex;
    justify-content: center;
    white-space: nowrap
`

