import React from 'react'
import styled from 'styled-components'
import PerformanceDetailTable from './PerformanceDetailTable'

const PerformanceDrawerContent = ({data, rowData}) => {
    return (
        <Box>
            <TopBox>
                <span className='title'><b>No of Tests attempted: </b> {data?.length}</span>
                <span className='title'><b>UserName: </b> {rowData?.userName}</span>
            </TopBox>
            <ContentBox>
                <PerformanceDetailTable data={data}/>
            </ContentBox>
        </Box>
    )
}

export default PerformanceDrawerContent

const Box = styled.div`
display: flex;
flex-direction: column;
gap: 1rem;



`

const TopBox = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;

.title {
border: 0.1rem solid var(--academicLavendar);
border-radius: 0.5rem;
padding: 0.8rem 1rem;
}


`

const ContentBox = styled.div`
display: flex;
flex-direction: column;
gap: 0.5rem;



`