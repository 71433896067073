import axios from "axios"

export const publicCreateIssue = async (clientCode, data) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Client-Code': clientCode,
        }
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/jira/createIssue`, data, config);
        console.log('Data:', response.data);
        console.log("Status", response.status)
        return response.data;
    } catch (error) {
        throw error;
    }
}

