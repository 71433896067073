import React from 'react';
import styled from 'styled-components';

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  
`;

const Checkbox = ({ checked, onChange, label }) => {
    return (
        <CheckboxLabel>
            <input type="checkbox" checked={checked} onChange={onChange} />
            {label}
        </CheckboxLabel>
    );
};

export default Checkbox;
