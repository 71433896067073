import axios from "axios";



export const getAllTrackers = async (accessToken,clientCode, page=1, size=1000000, jdId, interviewStatus, trackerStatus) => {

const config = {
  headers: {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
    'x-client-code': clientCode

  }
};

let url = `${process.env.REACT_APP_API_URL}/api/interviews/trackers?jdId=${jdId}&page=${page}&size=${size}&interviewStatus=${interviewStatus}&trackerStatus=${trackerStatus}`;
if(!jdId)url = `${process.env.REACT_APP_API_URL}/api/interviews/trackers?page=${page}&size=${size}&interviewStatus=${interviewStatus}&trackerStatus=${trackerStatus}`;

    try {
        const response = await axios.get(url,config);
        console.log('Data:', response.data);
        console.log('Status:', response.status);
        return response.data;
      } catch (error) {
        throw error;
      }
}


