import React, {useState} from 'react'
import styled from 'styled-components'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AvailableTest from './AvailableTest';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const AvailableTestTabs = () => {
    const [currentTab, setCurrentTab] = useState("PUBLISHED");

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    }

    return (
        <Box>
            <Tabs
                style={{
                    width: '40%',
                    borderRadius: '3rem',
                    backgroundColor: 'var(--lightOrange)'
                }}
                value={currentTab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: "var(--lightOrange)",
                    },
                }}
                variant="fullWidth"
                aria-label="wrapped label tabs example"
            >
                <Tab
                    value="PUBLISHED"
                    label="Published Tests"
                    sx={{
                        color: "var(--lightOrange)",
                        fontSize: "0.8rem",
                    }}
                    classes={{ root: 'custom-tab', selected: 'custom-tab-selected' }}
                />
                <Tab
                    value="DRAFT"
                    label="Draft Tests"
                    sx={{
                        color: "var(--lightOrange)",
                        fontSize: "0.8rem",
                    }}
                    classes={{ root: 'custom-tab', selected: 'custom-tab-selected' }}
                />
            </Tabs>
            {currentTab === "PUBLISHED" && <AvailableTest published={true}/> }
            {currentTab === "DRAFT" && <AvailableTest published={false}/>}
        </Box>
    )
}

export default AvailableTestTabs

const Box = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-top: 2rem;
width: 100%;

    .custom-tab {
      color: white;
      background-color: var(--lightOrange);
      transition: background-color 0.3s;
      text-decoration: none !important;
    }

.custom-tab-selected {
    background-color: white;
    color: var(--lightOrange) !important;
    border: 0.1rem solid var(--lightOrange);
    border-radius: 3rem;
    text-decoration: none !important;
  }

.custom-tab-selected .MuiTab-label {
  text-transform: none;
}
`