import React, { useState } from 'react'
import styled from 'styled-components'
import DemoHeader from '../components/commonComponents/DemoHeader'
import Footer from '../components/commonComponents/Footer'
import demoPageImg from '../assets/demoPageImg.webp'
import { Box, CircularProgress, MenuItem, TextField } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import { publicCreateIssue } from '../functions/api/AtlassianAPIs/publicCreateIssue'
import { requestProductDemo } from '../functions/api/globalFunctions/requestProductDemo'
import { toast } from 'react-toastify'
import ProductDemoSuccessMessage from '../components/commonComponents/InfoMessageComp/ProductDemoSuccessMessage'

const Demo = () => {
    const [clientName, setClientName] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [clientContact, setClientContact] = useState();
    const [companyName, setCompanyName] = useState('');
    const [companyWebsiteUrl, setCompanyWebsiteUrl] = useState('');
    const [numberOfEmployees, setNumberOfEmployees] = useState('');
    const [comments, setComments] = useState('');
    const [loading, setLoading] = useState(false);

    const [success, setSuccess] = useState(false);


    const handleContactChange = (newInput) => {
        setClientContact(newInput)
    }

    const handleSubmit = async () => {

        if (clientName === '' || clientName.length === 0) {
            toast.warning("Please fill Name", 5000);
            return;
        }
        if (clientEmail === '' || clientEmail.length === 0) {
            toast.warning("Please fill Email", 5000);
            return;
        }
        if (companyName === '' || companyName.length === 0) {
            toast.warning("Please fill Organization Name", 5000);
            return;
        }

        setLoading(true);
        try {
            const emailPayload = {
                clientName,
                clientEmail,
                clientContact,
                companyName,
                companyWebsiteUrl,
                numberOfEmployees,
                comments
            }

            const payload = {
                summary: "Request a Product Demo",
                description: `${clientName ? `Client Name: ${clientName}\n` : ''}${clientEmail ? `Client Email: ${clientEmail}\n` : ''}${clientContact ? `Client Contact: ${clientContact}\n` : ''}${comments ? `Comments: ${comments}\n` : ''}${companyName ? `Company Name: ${companyName}\n` : ''}${companyWebsiteUrl ? `Company Website URL: ${companyWebsiteUrl}\n` : ''}${numberOfEmployees ? `Number of Employees: ${numberOfEmployees}\n` : ''}`,
                reporterEmail: clientEmail,
                issueTypeId: "10003",
                projectId: "10003",
                urgencyId: "10023",
                impactId: "10003",
                requestType: "84",
            }

            const issueRes = await publicCreateIssue("regortalent", payload);
            const emailRes = await requestProductDemo("regortalent", emailPayload);
            if (issueRes && emailRes) {
                setLoading(false);
                setSuccess(true);
                setClientName("");
                setClientEmail("");
                setClientContact("");
                setCompanyName("");
                setCompanyWebsiteUrl("");
                setComments("");
                setNumberOfEmployees("");
            }
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
            setLoading(false);
        }
    }

    return (
        <>{!success ? <StyledDiv>
            <DemoHeader />
            {loading ? <div className='loaderBox'>
                <CircularProgress color="inherit" />
            </div> :
                <div className='content'>
                    <div className='left'>
                        <img src={demoPageImg} />
                    </div>
                    <div className='right'>
                        <span className='title'>Get a Free Demo</span>

                        <TextField id="outlined-basic" className='textInput' label="Name" variant="outlined" value={clientName} onChange={(e) => setClientName(e.target.value)} required />
                        <TextField id="outlined-basic" className='textInput' label="Email" variant="outlined" type='email' value={clientEmail} onChange={(e) => setClientEmail(e.target.value)} required />
                        <MuiTelInput defaultCountry="IN" className='textInput' forceCallingCode preferredCountries={['IN', 'US']} value={clientContact} onChange={handleContactChange} required />
                        <TextField id="outlined-basic" className='textInput' label="Organization Name" variant="outlined" value={companyName} onChange={(e) => setCompanyName(e.target.value)} required />
                        <TextField id="outlined-basic" className='textInput' label="Website URL" variant="outlined" value={companyWebsiteUrl} onChange={(e) => setCompanyWebsiteUrl(e.target.value)} />

                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Select number of Employees"
                            defaultValue="Select number of Employees"
                            className='textInput'
                            value={numberOfEmployees}
                            onChange={(e) => setNumberOfEmployees(e.target.value)}
                        >
                            <MenuItem value='Select number of Employees' disabled>
                                Select number of Employees
                            </MenuItem>
                            <MenuItem value='1'>
                                1
                            </MenuItem>
                            <MenuItem value='2-5'>
                                2-5
                            </MenuItem>
                            <MenuItem value='6-10'>
                                6-10
                            </MenuItem>
                            <MenuItem value='11-25'>
                                11-25
                            </MenuItem>
                            <MenuItem value='26-50'>
                                26-50
                            </MenuItem>
                            <MenuItem value='51-200'>
                                51-200
                            </MenuItem>
                            <MenuItem value='201-1000'>
                                201-1000
                            </MenuItem>
                            <MenuItem value='1001-10000'>
                                1001-10000
                            </MenuItem>
                            <MenuItem value='10001+'>
                                10001+
                            </MenuItem>
                        </TextField>
                        <TextField id="outlined-basic" className='textInput' label="Comment" variant="outlined" value={comments} onChange={(e) => setComments(e.target.value)} />


                        <button className='btn' onClick={handleSubmit}>Schedule a Demo</button>
                    </div>
                </div>}
            <Footer />
        </StyledDiv> : <ProductDemoSuccessMessage />}</>
    )
}

export default Demo


const StyledDiv = styled.div`
display: flex;
flex-direction: column;

.loaderBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    position: absolute;
    z-index: 1000;
    right: 0;
    top: 4.05rem;
    color: var(--lightOrange);
  
  }

.content {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 6rem;
    display: flex;
    flex-direction: row;
}

.left {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left > img {
    width: 75%;
}

.textInput {
    width: 80%;
}

.right {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    padding: 2rem 0rem;
}

.title {
    font-size: 1.5rem;
    font-weight: 600;
}


.btn {
    background-color: var(--lightOrange);
    color: var(--backgroundColor);
    font-size: 1.2rem;
    border-radius: 0.4rem;
    border: none;
    padding: 0.7rem 1rem;
    cursor: pointer;
  }

  @media(max-width: 800px) {

    .left {
        display: none;
    }

    .right {
        width: 100%;
    }

    form {
        width: 90%;
    }

    select {
        width: 80%;
    }

  }


`



