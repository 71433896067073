import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getJds } from '../../../../functions/api/employers/getJds';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import TagsInputClearable from '../../../../component/shared/TagsInputClearable';
import Button from '../../../../component/shared/Button';
import useFetchUsers from '../hooks/useFetchUsers';
import useFetchAgencies from '../hooks/useFetchAgencies';
import SelectInput from '../../../../component/shared/SelectInput';
import { generateCustomReport } from '../services/customReportService';
import moment from 'moment';


const StyledCardContent = styled.div`
  padding: 2rem 1%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  width: 96%;
  box-sizing: border-box;
  margin: 1rem auto;
  background-color: var(--backgroundColor);
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

 

  .topBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 1%;
  gap: 5%;

   .calendar {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  }

  .jd {
   width: 30%;
   }
  }

  .metricBox {
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: start;
   padding: 0 1% 2rem 1%;
   box-sizing: border-box;
   gap: 1rem;
  }

  .btnBox {
  display: flex;
  gap: 2rem;
  }

`;

const StyledLabel = styled.label`
  font-size: 1rem;
  font-weight: 600;
`;

const MetricGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  box-sizing: border-box;
  gap: 1rem;
`;

const MetricItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  padding-left: 0.5rem;
`;

const CustomReport = () => {
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);
    const [fromDate, setFromDate] = useState(dayjs(new Date()));
    const [toDate, setToDate] = useState(dayjs(new Date()));

    const [allJdList, setAllJdList] = useState([]);
    const [allInterviewStatus, setAllInterviewStatus] = useState(["Scheduled", "Rejected", "Completed"]);
    const [allRoundwiseInterviews, setAllRoundwiseInterviews] = useState(["1st Round", "2nd Round", "3rd Round", "4th Round", "5th Round"]);
    const [allRecruiterwiseJds, setAllRecruiterwiseJds] = useState([]);
    const [allAgencywiseJds, setAllAgencywiseJds] = useState([]);

    const [selectedJDs, setSelectedJDs] = useState([]);
    const [selectedInterviewStatus, setSelectedInterviewStatus] = useState([]);
    const [selectedRoundwiseInterview, setSelectedRoundwiseInterview] = useState([]);
    const [selectedRecruiterwiseJd, setSelectedRecruiterwiseJd] = useState('');
    const [selectedAgencywiseJd, setSelectedAgencywiseJd] = useState('');

    const [generateLoading, setGenerateLoading] = useState(false);

    const { users, userLoading } = useFetchUsers(accessToken, clientCode, 1, 10000);
    const { agencies, agencyLoading } = useFetchAgencies(accessToken, clientCode, 1, 10000);

    useEffect(() => {
        setAllRecruiterwiseJds(users);
    }, [users]);

    useEffect(() => {
        setAllAgencywiseJds(agencies);
    }, [agencies]);

    useEffect(() => {
        async function getData() {
            try {
                const res = await getJds(accessToken, clientCode, "", 1, 10000000);
                if (res && res?.data?.data) {
                    setAllJdList(res?.data?.data?.map(row => ({
                        jdId: row?.jdId,
                        title: row?.title,
                    })));
                }
            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }
        getData();
    }, []);

    const handleRoundChange = (_, newRound) => {
        setSelectedRoundwiseInterview(newRound);
    }

    const handleStatusChange = (_, newStatus) => {
        setSelectedInterviewStatus(newStatus);
    }

    const handleJdsChange = (_, newJds) => {
        const selectedJdIds = newJds?.map(jds => {
            const jd = allJdList?.find(jd => jd?.title === jds);
            return jd ? jd?.jdId : null;
        })?.filter(jdId => jdId !== null);
        setSelectedJDs(selectedJdIds);
    }


    const handleGenerateReport = async () => {
        setGenerateLoading(true);
        const formatedFromDate = moment(fromDate.format("YYYY-MM-DD")).format("YYYY-MM-DD")
        const formatedToDate = moment(toDate.format("YYYY-MM-DD")).format("YYYY-MM-DD")
        const today = moment().format("YYYY-MM-DD");

        const reportData = {
            interviewRounds: selectedRoundwiseInterview?.map((round) => round?.slice(0, 1)),
            interviewStatuses: selectedInterviewStatus?.map((status) => status?.toUpperCase()),
            jdIds: selectedJDs,
            agencyCode: selectedAgencywiseJd,
        }

        try {
            const res = await generateCustomReport(accessToken, clientCode, formatedFromDate === today ? null : formatedFromDate, formatedToDate === today ? null : formatedToDate, reportData);

            const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `CustomReport.xlsx`;

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

        } 
        catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg)
        } finally {
            setGenerateLoading(false);
        }
    };

    const handleResetReport = () => {
        setSelectedJDs([]);
        setFromDate(dayjs(new Date()));
        setToDate(dayjs(new Date()));
        setSelectedInterviewStatus([]);
        setSelectedRoundwiseInterview([]);
        setSelectedRecruiterwiseJd('');
        setSelectedAgencywiseJd('');
    }


    return (
        <StyledCardContent>
            <div className="topBox">
                <span className="jd">
                    <TagsInputClearable
                        options={allJdList ? allJdList?.filter(jd => !selectedJDs?.includes(jd?.jdId))?.map(jd => jd?.title) : [{ jdId: "Dummy Jd", title: "Dummy Jd" }]}
                        value={allJdList && allJdList?.filter(jd => selectedJDs?.includes(jd?.jdId))?.map(jd => jd?.title)}
                        onChange={handleJdsChange}
                        label="Select JDs*"
                    />
                </span>
                <span className='calendar'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker label="From-Date" value={fromDate} onChange={(newDate) => setFromDate(dayjs(newDate))} />
                        </DemoContainer>
                    </LocalizationProvider>
                    -
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker label="To-Date" value={toDate} onChange={(newDate) => setToDate(dayjs(newDate))} />
                        </DemoContainer>
                    </LocalizationProvider>
                </span>
            </div>

            <div className="metricBox">
                <StyledLabel>Select Report Metrics</StyledLabel>
                <MetricGrid>
                    <TagsInputClearable
                        options={allInterviewStatus}
                        value={selectedInterviewStatus}
                        onChange={handleStatusChange}
                        label="Select Interview Status*"
                    />
                    <TagsInputClearable
                        options={allRoundwiseInterviews}
                        value={selectedRoundwiseInterview}
                        onChange={handleRoundChange}
                        label="Select Interview Round*"
                    />
                    <SelectInput
                        label="Select Recruiter"
                        name="selectedRecruiterwiseJd"
                        value={selectedRecruiterwiseJd}
                        onChange={(e) => setSelectedRecruiterwiseJd(e.target.value)}
                        options={allRecruiterwiseJds?.map(user => ({
                            value: user?.id,
                            label: user?.name
                        }))}
                    />
                    <SelectInput
                        label="Select Agency"
                        name="selectedAgencywiseJd"
                        value={selectedAgencywiseJd}
                        onChange={(e) => setSelectedAgencywiseJd(e.target.value)}
                        options={allAgencywiseJds?.map(agency => ({
                            value: agency?.id,
                            label: agency?.agencyName
                        }))}
                    />
                </MetricGrid>
            </div>

            <div className='btnBox'>
                <Button text="Reset Selection" func={handleResetReport} disabled={false} />
                <Button text={generateLoading ? `Generating Report...` : `Generate Report`} func={handleGenerateReport} disabled={generateLoading} />
            </div>
        </StyledCardContent>
    );
};

export default CustomReport;
