import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router'
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import ReactQuill from 'react-quill'
import { createIssue } from '../../../functions/api/AtlassianAPIs/createIssue'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import JiraHeader from './JiraHeader'
import { addAttachments } from '../../../functions/api/AtlassianAPIs/addAttachments'
import { getRequestGroups } from '../../../utils/JiraHelpCenter'


const JiraCreateIssuePage = () => {
    const requestTypes = useSelector(state => state.atlassian?.portalRequestTypes);
    const accessToken = useSelector(state => state.auth.userData?.accessToken);
    const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);
    const navigate = useNavigate();
    const { groupId } = useParams();
    const {portalId} = useParams();
    const [selectedGroup, setSelectedGroup] = useState(getRequestGroups(portalId).find(group => group.id === parseInt(groupId))?.name);
    const [selectedGroupId, setSelectedGroupId] = useState(groupId);
    const { requestId } = useParams();
    const [selectedRequest, setSelectedRequest] = useState('');
    const [selectedRequestId, setSelectedRequestId] = useState(requestId);
    const [sortedRequestTypesList, setSortedRequestTypesList] = useState(null);
    const [summary, setSummary] = useState('');
    const [description, setDescription] = useState('');
    const [urgency, setUrgency] = useState('10023');
    const [impact, setImpact] = useState('10003');
    const [issueType, setIssueType] = useState(requestTypes?.find(request => request.requestId === requestId.toString()).requestIssueTypeId);
    const [project, setProject] = useState(() => {
        switch (parseInt(portalId)) {
            case 1:
                return '10000';
            case 4:
                return '10003';
            case 5:
                return '10004';
        }
    });    
    const authenticatedUserEmail = useSelector(state => state.auth.userData?.user?.email);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);



    const navigateToLandingPage = () => {
        navigate(`/servicedesk/customer/portal/${portalId}`)
    }

    const navigateToIssueDetails = (issueKey) => {
        navigate(`/servicedesk/customer/portal/${portalId}/${issueKey}`)
    }

    const navigateToGroups = () => {
    }

    const handleGroupClickNavigation = (groupId) => {
        navigate(`/servicedesk/customer/portal/${portalId}/group/${groupId}`)
    }

    useEffect(() => {
        const filteredRequestTypes = requestTypes?.filter(requestType => requestType?.requestGroupIds?.some(id => id === selectedGroupId.toString()));

        const sortedRequestTypes = filteredRequestTypes?.sort((a, b) => {
            const groupIdA = a.requestGroupIds[0];
            const groupIdB = b.requestGroupIds[0];

            if (groupIdA === groupIdB) {
                return parseInt(a.id) - parseInt(b.id);
            } else {
                return parseInt(groupIdA) - parseInt(groupIdB);
            }
        });

        setSortedRequestTypesList(sortedRequestTypes);

        setSelectedRequestTypeById(requestId);
    }, [])

    const setSelectedRequestTypeById = (id) => {
        const foundRequestType = requestTypes?.find(requestType => requestType.requestId === id);
        if (foundRequestType) {
            setSelectedRequest(foundRequestType);
        }
    };

    const handleGroupChange = (e) => {
        const groupName = e.target.value;
        setSelectedGroup(groupName);
        
        const groupId = getRequestGroups(portalId).find(group => group.name === groupName)?.id;
        setSelectedGroupId(groupId);
    }

    const handleRequestChange = (e) => {
        setSelectedRequest(e.target.value);
        setSelectedRequestId(e.target.value.id);
        setIssueType(requestTypes?.find(request => request.requestId === e.target.value.requestId.toString()).requestIssueTypeId)
    }

    const handleCancle = () => {
        navigate(`/servicedesk/customer/portal/${portalId}`)
    }


    const handleSend = async () => {
        if (summary === null || summary.length === 0) {
            toast.warning("Please fill summary", 5000);
            return;
        }
        if (description === null || description?.length === 0) {
            toast.warning("Please fill description", 5000);
            return;
        }

        setLoading(true);

        try {
            const payload = {
                summary: summary,
                description: description,
                reporterEmail: authenticatedUserEmail,
                issueTypeId: issueType,
                projectId: project,
                urgencyId: urgency,
                impactId: impact,
                requestType: selectedRequestId,
            }

            const res = await createIssue(accessToken, clientCode, payload);
            if (res) {
                if (file) {
                    const attachmentRes = await handleAddAttachment(res?.data?.body);
                    if (attachmentRes) {
                        setLoading(false);
                        toast.success("Issue added successfully", 5000);
                        navigateToIssueDetails(res?.data?.body)
                    }
                } else {
                    setLoading(false);
                    toast.success("Issue added successfully", 5000);
                    navigateToIssueDetails(res?.data?.body)
                }
            }
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
            setLoading(false);
        }
    }


    const handleAddAttachment = async (issueKey) => {
        setLoading(true)
        try {
            const formData = new FormData();
            formData.append('file', file[0]);
            const res = await addAttachments(accessToken, formData, issueKey);
            return res;
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
            setLoading(false);
        }
    }

    const handleFileChange = (event) => {
        const selectedFiles = event.target.files;
        setFile(selectedFiles);
    };



    return (
        <MainBox>
            <JiraHeader />
            <div className='topBox'>
                <div className='searchBox'>

                </div>
            </div>
            {loading ? <div className='loaderBox'>
                <CircularProgress color="inherit" />
            </div> : <div className='bottomBox'>
                <div className='contentBox'>
                    <span className='navigationBox'>
                        <span onClick={navigateToLandingPage}>Help Center </span> / <span onClick={navigateToGroups}> RegorTalent</span>
                    </span>
                    <span className='boldBigText'>RegorTalent</span>
                    <span className='text'>Welcome! You can raise a request for RegorTalent using the options provided.</span>
                    <span className='boldText'>Contact us about</span>
                    <FormControl sx={{ backgroundColor: '#ffffff' }} fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedGroup}
                            onChange={(e) => handleGroupChange(e)}
                        >
                            {
                                getRequestGroups(portalId)?.map((request, i) => (
                                    <MenuItem value={request?.name} onClick={() => handleGroupClickNavigation(request?.id)}>{request?.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <span className='boldText1'>What can we help you with?</span>
                    <FormControl sx={{ backgroundColor: '#ffffff' }} fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedRequest}
                            onChange={(e) => handleRequestChange(e)}
                        >
                            {
                                sortedRequestTypesList?.map((request, i) => (
                                    <MenuItem value={request}>{request?.requestName}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <label>
                        <span>Summary</span>
                        <input className='input' value={summary} onChange={(e) => setSummary(e.target.value)} />
                    </label>

                    <label>
                        <span>Description</span>
                        <ReactQuill className='textEditor' value={description} onChange={setDescription} />
                    </label>

                    <label>
                        <span>How urgently does this need to be fixed?</span>
                        <select value={urgency} onChange={(e) => setUrgency(e.target.value)}>
                            <option value='10020'>Critical</option>
                            <option value='10021'>High</option>
                            <option value='10022'>Medium</option>
                            <option value='10023'>Low</option>
                        </select>
                    </label>

                    <label>
                        <span>How big of an impact is the problem to you or the organization?</span>
                        <select value={impact} onChange={(e) => setImpact(e.target.value)}>
                            <option value='10000'>Extensive / Widespread</option>
                            <option value='10001'>Significant / Large</option>
                            <option value='10002'>Moderate / Limited</option>
                            <option value='10003'>Minor / Localized</option>
                        </select>
                    </label>

                    <label>
                        <span>Attachment</span>
                        <div className='attachmentBox'>
                            <input
                                id='input'
                                type="file"
                                accept='*'
                                onChange={handleFileChange}
                                className='fileInput'
                                multiple
                            />
                            <label className='button' for='input'>Browse</label>
                            <span>Drag and drop files, or browse</span>

                            <span className='text'>{file && file[0].name}</span>
                        </div>
                    </label>

                    <div className='btnBox'>
                        <button className='sendBtn' onClick={handleSend}>Send</button>
                        <button className='cancelBtn' onClick={handleCancle}>Cancel</button>
                    </div>
                </div>
            </div>}
        </MainBox>
    )
}

export default JiraCreateIssuePage

const MainBox = styled.div`

display: flex;
flex-direction: column;
align-items: center;
gap: 2rem;
width: 100%;


.loaderBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    position: absolute;
    z-index: 100000;
    right: 0;
    top: 4rem;
    color: var(--lightOrange);
  
  }

.topBox {
    background: rgb(255,255,226);
background: linear-gradient(90deg, rgba(255,255,226,1) 0%, rgba(24,226,211,1) 50%, rgba(24,226,211,1) 100%);
    color: var(--white);
    width: 100%;
    height: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .searchBox {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;

        .title {
            font-size: 1.5rem;
            font-weight: 600;
        }
    }
}


.bottomBox {
    background-color: var(--white);
    color: var(--color);
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10rem;
    top: 3.5rem;
    position: absolute;
    padding: 2rem 0 7.5rem 0;
    border: 0.05rem solid #CCD3D8;
    border-radius: 0.5rem;


    .contentBox {
        width: 90%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .navigationBox {
            

            span {
                font-size: 0.9rem;
                font-weight: 700;
                color: var(--lightOrange);
                cursor: pointer;
            }

            span:hover {
                text-decoration: underline;
            }
        }
        

        .text {
            font-size: 0.9rem;
            font-weight: 500;
            font-family: var(--font);
        }

        .boldBigText {
            font-size: 1.5rem;
            font-weight: 600;
            font-family: var(--font);
        }

        .boldText {
            font-size: 0.9rem;
            font-weight: 600;
            font-family: var(--font);
            color: grey;
            padding:0.25rem 0;
        }

        .boldText1 {
            font-size: 0.9rem;
            font-weight: 600;
            font-family: var(--font);
            color: var(--color);
            padding: 0.25rem 0;
        }

        .requestGroupBox {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }
}


label {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem 0;

    span {
        font-size: 0.9rem;
        font-weight: 600;
        font-family: var(--font);
    }

    .input {
        padding: 0.75rem 1rem;
        font-size: 0.9rem;
        font-weight: 400;
        outline-color: #1870c8;
        border: 0.05rem solid #C4C4C4;
    }

    .textEditor {

    }

    select {
        padding: 0.75rem 1rem;
        font-size: 0.9rem;
        font-weight: 400;
        outline-color: #1870c8;
        border-color: #C4C4C4;

        option {
            display: block;
            padding: 0.5rem;
            font-size: 0.9rem;
            line-height: 1rem;
        }
    }

    .attachmentBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        border: 0.075rem solid lightgrey;
        height: 10rem;
        border-radius: 0.5rem;

        .fileInput {
            display: none;
        }

        .button {
            border: 0.05rem solid lightgrey;
            border-radius: 0.25rem;
            padding: 0.5rem 0.8rem;
            font-size: 0.9rem;
            font-weight: 600;
            font-family: var(--font);
            color: var(--color);
            background-color: var(--white);
        }
    }
}


.btnBox {
    display: flex;
    gap: 1rem;
    padding-top: 1rem;


    .sendBtn {
        background-color: var(--lightOrange);
        border: none;
        border-radius: 0.25rem;
        padding: 0.5rem 1rem;
        color: var(--white);
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
    }

    .cancelBtn {
        background-color: var(--white);
        border: 0.1rem solid lightgrey;
        border-radius: 0.25rem;
        padding: 0.5rem 1rem;
        color: var(--color);
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
    }
}

`