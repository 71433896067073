import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const TimeSlot = ({ selectedTimeSlot, setSelectedTimeSlot, selectedDate, isReschedule }) => {
    const [timeMode, setTimeMode] = useState('');
    const [showPicker, setShowPicker] = useState(false);
    const dropdownRef = useRef(null);
    const clientCode = useSelector(
        (state) => state.auth.userData.user.clientCode
    );

    // Get current time and default time
    const now = new Date();
    const defaultTime = (isReschedule || (clientCode && clientCode === "CHIRAG03")) ? new Date(now.getTime()) : new Date(now.getTime() + 60 * 60 * 1000);
    const limitTime = new Date(now.getTime() + 61 * 60 * 1000);

    // Initialize selectedTimeSlot with default time
    useEffect(() => {
        setSelectedTimeSlot({
            $H: defaultTime.getHours(),
            $m: defaultTime.getMinutes(),
        });
        setTimeMode(defaultTime.getHours() >= 12 ? 'PM' : 'AM');
    }, []);

    const handleClick = () => {
        setShowPicker(!showPicker);
    };

    const handleSelect = (value, type) => {
        // Adjust hour if PM is selected
        if (type === 'ampm' && value === 'PM') {
            setSelectedTimeSlot({
                ...selectedTimeSlot,
                $H: (selectedTimeSlot.$H % 12) + 12,
            });
            setTimeMode(value);
        } else if (type === 'ampm' && value === 'AM') {
            setSelectedTimeSlot({
                ...selectedTimeSlot,
                $H: selectedTimeSlot.$H % 12,
            });
            setTimeMode(value);
        } else {
            setSelectedTimeSlot({
                ...selectedTimeSlot,
                [type]: value,
            });
        }
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setShowPicker(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    function formatTime(selectedTimeSlot) {
        const hour = selectedTimeSlot.$H;
        const minute = selectedTimeSlot.$m;

        let formattedHour;
        if (hour > 12) {
            formattedHour =
                hour % 12 < 10 ? '0' + (hour - 12) : (hour % 12 >= 10 ? hour - 12 : '00');
        } else {
            formattedHour = hour > 9 && hour <= 12 ? hour : '0' + hour;
        }

        const formattedMinute = minute < 10 ? '0' + minute : minute;
        const period = hour > 11 ? 'PM' : 'AM';

        return `${formattedHour}:${formattedMinute} ${period}`;
    }

    function formatDateToYYYYMMDD(dateString) {
        const date = new Date(dateString);
        
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    }

    const isDisabled = (hour, minute) => {
        const selectedDateTime = new Date();
        selectedDateTime.setHours(hour, minute, 0, 0);

        if (selectedDate !== formatDateToYYYYMMDD(now)) {
            return false;
        }

        return (isReschedule || (clientCode && clientCode === "CHIRAG03")) ? selectedDateTime < now :  selectedDateTime <= limitTime;
    };

    return (
        <MainBox ref={dropdownRef}>
            <input value={formatTime(selectedTimeSlot)} type='text' readOnly className='timeSlot' onClick={handleClick} />

            {showPicker && (
                <div className='dropDown'>
                    <div className='hrBox'>
                        {[...Array(12)].map((_, i) => {
                            const hour = i + 1;
                            const isDisabledHour = isDisabled(hour + (timeMode === 'PM' ? 12 : 0), selectedTimeSlot.$m);
                            return (
                                <HrSpan
                                    key={i}
                                    value={hour}
                                    isSelected={selectedTimeSlot.$H % 12 === hour || (selectedTimeSlot.$H % 12 === 0 && hour === 12)}
                                    onClick={() => !isDisabledHour && handleSelect(hour, '$H')}
                                    disabled={isDisabledHour}
                                >
                                    {hour}
                                </HrSpan>
                            );
                        })}
                    </div>

                    <div className='minBox'>
                        {[...Array(12)].map((_, i) => {
                            const minute = i * 5;
                            const isDisabledMinute = isDisabled(selectedTimeSlot.$H, minute);
                            return (
                                <MinSpan
                                    key={i}
                                    value={minute}
                                    isSelected={selectedTimeSlot.$m === minute}
                                    onClick={() => !isDisabledMinute && handleSelect(minute, '$m')}
                                    disabled={isDisabledMinute}
                                >
                                    {minute < 10 ? `0${minute}` : minute}
                                </MinSpan>
                            );
                        })}
                    </div>

                    <div className='ampmBox'>
                        <AmPmSpan value="AM" isSelected={timeMode === 'AM'} onClick={(e) => handleSelect('AM', 'ampm')}>AM</AmPmSpan>
                        <AmPmSpan value="PM" isSelected={timeMode === 'PM'} onClick={(e) => handleSelect('PM', 'ampm')}>PM</AmPmSpan>
                    </div>
                </div>
            )}
        </MainBox>
    )
}

export default TimeSlot;

const MainBox = styled.div`
width: 100%;

.timeSlot {
    border: 0.08rem solid lightgrey;
    padding: 1rem 1rem;
    outline-color: lightgrey;
    outline-width: 0.05rem;
    border-radius: 0.3rem;
    font-size: 0.9rem;
    font-weight: 600;
    width: calc(100% - 3rem);
    font-family: Quicksand, sans-serif;
}

.dropDown {
    display: flex;
    flex-direction: column;
    width: 17rem;
    background-color: var(--white);
    padding: 1rem 1rem;
    box-sizing: border-box;
    gap: 1rem;
    border: 0.1rem solid lightgrey;
    border-radius: 0.5rem;
    position: absolute;
    margin-top: 1rem;

    .hrBox {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;
        column-gap: 1%;
        width: 100%;
        border-bottom: 0.1rem solid lightgrey;
        padding-bottom: 1rem;
    }

    .minBox {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;
        column-gap: 1%;
        width: 100%;
        border-bottom: 0.1rem solid lightgrey;
        padding-bottom: 1rem;
    }

    .ampmBox {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
    }
}
`

const HrSpan = styled.span`
width: 8%;
display: flex;
justify-content: center;
align-items: center;
background-color: ${(props) => (props.isSelected ? 'var(--lightOrange)' : props.disabled ? 'var(--lightGrey)' : 'transparent')};
color: ${(props) => (props.isSelected ? 'var(--white)' : props.disabled ? 'var(--darkGrey)' : 'var(--color)')};
cursor: ${(props) => (props.isSelected ? 'auto' : props?.disabled ? 'not-allowed' : 'pointer')};
font-size: 0.9rem;
font-weight: 500;
padding: 0.5rem;
border-radius: 0.3rem;
opacity: ${(props) => (props.disabled ? 0.5 : 1)};
pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`

const MinSpan = styled.span`
width: 8%;
display: flex;
justify-content: center;
align-items: center;
background-color: ${(props) => (props.isSelected ? 'var(--lightOrange)' : props.disabled ? 'var(--lightGrey)' : 'transparent')};
color: ${(props) => (props.isSelected ? 'var(--white)' : props.disabled ? 'var(--darkGrey)' : 'var(--color)')};
cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
font-size: 0.9rem;
font-weight: 500;
padding: 0.5rem;
border-radius: 0.3rem;
opacity: ${(props) => (props.disabled ? 0.5 : 1)};
pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`

const AmPmSpan = styled.span`
background-color: ${(props) => (props.isSelected ? 'var(--lightOrange)' : 'transparent')};
color: ${(props) => (props.isSelected ? 'var(--white)' : 'var(--color)')};
cursor: pointer;
font-size: 0.9rem;
font-weight: 500;
padding: 0.5rem;
border-radius: 0.3rem;
`
